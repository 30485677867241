import { defineNode, SystemService } from '@nord-beaver/core/ecs';
import { MotionDetectorComponent } from '@nord-beaver/core/ecs/components/motionDetectorComponent';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';

class MoveSceneObjectNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    motionDetector: MotionDetectorComponent,
}) { }

export class MoveSystem extends SystemService {
    init() {
        this.setupNodeList({
            node: MoveSceneObjectNode,
            update: this.updateTransform,
        });
    }

    private updateTransform(node: MoveSceneObjectNode) {
        const { motionDetector, sceneObject } = node;
        const { object } = sceneObject;
        if (!object || (!motionDetector.moving && !motionDetector.forceMoving)) {
            return;
        }

        const { position, scale, rotation } = node.transform;
        object.x = position.x;
        object.y = position.y;
        object.rotation = rotation;

        if (scale.x !== object.scaleX || scale.y !== object.scaleY) {
            object.setScale(scale.x, scale.y);
        }

        motionDetector.forceMoving = false;
    }
}