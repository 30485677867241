import { defineNode } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { SpriteComponent } from '@nord-beaver/core/ecs/components/spriteComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';

export  class ReplicantNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    replicant: ReplicantComponent,
    sprite: SpriteComponent,
    runtimeId: RuntimeIdComponent,
}) { }