import { defineNode, SystemService } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { TargetViewSizeComponent } from 'game/ecs/components/targetViewSizeComponent';
import { getViewSize } from 'game/utils/viewSize';

class TargetViewSizeNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    targetViewSize: TargetViewSizeComponent,
}) { }

const logger = mainLogger.getLogger('TargetViewSize');

export class TargetViewSizeSystem extends SystemService {
    init() {
        this.setupNodeList({
            node: TargetViewSizeNode,
            add: this.addTargetViewSize,
            update: this.updateTargetViewSize,
        });
    }

    private addTargetViewSize(node: TargetViewSizeNode) {
        this.scaleView(node);
    }

    private updateTargetViewSize(node: TargetViewSizeNode) {
        const { targetViewSize } = node;
        if (!targetViewSize.isDirty) {
            return;
        }

        this.scaleView(node);
    }

    private scaleView(node: TargetViewSizeNode) {
        const { targetViewSize, transform, entity } = node;

        const viewSize = getViewSize(entity);
        if (!viewSize) {
            logger.error('tile entity without view bounds', entity);
            return;
        }

        targetViewSize.baseSize = viewSize;

        const viewScale = {
            x: targetViewSize.size.width / viewSize.width,
            y: targetViewSize.size.height / viewSize.height,
        };

        transform.scale.set(viewScale.x, viewScale.y);
    }
}