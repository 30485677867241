<script setup lang="ts">
import { services } from '@nord-beaver/html-ui';
import { windowManager } from 'game/ui/services/windowManager';
import { useResourceStore } from 'game/ui/store/resource';
import { useWindowsState } from 'game/ui/store/windowsState';
import { provide } from 'vue';

const { WindowManagerView } = services.WindowManager;

provide('WINDOW_MANAGER', windowManager);

useResourceStore();
useWindowsState();
</script>

<template>
    <div class="root">
        <WindowManagerView class="root__windows" injection-key="WINDOW_MANAGER" />

        <button id="gameFocus" class="root__focus-pixel" />
    </div>
</template>

<style scoped lang="scss">
@import '/node_modules/reset-css/reset.css';
@import '/node_modules/normalize.css/normalize.css';

.root {
    position: relative;

    width: 100%;
    height: 100%;

    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    &__windows {
        position: relative;

        width: 100%;
        height: 100%;
    }

    * {
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;

        user-select: none;
    }

    &__focus-pixel {
        position: absolute;
        left: -100vw;

        width: 1px;
        height: 1px;

        opacity: 0;
    }
}
</style>
