export type MatchDataHandlersDesc<
    OpCodeRequestType extends number = number,
    OpCodeResponseType extends number = number,
> = {
    response: {
        [key in OpCodeResponseType]?: (data: object) => object
    };
    request: {
        [key in OpCodeRequestType]?: (message: object) => string
    };
};

export class MatchDataHandlers<
    OpCodeRequestType extends number = number,
    OpCodeResponseType extends number = number,
> {
    private readonly decodeEmpty = () => ({});
    private readonly encodeEmpty = () => '';
    private readonly decoderMap: Map<OpCodeResponseType, (data: object) => object> = new Map();
    private readonly encoderMap: Map<OpCodeRequestType, (matchData: object) => string> = new Map();

    constructor(handlersDesc: MatchDataHandlersDesc<OpCodeRequestType, OpCodeResponseType>) {
        for (const opCodeKey in handlersDesc['request']) {
            const opCode = Number(opCodeKey) as OpCodeRequestType;
            const handler = handlersDesc['request'][opCode];

            this.encoderMap.set(opCode, handler ?? this.encodeEmpty);
        }

        for (const opCodeKey in handlersDesc['response']) {
            const opCode = Number(opCodeKey) as OpCodeResponseType;
            const handler = handlersDesc['response'][opCode];

            this.decoderMap.set(opCode, handler ?? this.decodeEmpty);
        }
    }

    decode<T>(opcode: OpCodeResponseType, data: object): T {
        const decoder = this.decoderMap.get(opcode) ?? this.decodeEmpty;
        if (!decoder) {
            throw new Error(`No decoder found for opcode: ${opcode}`);
        }
        return decoder(data) as T;
    }

    encode<T extends object>(opcode: OpCodeRequestType, matchData: T): string {
        const encoder = this.encoderMap.get(opcode) ?? this.encodeEmpty;
        if (!encoder) {
            throw new Error(`No encoder found for opcode: ${opcode}`);
        }
        return encoder(matchData);
    }
}
