import { NotificationHandlers, type NotificationHandlersDesc } from 'game/services/nakama/notification/notificationHandlers';
import { type NotificationSchema, type NotificationWithOptionalContent } from 'game/services/nakama/notification/notificationNakamaService';

export class NotificationNakamaComponent {
    /** Map of received notifications at that frame */
    readonly receivedNotifications: Map<number, NotificationWithOptionalContent[]> = new Map();

    readonly notificationHandlers: NotificationHandlers;

    constructor(notificationHandlers: NotificationHandlersDesc) {
        this.notificationHandlers = new NotificationHandlers(notificationHandlers);
    }

    getNotifications<Code extends number, NotificationSchemaType extends NotificationSchema<Code>>(code: Code) {
        return (this.receivedNotifications.get(code) ?? []) as NotificationWithOptionalContent<NotificationSchemaType[Code]>[];
    }
}