import { type EnvConfig } from 'game/types/envConfigService/config';
import { type IFontFace } from 'game/utils/fontLoader';

declare const __ENV_CONFIG: EnvConfig;

export const enum Layers {
    /**
     * use for all game objects
     */
    Game = 0,
    /**
     * ingame ui layer
     */
    View,
    /**
     * use to place wall to prevent clicks on game objects, when nedded
     */
    InputWall,
}

export const enum CursorState {
    Normal,
}
export const ENV_CONFIG = __ENV_CONFIG;

export const nakamaEntityId = 'nakama';

export const screenDefaultBounds = {
    width: 1290,
    height: 2796,
} as const;
export const screenDefaultBoundsMax = {
    width: screenDefaultBounds.width,
    height: screenDefaultBounds.height,
} as const;
export const canvasOffsetY = 323 as const;

export const fonts: Readonly<IFontFace[]> = [
    // { fileName: 'Inter-Black', filePath: 'inter' },
    // { fileName: 'Inter-ExtraLight', filePath: 'inter' },
    // { fileName: 'Inter-Regular', filePath: 'inter' },
    { fileName: 'Inter-Bold', filePath: 'inter' },
    // { fileName: 'Inter-Light', filePath: 'inter' },
    // { fileName: 'Inter-SemiBold', filePath: 'inter' },
    // { fileName: 'Inter-ExtraBold', filePath: 'inter' },
    // { fileName: 'Inter-Medium', filePath: 'inter' },
    // { fileName: 'Inter-Thin', filePath: 'inter' },
    // { fileName: 'Poppins-Black', filePath: 'poppins' },
    // { fileName: 'Poppins-Light', filePath: 'poppins' },
    // { fileName: 'Poppins-BlackItalic', filePath: 'poppins' },
    // { fileName: 'Poppins-LightItalic', filePath: 'poppins' },
    { fileName: 'Poppins-Bold', name: 'Poppins', filePath: 'poppins', descriptors: { weight: '700' } },
    { fileName: 'Poppins-Medium', name: 'Poppins', filePath: 'poppins', descriptors: { weight: '500' } },
    // { fileName: 'Poppins-BoldItalic', filePath: 'poppins' },
    // { fileName: 'Poppins-MediumItalic', filePath: 'poppins' },
    { fileName: 'Poppins-ExtraBold', name: 'Poppins', filePath: 'poppins', descriptors: { weight: '800' } },
    { fileName: 'Poppins-Regular', name: 'Poppins', filePath: 'poppins', descriptors: { weight: '400' } },
    // { fileName: 'Poppins-ExtraBoldItalic', filePath: 'poppins' },
    // { fileName: 'Poppins-SemiBold', filePath: 'poppins' },
    // { fileName: 'Poppins-ExtraLight', filePath: 'poppins' },
    // { fileName: 'Poppins-SemiBoldItalic', filePath: 'poppins' },
    // { fileName: 'Poppins-ExtraLightItalic', filePath: 'poppins' },
    // { fileName: 'Poppins-Thin', filePath: 'poppins' },
    // { fileName: 'Poppins-Italic', filePath: 'poppins' },
    // { fileName: 'Poppins-ThinItalic', filePath: 'poppins' },
    { fileName: 'Bangers-Regular', name: 'Bangers', filePath: 'bangers' },
] as const;