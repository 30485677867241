import type { Size } from '@nord-beaver/html-ui/utils/primitives';

import { type TargetViewSizeDesc } from 'game/types/entityDescs/targetViewSize';

export class TargetViewSizeComponent {
    baseSize?: Size;
    isDirty = false;

    get size(): Size {
        return this.desc;
    }

    constructor(readonly desc: TargetViewSizeDesc) {}
}