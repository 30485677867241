import { createUI as createCoreUI } from '@nord-beaver/html-ui';
import { config } from 'game/ui/config/app';
import { DEPENDENCY_CONTAINER_INJECTION_KEY } from 'game/ui/constants/provide';
import Root from 'game/ui/Root.vue';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { type App } from 'vue';

export const createUI = (dependencyContainer: DependencyContainer) => {
    const app = createCoreUI(Root, config);

    app.provide(DEPENDENCY_CONTAINER_INJECTION_KEY, dependencyContainer);

    app.mount('#ui');

    return app;
};

export const destroyUI = (app: App<Element>) => {
    app.unmount();
};
