type Data = { [key: string]: unknown };

export interface RpcHandlersDesc {
    [key: string]: {
        decoder?: (data: Data) => object;
        encoder?: (message: object) => Data;
    };
}

export class RpcHandlers<RpcType extends string = string> {
    private readonly decodeEmpty = () => ({});
    private readonly encodeEmpty = () => ({});
    private readonly decoderMap: Map<RpcType, (data: Data) => object> = new Map();
    private readonly encoderMap: Map<RpcType, (message: object, options?: protobuf.IConversionOptions) => Data> = new Map();

    constructor(rpcHandlersDesc: RpcHandlersDesc) {
        for (const rpcType in rpcHandlersDesc) {
            const rpcTypeKey = rpcType as RpcType;
            const handler = rpcHandlersDesc[rpcTypeKey]!;

            this.registerHandler(
                rpcTypeKey,
                handler.decoder ?? this.decodeEmpty,
                handler.encoder ?? this.encodeEmpty,
            );
        }
    }

    registerHandler(
        rpcType: RpcType,
        decoder: (data: Data) => object,
        encoder: (message: object, options?: protobuf.IConversionOptions) => Data,
    ): void {
        this.decoderMap.set(rpcType, decoder);
        this.encoderMap.set(rpcType, encoder);
    }

    decode<T>(rpcType: RpcType, data: Data): T {
        const decoder = this.decoderMap.get(rpcType) ?? this.decodeEmpty;
        if (!decoder) {
            throw new Error(`Decoder not found for RPC type ${rpcType}`);
        }
        return decoder(data) as T;
    }

    encode<T extends object>(rpcType: RpcType, message: T, options?: protobuf.IConversionOptions): Data {
        const encoder = this.encoderMap.get(rpcType) ?? this.encodeEmpty;
        if (!encoder) {
            throw new Error(`Encoder not found for RPC type ${rpcType}`);
        }
        return encoder(message, options);
    }
}
