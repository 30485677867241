<script setup lang="ts">
import Panel from 'game/ui/commonInstances/Panel.vue';
</script>

<template>
    <Panel padding-left-right="70px" padding-top-bottom="58px">
        <section class="tutorial-block section-text">
            <p>
                Create your own clan and invite participants or join an existing clan.
            </p>
        </section>
    </Panel>
</template>

<style scoped lang="scss">
.tutorial-block {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: size(124);
}
</style>
