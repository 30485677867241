import { type windowManager as windowManagerType } from 'game/ui/services/windowManager';
import { inject } from 'vue';

export const useWindowManager = () => {
    const windowManager = inject<typeof windowManagerType>('WINDOW_MANAGER'); // TODO: fix type

    if (!windowManager) {
        throw new Error('No window manager found');
    }

    return windowManager;
};