<script setup lang="ts">
import { commonSvgPackName } from 'game/ui/constants/assets';
import { getAssetUrl } from 'game/ui/utils/assets';
import { computed } from 'vue';

const props = withDefaults(
    defineProps<{
        name: string;
        pack?: string;
    }>(),
    {
        pack: commonSvgPackName,
    },
);
const href = computed(() => {
    const url = getAssetUrl(`icons/${props.pack}.svg`);
    return `${url}#${props.name}`;
});
</script>

<template>
    <div class="svg-icon">
        <svg>
            <use
                :xlink:href="href"
                :href="href"
            />
        </svg>
    </div>
</template>

<style lang="scss" scoped>
.svg-icon svg {
    display: block;

    width: 100%;
    height: 100%;
}
</style>