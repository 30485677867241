<script setup lang="ts">
import Container from 'game/ui/common/Container.vue';
import ScrollPan from 'game/ui/commonInstances/ScrollPan.vue';
import NavigationMenu from 'game/ui/components/lobby/NavigationMenu.vue';
import ResourcesCounter from 'game/ui/components/lobby/ResourcesCounter.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { useWindowManager } from 'game/ui/composables/windowManager';
import { type ContainerIds } from 'game/ui/config/containers';
import { navigationMenuButtons } from 'game/ui/constants/nav';
import { useResourceStore } from 'game/ui/store/resource';
import { ref } from 'vue';

defineProps<{
    containerId: ContainerIds;
    hideHeader?: boolean;
    hideNavigationMenu?: boolean;
}>();

const resourcesStore = useResourceStore();
const eventService = useEventService();
const isMenuVisible = useModel(['lobby', 'isMenuVisible']);

const pageScrollPan = ref<InstanceType<typeof ScrollPan> | null>(null);
const selectedTab = useModel(['lobby', 'selectedTab']);
const windowManager = useWindowManager();

const onFriendsClick = () => {
    selectedTab.value = 'friends';
    windowManager.setState('friends');

    scrollToTop();
};
const onClansClick = () => {
    selectedTab.value = 'clans';
    windowManager.setState('clans');

    scrollToTop();
};

const onHomeClick = () => {
    selectedTab.value = 'home';
    windowManager.setState('vault');

    scrollToTop();
};

const onTasksClick = () => {
    selectedTab.value = 'tasks';
    windowManager.setState('tasks');

    scrollToTop();
};

const onPlayClick = () => {
    eventService.lobby.dispatch('startMatchmaking');

    scrollToTop();
};

const scrollToTop = () => {
    pageScrollPan.value?.setScroll(0);
};

defineExpose({
    scrollToTop,
});
</script>

<template>
    <Container class="default-layout" :container-id="containerId">
        <ScrollPan ref="pageScrollPan" class="default-layout__content">
            <ResourcesCounter v-if="!hideHeader" class="default-layout__resources-counter" :resources="resourcesStore.currencies" />

            <div class="default-layout__body">
                <slot />
            </div>
        </ScrollPan>

        <NavigationMenu
            v-if="isMenuVisible && !hideNavigationMenu"
            class="default-layout__navigation-menu"
            :buttons="navigationMenuButtons"
            :selected-button-type="selectedTab"
            @vault="onHomeClick"
            @friends="onFriendsClick"
            @clans="onClansClick"
            @play="onPlayClick"
            @tasks="onTasksClick"
        />
    </Container>
</template>

<style scoped lang="scss">
.default-layout {
    position: absolute;
    top: 0;
    left: 50%;

    display: grid;

    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;

    width: 100%;
    max-width: size(1290px);
    height: 100%;

    transform: translateX(-50%);

    &__content {
        position: relative;
        z-index: 2;

        display: flex;

        flex-direction: column;

        grid-row: 1;
        grid-column: 1;

        width: 100%;
        height: 100%;
        padding: 0 size(90px);
    }

    &__resources-counter {
        padding-top: size(125px);
        padding-bottom: size(85px);
    }

    &__navigation-menu {
        z-index: 10;

        grid-row: 2;
        grid-column: 1;

        width: 100%;
    }

    &__body {
        position: relative;
    }
}
</style>
