import { Currency } from 'game/types/entityDescs/currency';

export const spritePath = 'assets/sprites/ui/' as const;
export const commonSvgPackName = 'common' as const;

export const resourceIcon: Readonly<{ [key in Currency]: string }> = {
    [Currency.Soft]: 'points',
    [Currency.Hard]: 'tokens',
    [Currency.Match]: 'points', // TODO: replace with match icon
};
