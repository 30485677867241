<script setup lang="ts">
import { type Currency } from 'game/types/entityDescs/currency';
import { type MatchRoom } from 'game/types/model/matchRoomsModel';
import Button from 'game/ui/common/Buttons/Button.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { formatNumber } from 'game/ui/utils/number';

defineProps<{
    matchRoom: MatchRoom;
    betCurrency: Currency;
}>();

const emit = defineEmits<{
    goToRoom: [MatchRoom];
}>();
</script>

<template>
    <div class="match-room panel-block-border">
        <h1 class="match-room__title">
            {{ matchRoom.name }}
        </h1>
        <h2 class="match-room__description">
            Bet: {{ formatNumber(matchRoom.betCoins[betCurrency]?.value ?? 0) }}
        </h2>

        <div class="match-room__bottom">
            <div class="match-room__players">
                <template v-for="index in 4" :key="index">
                    <img
                        v-if="matchRoom.playersUserIds[index - 1] !== undefined"
                        class="match-room__image"
                        :src="getAssetUrl('lobby/user-image.webp')"
                        alt=""
                    >

                    <div
                        v-else
                        class="match-room__image"
                    >
                        <div class="loading-icon match-room__loading">
                            <SvgIcon
                                class="loading-icon__icon"
                                name="loading"
                                pack="lobby"
                            />
                        </div>
                    </div>
                </template>
            </div>


            <Button class="match-room__button" @click="emit('goToRoom', matchRoom)">
                <div class="match-room__button-inner">
                    <p class="match-room__button-text">
                        Go to a room
                    </p>
                </div>
            </Button>
        </div>
    </div>
</template>

<style scoped lang="scss">
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-icon {
    color: var(--white, #FFF);

    animation: spin 1.2s steps(8) infinite;

    &__icon {
        width: 100%;
        height: 100%;
    }
}

.match-room {
    position: relative;

    $self: &;

    display: flex;

    flex-direction: column;

    align-items: center;

    width: size(1018px);
    height: size(300px) !important;
    padding: size(24px) size(34px) !important;

    background: var(--grey-400, #221F2B);
    border: size(4px) solid var(--grey-200, #464646);
    border-radius: size(20px);

    &__title {
        width: size(800px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 700;
        line-height: size(48px);
        color: var(--yellow-300, #E5AA13);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__description {
        margin-bottom: size(20px);

        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 400;
        line-height: size(47px);
        color: var(--white, #FFF);
        text-align: center;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: var(--grey-200, #464646);
    }

    &__bottom {
        display: flex;

        gap: size(20px);

        align-items: center;
        justify-content: space-between;

        width: 100%;
    }

    &__players {
        display: flex;

        gap: size(20px);
    }

    &__image {
        display: flex;

        align-items: center;
        justify-content: center;

        width: size(110px);
        height: size(110px);

        border: size(4px) solid var(--yellow-200, #DA8207);
        border-radius: size(2px);
    }

    &__loading {
        display: flex;

        align-items: center;
        justify-content: center;

        width: size(64px);
        height: size(64px);
    }

    &__loading-icon {
        width: 100%;
    }

    &__text {
        display: flex;

        flex-direction: column;

        justify-content: space-between;
    }

    &__button {
        position: relative;
        z-index: 1;

        display: flex;

        flex-wrap: nowrap;

        align-items: center;
        align-self: center;
        justify-content: center;

        width: size(317px);
        height: size(89px);
        padding: size(6px);
        overflow: hidden;

        background: var(--yellow-300, #E5AA13);
        border-radius: size(20px);

        @include hover {
            background: var(--white, #FFF);

            #{$self}__button-text {
                background: var(--white, #FFF);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    &__button-inner {
        display: flex;

        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        background: var(--grey-400, #221F2B);
        border: size(6px) solid transparent;
        border-radius: size(16px);
        box-shadow: 0 size(4px) size(4px) 0 rgb(0 0 0 / 25%)
    }

    &__button-text {
        font-family: $main-font-family;
        font-size: size(32px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: var(--yellow-300, #E5AA13);
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
    }
}
</style>
