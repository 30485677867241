import { engineService, type Entity } from '@nord-beaver/core/ecs';
import { type EngineService } from '@nord-beaver/core/ecs/engineService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { throwIfNull } from '@nord-beaver/core/utils/utils';
import { nakamaEntityId } from 'game/constants';
import { MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { MatchDataNakamaComponent } from 'game/ecs/components/nakama/matchDataNakamaComponent';
import { type NakamaCompositeConfig } from 'game/ecs/components/nakama/nakamaComponent';
import { NotificationNakamaComponent } from 'game/ecs/components/nakama/notificationNakamaComponent';
import { NakamaEntity } from 'game/ecs/entities/nakamaEntity';
import { type RpcNakamaService } from 'game/services/nakama/rpc/rpcNakamaService';
import { type RpcType } from 'game/services/nakama/rpc/rpcNakamaUtils';
import { type ApiMatchDataRequestSchema, type ApiMatchDataResponseSchema } from 'game/types/nakama/matchData';
import { type ApiNotificationCode } from 'game/types/nakama/notificationData';
import { type ApiRpc, type ApiRpcSchema } from 'game/types/nakama/rpcData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getReceivedMatchData, sendMatchData } from 'game/utils/nakama/matchDataUtils';
import { getNotifications } from 'game/utils/nakama/notificationUtils';
import { callRpc } from 'game/utils/nakama/rpcUtils';

const logger = mainLogger.getLogger('Nakama', '#2c92ff');

export class NakamaService {
    private nakamaEntityMap: Map<string, NakamaEntity> = new Map();

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly engineService: EngineService,
        private readonly rpcNakamaService: RpcNakamaService,
    ) {
    }

    add(id: string, config: NakamaCompositeConfig) {
        let nakamaEntity = this.nakamaEntityMap.get(id);
        if (nakamaEntity) {
            this.engineService.removeEntity(nakamaEntity);

            nakamaEntity = undefined;
        }

        nakamaEntity = new NakamaEntity(nakamaEntityId, config);

        this.nakamaEntityMap.set(id, nakamaEntity);

        engineService.addEntity(nakamaEntity);

        return nakamaEntity;
    }

    remove(id: string) {
        const nakamaEntity = this.nakamaEntityMap.get(id);
        if (!nakamaEntity) {
            logger.error('Nakama entity not found', { id });

            return;
        }

        this.engineService.removeEntity(nakamaEntity);

        this.nakamaEntityMap.delete(id);
    }

    get(id?: string) {
        if (!id) {
            return throwIfNull(this.nakamaEntityMap.values().next().value, 'Nakama entity not found');
        }

        return throwIfNull(this.nakamaEntityMap.get(id), 'Nakama entity not found');
    }

    getMatch(matchId?: string) {
        const entity = this.get();

        const matchMap = entity.get(MatchMapNakamaComponent);
        if (!matchMap) {
            return null;
        }

        return matchMap.getMatch(matchId);
    }

    getReceivedMatchData<OpCode extends keyof ApiMatchDataResponseSchema>({
        nakamaEntity = this.get(),
        opCode,
        matchId,
    }: {
        opCode: OpCode;
        nakamaEntity?: string | Entity;
        matchId?: string;
    }) {
        const entity = this.getNakamaEntity(nakamaEntity);
        const matchData = throwIfNull(entity.get(MatchDataNakamaComponent), 'Match data not found');
        const matchMap = throwIfNull(entity.get(MatchMapNakamaComponent), 'Match map not found');

        if (!matchId) {
            matchId = matchMap.getMatch()?.match_id;
        }

        if (!matchId || !matchMap.getMatch(matchId)) {
            throw new Error('Match not found');
        }

        return getReceivedMatchData<OpCode>(matchData, matchId, opCode);
    }

    sendMatchData<OpCode extends keyof ApiMatchDataRequestSchema>({
        nakamaEntity = this.get(),
        opCode,
        message,
        matchId,
    }: {
        opCode: OpCode;
        message: ApiMatchDataRequestSchema[OpCode];
        nakamaEntity?: string | Entity;
        matchId?: string;
    }) {
        const entity = this.getNakamaEntity(nakamaEntity);
        const matchData = throwIfNull(entity.get(MatchDataNakamaComponent), 'Match data not found');
        const matchMap = throwIfNull(entity.get(MatchMapNakamaComponent), 'Match map not found');

        if (!matchId) {
            matchId = matchMap.getMatch()?.match_id;
        }

        if (!matchId || !matchMap.getMatch(matchId)) {
            throw new Error('Match not found');
        }

        sendMatchData<OpCode>(matchData, matchId, opCode, message);
    }

    getNotifications({
        nakamaEntity = this.get(),
        code,
    }: {
        code: ApiNotificationCode;
        nakamaEntity?: string | Entity;
    }) {
        const entity = this.getNakamaEntity(nakamaEntity);
        const notification = throwIfNull(entity.get(NotificationNakamaComponent), 'Notification not found');

        return getNotifications(notification, code);
    }

    callRpc<T extends ApiRpc = ApiRpc>({
        rpcType,
        payload,
        nakamaEntity = this.get(),
        type,
    }: {
        rpcType: T;
        payload: ApiRpcSchema[T]['request'];
        nakamaEntity?: string | Entity;
        type?: RpcType;
    }) {
        const entity = this.getNakamaEntity(nakamaEntity);

        return callRpc<T>({
            rpcService: this.rpcNakamaService,
            entity,
            rpcType,
            payload,
            type,
        });
    }

    private getNakamaEntity(nakamaEntity: string | Entity) {
        return throwIfNull(
            typeof nakamaEntity === 'string'
                ? this.nakamaEntityMap.get(nakamaEntity)
                : nakamaEntity
            , 'Nakama entity not found',
        );
    }
}