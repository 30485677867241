import { SystemService } from '@nord-beaver/core/ecs';
import { MotionDetectorComponent } from '@nord-beaver/core/ecs/components/motionDetectorComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { type EngineService } from '@nord-beaver/core/ecs/engineService';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { GridTileComponent } from 'game/ecs/components/gridTilemap/gridTileComponent';
import { type GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';
import { TargetViewSizeComponent } from 'game/ecs/components/targetViewSizeComponent';
import { GridTilemapNode } from 'game/ecs/nodes/gridTilemap/gridTilemapNode';
import { EnitityComponents } from 'game/types/entityComponents';
import { type LevelTableDesc } from 'game/types/entityDescs/levelTable';
import { Region } from 'game/types/entityDescs/region';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEntityComponentDesc } from 'game/utils/entityDesc';

const logger = mainLogger.getLogger('Tilemap');

export class GridTilemapSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly engineService: EngineService,
        private readonly entityService: EntityService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: GridTilemapNode,
            add: this.onTilemapAdd,
            remove: this.onTilemapRemove,
        });
    }

    override destroy(): void {
        super.destroy();
    }

    private onTilemapAdd = (node: GridTilemapNode) => {
        const { gridTilemap, entity } = node;

        this.resolveTilemapEntity(gridTilemap);

        let index = 0;
        const { rows, columns } = gridTilemap.desc;
        entity.children.forEach(childEntity => {
            const gridTile = childEntity.get(GridTileComponent);
            if (!gridTile) {
                logger.error('Tile entity without GridTileComponent', childEntity);
                return;
            }

            const targetViewSize = childEntity.get(TargetViewSizeComponent);
            if (!targetViewSize) {
                logger.error('Tile entity without TargetViewSizeComponent', childEntity);
                return;
            }

            gridTile.index = index;
            gridTilemap.tileEntities.push(childEntity);

            childEntity
                .add(new RuntimeIdComponent(index))
                .add(new MotionDetectorComponent({ forceMoving: true }));

            index++;

            if (!gridTilemap.tileSize || gridTilemap.tileSize.width < targetViewSize.size.width || gridTilemap.tileSize.height < targetViewSize.size.height) {
                gridTilemap.tileSize = {
                    width: targetViewSize.size.width,
                    height: targetViewSize.size.height,
                };
            }

            this.engineService.addEntity(childEntity);

            // Hack to avoid flickering of tiles edges
            const transform = childEntity.get(TransformComponent);
            if (transform) {
                transform.scale.set(1.001, 1.001);
            }
        });

        if (index !== rows * columns) {
            logger.error(`Invalid number of tile entities, expected ${rows * columns}, got ${index}`);
        }

        if (!gridTilemap.tileSize) {
            logger.error('Tile size not found');
            return;
        }

        gridTilemap.size = {
            width: columns * gridTilemap.tileSize.width,
            height: rows * gridTilemap.tileSize.height,
        };
    };

    private onTilemapRemove = (node: GridTilemapNode) => {
        logger.info('Tile entities removed', node);
    };

    private resolveTilemapEntity(gridTilemap: GridTilemapComponent) {
        const levelTableDescs: Record<Region, LevelTableDesc | undefined> = {
            [Region.IndustrialArea]: undefined,
            [Region.RichDistrict]: undefined,
            [Region.Slums]: undefined,
        };
        for (const link of gridTilemap.desc.levelTableLinks) {
            const levelTable = getEntityComponentDesc<LevelTableDesc>(
                this.entityService,
                link,
                EnitityComponents.LevelTable,
            );
            if (!levelTable) {
                logger.error('Level table entity without LevelTableComponent', gridTilemap);

                throw new Error('Level table entity without LevelTableComponent');
            }

            if (!levelTable.region) {
                logger.error('Level table without region', levelTable);

                throw new Error('Level table without region');
            }

            levelTableDescs[levelTable.region] = levelTable;
        }

        gridTilemap.levelTableDescs = levelTableDescs as Record<Region, LevelTableDesc>;
    }
}