<script setup lang="ts">
import { clamp } from '@nord-beaver/core/utils/utils';
import Container from 'game/ui/common/Container.vue';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import { computed } from 'vue';

const progress = useModel(['lobby', 'loadingProgress']);

const progressPercent = computed(() => clamp(0, 100, Math.trunc(progress.value * 100)));
</script>

<template>
    <Container
        :container-id="ContainerIds.Loading"
        class="loading"
    >
        <h1 class="loading__text">
            &nbsp;greedy.games&nbsp;
        </h1>

        <div class="progress-bar loading__progress-bar">
            <div class="progress-bar__bg" />

            <div
                v-show="progressPercent > 0"
                class="progress-bar__fill"
                :style="{ width: `${progressPercent}%` }"
            />

            <div class="progress-bar__text">
                {{ progressPercent }}%
            </div>
        </div>
    </Container>
</template>

<style scoped lang="scss">
.progress-bar {
    position: relative;

    width: size(1113px);
    height: size(106px);
    overflow: hidden;

    background: var(--grey-halftone-250, rgb(34 31 43 / 60%));
    border-radius: size(20px);

    stroke: size(6px) solid var(--grey-200, #464646);

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        background: var(--grey-halftone-250, rgb(34 31 43 / 60%));
        border: size(6px) solid var(--grey-200, #464646);
        border-radius: size(20px);
    }

    &__fill {
        position: absolute;
        z-index: 2;

        width: 0%;
        height: 100%;

        background: var(--yellow-300, #E5AA13);
        border: size(6px) solid var(--grey-200, #464646);
        border-radius: inherit;

        transition: width .05s;
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;

        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--white, #FFF);
        text-align: center;
        text-shadow: size(8px) size(8px) 0 #000;

        transform: translate(-50%, -50%);
    }
}

.loading {
    $self: &;

    display: flex;

    flex-direction: column;

    gap: size(100px);

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    &__text {
        font-family: $game-title-font-family;
        font-size: size(180px);
        font-style: normal;
        font-weight: 400;
        line-height: size(180px);
        color: var(--yellow-300, #E5AA13);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: size(12.6px);
    }
}
</style>
