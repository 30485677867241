import { type NotificationNakamaComponent } from 'game/ecs/components/nakama/notificationNakamaComponent';
import { type NotificationHandlersDesc } from 'game/services/nakama/notification/notificationHandlers';
import { ApiNotificationCode, type ApiNotificationSchema } from 'game/types/nakama/notificationData';

const decoderEmpty = () => ({});

export const apiNotificationHandlersDesc: NotificationHandlersDesc<ApiNotificationCode> = {
    [ApiNotificationCode.Reserved]: decoderEmpty,
    [ApiNotificationCode.MessageReceivedOffline]: decoderEmpty,
    [ApiNotificationCode.FriendRequestReceived]: decoderEmpty,
    [ApiNotificationCode.FriendRequestAccepted]: decoderEmpty,
    [ApiNotificationCode.GroupAcceptance]: decoderEmpty,
    [ApiNotificationCode.GroupJoinRequest]: decoderEmpty,
    [ApiNotificationCode.FriendJoinedGame]: decoderEmpty,
    [ApiNotificationCode.SingleSocket]: decoderEmpty,
    [ApiNotificationCode.Banned]: decoderEmpty,
};

export function getNotifications(
    notification: NotificationNakamaComponent,
    code: ApiNotificationCode,
) {
    return notification.getNotifications<ApiNotificationCode, ApiNotificationSchema>(code);
}