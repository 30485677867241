import type Long from 'long';

import { isDefined } from '@nord-beaver/core/utils/utils';
import { type api } from 'game/api/api';
import { type DailyRewardsDesc } from 'game/types/entityDescs/dailyRewards';

export class DailyRewardsComponent {
    rewardDay: number;
    available: boolean;
    nextRewardS?: number | Long;
    isClaiming = false;
    isDirty = false;

    constructor(readonly desc: DailyRewardsDesc, data: api.DailyRewardData) {
        this.available = data.available;
        this.rewardDay = data.rewardDay ?? 0;

        if (isDefined(data.nextRewardUnix)) {
            this.nextRewardS = data.nextRewardUnix;
        }
    }
}