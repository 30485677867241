<script setup lang="ts">
import { getAssetUrl } from 'game/ui/utils/assets';
</script>

<template>
    <div class="background">
        <img :src="getAssetUrl('lobby/splashscreen.webp')" alt="" class="background__image">
    </div>
</template>

<style scoped lang="scss">
.background {
    position: relative;

    width: 100%;
    height: 100%;

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        width: 100%;
        height: 100%;

        object-fit: cover;

        transform: translate(-50%, -50%);
    }
}
</style>
