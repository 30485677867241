import { type Notification } from '@heroiclabs/nakama-js';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type NotificationHandlers } from 'game/services/nakama/notification/notificationHandlers';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Notification');

export type NotificationSchema<CodeType extends number> = Record<CodeType, object | undefined>;

export type NotificationWithOptionalContent<ContentType extends object | undefined = object | undefined> = {
    notification: Notification;
    content?: ContentType;
};

export interface NotificationNakamaConfig {
}

export class NotificationNakamaService {
    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        logger.info('Initialized');
    }

    configLogger<CodeType extends number>(codes: CodeType[], getNotificationCodeName?: (code: number) => string) {
        for (const code of codes) {
            logger.getLogger(getNotificationCodeName?.(code) ?? code.toString());
        }
    }

    getNotificationHandler<
        CodeType extends number,
        NotificationSchemaType extends NotificationSchema<CodeType>,
    >(
        notificationHandlers: NotificationHandlers,
        receivedNotifications: Map<CodeType, NotificationWithOptionalContent<NotificationSchemaType[CodeType]>[]>,
        getNotificationCodeName?: (code: number) => string,
    ) {
        return ([notification]: [notification: Notification]) => {
            const { code: codeKey, content } = notification;
            const code = codeKey as CodeType;

            const codeLogger = logger.getLogger(getNotificationCodeName?.(code) ?? code.toString());

            let notifications = receivedNotifications.get(code);
            if (!notifications) {
                notifications = [];
                receivedNotifications.set(code, notifications);
            }

            if (!content) {
                const notificationData = { notification };

                notifications.push(notificationData);
                codeLogger.info(notificationData);
                return;
            }

            try {
                const decodedContent = notificationHandlers.decode<NotificationSchemaType[CodeType]>(code, content);
                const notificationData = { notification, content: decodedContent };

                notifications.push(notificationData);
                codeLogger.info(notificationData);
            } catch (error) {
                logger.error(`Failed to decode notification content for code: ${code}`, error);
            }
        };
    }
}
