import { defineNode, SystemService } from '@nord-beaver/core/ecs';
import { type Model } from '@nord-beaver/core/mvc/model';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { DiceComponent } from 'game/ecs/components/match/diceComponent';
import { MatchComponent } from 'game/ecs/components/match/matchComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';
import { type LobbyModel } from 'game/types/model/lobbyModel';
import { type MatchModel } from 'game/types/model/matchModel';
import { type UsersModel } from 'game/types/model/usersModel';
import { windowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';

class MatchNode extends defineNode({
    match: MatchComponent,
    dice: DiceComponent,
}) { }

const logger = mainLogger.getLogger('Match').getLogger('View');

export class MatchViewSystem extends SystemService {
    private readonly matchModel: Model<MatchModel>;
    private readonly lobbyModel: Model<LobbyModel>;
    private readonly usersModel: Model<UsersModel>;

    constructor(
        dependencyContainer: DependencyContainer,
    ) {
        super();

        this.matchModel = getModel(dependencyContainer, ['match']);
        this.lobbyModel = getModel(dependencyContainer, ['lobby']);
        this.usersModel = getModel(dependencyContainer, ['users']);
    }

    init() {
        this.setupNodeList({
            node: MatchNode,
            add: this.addMatch,
            update: this.updateMatch,
            remove: this.removeMatch,
        });
    }

    private addMatch(_node: MatchNode) {
        this.lobbyModel.data.isMenuVisible = false;
    }

    private removeMatch(_node: MatchNode) {
        this.lobbyModel.data.isMenuVisible = true;

        this.clearMatchModel();
    }

    private clearMatchModel() {
        this.matchModel.data.turnTimeLeft = 1;
        this.matchModel.data.matchTimeLeft = 0;
        this.matchModel.data.coins = 0;
        this.matchModel.data.name = '';
        this.matchModel.data.tiles = [];
        this.matchModel.data.selectedTileIndex = null;
        this.matchModel.data.playerTileIndex = null;
        this.matchModel.data.leaderboard = [];
        this.matchModel.data.matchPlayers = [];
        this.matchModel.data.matchLink = '';
        this.matchModel.data.isBuyTileAvailable = false;
        this.matchModel.data.betCoins = undefined;
        this.matchModel.data.startMatchTimeLeft = undefined;
        this.matchModel.data.state = undefined;
        this.matchModel.data.rewards = undefined;
    }

    private updateMatch(node: MatchNode) {
        const { match } = node;

        this.matchModel.data.state = match.state;
        this.matchModel.data.turnTimeLeft = match.turnTimeMs?.current ?? 0;
        this.matchModel.data.matchTimeLeft = match.matchTimeMs !== undefined
            ? match.matchTimeMs
            : match.desc.timeS * 1000;
        this.matchModel.data.coins = match.coins ?? 0;
        this.matchModel.data.name = match.name;
        this.matchModel.data.matchLink = match.refLink;

        if (match.bet && !this.matchModel.data.betCoins) {
            this.matchModel.data.betCoins = {
                type: match.bet.type,
                value: match.bet.value,
            };
        }

        this.matchModel.data.isBuyTileAvailable = match.buyTileAvailability;

        this.updateReplicants(node);

        if (match.rewards.length > 0 && !windowManager.isVisible('EndMatch')) {
            this.matchModel.data.rewards = [];

            for (const reward of match.rewards) {
                this.matchModel.data.rewards.push({
                    avatarUrl: reward.avatar,
                    name: reward.name,
                    currency: {
                        type: reward.currency.type,
                        value: reward.currency.value,
                    },
                });
            }

            windowManager.setState('endMatch');
        }
    }

    private updateReplicants(node: MatchNode) {
        const { match } = node;

        const mapEntity = match.mapEntity;
        if (!mapEntity) {
            logger.error('MapEntity not found');

            return;
        }

        const gridTilemap = mapEntity.get(GridTilemapComponent);
        if (!gridTilemap) {
            logger.error('GridTilemap not found');

            return;
        }

        if (Object.keys(gridTilemap.replicantEntities).length === 0 && this.matchModel.data.leaderboard.length === 0) {
            return;
        }

        const leaderboard: {
            rId: number;
            name: string;
            coins: number;
        }[] = [];

        for (const rid in gridTilemap.replicantEntities) {
            const replicantEntity = gridTilemap.replicantEntities[rid];
            if (!replicantEntity) {
                logger.error('Replicant not found', rid);

                return;
            }

            const replicant = replicantEntity.get(ReplicantComponent);
            if (!replicant) {
                logger.error('Replicant without ReplicantComponent', replicantEntity);

                return;
            }

            const runtimeId = replicantEntity.get(RuntimeIdComponent);
            if (!runtimeId) {
                logger.error('Replicant without RuntimeIdComponent', replicantEntity);

                return;
            }

            const user = runtimeId.userId
                ? this.usersModel.data[runtimeId.userId]
                : undefined;

            leaderboard.push({
                rId: Number(rid),
                name: user?.name ?? `Player ${rid}`,
                coins: replicant.coins,
            });
        }

        if (!leaderboard.length && !this.matchModel.data.leaderboard.length) {
            return;
        }

        leaderboard.sort((a, b) => b.coins - a.coins);

        for (let i = 0; i < 4; i++) {
            const player = leaderboard[i];
            if (!player) {
                if (this.matchModel.data.leaderboard[i]) {
                    delete this.matchModel.data.leaderboard[i];
                }

                continue;
            }

            const playerView = this.matchModel.data.leaderboard[i];

            if (!playerView) {
                this.matchModel.data.leaderboard.push({
                    rId: player.rId,
                    name: player.name,
                    coins: player.coins,
                });

                continue;
            }

            playerView.rId = player.rId;
            playerView.name = player.name;
            playerView.coins = player.coins;
        }
    }
}