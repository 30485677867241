import { defineNode } from '@nord-beaver/core/ecs';
import { MatchRoomsComponent } from 'game/ecs/components/match/matchRoomsComponent';
import { DailyRewardsComponent } from 'game/ecs/components/meta/dailyRewardsComponent';
import { WelcomeRewardComponent } from 'game/ecs/components/meta/welcomeRewardComponent';
import { MetaComponent } from 'game/ecs/components/metaComponent';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { NakamaComponent } from 'game/ecs/components/nakama/nakamaComponent';
import { RpcNakamaComponent } from 'game/ecs/components/nakama/rpcNakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';

export class ReadyNode extends defineNode({
    nakama: NakamaComponent,
    client: ClientNakamaComponent,
    socket: SocketNakamaComponent,
    session: SessionNakamaComponent,
    rpc: RpcNakamaComponent,
    matchMap: MatchMapNakamaComponent,
    meta: MetaComponent,
    matchRooms: MatchRoomsComponent,
    welcomeReward: WelcomeRewardComponent,
    dailyReward: DailyRewardsComponent,
}) { }