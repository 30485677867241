<script setup lang="ts">
import PanelRightImage from 'game/ui/commonInstances/PanelRightImage.vue';
import { getAssetUrl } from 'game/ui/utils/assets';

defineProps<{
    title: string;
    assetPath: string;
    description?: string;
    description2?: string;
    align?: 'center' | 'left' | 'right';
}>();
</script>

<template>
    <div class="common-info-block">
        <PanelRightImage
            :title="title" height="405px"
            :align="align ?? 'left'"
            :image-url="getAssetUrl(assetPath)"
        >
            <div v-if="description" class="common-info-block__text">
                <div>
                    <p class="common-info-block__description common-info-block__description--big">
                        {{ description }}
                    </p>
                </div>
                <slot />
                <div v-if="description2" class="common-info-block__text__bottom">
                    <p class="common-info-block__description common-info-block__description--small">
                        {{ description2 }}
                    </p>
                </div>
            </div>
        </PanelRightImage>
    </div>
</template>

<style scoped lang="scss">
.common-info-block {
    &__text {
        display: flex;

        flex-direction: column;

        height: 100%;

        &__bottom {
            display: flex;

            align-items: flex-end;

            height: calc(100% - size(170px));
        }
    }

    &__description {
        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 600;
        line-height: size(36px);
        color: var(--grey-50, #D9D9D9);

        &--big {
            font-weight: 700;
            line-height: size(42px);
            color: var(--white, #FFF);
        }

        &--center {
            display: flex;

            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
        }
    }

    &__image {
        width: size(430px);
        height: 100%;

        object-fit: cover;
        border-left: size(6px) solid var(--grey-200, #464646);

        &--details {
            width: size(480px);
            height: size(417.426px);
        }
    }
}
</style>
