import { type Entity } from '@nord-beaver/core/ecs';
import { type OverlayTag } from 'game/ecs/components/gridTilemap/gridTileOverlayComponent';
import { type ChanceEventDesc } from 'game/types/entityDescs/chanceEvent';
import { type GridTileComponentDesc } from 'game/types/entityDescs/gridTile';

export interface ChanceGridTileLinkDescs {
    chanceEventDescs: ChanceEventDesc[];
}

export type GridTileLinkDescs = ChanceGridTileLinkDescs;

export class GridTileComponent {
    index = -1;
    overlayViewEntities: { [key in OverlayTag]?: Entity } = {};
    isJustOwnerChanged = false;
    ownerEntity: Entity | null = null;
    isJustEnabledChanged = false;
    isEnabled = false;
    isSelected = false;

    /** filled on entity add */
    linkDescs?: GridTileLinkDescs;

    constructor(readonly desc: GridTileComponentDesc) {}
}