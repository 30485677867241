import { COMPONENT_IS_VISIBLE_INJECTION_KEY } from 'game/ui/constants/provide';
import { computed, type Ref, inject, watch } from 'vue';

export const onHide = (hook: (() => void) | (() => Promise<void>), props?: { isVisible?: boolean }) => {
    let isVisibleInjected: Ref<boolean> | undefined;
    if (props === undefined) {
        isVisibleInjected = inject<Required<typeof isVisibleInjected>>(COMPONENT_IS_VISIBLE_INJECTION_KEY);
    }

    const isVisible = computed(() => props?.isVisible ?? isVisibleInjected?.value);
    if (isVisible.value === undefined) {
        return;
    }

    watch(
        () => isVisible.value,
        async isVisible => {
            if (isVisible) {
                await hook();
            }
        },
    );
};