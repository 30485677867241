import { type api } from 'game/api/api';

export interface RequestMetaDesc {
    dailyRewardData: api.DailyRewardData;
    welcomeReward: api.WelcomeRewardResponce;
    vaultData?: api.VaultData;
    vaultRecievedTime?: number;
    tasks: api.TaskData[];
    clientAccounts: api.ClientAccount[];
    clientRewardAccounts: api.ClientRewardAccount[];
    client: api.Client;
    inviteesData: api.RpcGetInviteesResponseData;
    groups: api.RpcGroupCreateResponseDataGroup[];
    groupsMeta: api.RpcGroupListResponseMeta | null;
    currencyRates: api.RpcGetCurrenciesRatesResponseData;
    currenciesList: api.RpcListCurrenciesList;
}

export class RequestMetaComponent {
}

export class MetaComponent {
    dailyRewardData: api.DailyRewardData;
    vaultData?: api.VaultData;
    vaultRecievedTime?: number;
    welcomeReward?: api.WelcomeRewardResponce;
    tasks: api.TaskData[];
    clientAccounts: api.ClientAccount[];
    clientRewardAccounts: api.ClientRewardAccount[];
    client: api.Client;
    inviteesData: api.RpcGetInviteesResponseData;
    groups: api.RpcGroupCreateResponseDataGroup[];
    groupsMeta: api.RpcGroupListResponseMeta | null;
    currencyRates: api.RpcGetCurrenciesRatesResponseData;
    currenciesList: api.RpcListCurrenciesList;

    constructor({
        dailyRewardData,
        vaultData,
        vaultRecievedTime,
        welcomeReward,
        tasks,
        clientAccounts,
        clientRewardAccounts,
        client,
        inviteesData,
        groups,
        groupsMeta,
        currencyRates,
        currenciesList,
    }: RequestMetaDesc) {
        this.dailyRewardData = dailyRewardData;
        if (vaultData) {
            this.vaultData = vaultData;
        }
        if (vaultRecievedTime) {
            this.vaultRecievedTime = vaultRecievedTime;
        }
        if (welcomeReward) {
            this.welcomeReward = welcomeReward;
        }
        this.tasks = tasks;
        this.clientAccounts = clientAccounts;
        this.clientRewardAccounts = clientRewardAccounts;
        this.client = client;
        this.inviteesData = inviteesData;
        this.groups = groups;
        this.groupsMeta = groupsMeta;
        this.currencyRates = currencyRates;
        this.currenciesList = currenciesList;
    }

}