import { defineNode } from '@nord-beaver/core/ecs';
import { InteractiveComponent } from '@nord-beaver/core/ecs/components/interactiveComponent';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { SpriteComponent } from '@nord-beaver/core/ecs/components/spriteComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { GridTileComponent } from 'game/ecs/components/gridTilemap/gridTileComponent';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';

export class GridTilemapNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    gridTilemap: GridTilemapComponent,
}) { }

export class GridTileCoreNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    sprite: SpriteComponent,
    gridTile: GridTileComponent,
    runtimeId: RuntimeIdComponent,
}) { }

export class GridTileNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    sprite: SpriteComponent,
    gridTile: GridTileComponent,
    runtimeId: RuntimeIdComponent,
    interactive: InteractiveComponent,
}) { }