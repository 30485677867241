import { type VaultComponentDesc } from 'game/types/entityDescs/vault';

export class VaultComponent {
    isFetching = false;
    isAvailable = false;
    progress = 0;
    leftTimeMS = 0;
    elapsedTimeMS = 0;
    fetchTimeMS = 0;
    fetchTimeoutMS = 1000;

    constructor(
        readonly desc: VaultComponentDesc,
    ) {
    }
}