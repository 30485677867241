import { type NakamaConfig } from 'game/services/nakama/clientNakamaService';
import { type MatchDataHandlersDesc } from 'game/services/nakama/matchData/matchDataHandlers';
import { type NotificationHandlersDesc } from 'game/services/nakama/notification/notificationHandlers';
import { type RpcHandlersDesc } from 'game/services/nakama/rpc/rpcHandlers';
import { type AuthNakamaConfig } from 'game/services/nakama/session/sessionNakamaUtils';

export interface NakamaCompositeConfig extends NakamaConfig {
    readonly authConfig: AuthNakamaConfig;
    readonly matchDataHandlersDesc: MatchDataHandlersDesc;
    readonly notificationHandlersDesc: NotificationHandlersDesc;
    readonly rpcHandlersDesc: RpcHandlersDesc;
    readonly getNotificationCodeName?: (code: number) => string;
    readonly getMatchDataCodeName?: (code: number, type: 'receive' | 'send') => string;
}

export class NakamaComponent {
    constructor(
        readonly id: string,
        readonly config: NakamaCompositeConfig,
    ) {
    }
}