import { mainLogger } from '@nord-beaver/core/utils/logger';
import { boot } from 'game/boot';

try {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.expand();
} catch (error) {
    mainLogger.error('Telegram WebApp not found');
}

boot();