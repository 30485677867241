export function formatNumber(num: number): string {
    const absNum = Math.abs(num);
    if (absNum >= 1_000_000_000) {
        return (Math.floor(num / 1_000_000_000 * 10) / 10).toFixed(1) + 'b';
    } else if (absNum >= 1_000_000) {
        return (Math.floor(num / 1_000_000 * 10) / 10).toFixed(1) + 'm';
    } else if (absNum >= 1_000) {
        return (Math.floor(num / 1_000 * 10) / 10).toFixed(1) + 'k';
    } else if (absNum >= 100) {
        return Math.floor(num).toString();
    } else if (absNum >= 10) {
        return (Math.floor(num * 10) / 10).toString();
    } else if (absNum > 0) {
        return (Math.floor(num * 100) / 100).toString();
    } else {
        return '0';
    }
}