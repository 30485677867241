import { type Entity } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { SpriteComponent } from '@nord-beaver/core/ecs/components/spriteComponent';

export function getViewSize(entity: Entity): { width: number; height: number } | undefined {
    const sprite = entity.get(SpriteComponent);
    if (sprite) {
        return (sprite.primitive.view as Phaser.GameObjects.Image)?.getBounds();
    }

    const spine = entity.get(SceneObjectComponent);
    if (spine) {
        return (spine.object as Phaser.GameObjects.Sprite)?.getBounds();
    }

    return undefined;
}