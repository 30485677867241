import { SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { isDefined } from '@nord-beaver/core/utils/utils';
import { WelcomeRewardComponent } from 'game/ecs/components/meta/welcomeRewardComponent';
import { type MetaComponent } from 'game/ecs/components/metaComponent';
import { WelcomeRewardNode } from 'game/ecs/nodes/meta/welcomeRewardNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { type EventService } from 'game/services/eventService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Meta').getLogger('WelcomeReward');

export class WelcomeRewardSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly eventService: EventService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
            remove: this.removeMeta,
        });
        this.setupNodeList({
            node: WelcomeRewardNode,
            add: this.addWelcomeReward,
        });

    }

    override destroy() {
        this.eventService.lobby.offAll('claimDailyReward', this);
    }

    private addMeta(node: MetaNode) {
        const { meta, entity } = node;

        this.eventService.lobby.on('claimWelcomeReward', () => this.claimWelcomeReward(meta), entity);

        entity.add(new WelcomeRewardComponent(isDefined(meta.welcomeReward?.available) ? meta.welcomeReward : undefined));

        logger.log('Welcome reward added', { entity });
    }

    private removeMeta(node: MetaNode) {
        const { entity } = node;

        if (!entity.has(WelcomeRewardComponent)) {
            return;
        }

        entity.remove(WelcomeRewardComponent);

        this.eventService.lobby.offAll('claimWelcomeReward', entity);
    }

    private addWelcomeReward(node: WelcomeRewardNode) {
        logger.log('Welcome reward added', { node });
    }

    private claimWelcomeReward = async (meta: MetaComponent) => {
        if (meta.welcomeReward) {
            meta.welcomeReward.available = false;
        }

        this.eventService.lobby.dispatch('lobbyShow');
    };
}