import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { MatchMapNakamaNode } from 'game/ecs/nodes/nakama/matchMapNakamaNode';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

export class FrameNakamaSystem extends SystemService {
    private matchNodeList!: NodeList<MatchMapNakamaNode>;
    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        super();
    }

    init() {
        this.matchNodeList = this.setupNodeList({
            node: MatchMapNakamaNode,
        });
    }

    override update(dt: number): void {
        super.update(dt);

        for (const node of this.matchNodeList) {
            this.onUpdateFrame(node);
        }
    }

    private onUpdateFrame = (node: MatchMapNakamaNode) => {
        const { matchMap, socket } = node;

        for (const [matchId] of matchMap.matchMap) {
            socket.processFrameSignal.dispatch(matchId);
        }
    };
}
