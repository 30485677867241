import { type Currency } from 'game/types/entityDescs/currency';

export interface MatchRoomsDesc {
    matchId: string;
    tilemapEntityId: string;
    matchName: string;
    bet: {
        type: Currency;
        value: number;
    }[];
    playerAvatars: (string | undefined)[];
    operationUid: string;
}

export class MatchRoomsComponent {
    isDirty = true;

    constructor(public rooms: Record<string, MatchRoomsDesc>) {
    }
}