import { AudioServiceHowler } from '@nord-beaver/core/services/audioService';
import { createUI as createCoreUI } from '@nord-beaver/html-ui';
import { EventService } from 'game/services/eventService';
import { config } from 'game/ui/config/app';
import { DEPENDENCY_CONTAINER_INJECTION_KEY } from 'game/ui/constants/provide';
import Root from 'game/ui/Root.vue';
import { SoundManager } from 'game/ui/services/soundManager';
import { initWindowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { type App } from 'vue';

export const createUI = (dependencyContainer: DependencyContainer) => {
    const app = createCoreUI(Root, config);

    app.provide(DEPENDENCY_CONTAINER_INJECTION_KEY, dependencyContainer);

    dependencyContainer.register(SoundManager, undefined, [EventService, AudioServiceHowler]);
    const soundManager = dependencyContainer.get(SoundManager);

    initWindowManager(dependencyContainer.get(EventService));
    soundManager.init();

    app.mount('#ui');

    return app;
};

export const destroyUI = (app: App<Element>) => {
    app.unmount();
};
