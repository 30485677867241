<script setup lang="ts">
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { SoundAssetIds } from 'game/constants';
import Button from 'game/ui/common/Buttons/Button.vue';
import SequenceAnimation from 'game/ui/common/SequenceAnimation.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { getAssetUrl } from 'game/ui/utils/assets';
import { getShortTime } from 'game/ui/utils/time';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps<{
    turnTimeLeft: number;
    isEnabled: boolean;
    isRolling: boolean;
}>();
const emit = defineEmits<{
    throwDice: [void];
}>();

const eventService = useEventService();

const targetDiceFaceIndex = ref<number | null>(0);

const endUrls: Readonly<string[][]> = [
    ['12', '13', '14', '15'].map(url => `dice1/dice1_${url}`),
    ['12', '13', '14', '15'].map(url => `dice2/dice2_${url}`),
    ['12', '13', '14', '15'].map(url => `dice3/dice3_${url}`),
    ['12', '13', '14', '15'].map(url => `dice4/dice4_${url}`),
    ['12', '13', '14', '15'].map(url => `dice5/dice5_${url}`),
    ['12', '13', '14', '15'].map(url => `dice6/dice6_${url}`),
].map(urls => urls.map(url => getAssetUrl(`dice/end/${url}.webp`)));
const startUrls: Readonly<string[][]> = [
    ['01', '02', '03'].map(url => `dice1/dice1_${url}`),
    ['01', '02', '03'].map(url => `dice2/dice2_${url}`),
    ['01', '02', '03'].map(url => `dice3/dice3_${url}`),
    ['01', '02', '03'].map(url => `dice4/dice4_${url}`),
    ['01', '02', '03'].map(url => `dice5/dice5_${url}`),
    ['01', '02', '03'].map(url => `dice6/dice6_${url}`),
].map(urls => urls.map(url => getAssetUrl(`dice/start/${url}.webp`)));
const rollUrls: Readonly<string[]> = ['04', '05', '06', '07', '08', '09', '10', '11']
    .map(url => getAssetUrl(`dice/roll/roll_${url}.webp`));

const state = ref<'start' | 'rolling' | 'end' | 'idle'>('idle');
const readyToEnd = ref(false);

watch(
    () => targetDiceFaceIndex.value,
    (targetDiceFaceIndex, prev) => {
        if (targetDiceFaceIndex === prev || targetDiceFaceIndex === null) {
            return;
        }

        if (state.value === 'rolling') {
            mainLogger.getLogger('UI').info('Dice rolling animation end', { targetDiceFaceIndex });

            readyToEnd.value = true;
        }
    },
);

watch(
    () => props.isRolling,
    (isRolling, prev) => {
        if (isRolling === prev) {
            return;
        }

        if (isRolling) {
            mainLogger.getLogger('UI').info('Dice rolling animation start', { targetDiceFaceIndex: targetDiceFaceIndex.value });

            state.value = 'start';
        }
    },
);

const urls = computed(() => {
    switch (state.value) {
        case 'start':
            mainLogger.getLogger('UI').info('Dice start animation', { targetDiceFaceIndex: targetDiceFaceIndex.value });

            return startUrls[targetDiceFaceIndex.value ?? 0];
        case 'rolling':
            mainLogger.getLogger('UI').info('Dice rolling animation');

            return rollUrls;
        case 'end':
            mainLogger.getLogger('UI').info('Dice end animation', { targetDiceFaceIndex: targetDiceFaceIndex.value });

            return endUrls[targetDiceFaceIndex.value ?? 0];
        case 'idle': {
            const diceEndUrls = endUrls[targetDiceFaceIndex.value ?? 0];
            if (!diceEndUrls?.length) {
                return [];
            }

            mainLogger.getLogger('UI').info('Dice idle animation', { targetDiceFaceIndex: targetDiceFaceIndex.value, url: diceEndUrls[diceEndUrls.length - 1] });

            return [diceEndUrls[diceEndUrls.length - 1]];
        }
        default:
            return [];
    }
});
const isActive = computed(() => state.value !== 'idle');

const onAnimationEnd = () => {
    switch (state.value) {
        case 'start':
            state.value = 'rolling';

            targetDiceFaceIndex.value = null;
            break;
        case 'rolling':
            if (readyToEnd.value) {
                state.value = 'end';
                readyToEnd.value = false;
            }
            break;
        case 'end':
            state.value = 'idle';
            break;
    }
};

const onDiceClick = () => {
    emit('throwDice');
};

const onEndDiceRoll = (diceFaceIndex: number) => {
    targetDiceFaceIndex.value = diceFaceIndex;
};

onMounted(() => {
    eventService.match.on('endDiceRoll', onEndDiceRoll);
});
onUnmounted(() => {
    eventService.match.off('endDiceRoll', onEndDiceRoll);
});
</script>

<template>
    <div
        class="dice"
        :class="{
            'dice--disabled': !isEnabled,
        }"
    >
        <h1 v-if="isEnabled" class="dice__title">
            roll the dice
        </h1>

        <Button
            :is-enabled="isEnabled"
            :sound="SoundAssetIds.RollDice"
            @click="onDiceClick"
        >
            <SequenceAnimation
                class="dice__dice"
                :urls="urls"
                type="image"
                :fps="20"
                :default-width="undefined"
                :default-height="undefined"
                :is-active="isActive"
                @animationend="onAnimationEnd"
            />
        </Button>

        <p class="dice__text">
            {{ turnTimeLeft ? getShortTime(turnTimeLeft) : '' }}
        </p>
    </div>
</template>

<style scoped lang="scss">
@keyframes roll-dice {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.dice {
    $self: &;
    position: relative;

    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    margin-bottom: size(50px);

    &__title {
        position: absolute;
        top: size(-40px);
        left: 50%;

        width: size(519px);

        font-family: $bangers-font-family;
        font-size: size(86px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #FFF;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: size(6.02px);

        pointer-events: none;

        transform: translateX(-50%);
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #000;
    }

    &__text {
        position: absolute;
        top: size(360px);
        left: 50%;

        height: size(70px);

        font-family: $bangers-font-family;
        font-size: size(70px);
        font-style: normal;
        font-weight: 400;
        line-height: size(70px);
        color: var(--white, #FFF);
        text-align: center;
        text-shadow: 0 size(4px) size(4px) #000;
        text-transform: uppercase;
        letter-spacing: size(2.8px);

        pointer-events: none;

        transform: translateX(-50%);

        -webkit-text-stroke-width: 2;
        -webkit-text-stroke-color: #000;
    }

    &--disabled {
        #{$self}__bg {
            filter: grayscale(100%);
        }

        #{$self}__dice-container {
            filter: grayscale(100%);
        }

        cursor: not-allowed;
    }

    &__dice {
        width: size(325.8px);
        height: size(399.6px);
        margin-bottom: size(35px);

        object-fit: contain;

        &--rolling {
            animation: roll-dice .3s infinite linear;
        }
    }
}
</style>
