import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('TelegramService');

export class TelegramService {
    private _webApp?: WebApp = window.Telegram?.WebApp;

    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        logger.info('Initialized');
    }

    get webApp() {
        return this._webApp;
    }

    getData() {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        const params = Array.from(new URLSearchParams(this.webApp.initData));
        const _parts = params.filter(el => el[0] !== 'hash');
        const hash = params.filter(el => el[0] === 'hash')[0]?.[1];
        const username = this.webApp.initDataUnsafe.user?.id;
        const seed = _parts.sort((a, b) => a[0].localeCompare(b[0]))
            .map(a => `${a[0]}=${a[1]}`)
            .join('\n');

        if (!hash || !username) {
            logger.error('No hash or username');
            return;
        }

        logger.info('Data', { hash, username, seed });

        return {
            hash,
            username,
            seed,
        };
    }
}