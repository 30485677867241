import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { FriendsComponent } from 'game/ecs/components/meta/friendsComponent';
import { FriendsNode } from 'game/ecs/nodes/meta/friendsNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { type EventService } from 'game/services/eventService';
import { copyToClipboard } from 'game/utils/copyToClipboard';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Meta').getLogger('Friends');

export class FriendsSystem extends SystemService {
    private friendsNodeList?: NodeList<FriendsNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private eventService: EventService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
        });
        this.friendsNodeList = this.setupNodeList({
            node: FriendsNode,
        });

        this.eventService.lobby.on('copyFriendInviteLink', this.copyFriendInviteLink, this);
        this.eventService.lobby.on('inviteFriendViaTelegram', this.copyFriendInviteLink, this);
    }

    override destroy() {
        this.eventService.lobby.offAll('copyFriendInviteLink', this);
        this.eventService.lobby.offAll('inviteFriendViaTelegram', this);
    }

    private copyFriendInviteLink = () => {
        if (!this.friendsNodeList) {
            logger.error('Friends node list not found');

            return;
        }

        const { friends, meta } = this.friendsNodeList.get(0) ?? {};
        if (!friends) {
            logger.error('Friends not found');

            return;
        }

        const refLink = meta?.client.ref_link;
        if (!refLink) {
            logger.error('Ref link not found');

            return;
        }

        copyToClipboard(refLink);

        logger.info('copyFriendInviteLink');
    };

    private addMeta(node: MetaNode) {
        const { meta, entity } = node;

        if (!meta.tasks) {
            logger.error('Friends not found', { entity });

            return;
        }

        entity.add(new FriendsComponent(meta.inviteesData.invitees));

        logger.info('Friends added', { entity });
    }
}
