<script setup lang="ts">
import Button from 'game/ui/common/Buttons/Button.vue';
import Input from 'game/ui/common/Input.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import PanelTopImage from 'game/ui/commonInstances/PanelTopImage.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { computed } from 'vue';

const props = defineProps<{
    codeText: string;
}>();

const emit = defineEmits<{
    'update:codeText': [string];
    close: [void];
    join: [string];
}>();

const isCodeTextValid = computed(() => {
    const code = props.codeText.trim();
    if (!code || code.length === 0) {
        return false;
    }

    return code.match(/(https:\/\/t.me\/YdeerGamesBot\?start=)([a-zA-Z0-9]{10})/);
});

const onJoinClan = () => {
    if (!props.codeText || !isCodeTextValid.value) {
        return;
    }

    emit('join', props.codeText.trim());
    emit('close');
};
const onClose = () => {
    emit('close');
};
</script>

<template>
    <div class="join-clan">
        <h1 class="join-clan__title section-title">
            Create or join a clan
        </h1>
        <PanelTopImage :image-url="getAssetUrl('lobby/create-clan.webp')" class="join-clan__panel">
            <Button class="join-clan__close-button" @click="onClose">
                <SvgIcon
                    class="join-clan__close-icon"
                    name="cross"
                    pack="lobby"
                />
            </Button>
            <section class="join-clan__inner">
                <p class="join-clan__description">
                    Just enter a link to a public group or channel and click "Join" below to automatically join or create a squad
                </p>

                <Input
                    :model-value="codeText"
                    :is-enabled="true"
                    class="join-clan__input section-input"
                    placeholder="Enter the link here"
                    color-placeholder="#939393"
                    @update:model-value="($event) => emit('update:codeText', $event)"
                />
            </section>
        </PanelTopImage>

        <MainButton
            class="join-clan__join-button"
            label="join the clan"
            :disabled="!isCodeTextValid"
            @click="onJoinClan"
        />
    </div>
</template>

<style scoped lang="scss">
.join-clan {
    &__panel {
        margin-bottom: size(64px);
    }

    &__description {
        padding-bottom: size(44px);

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 400;
        line-height: size(54px);
        color: var(--white, #FFF);
        text-align: center;
    }

    &__input {
        width: size(1025px);
        height: size(113px);
        margin-bottom: size(44px);
    }

    &__close-button {
        position: absolute;
        top: 0;
        right: 0;

        width: size(146px);
        height: size(146px);
        padding: size(50px);

        color: var(--grey-50, #D9D9D9);

        &:active {
            color: var(--grey-100, #939393);
        }
    }

    &__close-icon {
        width: 100%;
        height: 100%;
    }

    &__inner {
        position: relative;

        display: flex;

        flex-direction: column;

        align-items: center;
        justify-content: center;
    }

    &__join-button {
        font-size: size(80px);
    }
}
</style>
