import { type Entity } from '@nord-beaver/core/ecs';
import { InteractiveComponent } from '@nord-beaver/core/ecs/components/interactiveComponent';
import { MotionDetectorComponent } from '@nord-beaver/core/ecs/components/motionDetectorComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { debugPanelService } from '@nord-beaver/core/services/debugPanelService';
import { InputNumberElement } from '@nord-beaver/core/services/debugPanelService/debugElements';
import { FLAG_DEBUG, FLAG_TEST } from '@nord-beaver/core/utils/debug';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type PointLike } from '@nord-beaver/core/utils/point';
import { SHAPE_TYPE } from '@nord-beaver/core/utils/shapeDesc';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';
import { TargetViewSizeComponent } from 'game/ecs/components/targetViewSizeComponent';

const logger = mainLogger.getLogger('Tilemap');

export function placeTileEntity(entity: Entity, index: number) {
    const transform = entity.get(TransformComponent);
    if (!transform) {
        logger.error('TilemapSystem: tile entity without TransformComponent', entity);
        return;
    }

    const targetViewSizeComponent = entity.get(TargetViewSizeComponent);
    const viewSize = targetViewSizeComponent?.baseSize;
    if (!viewSize) {
        logger.error('TilemapSystem: tile entity without view bounds', entity);
        return;
    }

    if (!entity.has(ReplicantComponent)) {
        const tileWidth = viewSize.width;
        const tileHeight = viewSize.height;

        entity.add(new InteractiveComponent({
            type: SHAPE_TYPE.POLYGON,
            points: [
                { x: 0, y: -tileHeight / 2 },
                { x: tileWidth / 2, y: 0 },
                { x: 0, y: tileHeight / 2 },
                { x: -tileWidth / 2, y: 0 },
            ],
        }));
    }

    moveTileEntity(entity, index);
}

export function moveTileEntity(entity: Entity, index: number) {
    const transform = entity.get(TransformComponent);
    if (!transform) {
        logger.error('TilemapSystem: tile entity without TransformComponent', entity);
        return;
    }

    const tilemap = entity.parent?.get(GridTilemapComponent);
    if (!tilemap) {
        logger.error('TilemapSystem: tile entity without parent GridTilemapComponent', entity);
        return;
    }

    if (tilemap.tileSize === undefined) {
        logger.error('TilemapSystem: tilemap without tileSize', tilemap);
        return;
    }

    const tileWidth = tilemap.tileSize.width;
    const tileHeight = tilemap.tileSize.height;

    const tilemapWidth = tileWidth * tilemap.desc.columns;
    const tilemapHeight = tileHeight * tilemap.desc.rows;

    const columns = tilemap.desc.columns;
    const rows = tilemap.desc.rows;
    const column = index % columns;
    const row = Math.trunc(index / columns);

    const centerCol = (columns - 1) / 2;
    const centerRow = (rows - 1) / 2;

    const offsetX = (centerCol - centerRow) * (tileWidth / 2);
    const offsetY = (centerCol + centerRow) * (tileHeight / 2);

    const x = (column - row) * (tileWidth / 2) - offsetX;
    const y = (column + row) * (tileHeight / 2) - offsetY;

    const entityOffset: PointLike = { x: 0, y: 0 };

    const replicant = entity.get(ReplicantComponent);
    if (replicant) {
        entityOffset.x = replicant.desc.offset.x;
        entityOffset.y = replicant.desc.offset.y;

        if (FLAG_DEBUG || FLAG_TEST) {
            const rId = entity.get(RuntimeIdComponent)?.rId;
            const xElement = debugPanelService.addElement(new InputNumberElement({
                path: ['Features', 'Replicant', 'Offset'],
                id: `${rId?.toString() ?? 'undefined'}: x`,
                initValue: entityOffset.x,
                cb: value => {
                    if (value === undefined) {
                        return;
                    }

                    entityOffset.x = value;

                    moveTileEntity(entity, index);
                },
            }));

            const yElement = debugPanelService.addElement(new InputNumberElement({
                path: ['Features', 'Replicant', 'Offset'],
                id: `${rId?.toString() ?? 'undefined'}: y`,
                initValue: entityOffset.y,
                cb: value => {
                    if (value === undefined) {
                        return;
                    }

                    entityOffset.y = value;

                    moveTileEntity(entity, index);
                },
            }));

            if (xElement.value && yElement.value) {
                entityOffset.x = xElement.value;
                entityOffset.y = yElement.value;
            }
        }
    }

    // To remove the offset of the tilemap center need to rewrite camera move logic
    transform.position.set(
        x + entityOffset.x + tilemapWidth / 2,
        y + entityOffset.y + tilemapHeight / 2,
    );

    const motionDetector = entity.get(MotionDetectorComponent);
    if (motionDetector) {
        motionDetector.forceMoving = true;
    }
}