<script setup lang="ts">
const props = defineProps<{
    title?: string;
    height?: string;
    paddingTopBottom?: string;
    paddingLeftRight?: string;
    paddingRight?: string;
}>();

const overridedHeight = typeof props.height === 'string' && props.height.length > 3 ? props.height : 'auto';
const overridePaddingTopBottom = typeof props.paddingTopBottom === 'string' && props.paddingTopBottom.length > 2 ? props.paddingTopBottom : '30px';
const overridePaddingLeftRight = typeof props.paddingLeftRight === 'string' && props.paddingLeftRight.length > 2 ? props.paddingLeftRight : '32px';
const overridePaddingRight = typeof props.paddingRight === 'string' && props.paddingRight.length > 2 ? props.paddingRight : overridePaddingLeftRight;
</script>

<template>
    <section class="panel panel-dotted">
        <div class="panel__inner">
            <h1
                v-if="title"
                class="section-title panel__inner__title"
            >
                {{ title }}
            </h1>
            <slot />
        </div>
    </section>
</template>

<style scoped lang="scss">
.panel {
    position: relative;

    overflow-x: hidden;

    &__inner {
        height: size(v-bind(overridedHeight));
        padding: size(v-bind(overridePaddingTopBottom)) size(v-bind(overridePaddingLeftRight));
        padding-right: size(v-bind(overridePaddingRight));

        &__title {
            margin: 0;

            text-align: left;
        }
    }
}
</style>
