import type { PropertyPath } from '@nord-beaver/core/mvc/model';
import type { GetPropertyType } from '@nord-beaver/core/utils/types';

import { useModel as useModelCore } from '@nord-beaver/core/html-ui-core/hooks/model';
import { type ViewModel } from 'game/types/model/viewModel';
import { DEPENDENCY_CONTAINER_INJECTION_KEY } from 'game/ui/constants/provide';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';
import { inject, type Ref } from 'vue';

export function useModel<const Path extends readonly string[]>(
    path: PropertyPath<ViewModel, Path>,
): Ref<GetPropertyType<ViewModel, Path>> {
    const dependencyContainer = inject<DependencyContainer>(DEPENDENCY_CONTAINER_INJECTION_KEY);
    if (!dependencyContainer) {
        throw new Error('DependencyContainer not found');
    }

    const viewModel = getModel(dependencyContainer);

    return useModelCore(viewModel, path);
}