<script setup lang="ts">
import Button from 'game/ui/common/Buttons/Button.vue';
import Container from 'game/ui/common/Container.vue';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import { ContainerIds } from 'game/ui/config/containers';
import { type NavigationMenuButtonTypes, type NavigationMenuButton } from 'game/ui/constants/nav';
import { getAssetUrl } from 'game/ui/utils/assets';

defineProps<{
    buttons: NavigationMenuButton[];
    selectedButtonType?: NavigationMenuButtonTypes;
}>();

const emit = defineEmits<{
    vault: [void];
    friends: [void];
    clans: [void];
    play: [void];
    tasks: [void];
}>();

const onClick = (type: string) => {
    switch (type) {
        case 'home':
            emit('vault');
            break;
        case 'friends':
            emit('friends');
            break;
        case 'clans':
            emit('clans');
            break;
        case 'play':
            emit('play');
            break;
        case 'tasks':
            emit('tasks');
            break;
    }
};

function getName(name: string) {
    return 'lobby/' + name + '.webp';
}

function getAssetByButtonType(button: NavigationMenuButton, selectedButtonType: NavigationMenuButton['type']) {
    let postfix = selectedButtonType === button.type ? '-selected' : '-default';

    if (button.isInactive) {
        postfix = '-inactive';
    }

    if (button.isEnabled) {
        if (button.type === 'home') {
            return getName('home' + postfix);
        }

        if (button.type === 'friends') {
            return getName('friends' + postfix);
        }

        if (button.type === 'clans') {
            return getName('clans' + postfix);
        }

        if (button.type === 'tasks') {
            return getName('tasks' + postfix);
        }
    }

    return getName('lock-default');
}
</script>

<template>
    <Container
        :container-id="ContainerIds.NavigationMenu"
        class="navigation-menu"
    >
        <div class="navigation-menu__items">
            <template v-for="(button, _index) in buttons" :key="_index">
                <MainButton
                    v-if="button.type === 'play'" :no-border="true" label="play"
                    :is-enabled="button.isEnabled"
                    class="navigation-menu__play-button"
                    @click="onClick(button.type)"
                />
                <Button
                    v-if="button.type !== 'play'"
                    :is-enabled="button.isEnabled && !button.isInactive"
                    :class="{
                        'navigation-menu__item': true,
                        'navigation-menu__item--active': button.type === selectedButtonType,
                        'navigation-menu__item--enabled': button.isEnabled && !button.isInactive,
                        'navigation-menu__item--inactive': button.isInactive,
                    }"
                    @click="onClick(button.type)"
                >
                    <img
                        v-if="button.icon"
                        class="navigation-menu__item-icon"
                        :class="{
                            [`navigation-menu__item-icon--${button.type}`]: true
                        }"
                        :src="getAssetUrl(getAssetByButtonType(button, selectedButtonType))"
                        :pack="button.icon.pack || 'lobby'"
                    >

                    <div class="navigation-menu__item-text">
                        {{ button.isEnabled ? button.text : 'lock' }}
                    </div>
                </Button>
            </template>
        </div>
    </Container>
</template>

<style scoped lang="scss">
.navigation-menu {
    $self: &;
    position: relative;

    width: 100%;
    height: size(280px);

    background: #221F2B;

    &__items {
        display: flex;

        gap: size(50px);

        align-items: center;
        justify-content: center;

        height: 100%;
        padding: size(5px) size(30px);
    }

    &__item {
        $item-class: &;

        z-index: 100;

        width: size(153px);

        font-family: $navigation-menu-font-family;
        font-size: size(50px);
        font-weight: 400;
        line-height: size(53px);
        color: var(--grey-150, #939393);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: size(4px);

        &--enabled {
            color: var(--white-100, #FCFCFC);
        }

        &--active {
            color: var(--yellow-100, #F6D85E);
        }

        @include hover {
            &.button {
                &--enabled {
                    color: var(--yellow-100, #F6D85E);
                }
            }
        }

        &--inactive {
            color: var(--grey-150, #939393);
        }
    }

    &__item-icon {
        width: size(174px);
        height: size(150px);

        object-fit: contain;

        &--tasks {
            transform: scale(1.1);
        }
    }

    &__item-text {
        margin-top: size(30px);
    }

    &__play-button {
        width: size(406px);
        height: size(152px);

        font-family: $play-button-font-family;
        font-weight: 800;
        line-height: size(152px);
        color: var(--grey-300, #323232);

        background-position: size(-82px) size(-128px);

        transform: translateY(size(-36px));

        @include hover {
            color: var(--white, #FFF);
        }
    }
}
</style>
