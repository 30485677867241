import { api } from 'game/api/api';
import { type MatchDataNakamaComponent } from 'game/ecs/components/nakama/matchDataNakamaComponent';
import { type MatchDataHandlersDesc } from 'game/services/nakama/matchData/matchDataHandlers';
import { type ApiMatchDataRequestSchema, type ApiMatchDataResponseSchema } from 'game/types/nakama/matchData';

export const apiMatchDataHandlersDesc: MatchDataHandlersDesc<api.OpCodeRequest, api.OpCodeResponse> = {
    response: {
        [api.OpCodeResponse.PLAYER_DICE_RESPONCE]: (data: object) => api.PlayerDiceResponse.fromObject(data),
        [api.OpCodeResponse.COINS_UPDATE]: (data: object) => api.CoinsUpdateResponce.fromObject(data),
        [api.OpCodeResponse.TILE_OWNER_UPDATE]: (data: object) => api.TileOwnerUpdateResponce.fromObject(data),
        [api.OpCodeResponse.BUY_TILE_RESPONCE]: (data: object) => api.BuyTileResponce.fromObject(data),
        [api.OpCodeResponse.MATCH_TIME_RESPONCE]: (data: object) => api.MatchTimeResponce.fromObject(data),
        [api.OpCodeResponse.MOVE_ON_TILE_RESPONCE]: (data: object) => api.MoveOnTileResponce.fromObject(data),
        [api.OpCodeResponse.PLAYER_LEFT_GAME]: (data: object) => api.PlayerLeftGameResponce.fromObject(data),
        [api.OpCodeResponse.PLAYER_POSITION_UPDATE]: (data: object) => api.PlayerPositionUpdateResponce.fromObject(data),
        [api.OpCodeResponse.ADD_PLAYER]: (message: object) => api.AddPlayerResponce.fromObject(message),
        [api.OpCodeResponse.ADD_REPLICANT]: (message: object) => api.AddPlayerResponce.fromObject(message),
        [api.OpCodeResponse.PLAYER_MATCH_STATE_RESPONCE]: (message: object) => api.PlayerMatchStateResponce.fromObject(message),
        [api.OpCodeResponse.EVENT_RESPONCE]: (message: object) => api.EventResponce.fromObject(message),
        [api.OpCodeResponse.BUY_TILE_AVAILABILITY_RESPONCE]: (message: object) => api.PlayerBuyAvailabilityResponce.fromObject(message),
        [api.OpCodeResponse.SELL_TILE_RESPONCE]: (message: object) => api.SellTileResponce.fromObject(message),
        [api.OpCodeResponse.MATCH_OVER_RESPONCE]: (message: object) => api.MatchOverResponce.fromObject(message),
    },
    request: {
        [api.OpCodeRequest.BUY_TILE]: (message: object) => JSON.stringify(api.BuyTileRequest.create(message).toJSON()),
        [api.OpCodeRequest.MOVE_ON_TILE]: (message: object) => JSON.stringify(api.MoveOnTileRequest.create(message).toJSON()),
        [api.OpCodeRequest.TILE_SELL]: (message: object) => JSON.stringify(api.SellTileRequest.create(message).toJSON()),
    },
};

export function getReceivedMatchData<OpCode extends keyof ApiMatchDataResponseSchema>(
    matchData: MatchDataNakamaComponent,
    matchId: string,
    opCode: OpCode,
) {
    return matchData.getReceivedMatchData<OpCode, ApiMatchDataResponseSchema>(matchId, opCode);
}

export function sendMatchData<OpCode extends keyof ApiMatchDataRequestSchema>(
    matchData: MatchDataNakamaComponent,
    matchId: string,
    opCode: OpCode,
    message: ApiMatchDataRequestSchema[OpCode],
) {
    matchData.sendMatchData<OpCode, ApiMatchDataRequestSchema>(matchId, opCode, message);
}