import { Client } from '@heroiclabs/nakama-js';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Client');

export interface NakamaConfig {
    readonly SERVER_KEY: string;
    readonly HOST: string;
    readonly PORT: string;
    readonly USE_SSL: boolean;
    readonly RPC_HTTP_KEY: string | null;
}

export class ClientNakamaService {
    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        logger.info('Initialized');
    }

    init(config: NakamaConfig) {
        try {
            if (!config.SERVER_KEY || !config.HOST || !config.PORT || config.USE_SSL === undefined) {
                throw new Error('Incomplete Nakama configuration');
            }

            const client = new Client(
                config.SERVER_KEY,
                config.HOST,
                config.PORT,
                config.USE_SSL,
            );

            logger.info('Nakama client initialized');

            return client;
        } catch (error) {
            const message = error instanceof Error ? error.message : error;
            logger.error('Failed to initialize Nakama client', { error: message });
            throw error;
        }
    }
}
