<script setup lang="ts">
import { clamp } from '@nord-beaver/core/utils/utils';
import ScrollBar from 'game/ui/common/ScrollPan/ScrollBar.vue';
import ScrollPanel from 'game/ui/common/ScrollPan/ScrollPanel.vue';
import { ref } from 'vue';

const panel = ref<InstanceType<typeof ScrollPanel> | null>(null);

const props = withDefaults(defineProps<{
    initScroll?: number;
}>(), {
    initScroll: 0,
});

const emit = defineEmits<{
    scroll: [UIEvent];
    scrollUpdate: [{ scrollTop: number; scrollHeight: number; clientHeight: number; scrollBottom: number }];
}>();

const ignoreScrollEvent = ref(false);
const scrollModel = ref(props.initScroll);
const scrollAbsoluteValues = ref({
    scrollTop: 0,
    scrollBottom: 0,
});

const setScroll = (value: number) => {
    scrollModel.value = clamp(0, 1, value);
    panel.value?.updateScroll();
};

defineExpose({
    setScroll,
    updateScroll: () => panel.value?.updateScroll(),
    scrollModel,
});

const isScrollBarShowed = ref(false);

const onAbsoluteValuesUpdate = (event: { scrollTop: number; scrollHeight: number; clientHeight: number; scrollBottom: number }) => {
    scrollAbsoluteValues.value = event;
    emit('scrollUpdate', event);
};

</script>

<template>
    <div
        class="scroll-pan"
        :class="{
            'scroll-pan_bar-hided': !isScrollBarShowed,
        }"
    >
        <ScrollPanel
            ref="panel"
            v-model="scrollModel"
            class="scroll-pan__panel"
            :ignore-scroll-event="ignoreScrollEvent"
            @scroll="emit('scroll', $event)"
            @enable="isScrollBarShowed = true"
            @disable="isScrollBarShowed = false"
            @update-absolute-values="onAbsoluteValuesUpdate($event)"
        >
            <slot />
        </ScrollPanel>

        <Transition name="fade">
            <ScrollBar
                v-if="isScrollBarShowed"
                v-model="scrollModel"
                class="scroll-pan__bar"
            />
        </Transition>
    </div>
</template>

<style lang="scss" scoped>
$scroll-padding-x: 84px;
$scroll-padding-y: 25px;
$scroll-thumb-width: 8px;
$scroll-thumb-height: 224px;

.scroll-pan {
    overflow: hidden;

    &__panel {
        position: relative;
        z-index: 1;

        width: 100%;
        height: 100%;
    }

    &__bar {
        position: absolute;
        top: size($scroll-padding-x);
        right: size($scroll-padding-y);
        z-index: 3;

        height: calc(100% - calc(size($scroll-padding-x) + size($scroll-padding-x)));

        transform: translateX(50%);

    }

    &_bar-hided {
        &::before,
        &::after {
            display: none;
        }
    }
}

:deep(.scrollbar) {
    display: flex;

    justify-content: center;

    min-width: size($scroll-thumb-width);
    min-height: size($scroll-thumb-height);

    /* stylelint-disable-next-line selector-class-pattern */
    .scrollbar__track {
        width: size($scroll-thumb-width);
        height: 100%;

        background: var(--grey-200, #464646);
        border-radius: size(8px);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .scrollbar__thumb-container {
        position: absolute;

        width: 100%;
        height: calc(100% - size($scroll-thumb-height));
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .scrollbar__thumb {
        width: size($scroll-thumb-width);
        height: size($scroll-thumb-height);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .scrollbar__thumb-button {
        width: 100%;
        height: 100%;

        background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
        border-radius: size(8px);
    }
}
</style>
