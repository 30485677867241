import { SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { ensureArray, retry } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { MatchLinkComponent } from 'game/ecs/components/match/matchLinkComponent';
import { DailyRewardsComponent } from 'game/ecs/components/meta/dailyRewardsComponent';
import { WelcomeRewardComponent } from 'game/ecs/components/meta/welcomeRewardComponent';
import { MetaComponent, RequestMetaComponent, type RequestMetaDesc } from 'game/ecs/components/metaComponent';
import { RequestMetaNode } from 'game/ecs/nodes/metaNode';
import { CoreNakamaNode } from 'game/ecs/nodes/nakama/coreNakamaNode';
import { ReadyNode } from 'game/ecs/nodes/readyNode';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { type TelegramService } from 'game/services/telegramService';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { windowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('LobbySystem');

export class LobbySystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private eventService: EventService,
        private nakamaService: NakamaService,
        private telegramService: TelegramService,
    ) {
        super();

        this.setupNodeList({
            node: CoreNakamaNode,
            add: this.addCoreNakama,
            remove: this.removeCoreNakama,
        });

        this.setupNodeList({
            node: RequestMetaNode,
            add: this.addRequestMeta,
        });

        this.setupNodeList({
            node: ReadyNode,
            add: this.addReady,
        });
    }

    init() {
        this.eventService.lobby.on('openUrlViaTelegram', url => {
            this.telegramService.openUrl(url);
        }, this);
    }

    override destroy() {
        this.eventService.lobby.offAll('openUrlViaTelegram', this);

        super.destroy();
    }

    private async addCoreNakama(node: CoreNakamaNode) {
        const { nakama, entity } = node;

        this.compareHashes();

        if (nakama.config.authConfig.type === 'custom') {
            entity?.add(new RequestMetaComponent());
        } else {
            this.eventService.lobby.dispatch('lobbyReady');
        }
    }

    private removeCoreNakama(node: CoreNakamaNode) {
        const { entity } = node;

        this.eventService.lobby.dispatch('lobbyDestroyed');
        this.eventService.lobby.offAll('startMatchmaking', entity);
        this.eventService.lobby.offAll('lobbyShow', entity);
    }

    private async compareHashes() {
        const {
            api: serverApiHash,
            entities: serverEntitiesHash,
        } = await this.nakamaService.callRpc({
            rpcType: ApiRpc.compareHashes,
            payload: {},
        });

        const clientApiHash = await fetch('assets/api.hash').then(response => response.text());
        const clientEntitiesHash = await fetch('assets/entities.hash').then(response => response.text());

        if (serverApiHash !== clientApiHash) {
            logger.error('API hash mismatch', { serverApiHash, clientApiHash });
        }

        if (serverEntitiesHash !== clientEntitiesHash) {
            logger.error('Entities hash mismatch', { serverEntitiesHash, clientEntitiesHash });
        }
    }

    private async addRequestMeta(node: RequestMetaNode) {
        const { entity } = node;
        logger.log('Add meta node', { node });

        const requestMetaDesc: Partial<RequestMetaDesc> = {};

        const requests: (keyof RequestMetaDesc)[] = [
            'dailyRewardData',
            'tasks',
            'clientAccounts',
            'client',
            'inviteesData',
            'groups',
            'welcomeReward',
            'currenciesList',
            'currencyRates',
        ];

        await Promise.allSettled([
            ...requests.map(request => this.requestMeta(request, requestMetaDesc)),
        ]);

        const metaDesc = await retry({
            work: () => this.retryRequestMeta(requestMetaDesc),
            beforeRetry: () => logger.log('Retry request meta', { requestMetaDesc }),
            count: 3,
            timeout: 1000,
        });

        if (!metaDesc) {
            logger.error('Meta values not found', { requestMetaDesc });

            alert('You have been disconnected from the server. Please refresh the page to reconnect.');

            return;
        }

        const meta = new MetaComponent(metaDesc);

        entity.add(meta);

        logger.log('Meta node added', { node, metaDesc });

        this.eventService.metaData.on('request', async metaKey => {
            const metaKeyArray = ensureArray(metaKey);

            await Promise.allSettled(metaKeyArray.map(metaKey => this.requestMeta(metaKey, meta)));

            this.eventService.metaData.dispatch('response', metaKeyArray);
        }, this);
    }

    private async addReady(node: ReadyNode) {
        const { entity, matchMap } = node;

        this.eventService.lobby.dispatch('lobbyReady');

        const showLobbyWindows = () => {
            const hasMatch = matchMap.matchMap.size > 0;
            if (hasMatch) {
                return;
            }

            const welcomeReward = entity.get(WelcomeRewardComponent);
            if (welcomeReward?.data?.available) {
                windowManager.setState('welcomeReward');

                return;
            }

            const dailyReward = entity.get(DailyRewardsComponent);
            if (dailyReward?.available) {
                windowManager.setState('dailyReward');

                return;
            }

            const matchLink = entity.get(MatchLinkComponent);
            if (matchLink) {
                this.eventService.lobby.dispatch('openJoinMatchPopup', matchLink.matchId);

                windowManager.setState('matchRooms');

                return;
            }

            windowManager.setState('vault');
        };

        this.eventService.lobby.on('startMatchmaking', () => {
            if (windowManager.isVisible('MatchRooms')) {
                return;
            }

            if (windowManager.isVisible('DailyReward')) {
                windowManager.close('DailyReward');
            }

            windowManager.setState('matchRooms');
        }, entity);

        this.eventService.lobby.on('lobbyShow', showLobbyWindows, entity);

        showLobbyWindows();
    }

    private async requestMeta(metaKey: keyof RequestMetaDesc, metaDesc: Partial<RequestMetaDesc>) {
        switch (metaKey) {
            case 'vaultData':
            case 'dailyRewardData':
                return this.getUserStatus(metaDesc);
            case 'tasks':
                return this.getTasks(metaDesc);
            case 'clientRewardAccounts':
            case 'clientAccounts':
                return this.getAccounts(metaDesc);
            case 'client':
                return this.getClient(metaDesc);
            case 'inviteesData':
                return this.getInvitees(metaDesc);
            case 'groupsMeta':
            case 'groups':
                return this.getGroups(metaDesc);
            case 'welcomeReward':
                return this.getWelcomeReward(metaDesc);
            case 'currenciesList':
                return this.getCurrenciesList(metaDesc);
            case 'currencyRates':
                return this.getCurrenciesRates(metaDesc);
        }
    }

    private async getCurrenciesRates(metaDesc: Partial<RequestMetaDesc>) {
        const currencyRates = await this.nakamaService.callRpc({
            rpcType: ApiRpc.currenciesRates,
            payload: {},
        });

        const verifyResult = api.RpcGetCurrenciesRatesResponseData.verify(currencyRates);
        if (verifyResult) {
            logger.error('Currencies rates verification failed', { currencyRates, verifyResult });

            return;
        }

        if (!currencyRates.data) {
            logger.error('Currencies rates not found', { currencyRates });

            return;
        }

        metaDesc.currencyRates = currencyRates.data;
    }

    private async getCurrenciesList(metaDesc: Partial<RequestMetaDesc>) {
        const currenciesList = await this.nakamaService.callRpc({
            rpcType: ApiRpc.listCurrencies,
            payload: {},
        });

        const verifyResult = api.RpcListCurrenciesResponse.verify(currenciesList);
        if (verifyResult) {
            logger.error('Currencies list verification failed', { currenciesList, verifyResult });

            return;
        }

        if (!currenciesList.data) {
            logger.error('Currencies list not found', { currenciesList });

            return;
        }

        metaDesc.currenciesList = currenciesList.data;
    }

    private async retryRequestMeta(metaDesc: Partial<RequestMetaDesc>) {
        const retryMetaKeys = Object.entries(metaDesc).filter(([_, value]) => !value).map(([key]) => key as keyof RequestMetaDesc);

        if (retryMetaKeys.length === 0) {
            return metaDesc as RequestMetaDesc;
        }

        await Promise.allSettled(
            retryMetaKeys.map(metaKey => this.requestMeta(metaKey, metaDesc)),
        );

        if (!Object.values(metaDesc).some(value => !value)) {
            throw new Error('Some meta values are not found');
        }

        return metaDesc as RequestMetaDesc;
    }

    private async getWelcomeReward(metaDesc: Partial<RequestMetaDesc>) {
        const welcomeReward = await this.nakamaService.callRpc({
            rpcType: ApiRpc.welcomeReward,
            payload: {},
        });

        const verifyResult = api.WelcomeRewardResponce.verify(welcomeReward);
        if (verifyResult) {
            logger.error('Welcome reward verification failed', { welcomeReward, verifyResult });

            return;
        }

        metaDesc.welcomeReward = welcomeReward;
    }

    private async getUserStatus(metaDesc: Partial<RequestMetaDesc>) {
        const userStatus = await this.nakamaService.callRpc({
            rpcType: ApiRpc.userStatus,
            payload: {},
        });
        metaDesc.vaultRecievedTime = Date.now();

        const verifyResult = api.RpcUserStatusResponse.verify(userStatus);
        if (verifyResult) {
            logger.error('Daily reward data verification failed', { userStatus, verifyResult });

            return;
        }

        if (!userStatus.dailyRewardData) {
            logger.error('Daily reward data not found', { userStatus });

            return;
        }

        metaDesc.dailyRewardData = userStatus.dailyRewardData;

        if (userStatus.vaultData) {
            metaDesc.vaultData = userStatus.vaultData;
        } else {
            logger.error('Vault data not found', { userStatus });
        }
    }

    private async getTasks(metaDesc: Partial<RequestMetaDesc>) {
        const tasks = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getTasks,
            payload: {},
        });

        if (!tasks.tasks) {
            logger.error('Tasks not found', { tasks });

            return;
        }

        const verifyResult = api.RpcGetTasksResponse.verify(tasks);
        if (verifyResult) {
            logger.error('Tasks verification failed', { tasks, verifyResult });

            return;
        }

        metaDesc.tasks = tasks.tasks;
    }

    private async getAccounts(metaDesc: Partial<RequestMetaDesc>) {
        const accounts = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getAccounts,
            payload: {},
        });

        if (!accounts.data) {
            logger.error('Client accounts not found', { accounts });

            return;
        }

        const verifyResult = api.RpcGetAccountsResponse.verify(accounts);
        if (verifyResult) {
            logger.error('Client accounts verification failed', { accounts, verifyResult });

            return;
        }

        metaDesc.clientAccounts = accounts.data.client_accounts;
        metaDesc.clientRewardAccounts = accounts.data.client_reward_accounts;
    }

    private async getClient(metaDesc: Partial<RequestMetaDesc>) {
        const client = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getClient,
            payload: {},
        });

        if (!client.data?.client) {
            logger.error('Client not found', { client });

            return;
        }

        const verifyResult = api.Client.verify(client.data.client);
        if (verifyResult) {
            logger.error('Client verification failed', { client, verifyResult });

            return;
        }

        metaDesc.client = client.data.client;
    }

    private async getInvitees(metaDesc: Partial<RequestMetaDesc>) {
        const invitees = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getInvitees,
            payload: {},
        });

        if (!invitees.data) {
            logger.error('Invitees not found', { invitees });

            return;
        }

        const verifyResult = api.RpcGetInviteesResponseData.verify(invitees.data);
        if (verifyResult) {
            logger.error('Invitees verification failed', { invitees, verifyResult });

            return;
        }

        metaDesc.inviteesData = invitees.data;
    }

    private async getGroups(metaDesc: Partial<RequestMetaDesc>) {
        const groups = await this.nakamaService.callRpc({
            rpcType: ApiRpc.groupList,
            payload: {},
        });

        if (api.RpcGroupListResponseData.verify(groups)) {
            logger.error('Groups verification failed', { groups });

            return;
        }

        metaDesc.groups = groups.data?.groups ?? [];
        metaDesc.groupsMeta = groups.data?.meta ?? null;
    }
}