import { SystemService } from '@nord-beaver/core/ecs';
import { type Model } from '@nord-beaver/core/mvc/model';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { NakamaNode } from 'game/ecs/nodes/nakama/nakamaNode';
import { type UsersModel } from 'game/types/model/usersModel';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';

const logger = mainLogger.getLogger('MatchPresence').getLogger('View');

export class MatchPresenceViewSystem extends SystemService {
    private readonly usersModel: Model<UsersModel>;

    constructor(
        dependencyContainer: DependencyContainer,
    ) {
        super();

        this.usersModel = getModel(dependencyContainer, ['users']);
    }

    async init() {
        this.setupNodeList({
            node: NakamaNode,
            update: this.updateNakama,
        });
    }

    private updateNakama(node: NakamaNode) {
        const { matchPresence } = node;

        matchPresence.matchPresenceMap.forEach(matchPresence => {
            if (!matchPresence.isDirty) {
                return;
            }

            for (const userId in matchPresence.userData) {
                const userData = matchPresence.userData[userId];
                if (!userData) {
                    logger.error('MatchPresence: userData is not found', { userId });

                    continue;
                }

                const user = this.usersModel.data[userId];
                if (user) {
                    user.id = userId;
                    user.name = userData.display_name ?? userData.username ?? '';
                    user.avatarUrl = userData.avatar_url;
                } else {
                    this.usersModel.data[userId] = {
                        id: userId,
                        name: userData.display_name ?? userData.username ?? '',
                        avatarUrl: userData.avatar_url,
                    };
                }
            }
        });
    }
}