<script setup lang="ts">
import { SoundAssetIds } from 'game/constants';
import { type Clan } from 'game/types/model/clansModel';
import Button from 'game/ui/common/Buttons/Button.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';

defineProps<{
    clan: Clan;
}>();

const emit = defineEmits<{
    selectClan: [Clan];
}>();
</script>

<template>
    <div class="panel-block-border clan-item">
        <div class="clan-item__inner">
            <img
                v-if="clan.avatarUrl"
                class="clan-item__image"
                :src="clan.avatarUrl"
                alt=""
            >
            <div
                v-else
                class="clan-item__image"
            >
                <div class="loading-icon">
                    <SvgIcon
                        class="loading-icon__icon clan-item__loading"
                        name="loading"
                        pack="lobby"
                    />
                </div>
            </div>

            <div class="clan-item__text">
                <h3 class="clan-item__title">
                    {{ clan.name }}
                </h3>
            </div>
        </div>

        <Button
            class="clan-item__button"
            :sound="SoundAssetIds.Button"
            @click="emit('selectClan', clan)"
        >
            <div class="clan-item__button-inner">
                <p class="clan-item__button-text">
                    Join to clan
                </p>
            </div>
        </Button>
    </div>
</template>

<style scoped lang="scss">
.clan-item {
    $self: &;
    display: grid;

    grid-template-columns: 1fr auto;

    gap: size(23px);

    padding: 0 size(20px) !important;

    &__loading {
        width: size(64px);
        height: size(64px);
    }

    &__inner {
        display: flex;

        gap: size(23px);

        align-items: center;
    }

    &__image {
        display: flex;

        align-items: center;
        justify-content: center;

        width: size(110px);
        height: size(110px);

        border: size(4px) solid var(--yellow-200, #DA8207);
        border-radius: size(2px);
    }

    &__text {
        display: flex;

        flex-direction: column;

        justify-content: space-between;
    }

    &__title {
        width: size(408px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(50px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--white, #FFF);
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__description {
        width: size(408px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--grey-100, var(--grey, #939393));
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__button {
        $parent: &;
        position: relative;
        z-index: 1;

        display: flex;

        flex-wrap: nowrap;

        align-items: center;
        align-self: center;
        justify-content: center;

        width: size(317px);
        height: size(89px);
        padding: size(6px);
        overflow: hidden;

        border: size(6px) solid var(--yellow-300, #E5AA13);
        border-radius: size(20px);
        box-shadow: 0 size(4px) size(4px) 0 rgb(0 0 0 / 25%);

        @include hover {
            background: var(--white, #FFF);

            #{$self}__button-text {
                background: var(--white, #FFF);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    &__button-inner {
        display: flex;

        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        background: var(--grey-400, #221F2B);
        border: size(6px) solid transparent;
        border-radius: size(16px);
        box-shadow: 0 size(4px) size(4px) 0 rgb(0 0 0 / 25%)
    }

    &__button-text {
        font-family: $main-font-family;
        font-size: size(32px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: var(--yellow-300, #E5AA13);
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
    }
}
</style>
