import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { FriendsComponent } from 'game/ecs/components/meta/friendsComponent';
import { FriendsNode } from 'game/ecs/nodes/meta/friendsNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { type EventService } from 'game/services/eventService';
import { type TelegramService } from 'game/services/telegramService';
import { copyToClipboard } from 'game/utils/copyToClipboard';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Meta').getLogger('Friends');

export class FriendsSystem extends SystemService {
    private friendsNodeList?: NodeList<FriendsNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private eventService: EventService,
        private telegramService: TelegramService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
            remove: this.removeMeta,
        });
        this.friendsNodeList = this.setupNodeList({
            node: FriendsNode,
        });

        this.eventService.lobby.on('copyFriendInviteLink', this.copyFriendInviteLink, this);
        this.eventService.lobby.on('inviteFriendViaTelegram', this.shareFriendInviteLink, this);
        this.eventService.windowManager.on('changeState', state => {
            if (state.stateKey !== 'friends') {
                return;
            }

            this.eventService.metaData.dispatch('request', ['client', 'inviteesData']);
        }, this);
    }

    override destroy() {
        this.eventService.lobby.offAll('copyFriendInviteLink', this);
        this.eventService.lobby.offAll('inviteFriendViaTelegram', this);
        this.eventService.windowManager.offAll('changeState', this);
    }

    private shareFriendInviteLink = () => {
        if (!this.friendsNodeList) {
            logger.error('Friends node list not found');

            return;
        }

        const { friends, meta } = this.friendsNodeList.get(0) ?? {};
        if (!friends) {
            logger.error('Friends not found');

            return;
        }

        const refLink = meta?.client.ref_link;
        if (!refLink) {
            logger.error('Ref link not found');

            return;
        }

        this.telegramService.shareMessageDirectly('Join me in the game', refLink);

        logger.info('copyFriendInviteLink');
    };

    private copyFriendInviteLink = () => {
        if (!this.friendsNodeList) {
            logger.error('Friends node list not found');

            return;
        }

        const { friends, meta } = this.friendsNodeList.get(0) ?? {};
        if (!friends) {
            logger.error('Friends not found');

            return;
        }

        const refLink = meta?.client.ref_link;
        if (!refLink) {
            logger.error('Ref link not found');

            return;
        }

        copyToClipboard(refLink);

        logger.info('copyFriendInviteLink');
    };

    private addMeta(node: MetaNode) {
        const { meta, entity } = node;

        if (!meta.tasks) {
            logger.error('Friends not found', { entity });

            return;
        }

        const friends = new FriendsComponent(meta.inviteesData.invitees);
        entity.add(friends);

        this.eventService.metaData.on('response', keys => {
            if (!keys.includes('inviteesData')) {
                return;
            }

            friends.list = meta.inviteesData.invitees;
            friends.isDirty = true;
        }, entity);

        logger.info('Friends added', { entity });
    }

    private removeMeta(node: MetaNode) {
        const { entity } = node;

        if (entity.has(FriendsComponent)) {
            entity.remove(FriendsComponent);
        }

        this.eventService.metaData.offAll('response', entity);
    }
}
