import { type NodeList, defineNode, SystemService } from '@nord-beaver/core/ecs';
import { InteractiveComponent } from '@nord-beaver/core/ecs/components/interactiveComponent';
import { type StageService } from '@nord-beaver/core/services/stageService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

class CursorNode extends defineNode({
    interactiveComponent: InteractiveComponent,
}) { }

export class CursorSystem extends SystemService {
    private cursorNodeList!: NodeList<CursorNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly stageService: StageService,
    ) {
        super();
    }

    init() {
        this.cursorNodeList = this.setupNodeList({
            node: CursorNode,
        });
    }

    override update(dt: number): void {
        super.update(dt);

        let pointerOverNode: CursorNode | undefined = undefined;
        for (const cursorNode of this.cursorNodeList) {
            const { interactiveComponent } = cursorNode;

            if (interactiveComponent.isEnabled && interactiveComponent.isPointerOver) {
                pointerOverNode = cursorNode; // only one node can be hovered at a time
            }
        }

        this.stageService.stage.input.setDefaultCursor(pointerOverNode ? 'pointer' : 'default');
    }
}