import { type Entity, SystemService } from '@nord-beaver/core/ecs';
import { type KeyControl } from '@nord-beaver/core/utils/input/controls';
import { type Mouse, MOUSE_CONTROLS } from '@nord-beaver/core/utils/input/devices/mouse';
import { TOUCH_CONTROLS, type Touch } from '@nord-beaver/core/utils/input/devices/touch';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { DragComponent } from 'game/ecs/components/dragComponent';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { GridTilemapNode, GridTileNode } from 'game/ecs/nodes/gridTilemap/gridTilemapNode';
import { type EventService } from 'game/services/eventService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Tilemap').getLogger('Interaction');

export class GridTilemapInteractionSystem extends SystemService {
    private readonly mouseLeftControl: Readonly<KeyControl>;
    private readonly touchControl: Readonly<KeyControl>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly mouse: Mouse,
        private readonly touch: Touch,
        private readonly eventService: EventService,
    ) {
        super();

        this.mouseLeftControl = this.mouse.getControl(MOUSE_CONTROLS.Left);
        this.touchControl = this.touch.getControl(TOUCH_CONTROLS.Touch);
    }

    init() {
        this.setupNodeList({
            node: GridTileNode,
            update: this.onTileUpdate,
        });

        this.setupNodeList({
            node: GridTilemapNode,
            add: this.onTilemapAdd,
            remove: this.onTilemapRemove,
        });
    }

    private onTilemapAdd(node: GridTilemapNode) {
        const { gridTilemap, entity } = node;

        this.eventService.match.on('unselectTile', () => {
            this.selectTile(gridTilemap);
        }, entity);
    }

    private onTilemapRemove(node: GridTilemapNode) {
        const { entity } = node;

        this.eventService.match.offAll('unselectTile', entity);
    }

    private onTileUpdate(node: GridTileNode) {
        const { gridTile, interactive, entity } = node;

        const gridTilemapEntity = entity.parent;
        if (!gridTilemapEntity) {
            return;
        }

        const gridTilemap = gridTilemapEntity.get(GridTilemapComponent);
        if (!gridTilemap) {
            return;
        }

        const drag = gridTilemapEntity.get(DragComponent);
        if (!drag) {
            return;
        }

        const isInteracted = interactive.isPointerOver
            && (
                this.mouseLeftControl.state.isJustUp
                || this.touchControl.state.isJustUp
            )
            && !drag.hasMoved();

        if (!isInteracted) {
            return;
        }

        if (gridTilemap.selectedTileEntity === entity) {
            logger.info('Deselected tile', gridTile.index);

            this.selectTile(gridTilemap);

            return;
        }

        this.selectTile(gridTilemap, entity);

        logger.info('Selected tile', gridTile.index);
    }

    private selectTile(gridTilemap: GridTilemapComponent, entity: Entity | null = null) {
        gridTilemap.prevSelectedTileEntity = gridTilemap.selectedTileEntity;
        gridTilemap.selectedTileEntity = entity;
    }
}