
export type NavigationMenuButtonTypes = 'home' | 'vault' | 'friends' | 'clans' | 'tasks' | 'play' | 'lock';

export type NavigationMenuButton = {
    type: NavigationMenuButtonTypes;
    isEnabled: boolean;
    isInactive: boolean;
    text: string;
    icon?: {
        name: string;
        pack?: string;
    };
};

export const navigationMenuButtons: [NavigationMenuButton, NavigationMenuButton, NavigationMenuButton, NavigationMenuButton, NavigationMenuButton] = [
    {
        type: 'home',
        isEnabled: true,
        isInactive: false,
        text: 'Home',
        icon: {
            name: 'home',
            pack: 'lobby',
        },
    },
    {
        type: 'friends',
        isEnabled: true,
        isInactive: false,
        text: 'Friends',
        icon: {
            name: 'friends',
            pack: 'lobby',
        },
    },
    {
        type: 'play',
        isEnabled: true,
        isInactive: false,
        text: 'Play',
    },
    {
        type: 'clans',
        isEnabled: true,
        isInactive: false,
        text: 'Clans',
        icon: {
            name: 'guild',
            pack: 'lobby',
        },
    },
    {
        type: 'tasks',
        isEnabled: true,
        isInactive: false,
        text: 'Tasks',
        icon: {
            name: 'tasks',
            pack: 'lobby',
        },
    },
];