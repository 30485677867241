import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type api } from 'game/api/api';
import { ClansComponent } from 'game/ecs/components/meta/clansComponent';
import { ClansNode } from 'game/ecs/nodes/meta/clansNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { type TelegramService } from 'game/services/telegramService';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { copyToClipboard } from 'game/utils/copyToClipboard';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Meta').getLogger('Friends');

export class ClansSystem extends SystemService {
    private clansNodeList?: NodeList<ClansNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private eventService: EventService,
        private nakamaService: NakamaService,
        private telegramService: TelegramService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
            remove: this.removeMeta,
        });
        this.clansNodeList = this.setupNodeList({
            node: ClansNode,
        });

        this.eventService.lobby.on('inviteClan', this.inviteClan, this);
        this.eventService.lobby.on('joinClanById', this.joinClanById, this);
        this.eventService.lobby.on('joinClanByCode', this.joinClanByCode, this);
        this.eventService.lobby.on('joinCommunity', this.joinCommunity, this);
        this.eventService.lobby.on('leaveClan', this.leaveClan, this);
        this.eventService.windowManager.on('changeState', state => {
            if (state.stateKey !== 'clans') {
                return;
            }

            this.eventService.metaData.dispatch('request', ['client', 'groups']);
        }, this);
    }

    override destroy() {
        this.eventService.lobby.offAll('inviteClan', this);
        this.eventService.lobby.offAll('joinClanById', this);
        this.eventService.lobby.offAll('joinClanByCode', this);
        this.eventService.lobby.offAll('joinCommunity', this);
        this.eventService.lobby.offAll('leaveClan', this);
        this.eventService.windowManager.offAll('changeState', this);
    }

    private async addMeta(node: MetaNode) {
        const { meta, entity } = node;

        if (!meta.groups) {
            logger.error('Clans not found', { entity });

            return;
        }

        const userGroupUid = meta.client.group?.uid;
        const groupClients: Record<string, api.RpcGroupListClient[]> = {};

        const clans = new ClansComponent(meta.groups, groupClients);

        for (const group of meta.groups) {
            const isJoined = group.uid === userGroupUid;
            if (!isJoined) {
                continue;
            }

            clans.group = group;

            await this.updateClanMembers(clans);

            clans.isDirty = true;
        }

        entity.add(clans);

        this.eventService.metaData.on('response', async keys => {
            if (!keys.includes('groups') && !keys.includes('groupsMeta') && !keys.includes('client')) {
                return;
            }

            clans.list = meta.groups;

            if (keys.includes('client')) {
                clans.group = clans.list.find(clan => clan.uid === meta.client.group?.uid) ?? null;
            }

            await this.updateClanMembers(clans);

            clans.isDirty = true;
        }, entity);

        logger.info('Clans added', { entity });
    }

    private removeMeta(node: MetaNode) {
        const { entity } = node;

        if (entity.has(ClansComponent)) {
            entity.remove(ClansComponent);
        }

        this.eventService.metaData.offAll('response', entity);
    }

    private inviteClan = async () => {
        if (!this.clansNodeList) {
            logger.error('Friends node list not found');

            return;
        }

        const { clans } = this.clansNodeList.get(0) ?? {};
        if (!clans) {
            logger.error('Friends not found');

            return;
        }

        const refLink = clans.group?.ref_link;
        if (!refLink) {
            logger.error('Ref link not found');

            return;
        }

        copyToClipboard(refLink);

        logger.info('copyFriendInviteLink');
    };

    private joinCommunity = async () => {
        if (!this.clansNodeList) {
            logger.error('Friends node list not found');

            return;
        }

        const { clans } = this.clansNodeList.get(0) ?? {};
        if (!clans) {
            logger.error('Friends not found');

            return;
        }

        const link = clans.group?.link;
        if (!link) {
            return;
        }

        this.telegramService.openUrl(link);
    };

    private joinClanById = async (clanUid: string) => {
        if (!this.clansNodeList) {
            logger.error('Clans node list not found');

            return;
        }

        const { clans } = this.clansNodeList.get(0) ?? {};
        if (!clans) {
            logger.error('Clans not found');

            return;
        }

        const result = await this.nakamaService.callRpc({
            rpcType: ApiRpc.groupAddClient,
            payload: { group_uid: clanUid },
        });

        if (result.code) {
            logger.error('Group add client error', { result });

            return;
        }

        clans.group = clans.list.find(clan => clan.uid === clanUid) ?? null;

        logger.info('Group joined', { clanUid, clan: clans.group });

        this.eventService.metaData.dispatch('request', 'groups');
    };

    private joinClanByCode = async (code: string) => {
        if (!this.clansNodeList) {
            logger.error('Clans node list not found');

            return;
        }

        const { clans } = this.clansNodeList.get(0) ?? {};
        if (!clans) {
            logger.error('Clans not found');

            return;
        }

        const result = await this.nakamaService.callRpc({
            rpcType: ApiRpc.groupCreate,
            payload: {
                name: code,
                force: true,
            },
        });

        const group = result.data?.group;
        if (!group) {
            logger.error('Group not found', { result });

            return;
        }

        clans.group = group;

        logger.info('Group created', { clan: group });

        this.eventService.metaData.dispatch('request', 'groups');
    };

    private leaveClan = async () => {
        if (!this.clansNodeList) {
            logger.error('Clans node list not found');

            return;
        }

        const { clans, meta } = this.clansNodeList.get(0) ?? {};
        if (!clans) {
            logger.error('Clans not found');

            return;
        }

        const clan = clans.group;
        if (!clan) {
            logger.error('Group not found');

            return;
        }

        if (clan.admin_client_uid === meta?.client.uid) {
            const result = await this.nakamaService.callRpc({
                rpcType: ApiRpc.groupDelete,
                payload: { group_uid: clan.uid },
            });

            if (result.code) {
                logger.error('Group delete error', { result });

                return;
            }
        } else {
            const result = await this.nakamaService.callRpc({
                rpcType: ApiRpc.groupExclude,
                payload: { group_uid: clan.uid },
            });

            if (result.code) {
                logger.error('Group exclude error', { result });

                return;
            }
        }

        logger.info('Group left', { clan });

        clans.group = null;
        clans.groupClients = {};

        this.eventService.metaData.dispatch('request', 'groups');
    };


    private async updateClanMembers(clans: ClansComponent) {
        const clan = clans.group;
        if (!clan) {
            if (clans.groupClients) {
                clans.groupClients = {};
            }

            return;
        }

        const groupClients = await this.nakamaService.callRpc({
            rpcType: ApiRpc.groupListClients,
            payload: {
                group_uid: clan.uid,
            },
        });

        if (!groupClients.data) {
            logger.error('Group clients not found', { clans });

            return;
        }

        clans.groupClients[clan.uid] = groupClients.data.clients;

        logger.info('Group clients updated', { clan, clients: groupClients.data.clients });
    }
}
