import { type Model } from '@nord-beaver/core/mvc';
import { debugPanelService } from '@nord-beaver/core/services/debugPanelService';
import { CodeElement, ELEMENT_TYPES, InputCheckboxElement } from '@nord-beaver/core/services/debugPanelService/debugElements';
import { FLAG_DEBUG, FLAG_TEST } from '@nord-beaver/core/utils/debug';
import { isDefined } from '@nord-beaver/core/utils/utils';

const context = 'debug-panel-context';

export const initModelDebugElements = FLAG_DEBUG || FLAG_TEST
    ? (model: Model<Record<string, unknown>>) => {
        const path = ['Model'];
        let isInited = false;
        const needChangeMap = new Map<string, boolean>(); // key - model key
        const UPDATE_TIME = 1000;
        let nextUpdateTime = 0;

        const createElement = (key: string, isGroup: boolean) => {
            const elementPath = isGroup
                ? [...path, key]
                : path;

            let dataElement = debugPanelService?.getElement(ELEMENT_TYPES.CODE, [...elementPath, key]);
            if (!dataElement) {
                dataElement = debugPanelService?.addElement(new CodeElement({
                    path: elementPath,
                    id: key,
                }));
            }

            return dataElement;
        };

        const onModelUpdate = (key: string) => {
            needChangeMap.set(key, true);
            if (!debugPanelService.isVisible || !isModelUpdateEnabledElement.value) {
                return;
            }

            const value = model.data[key];
            const dataElement = createElement(key, typeof value === 'object' && !Array.isArray(value));
            dataElement.value = isDefined(value)
                ? JSON.stringify(value, null, 2)
                : '';
            needChangeMap.set(key, false);
        };

        const updateAll = (isInit = false) => {
            for (const key in model.data) {
                if (isInit || needChangeMap.get(key)) {
                    onModelUpdate(key);
                }
            }
        };

        const onModelUpdateChange = (value: boolean) => {
            if (value && !isInited) {
                updateAll(true);

                model.subscribe(() => {
                    const now = Date.now();
                    if (now < nextUpdateTime) {
                        return;
                    }

                    nextUpdateTime = now + UPDATE_TIME;

                    for (const key in model.data) {
                        onModelUpdate(key);
                    }
                }, context);

                isInited = true;
            }
        };

        const isModelUpdateEnabledElement = debugPanelService?.addElement(new InputCheckboxElement({
            path,
            id: 'is model update enabled',
            initValue: false,
            isStorable: true,
            sortKey: '0',
            cb: onModelUpdateChange,
        }));
        isModelUpdateEnabledElement.triggerCb();

        debugPanelService.visibilityChangeSignal.on(isVisible => {
            if (isVisible && isModelUpdateEnabledElement.value) {
                updateAll();
            }
        }, context);
    }
    : null;


export const destroyModelDebugElements = FLAG_DEBUG || FLAG_TEST
    ? (model: Model<Record<string, unknown>>) => {
        debugPanelService.visibilityChangeSignal.offAll(context);
        model.unsubscribeAll(context);
    }
    : null;