import type { Size } from '@nord-beaver/html-ui/utils/primitives';

import { type Entity } from '@nord-beaver/core/ecs';
import { type GridTilemapComponentDesc } from 'game/types/entityDescs/gridTilemap';
import { type LevelTableDesc } from 'game/types/entityDescs/levelTable';
import { type Region } from 'game/types/entityDescs/region';

export class GridTilemapComponent {
    readonly replicantEntities: { [rId: number]: Entity } = {};

    readonly tileEntities: Entity[] = [];

    get maxZoomSize() {
        return this.tileSize
            ? {
                width: this.tileSize.width * 2,
                height: this.tileSize.height * 2,
            }
            : undefined;
    }

    get minZoomSize() {
        return this.size;
    }

    size?: { width: number; height: number };
    tileSize?: Size;
    moveTileIndexes?: number[];
    selectedTileEntity: Entity | null = null;
    prevSelectedTileEntity: Entity | null = null;
    playerEntityRid: number | null = null;

    /** filled on entity add */
    levelTableDescs?: Record<Region, LevelTableDesc>;

    constructor(readonly desc: GridTilemapComponentDesc) {}
}