import { services } from '@nord-beaver/html-ui';
import Background from 'game/ui/windows/Background.vue';
import BackgroundLoading from 'game/ui/windows/BackgroundLoading.vue';
import Clans from 'game/ui/windows/Clans.vue';
import DailyReward from 'game/ui/windows/DailyReward.vue';
import EndMatch from 'game/ui/windows/EndMatch.vue';
import Friends from 'game/ui/windows/Friends.vue';
import Loading from 'game/ui/windows/Loading.vue';
import Match from 'game/ui/windows/Match.vue';
import Matchmaking from 'game/ui/windows/Matchmaking.vue';
import MatchRooms from 'game/ui/windows/MatchRooms.vue';
import Tasks from 'game/ui/windows/Tasks.vue';
import Tutorial from 'game/ui/windows/Tutorial.vue';
import Vault from 'game/ui/windows/Vault.vue';

const { WindowManager } = services.WindowManager;

export const windowManager = new WindowManager({
    layers: {
        Game: { zIndex: 0 },
        GameOverlay: { zIndex: 1 },
        Lobby: { zIndex: 2 },
        LobbyOverlay: { zIndex: 3 },
    },
    windows: {
        Background: {
            component: Background,
            layer: 'Lobby',
            renderType: 'initial',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
        },
        BackgroundLoading: {
            component: BackgroundLoading,
            layer: 'Lobby',
            renderType: 'initial',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
        },
        Loading: {
            component: Loading,
            layer: 'Lobby',
            renderType: 'initial',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        Tasks: {
            component: Tasks,
            layer: 'Lobby',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        Clans: {
            component: Clans,
            layer: 'Lobby',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        Friends: {
            component: Friends,
            layer: 'Lobby',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        DailyReward: {
            component: DailyReward,
            layer: 'Lobby',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        EndMatch: {
            component: EndMatch,
            layer: 'Lobby',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        MatchRooms: {
            component: MatchRooms,
            layer: 'Lobby',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        Matchmaking: {
            component: Matchmaking,
            layer: 'Lobby',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        Match: {
            component: Match,
            layer: 'LobbyOverlay',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        Vault: {
            component: Vault,
            layer: 'LobbyOverlay',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
        Tutorial: {
            component: Tutorial,
            layer: 'LobbyOverlay',
            renderType: 'rerender',
            position: {
                align: 'stretch',
                justify: 'stretch',
                offset: { x: 0, y: 0 },
            },
            fadeDuration: 400,
        },
    },
    states: {
        default: {
            windows: {
                Background: 'alwaysVisible',
            },
        },
        loading: {
            windows: {
                BackgroundLoading: 'alwaysVisible',
                Loading: 'alwaysVisible',
            },
        },
        tasks: {
            windows: {
                Background: 'alwaysVisible',
                Tasks: 'alwaysVisible',
            },
        },
        dailyReward: {
            windows: {
                Background: 'alwaysVisible',
                DailyReward: 'alwaysVisible',
            },
        },
        clans: {
            windows: {
                Background: 'alwaysVisible',
                Clans: 'alwaysVisible',
            },
        },
        friends: {
            windows: {
                Background: 'alwaysVisible',
                Friends: 'alwaysVisible',
            },
        },
        matchmaking: {
            windows: {
                Background: 'alwaysVisible',
                Matchmaking: 'alwaysVisible',
            },
        },
        match: {
            windows: {
                Match: 'alwaysVisible',
            },
        },
        endMatch: {
            windows: {
                Background: 'alwaysVisible',
                EndMatch: 'alwaysVisible',
            },
        },
        matchRooms: {
            windows: {
                Background: 'alwaysVisible',
                MatchRooms: 'alwaysVisible',
            },
        },
        tutorial: {
            windows: {
                Background: 'alwaysVisible',
                Tutorial: 'alwaysVisible',
            },
        },
        vault: {
            windows: {
                Vault: 'alwaysVisible',
            },
        },
    },
    groups: {
    },
}, 'default');

export type WindowKey = keyof typeof windowManager.view.windows;
