import { mainLogger } from '@nord-beaver/core/utils/logger';
import { api } from 'game/api/api';

export const getUserMetadata = (nakamaMetadata?: Record<string, unknown>) => {
    if (!nakamaMetadata) {
        return;
    }

    const result = api.UserMetadata.verify(nakamaMetadata);
    if (result) {
        mainLogger.error('getUserMetadata error', result);
    }

    const metadata = api.UserMetadata.fromObject(nakamaMetadata);

    return metadata;
};