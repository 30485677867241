<script setup lang="ts">
import InfoBlock from 'game/ui/commonInstances/InfoBlock.vue';
</script>

<template>
    <InfoBlock
        title="Invite a friend!"
        description="Gather your friends to conquer the city"
        description2="For each referral, you will receive a bonus from his earnings"
        :asset-path="'lobby/invite-friends.webp'"
    />
</template>
