import { type Session, type Client, type User } from '@heroiclabs/nakama-js';
import { defineNode, SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { MatchDataNakamaComponent } from 'game/ecs/components/nakama/matchDataNakamaComponent';
import { MatchPresenceNakamaComponent } from 'game/ecs/components/nakama/matchPresenceNakamaComponent';
import { NakamaComponent } from 'game/ecs/components/nakama/nakamaComponent';
import { NotificationNakamaComponent } from 'game/ecs/components/nakama/notificationNakamaComponent';
import { RpcNakamaComponent } from 'game/ecs/components/nakama/rpcNakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';
import { type EventService } from 'game/services/eventService';
import { type ClientNakamaService } from 'game/services/nakama/clientNakamaService';
import { type RpcNakamaService } from 'game/services/nakama/rpc/rpcNakamaService';
import { type SessionNakamaService } from 'game/services/nakama/session/sessionNakamaService';
import { AuthNakamaType } from 'game/services/nakama/session/sessionNakamaUtils';
import { type SocketNakamaService } from 'game/services/nakama/socketNakamaService';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getStringEnumValues } from 'game/utils/enum';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('System');

class NakamaNode extends defineNode({
    nakama: NakamaComponent,
}) { }

export class NakamaSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly clientNakamaService: ClientNakamaService,
        private readonly sessionNakamaService: SessionNakamaService,
        private readonly socketNakamaService: SocketNakamaService,
        private readonly rpcNakamaService: RpcNakamaService,
        private readonly eventService: EventService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: NakamaNode,
            add: this.addNakama,
            remove: this.removeNakama,
        });
    }

    private addNakama = async (node: NakamaNode) => {
        const { entity, nakama } = node;
        const { config } = nakama;
        const { authConfig } = config;

        const client = this.clientNakamaService.init(config);

        this.rpcNakamaService.configLogger(getStringEnumValues(ApiRpc));

        let authFunc: (() => Promise<Session>) | undefined;
        switch (authConfig.type) {
            case AuthNakamaType.Device:
                authFunc = () => this.sessionNakamaService.authenticateWithDevice(client, authConfig);
                break;
            case AuthNakamaType.Email:
                authFunc = () => this.sessionNakamaService.authenticateWithEmail(client, authConfig);
                break;
            case AuthNakamaType.Custom:
                authFunc = () => this.sessionNakamaService.authenticateWithCustom(client, authConfig);
                break;
            default:
                throw new Error('Unknown auth type');
        }

        const session = await this.createSession(authFunc, true);
        if (!session) {
            // throw global error
            return;
        }

        const [socket, socketAdapter] = await this.connectSocket(client, session);
        // if (!socket || !socketAdapter) {
        //     session = await this.createSession(authFunc, true);
        //     if (!session) {
        //         // throw global error
        //         return;
        //     }

        //     [socket, socketAdapter] = await this.connectSocket(client, session);
        // }
        if (!socket || !socketAdapter) {
            // throw global error
            return;
        }

        const disconnectSignal = this.socketNakamaService.getSocketEventSignal(socket, 'ondisconnect');
        disconnectSignal.on(() => {
            if (!entity.has(SocketNakamaComponent)) {
                return;
            }

            this.eventService.nakama.dispatch('disconnect', entity);

            logger.info('Socket disconnected', { entity });

            if (socket && socketAdapter) {
                this.socketNakamaService.disconnect(socket, socketAdapter);
            }

            entity.remove(SocketNakamaComponent);
        }, entity);

        let userData: User | undefined;
        if (session.user_id) {
            userData = await this.sessionNakamaService.getUserData(client, session, session.user_id);

            logger.info('User data received', { userData });
        }

        entity
            .add(new ClientNakamaComponent(client))
            .add(new SocketNakamaComponent(socket, socketAdapter))
            .add(new SessionNakamaComponent(session, userData))
            .add(new NotificationNakamaComponent(config.notificationHandlersDesc))
            .add(new MatchDataNakamaComponent(config.matchDataHandlersDesc))
            .add(new RpcNakamaComponent(config.rpcHandlersDesc))
            .add(new MatchPresenceNakamaComponent())
            .add(new MatchMapNakamaComponent());

        logger.info('Nakama entity added', { entity });
    };

    private removeNakama = (node: NakamaNode) => {
        const { entity } = node;

        const socket = entity.get(SocketNakamaComponent);
        const session = entity.get(SessionNakamaComponent);

        if (session && session.isSessionConnected) {
            this.sessionNakamaService.logout(session.session);

            session.isSessionConnected = false;
        }

        if (socket && socket.isConnected) {
            this.socketNakamaService.getSocketEventSignal(socket.socket, 'ondisconnect').offAll(entity);
            this.socketNakamaService.disconnect(socket.socket, socket.socketAdapter);
            socket.isConnected = false;
        }

        logger.info('Nakama entity removed', { entity });
    };

    private createSession = async (authFunc: () => Promise<Session>, skipRestore = false) => {
        let session = !skipRestore
            ? this.sessionNakamaService.restoreSession()
            : undefined;
        if (!session) {
            try {
                session = await authFunc();
            } catch (error) {
                logger.error('Failed to authenticate', { error });
            }
        }

        return session;
    };

    private connectSocket = async (client: Client, session: Session, withRetry = true) => {
        try {
            return await this.socketNakamaService.connect(client, session, withRetry);
        } catch (error) {
            logger.error('Failed to connect to socket', { error });
        }

        return [undefined, undefined] as const;
    };
}
