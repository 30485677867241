/**
 * Enum representing the type of the chance event.
 * @enum {string}
 * @description The type of the chance event, which can be either GainMoney or LoseMoney.
 */
export enum ChanceEventType {
    /**
     * Represents an event where the player gains money.
     */
    GainMoney = 'GainMoney',
    /**
     * Represents an event where the player loses money.
     */
    LoseMoney = 'LoseMoney',
}

/**
 * Represents the Gain Money event data.
 * @description Contains the amount of money a player gains during the GainMoney event.
 */
export type GainMoneyEventData = {
    /**
     * The amount of money the player gains.
     * @minimum 0
     */
    money: number;
};

/**
 * Represents the Lose Money event data.
 * @description Contains the amount of money a player loses during the LoseMoney event.
 */
export type LoseMoneyEventData = {
    /**
     * The amount of money the player loses.
     * @minimum 0
     */
    money: number;
};

/**
 * Represents the description of the Chance Event Component.
 * @description The Chance Event Component defines random events that can occur on a chance tile, with data specific to gaining or losing money.
 */
export type ChanceEventDesc = {
    /**
     * The type of the chance event.
     * @enum {ChanceEventType}
     * @description Specifies whether the event involves gaining or losing money.
     */
    type: ChanceEventType;

    /**
     * The data associated with the chance event.
     * @oneOf GainMoneyEventData | LoseMoneyEventData
     * @description The data specific to the event type, either containing the amount of money gained or lost.
     */
    data: GainMoneyEventData | LoseMoneyEventData;

    /**
     * The probability of the event occurring.
     * @minimum 0
     * @maximum 1
     */
    weight: number;
};