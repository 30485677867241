import { Session } from '@heroiclabs/nakama-js';
import { type Entity } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { retry } from '@nord-beaver/core/utils/utils';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';

export interface SessionConfig {
    sessionExpirationTimespanS?: number;
    sessionRefreshRetryCount?: number;
    sessionRefreshRetryTimeoutMs?: number;
    authRetryCount?: number;
    authRetryTimeoutMs?: number;
    userDataRetryCount?: number;
    userDataRetryTimeoutMs?: number;
}

export interface IEnvService {
    getStr(key: string): string | null;
    setStr(key: string, value: string): void;
    remove(key: string): void;
}

export type Data = { [key: string]: unknown };

export enum AuthNakamaType {
    Device = 'device',
    Email = 'email',
    Custom = 'custom',
}

export interface AuthBaseNakamaConfig<Type extends string = AuthNakamaType> {
    type: Type;
    username?: string;
    vars?: Record<string, string>;
}

export interface AuthWithDeviceNakamaConfig extends AuthBaseNakamaConfig<AuthNakamaType.Device> {
    deviceId: string;
}

export interface AuthWithEmailNakamaConfig extends AuthBaseNakamaConfig<AuthNakamaType.Email> {
    email: string;
    password: string;
}

export interface AuthWithCustomNakamaConfig extends AuthBaseNakamaConfig<AuthNakamaType.Custom> {
    customId: string;
}

export type AuthNakamaConfig = AuthWithEmailNakamaConfig | AuthWithDeviceNakamaConfig | AuthWithCustomNakamaConfig;

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('SessionUtils');

export const NAKAMA_STORAGE_KEY = 'nakama';
export const SESSION_STORAGE_KEY = 'session';
export const SESSION_TOKEN_STORAGE_KEY = 'token';
export const SESSION_REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';
export const SESSION_USER_ID_STORAGE_KEY = 'user_id';

export function getStorageKey(userId: string, key: string): string {
    return `${NAKAMA_STORAGE_KEY}:${SESSION_STORAGE_KEY}:${userId}:${key}`;
}

export async function sessionRefreshWithRetry(
    entity: Entity,
    retryCount: number,
    retryTimeoutMs: number,
) {
    let attempt = 0;

    return new Promise<Session>((resolve, reject) => {
        retry({
            work: async () => {
                const client = entity.get(ClientNakamaComponent);
                if (!client) {
                    const error = new Error('Client is not found');
                    reject(error);
                    throw error;
                }

                const session = entity.get(SessionNakamaComponent);
                if (!session || !session.isSessionConnected) {
                    const error = new Error('Session is not found');
                    reject(error);
                    throw error;
                }

                try {
                    return await client.client.sessionRefresh(session.session);
                } catch (error) {
                    const message = error instanceof Error ? error.message : error;
                    logger.error('Session refresh attempt failed', { error: message });
                    throw error;
                }
            },
            beforeRetry: () => logger.warn(`Retrying session refresh, attempt ${++attempt}`),
            count: retryCount,
            timeout: retryTimeoutMs,
        })
            .then(resolve)
            .catch(reject);
    });
}

export function saveSession(
    session: Session,
    storageService: IEnvService,
    userId: string,
) {
    const tokenKey = getStorageKey(userId, SESSION_TOKEN_STORAGE_KEY);
    const refreshTokenKey = getStorageKey(userId, SESSION_REFRESH_TOKEN_STORAGE_KEY);
    const userIdKey = getStorageKey(NAKAMA_STORAGE_KEY, SESSION_USER_ID_STORAGE_KEY);

    try {
        storageService.setStr(tokenKey, session.token);
        storageService.setStr(refreshTokenKey, session.refresh_token);
        storageService.setStr(userIdKey, userId);
        logger.info('Session tokens saved to storage');
    } catch (error) {
        const message = error instanceof Error ? error.message : error;
        logger.error('Failed to save session tokens', { error: message });
    }
}

export function restoreSession(
    storageService: IEnvService,
    userId?: string,
) {
    if (!userId) {
        const userIdKey = getStorageKey(NAKAMA_STORAGE_KEY, SESSION_USER_ID_STORAGE_KEY);
        userId = storageService.getStr(userIdKey) ?? undefined;
        if (!userId) {
            logger.info('No user id found in storage', { userIdKey });
            return null;
        }
    }

    const tokenKey = getStorageKey(userId, SESSION_TOKEN_STORAGE_KEY);
    const refreshTokenKey = getStorageKey(userId, SESSION_REFRESH_TOKEN_STORAGE_KEY);

    const token = storageService.getStr(tokenKey);
    const refreshToken = storageService.getStr(refreshTokenKey);

    if (!token || !refreshToken) {
        logger.info('No session found in storage', { tokenKey, refreshTokenKey });
        return null;
    }

    try {
        const session = Session.restore(token, refreshToken);
        logger.info('Session restored from storage');
        return session;
    } catch (error) {
        const message = error instanceof Error ? error.message : error;
        logger.error('Failed to restore session', { error: message });
        storageService.remove(tokenKey);
        storageService.remove(refreshTokenKey);
        return null;
    }
}

export function removeSession(
    storageService: IEnvService,
    userId: string,
) {
    const tokenKey = getStorageKey(userId, SESSION_TOKEN_STORAGE_KEY);
    const refreshTokenKey = getStorageKey(userId, SESSION_REFRESH_TOKEN_STORAGE_KEY);

    storageService.remove(tokenKey);
    storageService.remove(refreshTokenKey);

    logger.info('Session removed from storage');
}
