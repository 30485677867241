<script setup lang="ts">
import { clamp } from '@nord-beaver/core/utils/utils';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { computed } from 'vue';

const progress = useModel(['vault', 'progress']);
const isAvailable = useModel(['vault', 'isAvailable']);
const eventService = useEventService();

const isActive = computed(() => progress.value !== 1);

const images = [
    'vault/coins0.webp',
    'vault/coins1.webp',
    'vault/coins2.webp',
];

const activeImageIndex = computed(() => clamp(0, images.length, Math.floor(progress.value * (images.length + 1))));

const claim = () => {
    if (!isAvailable.value) {
        return;
    }

    eventService.lobby.dispatch('claimVaultReward');
};
</script>

<template>
    <div class="vault-container">
        <div v-if="isActive" class="animation">
            <img class="animation__sprite" :src="getAssetUrl('gifs/coin30.gif')" alt="">
        </div>

        <div class="background">
            <img :src="getAssetUrl('vault/vault.webp')" alt="" class="background__image">

            <template v-for="(image, index) in images" :key="image">
                <img
                    v-show="(index) === activeImageIndex - 1"
                    :src="getAssetUrl(image)"
                    :class="`background__image background__image--${index}`"
                    alt=""
                >
            </template>
        </div>

        <LobbyLayout :container-id="ContainerIds.Vault" class="vault-container__layout" />

        <div class="vault-container__button">
            <MainButton
                class="vault-container__claim-button"
                label="Claim"
                :disabled="!isAvailable"
                @click="claim"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
.background {
    position: absolute;
    bottom: size(260px);
    left: 50%;
    z-index: 0;

    width: 100%;
    height: 100%;

    transform: translate(-50%, 0);

    &__image {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        object-fit: cover;

        &--0 {
            bottom: size(78px);
            z-index: 1;
        }

        &--1 {
            bottom: size(78px);
            z-index: 2;
        }

        &--2 {
            bottom: size(78px);
            z-index: 3;
        }
    }
}

.animation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;

    align-items: flex-start;

    width: 100%;
    height: 100%;

    &__sprite {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }
}

.vault-container {
    &__layout {
        z-index: 3;

        width: 100%;
        height: 100%;
    }

    &__button {
        position: absolute;
        bottom: size(460px);
        left: 50%;
        z-index: 5;

        display: flex;

        align-items: flex-end;
        justify-content: center;

        width: 100%;
        height: 100%;

        transform: translate(-50%, 0);
    }

    &__claim-button {
        width: size(1124px);
        height: size(178.119px);
    }
}
</style>
