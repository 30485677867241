import { type Region } from 'game/types/entityDescs/region';

/**
 * Enum representing the type of the grid tile.
 * @enum {string}
 * @description The type of the tile, which can be either Regular or Chance.
 */
export enum GridTileType {
    /** Represents a regular tile. */
    Regular = 'Regular',
    /** Represents a chance tile. */
    Chance = 'Chance',
}

/**
 * Represents the Regular Tile data.
 * @description Contains data for a regular grid tile, including ownership, profitability, and cost-related information.
 */
export type RegularTileData = {
    /**
     * The type of the tile.
     * @constant
     * @default "Regular"
     */
    type: GridTileType.Regular;

    /**
     * The region to which the tile belongs.
     */
    region: Region;

    /**
     * The nominal cost to purchase a neutral tile.
     * @minimum 0
     */
    nominalCost: number;
};

/**
 * Represents the Chance Tile data.
 * @description Contains data for a chance tile, including the region and possible chance events.
 */
export type ChanceTileData = {
    /**
     * The type of the tile.
     * @constant
     * @default "Chance"
     */
    type: GridTileType.Chance;

    /**
     * The region to which the tile belongs.
     * Can be null if the tile is not associated with a region.
     */
    region?: Region;

    /**
     * An array of links to possible chance events.
     */
    chanceEventLinks: string[];
};

/**
 * Represents the description of the Grid Tile Component.
 * @description The Grid Tile Component can represent either a Regular or Chance tile, with data specific to each type.
 */
export type GridTileComponentDesc = {
    /**
     * The type of the grid tile.
     * @description Specifies whether the tile is a Regular or Chance tile.
     */
    type: GridTileType;

    /**
     * The data associated with the tile, which varies depending on the type.
     */
    data: RegularTileData | ChanceTileData;
};