<script setup lang="ts">
import { SoundAssetIds } from 'game/constants';
import Button from 'game/ui/common/Buttons/Button.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';

type ButtonColor = 'yellow' | 'red';

withDefaults(
    defineProps<{
        iconName?: string | null;
        iconPack?: string | null;
        color?: ButtonColor;
        label?: string | null;
        disabled?: boolean;
        noBorder?: boolean;
        sound?: SoundAssetIds | null;
    }>(),
    {
        iconName: null,
        iconPack: null,
        label: null,
        color: 'yellow',
        noBorder: false,
        sound: SoundAssetIds.Button,
    },
);

const emit = defineEmits<{
    (event: 'click'): void;
}>();
</script>

<template>
    <Button
        :class="[
            'main-button',
            `main-button--color-${color}`,
            {
                'main-button--disabled': disabled,
            }, {
                'main-button--no-border': noBorder,
            }
        ]"
        :is-enabled="!disabled"
        :sound="sound"
        @click="emit('click')"
    >
        <SvgIcon
            v-if="iconName && iconPack"
            class="main-button__icon"
            :name="iconName"
            :pack="iconPack"
        />
        <div class="main-button__label">
            {{ label }}
        </div>

        <slot />
    </Button>
</template>

<style scoped lang="scss">
.main-button {
    $parent: &;

    position: relative;

    gap: size(20px);

    padding: size(34px);
    overflow: hidden;

    font-family: $main-button-font-family;
    font-size: size(64px);
    font-weight: 700;
    line-height: size(95px);
    color: var(--grey-300, #323232);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: size(4px);

    user-select: none;

    background-image: url("assets/sprites/ui/lobby/dotted-bg.webp");
    background-position: center;
    background-blend-mode: color-burn;
    border: size(6px) solid var(--black, #000);
    border-radius: size(40px);
    box-shadow: 0 size(4px) size(4px) 0 rgb(0 0 0 / 25%);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    * {
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
    }

    @include center;

    &__icon {
        width: size(64px);
        height: size(64px);
    }

    &--color-yellow {
        background-color: var(--yellow-300, #E5AA13ee);
    }

    &--color-red {
        background-color: var(--red-300, #B74567ee);
    }

    &--disabled {
        background-color: var(--grey, #939393ee);
    }

    &--no-border {
        border: initial;
        border-radius: initial;
        box-shadow: initial;
    }

    @include hover {
        &:not(#{$parent}--disabled) {
            color: var(--white, #FFF);
        }
    }
}
</style>
