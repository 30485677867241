<script setup lang="ts">
import { clamp } from '@nord-beaver/core/utils/utils';
import { hooks, type utils } from '@nord-beaver/html-ui';
import Button from 'game/ui/common/Buttons/Button.vue';
import { computed, ref, watch } from 'vue';

const { useMouse } = hooks.mouse;
const { useWindowEvent } = hooks.windowEvent;
const { useResize } = hooks.resize;

const props = defineProps<{
    modelValue: number;
}>();
const emit = defineEmits(['update:modelValue', 'scrollStart', 'scrollEnd']);

const outerModel = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', clamp(0, 1, value)),
});
const innerModel = ref(props.modelValue);
const mouse = useMouse();
const scrollbar = ref<HTMLDivElement | null>(null);
const thumb = ref<HTMLDivElement | null>(null);

const data = ref<{
    pointer: utils.primitives.Point;
    offset: utils.primitives.Point;
} | null>(null);

const onPointerDown = (event: PointerEvent) => {
    data.value = {
        pointer: { x: mouse.x.value, y: mouse.y.value },
        offset: { x: event.offsetX, y: event.offsetY },
    };
};
const onPointerUp = () => {
    data.value = null;
};
const updateModel = () => {
    if (data.value !== null && scrollbar.value !== null && thumb.value !== null) {
        const position = { x: mouse.x.value - data.value.offset.x, y: mouse.y.value - data.value.offset.y };
        const scrollbarBounds = scrollbar.value.getBoundingClientRect();
        const thumbBounds = thumb.value.getBoundingClientRect();

        innerModel.value = clamp(0, 1, (position.y - scrollbarBounds.y) / (scrollbarBounds.height - thumbBounds.height));

        outerModel.value = innerModel.value;
    }
};

useWindowEvent('pointerup', onPointerUp);
useResize(updateModel);

watch([mouse.x, mouse.y], updateModel);
watch([outerModel], () => {
    if (data.value !== null) {
        return;
    }

    innerModel.value = outerModel.value;
});

const getTransformString = (value: number) => `translateY(${value * 100}%)`;
</script>

<template>
    <div ref="scrollbar" :class="`scrollbar`">
        <div :class="`scrollbar__track`" />

        <div :class="`scrollbar__thumb-container`" :style="{ transform: getTransformString(innerModel) }">
            <div ref="thumb" :class="`scrollbar__thumb`">
                <Button
                    ref="thumb"
                    :class="`scrollbar__thumb-button`"
                    @pointerdown="onPointerDown"
                    @pointerup="onPointerUp"
                />
            </div>
        </div>
    </div>
</template>