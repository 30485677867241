import { type MatchPresenceEvent } from '@heroiclabs/nakama-js';
import { SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { Signal } from '@nord-beaver/core/utils/signal';
import { type ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { type MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { type MatchPresenceData, type MatchPresenceNakamaComponent } from 'game/ecs/components/nakama/matchPresenceNakamaComponent';
import { type SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { MatchPresenceNakamaNode } from 'game/ecs/nodes/nakama/matchPresenceNakamaNode';
import { type SessionNakamaService } from 'game/services/nakama/session/sessionNakamaService';
import { type SocketNakamaService } from 'game/services/nakama/socketNakamaService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('MatchPresence');

export class MatchPresenceNakamaSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        readonly socketNakamaService: SocketNakamaService,
        readonly sessionNakamaService: SessionNakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MatchPresenceNakamaNode,
            add: this.addMatchPresence,
            remove: this.removeMatchPresence,
        });
    }

    private addMatchPresence = (node: MatchPresenceNakamaNode) => {
        const { matchMap, matchPresence, socket, client, session, entity } = node;
        const matchPresenceSocketSignal = this.socketNakamaService.getSocketEventSignal(socket.socket, 'onmatchpresence');

        for (const [matchId] of matchMap.matchMap) {
            this.onMatchJoin(client, session, matchMap, matchPresence, matchId);
        }

        matchMap.matchJoinSignal.on((matchId: string) => this.onMatchJoin(client, session, matchMap, matchPresence, matchId), entity);
        matchMap.matchLeaveSignal.on((matchId: string) => this.onMatchLeave(matchPresence, matchId), entity);
        matchPresenceSocketSignal.on(([matchPresenceEvent]) => this.onMatchPresence(client, session, matchPresence, matchPresenceEvent), entity);
        socket.processFrameSignal.on(() => this.processFrame(matchMap, matchPresence), entity);

        logger.info('add match presence handler', { entity });
    };

    private removeMatchPresence = (node: MatchPresenceNakamaNode) => {
        const { matchMap, socket, matchPresence, entity } = node;
        const matchPresenceSocketSignal = this.socketNakamaService.getSocketEventSignal(socket.socket, 'onmatchpresence');

        matchPresenceSocketSignal.offAll(entity);
        socket.processFrameSignal.offAll(entity);
        matchMap.matchJoinSignal.offAll(entity);
        matchMap.matchLeaveSignal.offAll(entity);

        for (const [matchId] of matchMap.matchMap) {
            this.onMatchLeave(matchPresence, matchId);
        }

        logger.info('remove match presence handler', { entity });
    };

    private onMatchPresence = async (
        client: ClientNakamaComponent,
        session: SessionNakamaComponent,
        matchPresence: MatchPresenceNakamaComponent,
        matchPresenceEvent: MatchPresenceEvent,
    ) => {
        const { match_id: matchId } = matchPresenceEvent;

        logger.info(`Received match presence event for matchId: ${matchId}`, matchPresenceEvent);

        const matchPresenceData = matchPresence.matchPresenceMap.get(matchId);
        if (!matchPresenceData) {
            logger.warn(`No match presence data found for matchId: ${matchId}`);

            return;
        }

        matchPresenceData.frameJoins = [
            ...matchPresenceData.frameJoins,
            ...matchPresenceEvent.joins,
        ];
        matchPresenceData.frameLeaves = [
            ...matchPresenceData.frameLeaves,
            ...matchPresenceEvent.leaves,
        ];
        matchPresenceData.presence = [
            ...matchPresenceEvent.joins,
            ...matchPresenceData.presence.filter(presence => !matchPresenceEvent.leaves.some(leave => leave.session_id === presence.session_id)),
        ];

        if (matchPresence.isRequestUserDatas) {
            await this.requestUserDatas(client, session, matchPresenceData);
        }

        const matchPresenceSignal = matchPresence.matchPresenceSignals.get(matchId);
        if (!matchPresenceSignal) {
            logger.warn(`No match presence signal found for matchId: ${matchId}`);
            return;
        }

        matchPresenceSignal.dispatch(matchPresenceEvent);
    };

    private requestUserDatas = async (
        client: ClientNakamaComponent,
        session: SessionNakamaComponent,
        matchPresenceData: MatchPresenceData,
    ) => {
        for (const leave of matchPresenceData.frameLeaves) {
            delete matchPresenceData.userData[leave.user_id];
        }

        const joinUserIds = matchPresenceData.frameJoins.map(join => join.user_id);
        if (joinUserIds.length === 0) {
            return;
        }

        const userDatas = await this.sessionNakamaService.getUserData(client.client, session.session, joinUserIds);

        logger.info('Received user datas', userDatas);

        for (let i = 0; i < joinUserIds.length; i++) {
            const userId = joinUserIds[i];
            const userData = userDatas[i];
            if (!userData || !userId) {
                logger.warn(`No user data found for userId: ${userId}`);
                continue;
            }

            matchPresenceData.userData[userId] = userData;
        }
    };

    private processFrame = (matchMap: MatchMapNakamaComponent, matchPresence: MatchPresenceNakamaComponent) => {
        for (const [matchId] of matchMap.matchMap) {
            const matchPresenceData = matchPresence.matchPresenceMap.get(matchId);
            if (!matchPresenceData) {
                logger.warn(`No match presence data found for matchId: ${matchId}`);
                return;
            }

            matchPresenceData.frameJoins = [];
            matchPresenceData.frameLeaves = [];
        }
    };

    private onMatchJoin = async (
        client: ClientNakamaComponent,
        session: SessionNakamaComponent,
        matchMap: MatchMapNakamaComponent,
        matchPresence: MatchPresenceNakamaComponent,
        matchId: string,
    ) => {
        const match = matchMap.matchMap.get(matchId);
        if (!match) {
            logger.warn(`No match found for matchId: ${matchId}`);
            return;
        }

        let matchPresenceData = matchPresence.matchPresenceMap.get(matchId);
        if (!matchPresenceData) {
            matchPresenceData = {
                frameJoins: [
                    ...match.presences,
                ],
                frameLeaves: [],
                presence: [
                    ...match.presences,
                ],
                userData: {},
            };

            if (matchPresence.isRequestUserDatas) {
                await this.requestUserDatas(client, session, matchPresenceData);
            }

            matchPresence.matchPresenceMap.set(matchId, matchPresenceData);
        }

        if (!matchPresence.matchPresenceSignals.has(matchId)) {
            matchPresence.matchPresenceSignals.set(matchId, new Signal());
        }

        logger.info(`Subscribing to match presence for matchId: ${matchId}`);
    };

    private onMatchLeave = (matchPresence: MatchPresenceNakamaComponent, matchId: string) => {
        matchPresence.matchPresenceSignals.delete(matchId);
        matchPresence.matchPresenceMap.delete(matchId);

        logger.info(`Unsubscribing from match presence for matchId: ${matchId}`);
    };
}