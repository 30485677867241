export enum EnitityComponents {
    Player = 'player',
    Match = 'match',
    GridTilemap = 'gridTilemap',
    GridTile = 'gridTile',
    LevelTable = 'levelTable',
    ChanceEvent = 'chanceEvent',
    DiceFace = 'diceFace',
    Dice = 'dice',
    DailyRewards = 'dailyRewards',
    Vault = 'vault',
}