import { SystemService } from '@nord-beaver/core/ecs';
import { type Model } from '@nord-beaver/core/mvc/model';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { MatchRoomsComponent } from 'game/ecs/components/match/matchRoomsComponent';
import { NakamaNode } from 'game/ecs/nodes/nakama/nakamaNode';
import { type MatchModel } from 'game/types/model/matchModel';
import { type MatchRoom, type MatchRoomsModel } from 'game/types/model/matchRoomsModel';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';

const logger = mainLogger.getLogger('Matchmaking').getLogger('View');

export class MatchmakingViewSystem extends SystemService {
    private readonly matchModel: Model<MatchModel>;
    private readonly matchRoomsModel: Model<MatchRoomsModel>;

    constructor(
        dependencyContainer: DependencyContainer,
    ) {
        super();

        this.matchModel = getModel(dependencyContainer, ['match']);
        this.matchRoomsModel = getModel(dependencyContainer, ['matchRooms']);
    }

    async init() {
        this.setupNodeList({
            node: NakamaNode,
            update: this.updateNakama,
        });
    }

    private updateNakama(node: NakamaNode) {
        const { matchPresence, entity } = node;

        matchPresence.matchPresenceMap.forEach(matchPresence => {
            matchPresence.frameLeaves.forEach(frameLeave => {
                delete this.matchModel.data.matchPlayers[frameLeave.user_id];
            });

            matchPresence.presence.forEach(presence => {
                if (this.matchModel.data.matchPlayers[presence.user_id]) {
                    return;
                }

                this.matchModel.data.matchPlayers[presence.user_id] = {
                    name: presence.username,
                };
            });
        });

        const matchRooms = entity.get(MatchRoomsComponent);
        if (matchRooms && matchRooms.isDirty) {
            this.updateMatchRooms(matchRooms);
        }
    }

    private updateMatchRooms(matchRooms: MatchRoomsComponent) {
        matchRooms.isDirty = false;

        const roomsView: MatchRoom[] = [];

        for (const matchId in matchRooms.rooms) {
            const matchDesc = matchRooms.rooms[matchId];
            if (!matchDesc) {
                logger.error('MatchRoomsComponent: matchDesc is not found', { matchId });

                continue;
            }

            roomsView.push({
                matchId,
                name: matchDesc.matchName,
                betCoins: matchDesc.bet.map(bet => ({
                    type: bet.type,
                    value: bet.value,
                })),
                playersUserIds: matchDesc.playerIds,
            });
        }

        this.matchRoomsModel.data.rooms = roomsView;
    }
}