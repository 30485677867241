import type { Size } from '@nord-beaver/html-ui/utils/primitives';

import { defineNode, type Entity, SystemService } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { pointerInputSystem } from '@nord-beaver/core/ecs/systems/pointerInputSystem';
import { type CameraService } from '@nord-beaver/core/services/cameraService';
import { type StageService } from '@nord-beaver/core/services/stageService';
import { type TweenService, type Tween } from '@nord-beaver/core/services/tweenService';
import { type WheelControl, type KeyControl } from '@nord-beaver/core/utils/input/controls';
import { type Mouse, MOUSE_CONTROLS } from '@nord-beaver/core/utils/input/devices/mouse';
import { type Touch, TOUCH_CONTROLS } from '@nord-beaver/core/utils/input/devices/touch';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { Point, type PointLike } from '@nord-beaver/core/utils/point';
import { clamp } from '@nord-beaver/core/utils/utils';
import { canvasOffsetY } from 'game/constants';
import { DragComponent } from 'game/ecs/components/dragComponent';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { GridTilemapNode } from 'game/ecs/nodes/gridTilemap/gridTilemapNode';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Camera');

class DragGridTilemapNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    gridTilemap: GridTilemapComponent,
    drag: DragComponent,
}) { }

export class CameraMoveSystem extends SystemService {
    private readonly wheelZoomRate = 0.004;
    private readonly touchPinchZoomRate = 0.03;
    private readonly cameraTweenTime = 150;
    private readonly cameraZoomStep = 0.1;
    private cameraPosition = new Point();
    private cameraMoveTween: Tween<Point> | null = null;
    private cameraZoomTween: Tween<CameraService> | null = null;
    private cameraZoomDirty = false;
    private minZoom: number | null = null;
    private maxZoom: number | null = null;
    private followEntity: Entity | null = null;
    private mouseLeftControl!: Readonly<KeyControl>;
    private touchControl!: Readonly<KeyControl>;
    private mouseWheelControl!: Readonly<WheelControl>;
    private touchPinchControl!: Readonly<WheelControl>;
    private initCameraPosition = new Point();

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly cameraService: CameraService,
        private readonly stageService: StageService,
        private readonly mouse: Mouse,
        private readonly touch: Touch,
        private readonly tweenService: TweenService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: GridTilemapNode,
            add: this.onTilemapAdd,
            remove: this.onTilemapRemove,
        });
        this.setupNodeList({
            node: DragGridTilemapNode,
            update: this.onTilemapUpdate,
        });

        this.mouseLeftControl = this.mouse.getControl(MOUSE_CONTROLS.Left);
        this.touchControl = this.touch.getControl(TOUCH_CONTROLS.Touch);
        this.mouseWheelControl = this.mouse.getControl(MOUSE_CONTROLS.Wheel);
        this.touchPinchControl = this.touch.getControl(TOUCH_CONTROLS.Pinch);
    }

    private onTilemapUpdate(node: DragGridTilemapNode, dt: number): void {
        const { gridTilemap, drag, entity } = node;
        const { minZoomSize } = gridTilemap;

        if (this.followEntity === null) {
            this.followEntity = entity;
            logger.warn('follow entity was empty, set to current entity');
        }

        if (minZoomSize === undefined) {
            return;
        }
        if (this.followEntity !== entity) {
            logger.error('TilemapSystem: follow entity already set');

            return;
        }

        const worldPointerPosition = pointerInputSystem.pointerWorld;
        const isJustDown = this.mouseLeftControl.state.isJustDown || this.touchControl.state.isJustDown;
        const isJustUp = this.mouseLeftControl.state.isJustUp || this.touchControl.state.isJustUp;
        if (isJustDown) {
            drag.prepare(worldPointerPosition);
        } else if ((drag.isDragging || drag.isPressed) && isJustUp) {
            drag.endDrag();
        }

        if (drag.isPressed) {
            const diffX = worldPointerPosition.x - drag.mouseDownPosition.x;
            const diffY = worldPointerPosition.y - drag.mouseDownPosition.y;

            if (drag.isDragging || (Math.abs(diffX) > drag.cameraStartMoveThreshold || Math.abs(diffY) > drag.cameraStartMoveThreshold)) {
                drag.startDrag(dt);

                this.updateCameraBounds(minZoomSize, {
                    x: this.cameraPosition.x - diffX,
                    y: this.cameraPosition.y - diffY,
                });
            }
        } else if (this.cameraZoomDirty) {
            this.updateCameraBounds(minZoomSize);
        }
    }

    private onTilemapAdd = (node: GridTilemapNode) => {
        const { gridTilemap, entity } = node;
        const { minZoomSize, maxZoomSize } = gridTilemap;

        if (!minZoomSize || !maxZoomSize) {
            logger.error('TilemapSystem: invalid map size');

            return;
        }

        if (this.followEntity) {
            logger.error('TilemapSystem: follow entity already set');

            return;
        }

        entity.add(new DragComponent());

        this.cameraPosition.set(minZoomSize.width / 2, minZoomSize.height / 2);
        Point.copy(this.cameraPosition, this.initCameraPosition);
        this.cameraService.defaultCamera.follow(this.cameraPosition, { resetPosition: true });

        this.setupCameraZoom(maxZoomSize, minZoomSize);
        this.stageService.resizeSignal.on(() => this.setupCameraZoom(maxZoomSize, minZoomSize), entity);
        this.mouseWheelControl.signal.on(event => {
            this.zoomCamera(minZoomSize, event.deltaY * -this.wheelZoomRate);
        }, entity);
        this.touchPinchControl.signal.on(event => {
            this.zoomCamera(minZoomSize, event.deltaY * this.touchPinchZoomRate);
        }, entity);

        this.followEntity = entity;
    };

    private onTilemapRemove = (node: GridTilemapNode) => {
        this.mouseWheelControl.signal.offAll(node.entity);
        this.touchPinchControl.signal.offAll(node.entity);
        this.stageService.resizeSignal.offAll(node.entity);

        if (this.followEntity === node.entity) {
            this.followEntity = null;
        }
    };

    private updateCameraBounds(minZoomSize: Size, cameraPosition: PointLike = this.cameraPosition) {
        const camera = this.cameraService.defaultCamera;
        const viewportWidth = camera.width / camera.zoom;
        const viewportHeight = camera.height / camera.zoom;
        const halfViewportWidth = viewportWidth / 2;
        const halfViewportHeight = viewportHeight / 2;
        const offsetY = canvasOffsetY;

        const minX = Math.min(minZoomSize.width / 2, halfViewportWidth);
        const minY = Math.min(minZoomSize.height / 2, halfViewportHeight) - offsetY;
        const maxX = Math.max(minZoomSize.width - halfViewportWidth, minZoomSize.width / 2);
        const maxY = Math.max(minZoomSize.height - halfViewportHeight, minZoomSize.height / 2) + offsetY;

        if (this.cameraMoveTween) {
            this.tweenService.removeTween(this.cameraMoveTween);
        }
        const cameraMoveTween = this.tweenService.addTween({
            target: this.cameraPosition,
            tween: { to: {
                x: Math.trunc(clamp(minX, maxX, cameraPosition.x)),
                y: Math.trunc(clamp(minY, maxY, cameraPosition.y)),
            }, time: this.cameraTweenTime },
        });

        cameraMoveTween.onComplete.once(() => {
            this.cameraZoomDirty = false;
        });
    }

    private zoomCamera(minZoomSize: Size, diff: number) {
        if (this.cameraZoomTween) {
            this.tweenService.removeTween(this.cameraZoomTween);
        }
        this.cameraZoomTween = this.tweenService.addTween({
            target: this.cameraService.defaultCamera,
            tween: { to: { zoom: this.cameraService.defaultCamera.zoom + diff }, time: this.cameraTweenTime },
        });

        this.cameraZoomDirty = true;
        this.cameraZoomTween.onComplete.once(() => {
            if (this.cameraZoomTween) {
                this.tweenService.removeTween(this.cameraZoomTween);
            }
            this.cameraZoomTween = null;
            this.updateCameraBounds(minZoomSize);
            this.cameraZoomDirty = false;
        });
    }

    private setupCameraZoom(maxZoomSize: Size, minZoomSize: Size) {
        if (this.cameraZoomTween) {
            this.tweenService.removeTween(this.cameraZoomTween);
        }

        const previousMinZoom = this.minZoom;
        const previousMaxZoom = this.maxZoom;

        this.minZoom = Math.min(
            this.cameraService.defaultCamera.width / minZoomSize.width,
            this.cameraService.defaultCamera.height / minZoomSize.height,
        );
        this.maxZoom = Math.min(
            this.cameraService.defaultCamera.width / maxZoomSize.width,
            this.cameraService.defaultCamera.height / maxZoomSize.height,
        );

        let newZoom: number;
        if (previousMinZoom === null || previousMaxZoom === null) {
            newZoom = this.minZoom;
        } else {
            const zoomRatio = (this.cameraService.defaultCamera.zoom - previousMinZoom) / (previousMaxZoom - previousMinZoom);
            newZoom = this.minZoom + zoomRatio * (this.maxZoom - this.minZoom);
            newZoom = clamp(this.minZoom, this.maxZoom, newZoom);
        }

        this.cameraService.defaultCamera.setupZoomParams({
            min: this.minZoom,
            max: this.maxZoom,
            step: this.cameraZoomStep,
            curent: newZoom,
        });

        this.cameraZoomDirty = true;
    }
}