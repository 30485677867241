import { type AudioEvents } from 'game/types/eventService/audioEvents';
import { type GameEvents } from 'game/types/eventService/game';
import { type LobbyEvents } from 'game/types/eventService/lobby';
import { type MatchEvents } from 'game/types/eventService/match';
import { type RequestMetaEvents } from 'game/types/eventService/meta';
import { type NakamaEvents } from 'game/types/eventService/nakama';
import { type ViewCoreEvents } from 'game/types/eventService/view';
import { type WindowManagerEvents } from 'game/types/eventService/windowManager';
import { EventBus } from 'game/utils/eventBus';

export class EventService {
    readonly core = new EventBus<ViewCoreEvents>();
    readonly lobby = new EventBus<LobbyEvents>();
    readonly match = new EventBus<MatchEvents>();
    readonly game = new EventBus<GameEvents>();
    readonly nakama = new EventBus<NakamaEvents>();
    readonly metaData = new EventBus<RequestMetaEvents>();
    readonly windowManager = new EventBus<WindowManagerEvents>();
    readonly audio = new EventBus<AudioEvents>();
}