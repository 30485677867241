import { EventService } from 'game/services/eventService';
import { DEPENDENCY_CONTAINER_INJECTION_KEY } from 'game/ui/constants/provide';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { inject } from 'vue';

export const useEventService = () => {
    const dependencyContainer = inject<DependencyContainer>(DEPENDENCY_CONTAINER_INJECTION_KEY);
    if (!dependencyContainer) {
        throw new Error('DependencyContainer not found');
    }

    return dependencyContainer.get(EventService);
};