import { type config as coreConfig, utils } from '@nord-beaver/html-ui';
import { screenDefaultBounds } from 'game/constants';

export const config: coreConfig.app.AppConfig = {
    desktop: {
        size: {
            width: screenDefaultBounds.width,
            height: screenDefaultBounds.height,
        },
        scale: utils.scale.scaleXLimited,
    },
    threshold: 0,
};