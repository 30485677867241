<script setup lang="ts">
import Panel from 'game/ui/commonInstances/Panel.vue';
import ScrollPan from 'game/ui/commonInstances/ScrollPan.vue';
</script>

<template>
    <Panel class="panel-list" padding-right="0px">
        <ScrollPan class="panel-list__block">
            <div class="panel-list__block__inner">
                <slot />
            </div>
        </ScrollPan>
    </Panel>
</template>

<style scoped lang="scss">
.panel-list {
    &__block {
        &__inner {
            display: flex;

            flex-direction: column;

            gap: size(21px);

            max-height: size(988px);
            padding-right: size(60px);
        }
    }
}
</style>
