import { MatchDataHandlers, type MatchDataHandlersDesc } from 'game/services/nakama/matchData/matchDataHandlers';
import { type MatchDataRequestSchema } from 'game/services/nakama/matchData/matchDataNakamaService';
import { MatchDataQueue } from 'game/services/nakama/matchData/matchDataQueue';

export class MatchDataNakamaComponent {
    /** Queue of received and to send match data queues at that frame */
    readonly matchDataQueue = new MatchDataQueue();
    readonly matchDataHandlers: MatchDataHandlers;

    constructor(handlersDesc: MatchDataHandlersDesc) {
        this.matchDataHandlers = new MatchDataHandlers(handlersDesc);
    }

    /** Returns the received match data at that frame */
    getReceivedMatchData<Code extends number, MatchDataSchemaType extends MatchDataRequestSchema<Code>>(matchId: string, opcode: Code): MatchDataSchemaType[Code][] {
        return this.matchDataQueue.getReceivedMatchData(matchId, opcode) as MatchDataSchemaType[Code][];
    }

    sendMatchData<Code extends number, MatchDataSchemaType extends MatchDataRequestSchema<Code>>(
        matchId: string,
        opcode: Code,
        data: MatchDataSchemaType[Code],
    ) {
        this.matchDataQueue.enqueueSendMatchData(matchId, opcode, data);
    }
}