import { type DependencyContainer } from 'game/utils/dependencyContainer';

class DependencyGraph<T> {
    private graph: Map<T, T[]> = new Map();
    private cyclePath: T[] = [];

    constructor(dependencies: Map<T, T[]>) {
        this.graph = dependencies;
    }

    checkForCycles(): boolean {
        const visited = new Set<T>();
        const recursionStack = new Set<T>();
        const pathStack: T[] = [];

        const visit = (serviceName: T): boolean => {
            if (recursionStack.has(serviceName)) {
                this.cyclePath = [...pathStack, serviceName];
                return true;
            }

            if (visited.has(serviceName)) {
                return false;
            }

            visited.add(serviceName);
            recursionStack.add(serviceName);
            pathStack.push(serviceName);

            const dependencies = this.graph.get(serviceName) || [];
            for (const dependency of dependencies) {
                if (visit(dependency)) {
                    return true;
                }
            }

            recursionStack.delete(serviceName);
            pathStack.pop();
            return false;
        };

        for (const service of this.graph.keys()) {
            if (visit(service)) {
                return true;
            }
        }

        return false;
    }

    getCyclePath(): T[] {
        return this.cyclePath;
    }
}

export function testForDependencyCycles(dependencyContainer: DependencyContainer) {
    const graph = new DependencyGraph(dependencyContainer.dependencyMap);
    if (!graph.checkForCycles()) {
        return;
    }

    const cyclePath = graph.getCyclePath();
    const cycleMessage = cyclePath.length > 0
        ? cyclePath.length === 2 && cyclePath[0] === cyclePath[1]
            ? `Self-referencing dependency found: ${cyclePath[0]?.name}`
            : `Dependency cycle found: ${cyclePath.map(ctor => ctor.name).join(' -> ')}`
        : 'Cycle detected';
    throw new Error(`${cycleMessage}. Resolve the cycle to continue.`);
}