<script setup lang="ts">
import { SoundAssetIds } from 'game/constants';
import { type Task } from 'game/types/model/tasksModel';
import Button from 'game/ui/common/Buttons/Button.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Resource from 'game/ui/components/lobby/Resource.vue';
import { computed } from 'vue';

const props = defineProps<{
    task: Task;
}>();

const emit = defineEmits<{
    'select-task': [Task];
}>();

const icon = computed(() => {
    switch (props.task.key) {
        case 'phone':
            return 'phone';
        case 'wallet':
            return 'wallet';
        case 'subscribe-x':
            return 'x';
        case 'subscribe-yt':
            return 'youtube';
        default:
            return 'phone';
    }
});
</script>

<template>
    <Button
        class="task-item panel-block-border"
        :class="{
            'task-item--completed': task.completed && task.claimed,
        }"
        :sound="SoundAssetIds.Button"
        :is-enabled="!task.claimed"
        @click="emit('select-task', task)"
    >
        <SvgIcon
            class="task-item__icon task-item__icon--default"
            :name="icon"
            pack="lobby"
        />

        <span class="task-item__title">
            {{ task.title }}
        </span>

        <SvgIcon
            v-if="task.completed && task.claimed"
            class="task-item__icon task-item__icon--completed"
            name="check_mark_gold"
            pack="lobby"
        />
        <MainButton
            v-else-if="task.completed"
            class="task-item__claim-button"
            label="Claim"
            @click="emit('select-task', task)"
        />
        <Resource
            v-else
            class="task-item__reward"
            :resource-type="task.reward.type"
            :count="task.reward.value"
        />
    </Button>
</template>

<style scoped lang="scss">
.task-item {
    $self: &;
    position: relative;

    display: flex;

    gap: size(27px);

    align-items: center;
    justify-content: flex-start;

    font-family: $main-font-family;
    font-size: size(50px);
    font-style: normal;
    font-weight: 500;
    line-height: size(62px);
    color: var(--white, #FFF);
    text-align: left;

    pointer-events: all;

    &__icon {
        width: size(100px);
        height: size(80px);
    }

    &__title {
        flex-grow: 1;
    }

    &--completed {
        color: var(--grey-100, var(--grey, #939393));

        #{$self}__icon {
            &--default {
                opacity: 0.6;
            }
        }
    }

    &__reward {
        justify-content: flex-end;

        min-width: size(255px);
        height: size(105px);
    }

    &__claim-button {
        width: size(275px);
        height: size(113px);
        margin-bottom: 0 !important;

        font-family: $bangers-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 400;
        line-height: size(64px);
        color: var(--grey-300, #323232);
        text-align: center;
        letter-spacing: size(4.48px);
    }
}
</style>
