import { type User, type Session } from '@heroiclabs/nakama-js';

export class SessionNakamaComponent {
    isSessionValid = false;
    isSessionRefreshing = false;
    isSessionConnected = true;

    constructor(readonly session: Session, readonly userData?: User) {
    }
}
