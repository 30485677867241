import { defineNode, SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { MatchJoinNakamaComponent } from 'game/ecs/components/nakama/match/matchJoinNakamaComponent';
import { MatchLeaveNakamaComponent } from 'game/ecs/components/nakama/match/matchLeaveNakamaComponent';
import { MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';
import { MatchMapNakamaNode } from 'game/ecs/nodes/nakama/matchMapNakamaNode';
import { type MatchNakamaService } from 'game/services/nakama/matchNakamaService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

class MatchJoinNakamaNode extends defineNode({
    client: ClientNakamaComponent,
    socket: SocketNakamaComponent,
    session: SessionNakamaComponent,
    matchJoin: MatchJoinNakamaComponent,
}) {}

class MatchLeaveNakamaNode extends defineNode({
    client: ClientNakamaComponent,
    socket: SocketNakamaComponent,
    session: SessionNakamaComponent,
    matchLeave: MatchLeaveNakamaComponent,
}) {}

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Match');

export class MatchNakamaSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly matchNakamaService: MatchNakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MatchMapNakamaNode,
            add: this.addMatchMap,
            remove: this.removeMatchMap,
        });

        this.setupNodeList({
            node: MatchJoinNakamaNode,
            add: this.addMatchJoin,
        });

        this.setupNodeList({
            node: MatchLeaveNakamaNode,
            add: this.addMatchLeave,
        });
    }

    private addMatchMap = (node: MatchMapNakamaNode) => {
        const { matchMap } = node;

        for (const [matchId] of matchMap.matchMap) {
            matchMap.matchJoinSignal.dispatch(matchId);
        }
    };

    private removeMatchMap = (node: MatchMapNakamaNode) => {
        const { matchMap } = node;

        for (const [matchId] of matchMap.matchMap) {
            matchMap.matchLeaveSignal.dispatch(matchId);
        }
    };

    private addMatchJoin = (node: MatchJoinNakamaNode) => {
        const { matchJoin, entity } = node;

        const matchMap = entity.get(MatchMapNakamaComponent);
        if (matchMap?.getMatch(matchJoin.matchId)) {
            entity.remove(MatchJoinNakamaComponent);

            logger.warn('Match already joined', { entity, matchId: matchJoin.matchId });
            return;
        }

        matchJoin.isJoining = true;

        this.matchNakamaService.joinMatch(entity, matchJoin.matchId)
            .then(match => {
                let matchMap = entity.get(MatchMapNakamaComponent);
                if (!matchMap) {
                    matchMap = new MatchMapNakamaComponent();
                    entity.add(matchMap);
                }

                matchMap.matchMap.set(match.match_id, match);
                matchMap.matchJoinSignal.dispatch(match.match_id);

                matchJoin.isJoining = false;
                entity.remove(MatchJoinNakamaComponent);
            })
            .catch(error => {
                logger.error('Error join match', { entity, error });

                matchJoin.isJoining = false;
                entity.remove(MatchJoinNakamaComponent);

                matchMap?.matchJoinErrorSignal.dispatch(matchJoin.matchId);
            });
    };

    private addMatchLeave = (node: MatchLeaveNakamaNode) => {
        const { matchLeave, entity } = node;

        const matchMap = entity.get(MatchMapNakamaComponent);
        if (!matchMap) {
            entity.remove(MatchLeaveNakamaComponent);
            logger.error('MatchMapNakamaComponent not found', { entity });

            return;
        }

        matchLeave.isLeaving = true;

        this.matchNakamaService.leaveMatch(entity, matchLeave.matchId)
            .then(() => {
                const matchMap = entity.get(MatchMapNakamaComponent);
                if (matchMap) {
                    matchMap.matchMap.delete(matchLeave.matchId);
                    matchMap.matchLeaveSignal.dispatch(matchLeave.matchId);
                }

                matchLeave.isLeaving = false;
                entity.remove(MatchLeaveNakamaComponent);
            })
            .catch(error => {
                logger.error('Error leave match', { entity, error });

                matchLeave.isLeaving = false;
                entity.remove(MatchLeaveNakamaComponent);

                // throw global error
            });
    };
}
