type Data = { [key: string]: unknown };

export type NotificationHandlersDesc<CodeType extends number = number> = {
    [key in CodeType]: (data: Data) => object;
};

export class NotificationHandlers<CodeType extends number = number> {
    private readonly decodeEmpty = () => ({});
    private readonly decoderMap: Map<CodeType, (data: Data) => object> = new Map();

    constructor(notificationHandlersDesc: NotificationHandlersDesc) {
        for (const codeKey in notificationHandlersDesc) {
            const code = Number(codeKey) as CodeType;

            this.registerHandler(code, notificationHandlersDesc[code]);
        }
    }

    registerHandler(
        code: CodeType,
        decoder: (data: Data) => object,
    ) {
        this.decoderMap.set(code, decoder);
    }

    decode<T>(code: CodeType, data: Data): T {
        const decoder = this.decoderMap.get(code) ?? this.decodeEmpty;
        if (!decoder) {
            throw new Error(`Decoder not found for code ${code}`);
        }
        return decoder(data) as T;
    }
}
