import { type Entity, SystemService } from '@nord-beaver/core/ecs';
import { type SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { SpriteComponent } from '@nord-beaver/core/ecs/components/spriteComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { type EngineService } from '@nord-beaver/core/ecs/engineService';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { debugPanelService } from '@nord-beaver/core/services/debugPanelService';
import { ButtonElement, InputCheckboxElement } from '@nord-beaver/core/services/debugPanelService/debugElements';
import { type TweenService } from '@nord-beaver/core/services/tweenService';
import { FLAG_DEBUG, FLAG_TEST } from '@nord-beaver/core/utils/debug';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { clamp } from '@nord-beaver/core/utils/utils';
import { GridTileComponent } from 'game/ecs/components/gridTilemap/gridTileComponent';
import { GridTileOverlayComponent, OverlayTag } from 'game/ecs/components/gridTilemap/gridTileOverlayComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';
import { GridTileCoreNode } from 'game/ecs/nodes/gridTilemap/gridTilemapNode';
import { GridTileOverlayNode } from 'game/ecs/nodes/gridTilemap/gridTileOverlayNode';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getViewSize } from 'game/utils/viewSize';

const logger = mainLogger.getLogger('Tilemap').getLogger('TileOverlay');

export class GridTilemapTileOverlaySystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly entityService: EntityService,
        private readonly tweenService: TweenService,
        private readonly engineService: EngineService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: GridTileCoreNode,
            add: this.onTileCoreAdd,
        });
        this.setupNodeList({
            node: GridTileOverlayNode,
            update: this.onTileOverlayUpdate,
            remove: this.onTileOverlayRemove,
        });
    }

    private onTileCoreAdd(node: GridTileCoreNode) {
        const { entity } = node;

        this.addOverlayEntity(entity, {
            tag: OverlayTag.Replicant,
            alpha: 0.76,
        });

        this.addOverlayEntity(entity, {
            tag: OverlayTag.Disabled,
            alpha: 0.70,
        });
    }

    private onTileOverlayUpdate = (node: GridTileOverlayNode) => {
        const { sceneObject, gridTileOverlay, sprite, entity } = node;

        const parentEntity = entity.parent;
        if (!parentEntity) {
            logger.error('Tile without parent', entity);
            return;
        }

        const gridTile = parentEntity.get(GridTileComponent);
        if (!gridTile) {
            logger.error('Tile without GridTileComponent', parentEntity);
            return;
        }

        if (!sprite.primitive.view) {
            logger.error('Overlay without view', entity);
            return;
        }

        switch (gridTileOverlay.tag) {
            case OverlayTag.Replicant: {
                if (gridTile.desc.data.type === 'Chance') {
                    if (!gridTileOverlay.inited) {
                        this.toggleTileOverlay(gridTileOverlay, sceneObject,  false, true);
                    }

                    break;
                }

                const ownerReplicant = gridTile.ownerEntity?.get(ReplicantComponent);

                if (!gridTile.isJustOwnerChanged && !ownerReplicant?.isTilesCountChanged && gridTileOverlay.inited) {
                    break;
                }

                if (gridTile.ownerEntity && (gridTile.isJustOwnerChanged || ownerReplicant?.isTilesCountChanged)) {
                    const runtimeId = gridTile.ownerEntity.get(RuntimeIdComponent);
                    if (!runtimeId) {
                        logger.error('Owner without RuntimeIdComponent', gridTile.ownerEntity);
                        return;
                    }
                    const ownerTileLevel = ownerReplicant?.tilesCount[gridTile.desc.data.region] ?? 0;

                    this.changeReplicantOverlayTexture(parentEntity, runtimeId.rId, ownerTileLevel);
                }

                if (gridTile.isJustOwnerChanged || !gridTileOverlay.inited) {
                    this.toggleTileOverlay(gridTileOverlay, sceneObject,  gridTile.ownerEntity !== null, !gridTileOverlay.inited);
                }
                break;
            }
            case OverlayTag.Disabled: {
                if (gridTile.isJustEnabledChanged || !gridTileOverlay.inited) {
                    this.toggleTileOverlay(gridTileOverlay, sceneObject, !gridTile.isEnabled, !gridTileOverlay.inited);
                }
                break;
            }
            default: {
                logger.error('Unknown overlay tag', gridTileOverlay.tag);
                break;
            }
        }

        gridTileOverlay.inited = true;

        if (FLAG_DEBUG || FLAG_TEST) {
            debugPanelService.addElement(new InputCheckboxElement({
                path: ['Features', 'Overlay', gridTileOverlay.tag],
                id: `${gridTile.index}`,
                cb: value => {
                    this.toggleTileOverlay(gridTileOverlay, sceneObject, value);
                },
            }));

            let ownerIndex = 0;
            let turn = 1;
            debugPanelService.addElement(new ButtonElement({
                path: ['Features', 'Overlay'],
                id: `Change owner ${gridTile.index}`,
                initValue: () => {
                    ownerIndex = (ownerIndex + 1 * turn) % 3;
                    if (ownerIndex === 0) {
                        turn = -turn;
                    }
                    this.changeReplicantOverlayTexture(parentEntity, ownerIndex, 1);
                },
            }));
        }
    };

    private onTileOverlayRemove(node: GridTileOverlayNode) {
        const { gridTileOverlay } = node;

        if (gridTileOverlay.viewTween) {
            this.tweenService.removeTween(gridTileOverlay.viewTween);
        }
    }

    private toggleTileOverlay(gridTileOverlay: GridTileOverlayComponent, sceneObject: SceneObjectComponent, isVisible: boolean, force = false) {
        const overlayObject = sceneObject.object;

        if (force) {
            overlayObject.setAlpha(isVisible ? gridTileOverlay.defaultAlpha : 0);

            return;
        }

        overlayObject.setAlpha(isVisible ? 0 : gridTileOverlay.defaultAlpha);

        if (gridTileOverlay?.viewTween) {
            this.tweenService.removeTween(gridTileOverlay.viewTween);
        }

        gridTileOverlay.viewTween = this.tweenService.addTween({
            target: overlayObject,
            tween: {
                to: {
                    alpha: isVisible ? gridTileOverlay.defaultAlpha : 0,
                },
                time: gridTileOverlay.fadeTweenTime,
            },
        });
        gridTileOverlay.viewTween.onComplete.once(() => {
            if (!gridTileOverlay?.viewTween) {
                return;
            }

            this.tweenService.removeTween(gridTileOverlay.viewTween);
            gridTileOverlay.viewTween = null;
        });
    }

    private addOverlayEntity(entity: Entity, desc: {
        tag: OverlayTag.Disabled;
        alpha: number;
    } | {
        tag: OverlayTag.Replicant;
        alpha: number;
    }) {
        const transform = entity.get(TransformComponent);
        if (!transform) {
            logger.error('Tile entity without TransformComponent', entity);
            return;
        }

        const gridTile = entity.get(GridTileComponent);
        if (!gridTile) {
            logger.error('Tile entity without GridTileComponent', entity);
            return;
        }

        const viewBounds = getViewSize(entity);
        if (!viewBounds) {
            logger.error('Tile entity without view bounds', entity);
            return;
        }

        let entityId: string;
        switch (desc.tag) {
            case OverlayTag.Replicant: {
                entityId = 'objects/overlays/player1_1';

                break;
            }
            case OverlayTag.Disabled: {
                entityId = 'objects/overlays/shadow';

                break;
            }
        }

        const overlayEntity = this.entityService.createEntity(entityId);
        if (!overlayEntity) {
            logger.error('Overlay entity not found', entityId);
            return;
        }

        const overlayTransform = overlayEntity.get(TransformComponent);
        if (!overlayTransform) {
            logger.error('Overlay entity without TransformComponent', overlayEntity);
            return;
        }

        overlayTransform.matrix = transform.matrix.clone();
        overlayEntity.add(new GridTileOverlayComponent(desc.tag, desc.alpha));

        entity?.children.push(overlayEntity);
        overlayEntity.parent = entity;

        gridTile.overlayViewEntities[desc.tag] = overlayEntity;

        overlayTransform.position.set(0, 0);

        this.engineService.addEntity(overlayEntity);

        overlayTransform.scale.set(1.001, 1.001); // Hack to avoid flickering of tiles edges

        return overlayEntity;
    }

    private changeReplicantOverlayTexture(entity: Entity, ownerRid: number, level: number) {
        if (level < 1) {
            logger.error('Invalid level', level);

            return;
        }

        const gridTile = entity.get(GridTileComponent);
        if (!gridTile) {
            logger.error('Tile entity without GridTileComponent', entity);
            return;
        }

        const overlayEntity = gridTile.overlayViewEntities[OverlayTag.Replicant];
        if (!overlayEntity) {
            logger.error('Tile entity without Replicant overlay', entity);
            return;
        }

        const sprite = overlayEntity.get(SpriteComponent);
        if (!sprite) {
            logger.error('Overlay entity without SpriteComponent', overlayEntity);
            return;
        }

        const image = sprite.primitive.view as Phaser.GameObjects.Image;

        const imageAssetId = `assets/sprites/overlays/player${ownerRid + 1}_${clamp(1, 2, level)}.webp`;
        image.setTexture(imageAssetId);
    }
}