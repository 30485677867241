import { type Socket } from '@heroiclabs/nakama-js';
import { type Entity } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { retry } from '@nord-beaver/core/utils/utils';
import { SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

export interface MatchNakamaConfig {
    matchJoinRetryCount?: number;
    matchJoinRetryTimeoutMs?: number;
}

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Match');

export class MatchNakamaService {
    private matchJoinRetryCount = 5;
    private matchJoinRetryTimeoutMs = 100;

    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        logger.info('Initialized');
    }

    config(config: MatchNakamaConfig) {
        if (config.matchJoinRetryCount !== undefined) {
            this.matchJoinRetryCount = config.matchJoinRetryCount;
        }
        if (config.matchJoinRetryTimeoutMs !== undefined) {
            this.matchJoinRetryTimeoutMs = config.matchJoinRetryTimeoutMs;
        }
    }

    async createMatch(entity: Entity) {
        try {
            const match = await this.retryMatchAction(
                entity,
                async socket => await socket.createMatch(),
                'createMatch',
            );

            logger.info('Created match', { matchId: match.match_id });
            return match;
        } catch (error) {
            const message = error instanceof Error ? error.message : error;
            logger.error('Failed to create match', { error: message });
            throw error;
        }
    }

    async joinMatch(entity: Entity, matchId: string) {
        try {
            const match = await this.retryMatchAction(
                entity,
                async socket => await socket.joinMatch(matchId),
                'joinMatch',
                matchId,
            );

            logger.info('Joined match', { matchId: match.match_id, match });
            return match;
        } catch (error) {
            const message = error instanceof Error ? error.message : error;
            logger.error('Failed to join match', { matchId, error: message });
            throw error;
        }
    }

    async leaveMatch(entity: Entity, matchId: string) {
        try {
            await this.retryMatchAction(
                entity,
                async socket => await socket.leaveMatch(matchId),
                'leaveMatch',
                matchId,
            );

            logger.info('Left match', { matchId });
        } catch (error) {
            const message = error instanceof Error ? error.message : error;
            logger.error('Failed to leave match', { matchId, error: message });
            throw error;
        }
    }

    private async retryMatchAction<T>(
        entity: Entity,
        action: (socket: Socket) => Promise<T>,
        methodName: string,
        matchId?: string,
    ) {

        return new Promise<T>((resolve, reject) => {
            let attempt = 0;

            retry({
                work: async () => {
                    const socket = entity.get(SocketNakamaComponent);
                    if (!socket || !socket.isConnected) {
                        reject(new Error('Socket is not connected'));
                        throw new Error('Socket is not connected');
                    }

                    try {
                        return await action(socket.socket);
                    } catch (error) {
                        const message = error instanceof Error ? error.message : error;
                        logger.error(`${methodName} attempt failed`, { matchId, error: message });
                        throw error;
                    }
                },
                beforeRetry: () => logger.warn(`Retrying ${methodName}, match ${matchId}, attempt ${++attempt}`),
                count: this.matchJoinRetryCount,
                timeout: this.matchJoinRetryTimeoutMs,
            })
                .then(resolve)
                .catch(reject);
        });
    }
}
