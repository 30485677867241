import { type Entity } from '@nord-beaver/core/ecs';
import { api } from 'game/api/api';
import { type Currency } from 'game/types/entityDescs/currency';
import { type MatchComponentDesc } from 'game/types/entityDescs/match';

export class MatchComponent {
    matchId: string;
    name: string;

    state: api.PlayerMatchState = api.PlayerMatchState.DICE;
    coins = 0;

    matchTimeMs?: number;
    turnTimeMs?: {
        current: number;
        max: number;
    };

    moveTileIndexes?: number[];
    buyTileAvailability = false;
    rewards: {
        name: string;
        avatar: string;
        currency: {
            type: Currency;
            value: number;
        };
    }[] = [];

    constructor(
        readonly desc: MatchComponentDesc,
        readonly mapEntity?: Entity,
        matchId?: string,
        name?: string,
    ) {
        this.matchId = matchId ?? '';
        this.name = name ?? '';
    }
}