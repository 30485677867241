import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { api } from 'game/api/api';
import { TasksComponent } from 'game/ecs/components/meta/tasksComponent';
import { TasksNode } from 'game/ecs/nodes/meta/tasksNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Meta').getLogger('TasksSystem');

export class TasksSystem extends SystemService {
    private tasksNodeList?: NodeList<TasksNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly eventService: EventService,
        private readonly nakamaService: NakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
        });
        this.tasksNodeList = this.setupNodeList({
            node: TasksNode,
        });

        this.eventService.lobby.on('claimTaskReward', this.claimTaskReward, this);
    }

    override destroy() {
        this.eventService.lobby.offAll('claimTaskReward', this);
    }

    private addMeta(node: MetaNode) {
        const { meta, entity } = node;

        if (!meta.tasks) {
            logger.error('Tasks not found', { entity });

            return;
        }

        entity.add(new TasksComponent(meta.tasks));

        logger.info('Tasks added', { entity });
    }

    private claimTaskReward = async (taskIndex: number) => {
        if (!this.tasksNodeList) {
            logger.error('Tasks node list not found');

            return;
        }

        const { tasks } = this.tasksNodeList.get(0) ?? {};
        if (!tasks) {
            logger.error('Tasks not found');

            return;
        }

        const task = tasks.list[taskIndex];
        if (!task) {
            logger.error('Task not found', { taskIndex });

            return;
        }

        tasks.isFetching = true;

        const { result } = await this.nakamaService.callRpc({
            rpcType: ApiRpc.collectTaskReward,
            payload: {
                key: task.key,
            },
        });

        tasks.isFetching = false;

        task.collected = result === api.CollectTaskRewardResult.TASK_REWARD_SUCCESS;
        tasks.isDirty = true;
    };
}