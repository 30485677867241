import { SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type NotificationNakamaComponent } from 'game/ecs/components/nakama/notificationNakamaComponent';
import { NotificationNakamaNode } from 'game/ecs/nodes/nakama/notificationNakamaNode';
import { type NotificationNakamaService } from 'game/services/nakama/notification/notificationNakamaService';
import { type SocketNakamaService } from 'game/services/nakama/socketNakamaService';
import { ApiNotificationCode } from 'game/types/nakama/notificationData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEnumValues } from 'game/utils/enum';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Notification');

export class NotificationNakamaSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly socketNakamaService: SocketNakamaService,
        private readonly notificationNakamaService: NotificationNakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: NotificationNakamaNode,
            add: this.addNotification,
            remove: this.removeNotification,
        });
    }

    private addNotification = (node: NotificationNakamaNode) => {
        const { notification, socket, entity, nakama } = node;
        this.notificationNakamaService.configLogger(getEnumValues(ApiNotificationCode), nakama.config.getNotificationCodeName);
        const notificationSocketSignal = this.socketNakamaService.getSocketEventSignal(socket.socket, 'onnotification');
        const onNotificationHandler = this.notificationNakamaService.getNotificationHandler(notification.notificationHandlers, notification.receivedNotifications, nakama.config.getNotificationCodeName);

        socket.processFrameSignal.on(() => this.processFrame(notification), entity);
        notificationSocketSignal.on(onNotificationHandler, entity);

        logger.info('add notification handler', { entity });
    };

    private removeNotification = (node: NotificationNakamaNode) => {
        const { notification, socket, entity } = node;
        const notificationSocketSignal = this.socketNakamaService.getSocketEventSignal(socket.socket, 'onnotification');

        notificationSocketSignal.offAll(entity);
        socket.processFrameSignal.offAll(entity);

        notification.receivedNotifications.clear();

        logger.info('remove notification handler', { entity });
    };

    private processFrame = (notification: NotificationNakamaComponent) => {
        notification.receivedNotifications.clear();
    };
}
