import { api } from 'game/api/api';

export const getUserMetadata = (nakamaMetadata?: Record<string, unknown>) => {
    if (!nakamaMetadata) {
        return;
    }

    const metadata = api.UserMetadata.fromObject(nakamaMetadata);

    return metadata;
};