<script setup lang="ts">
import { Currency } from 'game/types/entityDescs/currency';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import Resource from 'game/ui/components/lobby/Resource.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { useResourceStore } from 'game/ui/store/resource';
import { formatNumber } from 'game/ui/utils/number';

const reward = useModel(['welcomeReward', 'reward']);
const eventService = useEventService();
const resourceStore = useResourceStore();

const claim = () => {
    eventService.lobby.dispatch('claimWelcomeReward');
};
</script>

<template>
    <LobbyLayout :container-id="ContainerIds.WelcomeReward" class="welcome-reward-container" hide-navigation-menu>
        <h1 class="welcome-reward-container__title section-title">
            welcome bonus
        </h1>

        <div class="welcome-reward-container__inner">
            <div class="welcome-reward-container__page">
                <Panel class="welcome-reward-container__panel">
                    <div class="welcome-reward__inner">
                        <div class="welcome-reward__balance-container panel-block-border">
                            <Resource
                                class="welcome-reward__icon"
                                :resource-type="Currency.Soft"
                            />

                            <h3 class="welcome-reward__balance">
                                You got
                                <div class="welcome-reward__count">
                                    {{ formatNumber(resourceStore.getValue(reward.type, reward.count)) }}
                                </div>
                            </h3>
                        </div>

                        <p class="welcome-reward__description">
                            Welcome to our game! We are glad to see you in the game, so we offer you a bonus
                        </p>
                    </div>
                </Panel>

                <MainButton
                    class="welcome-reward__start-button"
                    label="claim"
                    @click="claim"
                />
            </div>
        </div>
    </LobbyLayout>
</template>

<style scoped lang="scss">
.welcome-reward-container {
    &__inner {
        position: relative;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        &::after {
            display: block;

            width: 100%;
            height: size(20px);

            content: '';
        }
    }

    &__panel {
        margin-bottom: size(75px);
    }
}

.welcome-reward {
    $parent: &;

    position: relative;

    padding: size(52px) size(42px) size(83px);
    margin-bottom: size(55px);

    &__inner {
        position: relative;
        z-index: 20;

        display: flex;

        flex-direction: column;

        gap: size(60px);

        align-items: center;

        margin-top: size(6px);
    }

    &__balance-container {
        display: flex;

        flex-direction: column;

        gap: size(34px);

        align-items: center;

        min-width: size(484px);
        min-height: size(484px);
        padding-top: size(70px);
    }

    &__icon {
        width: size(217px);
        height: size(217px);
    }

    &__balance {
        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 700;
        line-height: size(48px);
        color: var(--white, #FFF);
        text-align: center;
    }

    &__count {
        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--yellow-300, #E5AA13);
        text-align: center;
    }

    &__description {
        width: size(805px);
        margin-top: size(-28px);

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 700;
        line-height: size(60.96px);
        color: var(--white, #FFF);
        text-align: center
    }

    &__rewards {
        display: grid;

        grid-template-columns: repeat(4, 1fr);

        gap: size(29px) size(16px);

        width: 100%;
    }

    &__reward {
        display: flex;

        flex-direction: column;

        width: size(245px);
        height: size(198px);

        background: var(--grey-opacity-70, rgb(34 31 43 / 90%));
        border: size(6px) solid var(--grey-400, #221F2B);
        border-radius: size(20px);

        @include center;

        &--claimed {
            background: var(--yellow-opacity-50, rgb(229 170 19 / 31%));
            border: size(6px) solid var(--grey-50, #D9D9D9);
        }

        &--active {
            border: size(6px) solid var(--grey-50, #D9D9D9);
        }
    }

    &__reward-text {
        display: flex;

        @include center;

        gap: size(5px);

        font-family: $main-font-family;
        font-size: size(62px);
        font-style: normal;
        font-weight: 500;
        line-height: size(62px);
        color: var(--yellow-200, #FAC526);
        text-align: center;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: var(--grey-200, #464646);
    }

    &__reward-icon {
        width: size(73px);
        height: size(73px);
    }

    &__reward-description {
        padding-top: size(14px);

        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 400;
        line-height: size(36px);
        color: var(--grey-50, #D9D9D9);
        text-align: center;
    }
}
</style>
