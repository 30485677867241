import { type Entity } from '@nord-beaver/core/ecs';
import { Region } from 'game/types/entityDescs/region';
import { type ReplicantComponentDesc } from 'game/types/entityDescs/replicant';

export class ReplicantComponent {
    placeIndex: number | undefined = undefined;
    gridTilemapEntity: Entity | null = null;
    coins = 0;
    tilesCount: Record<Region, number> = {
        [Region.Slums]: 0,
        [Region.IndustrialArea]: 0,
        [Region.RichDistrict]: 0,
    };

    isTilesCountChanged = false;

    constructor(readonly desc: ReplicantComponentDesc) {}
}