import { utils, store } from '@nord-beaver/html-ui';
import { type ComputedRef, computed } from 'vue';

const { toPX } = utils.size;
const { useScaleStore } = store.scale;

export const useSize = (scaleMode: 'auto' | 'desktop' | 'mobile' = 'auto') => {
    const scaleStore = useScaleStore();

    let currentScale: ComputedRef<number>;
    switch (scaleMode) {
        case 'auto':
            currentScale = computed(() => scaleStore.scale);
            break;
        case 'desktop':
            currentScale = computed(() => scaleStore.scaleDesktop);
            break;
        case 'mobile':
            currentScale = computed(() => scaleStore.scaleMobile);
            break;
    }

    return {
        currentScale,
        toPX,
    };
};