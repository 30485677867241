<script setup lang="ts">
import { common, hooks } from '@nord-beaver/html-ui';
import { ref } from 'vue';

const { Button } = common;

const props = withDefaults(
    defineProps<{
        isEnabled?: boolean;
        isCursorEnabled?: boolean;
        buttons?: number;
    }>(),
    {
        isEnabled: true,
        isCursorEnabled: true,
        buttons: hooks.mouseState.MOUSE_BUTTONS.LEFT,
    },
);
const emit = defineEmits<{
    (event: 'click', payload: MouseEvent): void;
    (event: 'pointerdown', payload: PointerEvent): void;
    (event: 'pointerup', payload: PointerEvent): void;
    (event: 'pointerout', payload: PointerEvent): void;
    (event: 'pointerover', payload: PointerEvent): void;
    (event: 'pointerenter', payload: PointerEvent): void;
    (event: 'hoverStart', payload: PointerEvent): void;
    (event: 'hoverEnd', payload: PointerEvent): void;
}>();

const isHovered = ref(false);

const onClick = (event: MouseEvent) => {
    emit('click', event);
};

const onHoverStart = (event: PointerEvent) => {
    isHovered.value = true;

    emit('hoverStart', event);
};

const onHoverEnd = (event: PointerEvent) => {
    isHovered.value = false;

    emit('hoverEnd', event);
};
</script>

<template>
    <Button
        class="button"
        :class="{
            'button--enabled': props.isEnabled,
            'button--disabled': !props.isEnabled,
        }"
        :style="{ pointerEvents: 'initial' }"
        :is-enabled="props.isEnabled"
        :buttons="props.buttons"

        @click="onClick"

        @pointerdown="payload => { isCursorEnabled && emit('pointerdown', payload) }"
        @pointerover="payload => { isCursorEnabled && emit('pointerover', payload) }"
        @pointerup="payload => { isCursorEnabled && emit('pointerup', payload) }"
        @pointerout="payload => { isCursorEnabled && onHoverEnd(payload); emit('pointerout', payload) }"
        @pointerenter="payload => { isCursorEnabled && onHoverStart(payload); emit('pointerenter', payload) }"
    >
        <slot />
    </Button>
</template>

<style scoped lang="scss">
    .button {
        &--enabled {
            cursor: pointer;
        }

        &--disabled {
            cursor: not-allowed;
        }
    }
</style>
