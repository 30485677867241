import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('TelegramService');

export class TelegramService {
    private _webApp?: WebApp = window.Telegram?.WebApp;

    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        logger.info('Initialized');

        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
        }

        const version = this.webApp?.version;

        logger.info('Bot API version', { version });
    }

    get webApp() {
        return this._webApp;
    }

    expand() {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        if (this.webApp.isExpanded) {
            return;
        }

        this.webApp.expand();
    }

    ready() {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        this.webApp.ready();
    }

    openUrl(url: string) {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        this.webApp.openLink(url);
    }

    shareMessageDirectly(text: string, url?: string) {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        let telegramShareLink = 'https://t.me/share/url';
        const urlText = url ? url.trim() : undefined;
        const textText = text ? text.trim() : undefined;

        if (!textText) {
            logger.error('No text to share');

            return;
        }

        if (urlText) {
            telegramShareLink += `?url=${encodeURIComponent(urlText)}&text=${encodeURIComponent(textText)}`;
        } else {
            telegramShareLink += `?text=${encodeURIComponent(textText)}`;
        }

        this.webApp.openLink(telegramShareLink, { try_instant_view: true });
    }


    setFullscreen() {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        // https://core.telegram.org/bots/webapps#initializing-mini-apps
        (this.webApp as unknown as { requestFullscreen: () => void }).requestFullscreen();
    }

    getStartParams() {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        return this.webApp.initDataUnsafe.start_param;
    }

    getData() {
        if (!this.webApp) {
            logger.error('Telegram WebApp not found');
            return;
        }

        const params = Array.from(new URLSearchParams(this.webApp.initData));
        const _parts = params.filter(el => el[0] !== 'hash');
        const hash = params.filter(el => el[0] === 'hash')[0]?.[1];
        const username = this.webApp.initDataUnsafe.user?.id;
        const seed = _parts.sort((a, b) => a[0].localeCompare(b[0]))
            .map(a => `${a[0]}=${a[1]}`)
            .join('\n');

        if (!hash || !username) {
            logger.error('No hash or username');
            return;
        }

        logger.info('Data', { hash, username, seed });

        return {
            hash,
            username,
            seed,
        };
    }
}