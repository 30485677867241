<script setup lang="ts">
import { Currency } from 'game/types/entityDescs/currency';
import { type MatchRoom as MatchRoomType } from 'game/types/model/matchRoomsModel';
import Button from 'game/ui/common/Buttons/Button.vue';
import Input from 'game/ui/common/Input.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import PanelTopImage from 'game/ui/commonInstances/PanelTopImage.vue';
import ScrollPan from 'game/ui/commonInstances/ScrollPan.vue';
import MatchRoom from 'game/ui/components/lobby/matchRooms/MatchRoom.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { windowManager } from 'game/ui/services/windowManager';
import { getAssetUrl } from 'game/ui/utils/assets';
import { formatNumber } from 'game/ui/utils/number';
import { computed, ref } from 'vue';

const eventService = useEventService();
const rooms = useModel(['matchRooms', 'rooms']);

const popupState = ref<'none' | 'create-room' | 'selected-room'>('none');
const createRoomName = ref('');
const createRoomBet = ref(100); // TODO: move to constants
const selectedRoom = ref<MatchRoomType | null>(null);
const tokenCost = computed(() => createRoomBet.value);
const pointsCost = computed(() => createRoomBet.value);

const goToRoom = (room: MatchRoomType) => {
    selectedRoom.value = room;
    popupState.value = 'selected-room';
};

const onCreateRoom = () => {
    popupState.value = 'none';

    eventService.lobby.dispatch('createMatch', {
        name: createRoomName.value,
        bet: {
            type: Currency.Soft,
            value: createRoomBet.value,
        },
    });
};
const onTutorialClick = () => {
    windowManager.setState('tutorial');
};
const onCreateRoomClick = () => {
    popupState.value = 'create-room';
};

const incrementCreateRoomBet = () => {
    createRoomBet.value += 10; // TODO: move to constants
};

const decrementCreateRoomBet = () => {
    createRoomBet.value = Math.max(100, createRoomBet.value - 10);
};
const joinRoom = (type: Currency.Soft | Currency.Hard) => {
    if (!selectedRoom.value) {
        return;
    }

    popupState.value = 'none';

    eventService.lobby.dispatch('joinMatch', {
        matchId: selectedRoom.value.matchId,
        currencyType: type,
    });
};
</script>

<template>
    <LobbyLayout :container-id="ContainerIds.MatchRooms" class="match-rooms-container">
        <h1 class="match-rooms-container__title section-title">
            Available rooms
        </h1>

        <div class="match-rooms-container__inner">
            <div class="match-rooms-container__page">
                <PanelTopImage
                    :image-url="getAssetUrl('lobby/available-rooms.webp')"
                    padding-right="0px"
                >
                    <section class="match-rooms">
                        <div class="match-rooms__inner">
                            <ScrollPan class="match-rooms__content">
                                <div class="match-rooms__content-inner">
                                    <template v-if="rooms.length === 0">
                                        <h1 class="match-rooms__empty">
                                            There’s not available rooms yet.
                                        </h1>
                                    </template>

                                    <template v-else>
                                        <template v-for="(room, _index) in rooms" :key="_index">
                                            <MatchRoom
                                                class="match-rooms__match-room"
                                                :match-room="room"
                                                :bet-currency="Currency.Soft"
                                                @go-to-room="goToRoom"
                                            />
                                        </template>
                                    </template>
                                </div>
                            </ScrollPan>
                        </div>
                    </section>
                </PanelTopImage>

                <div class="match-rooms-container__buttons">
                    <MainButton
                        class="match-rooms-container__button"
                        label="tutorial"
                        @click="onTutorialClick"
                    />

                    <MainButton
                        class="match-rooms-container__button"
                        @click="onCreateRoomClick"
                    >
                        <div class="match-rooms-container__button-inner">
                            <span>create a room</span>

                            <div class="match-rooms-container__cost">
                                <SvgIcon
                                    class="match-rooms-container__cost-icon"
                                    name="tokens"
                                    pack="lobby"
                                />

                                <SvgIcon
                                    class="match-rooms-container__cost-icon"
                                    name="points"
                                    pack="lobby"
                                />
                            </div>
                        </div>
                    </MainButton>
                </div>
            </div>
        </div>

        <div
            v-if="popupState === 'create-room'"
            class="create-room match-rooms-container__create-room"
            @click.self="popupState = 'none'"
        >
            <div class="create-room__inner">
                <h1 class="section-title">
                    Create a room
                </h1>

                <Panel>
                    <Input
                        v-model="createRoomName"
                        class="create-room__input section-input"
                        color-placeholder="#939393"
                        placeholder="Enter the room name here"
                    />

                    <div class="create-room__bet-container">
                        <Button
                            class="create-room__button"
                            @click="decrementCreateRoomBet"
                        >
                            <SvgIcon
                                class="create-room__button-icon create-room__button-icon--minus"
                                name="minus"
                                pack="lobby"
                            />
                        </Button>

                        <div
                            class="create-room__bet section-input"
                        >
                            {{ createRoomBet.toString().padStart(3, '0') }}
                        </div>

                        <Button
                            class="create-room__button"
                            @click="incrementCreateRoomBet"
                        >
                            <SvgIcon
                                class="create-room__button-icon create-room__button-icon--plus"
                                name="plus"
                                pack="lobby"
                            />
                        </Button>
                    </div>
                </Panel>
            </div>

            <div class="create-room__buttons">
                <MainButton
                    class="match-rooms-container__button"
                    @click="onCreateRoom"
                >
                    <div class="match-rooms-container__button-inner">
                        <span>create a room</span>

                        <div class="match-rooms-container__cost">
                            <span class="match-rooms-container__cost__amount">{{ formatNumber(tokenCost) }}</span>
                            <SvgIcon
                                class="match-rooms-container__cost-icon"
                                name="tokens"
                                pack="lobby"
                            />
                        </div>
                    </div>
                </MainButton>
                <MainButton
                    class="match-rooms-container__button"
                    @click="onCreateRoom"
                >
                    <div class="match-rooms-container__button-inner">
                        <span>create a room</span>

                        <div class="match-rooms-container__cost">
                            <span class="match-rooms-container__cost__amount">{{ formatNumber(pointsCost) }}</span>
                            <SvgIcon
                                class="match-rooms-container__cost-icon"
                                name="points"
                                pack="lobby"
                            />
                        </div>
                    </div>
                </MainButton>
            </div>
        </div>

        <div
            v-if="selectedRoom && popupState === 'selected-room'"
            class="join-room match-rooms-container__join-room"
            @click.self="popupState = 'none'"
        >
            <h1 class="section-title">
                {{ selectedRoom.name }}
            </h1>
            <Panel>
                <div class="join-room__inner">
                    <p class="join-room__description">
                        Select the Currency to join the match
                    </p>
                </div>
            </Panel>

            <div class="join-room__buttons">
                <MainButton
                    class="join-room__button"
                    @click="() => joinRoom(Currency.Soft)"
                >
                    <div class="join-room__button-inner">
                        <span>join a room</span>

                        <div class="join-room__cost">
                            <span class="match-rooms-container__cost__amount">
                                {{ formatNumber(selectedRoom.betCoins.find(bet => bet.type === Currency.Soft)?.value ?? 0) }}
                            </span>

                            <SvgIcon
                                class="join-room__cost-icon"
                                name="points"
                                pack="lobby"
                            />
                        </div>
                    </div>
                </MainButton>

                <MainButton
                    class="join-room__button"
                    @click="() => joinRoom(Currency.Hard)"
                >
                    <div class="join-room__button-inner">
                        <span>join a room</span>

                        <div class="join-room__cost">
                            <span class="match-rooms-container__cost__amount">
                                {{ formatNumber(selectedRoom.betCoins.find(bet => bet.type === Currency.Hard)?.value ?? 0) }}
                            </span>

                            <SvgIcon
                                class="join-room__cost-icon"
                                name="tokens"
                                pack="lobby"
                            />
                        </div>
                    </div>
                </MainButton>
            </div>
        </div>
    </LobbyLayout>
</template>

<style scoped lang="scss">
.create-room {
    display: flex;

    flex-direction: column;

    gap: size(36px);

    @include center;

    width: 100%;
    height: 100%;

    &__inner {
        display: flex;

        flex-direction: column;

        gap: size(20px);

        align-items: center;
        justify-content: center;

        width: size(1113px);
    }

    &__input {
        width: size(1025px);
        height: size(113px);
    }

    &__bet-container {
        display: flex;

        gap: size(32px);

        align-items: center;
        justify-content: center;

        margin-top: size(40px);
    }

    &__buttons {
        display: grid;

        grid-template-columns: repeat(2, 1fr);

        gap: size(46px);

        justify-content: center;

        width: size(1113px);
        margin-top: size(42px);
    }

    &__button {
        display: flex;

        width: size(110px);
        height: size(110px);

        background: rgb(34 31 43 / 80%);
        border: size(4px) solid var(--yellow-200, #DA8207);
        border-radius: size(2px);

        @include center;
    }

    &__button-icon {
        &--minus {
            width: size(61px);
            height: size(7px);
        }

        &--plus {
            width: size(61px);
            height: size(61px);
        }
    }

    &__bet {
        display: flex;

        width: size(690px);
        height: size(113px);

        @include center;

        color: var(--white, #fff);
    }
}

.join-room {
    display: flex;

    flex-direction: column;

    @include center;

    width: 100%;
    height: 100%;

    &__inner {
        display: flex;

        flex-direction: column;

        gap: size(20px);

        align-items: center;
        justify-content: center;

        width: size(1040px);
        padding: size(60px) 0;
    }

    &__description {
        width: size(800px);

        font-family: $main-font-family;
        font-size: size(67px);
        font-style: normal;
        font-weight: 700;
        line-height: size(80px);
        color: var(--white, #FFF);
        text-align: center;
    }

    &__buttons {
        display: grid;

        grid-template-columns: repeat(2, 1fr);

        gap: size(46px);

        justify-content: center;

        width: size(1113px);
        margin-top: size(72px);
    }

    &__button {
        height: size(178.119px);

        line-height: size(64px);
    }

    &__button-inner {
        display: flex;

        flex-direction: column;

        align-items: center;
        justify-content: center;

        font-size: size(48px);
    }

    &__cost {
        display: flex;

        gap: size(10px);
    }

    &__cost-icon {
        width: size(78px);
        height: size(70px);
    }
}

.coins-icon {
    width: size(66px);
    height: size(59px);
}

.match-rooms-container {
    &__inner {
        position: relative;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        &::after {
            display: block;

            width: 100%;
            height: size(20px);

            content: '';
        }
    }

    &__join-room,
    &__create-room {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;

        width: 100%;
        height: 100%;

        background: rgb(0 0 0 / 80%);
    }

    &__buttons {
        display: grid;

        grid-template-columns: repeat(2, 1fr);

        gap: size(46px);

        justify-content: center;

        margin-top: size(68px);
    }

    &__button {
        height: size(178.119px);
    }

    &__button-inner {
        display: flex;

        flex-direction: column;

        align-items: center;

        font-family: $main-button-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
    }

    &__cost {
        display: flex;

        gap: size(10px);

        align-items: center;

        &__amount {
            font-size: size(78px);
        }
    }

    &__cost-icon {
        width: size(78px);
        height: size(70px);
    }
}

.match-rooms {
    $parent: &;

    position: relative;

    height: auto;

    &__empty {
        width: size(522px);
        margin-top: size(73px);

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 400;
        line-height: size(60.96px);
        color: var(--white, #FFF);
        text-align: center;
    }

    &__inner {
        position: relative;
        z-index: 20;

        display: flex;

        flex-direction: column;

        gap: size(60px);

        align-items: center;

        margin-top: size(-28px);
    }

    &__content {
        height: size(1100px);

        :deep(.scroll-panel) {
            padding: size(10px) size(61px) size(70px) size(34px);
        }
    }

    &__content-inner {
        display: flex;

        flex-direction: column;

        gap: size(20px);

        align-items: center;
        justify-content: center;

        padding-right: size(42px);

        &--claimed {
            filter: grayscale(100%);
        }
    }

    &__icon {
        width: size(283px);
        height: size(252px);
    }

    &__count {
        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }

    &__description {
        width: size(554px);

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 700;
        line-height: size(60.96px);
        color: var(--white, #FFF);
        text-align: center
    }
}
</style>
