import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { type EnitityComponents } from 'game/types/entityComponents';

export function getEntityComponentDesc<ComponentDescType>(entityService: EntityService, entityId: string, component: EnitityComponents): ComponentDescType | undefined {
    const entity = entityService.getEntityDesc(entityId);
    if (!entity) {
        return;
    }

    const componentDesc = entity.components[component];
    if (!componentDesc) {
        return;
    }

    return componentDesc as ComponentDescType;
}