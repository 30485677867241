import { defineNode } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { SpriteComponent } from '@nord-beaver/core/ecs/components/spriteComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { GridTileOverlayComponent } from 'game/ecs/components/gridTilemap/gridTileOverlayComponent';

export class GridTileOverlayNode extends defineNode({
    transform: TransformComponent,
    sceneObject: SceneObjectComponent,
    gridTileOverlay: GridTileOverlayComponent,
    sprite: SpriteComponent,
}) { }