import { type PropertyPath, type Model } from '@nord-beaver/core/mvc/model';
import { DataService } from '@nord-beaver/core/services/dataService';
import { type GetPropertyType } from '@nord-beaver/core/utils/types';
import { type ViewModel } from 'game/types/model/viewModel';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

export function getModel(dependencyContainer: DependencyContainer): Model<ViewModel>;
export function getModel<const Path extends readonly string[]>(dependencyContainer: DependencyContainer, path: PropertyPath<ViewModel, Path>): Model<GetPropertyType<ViewModel, Path>>;
export function getModel<const Path extends readonly string[]>(dependencyContainer: DependencyContainer, path?: PropertyPath<ViewModel, Path>): Model<ViewModel> | Model<GetPropertyType<ViewModel, Path>> {
    const dataService = dependencyContainer.get(DataService);

    const root = dataService.getRootModel<ViewModel>();
    if (path) {
        return root.getSubModel<Path>(path);
    }

    return root;
}