import { type User } from '@heroiclabs/nakama-js';
import { SystemService } from '@nord-beaver/core/ecs';
import { type EngineService } from '@nord-beaver/core/ecs/engineService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { clamp } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { PlayerComponent } from 'game/ecs/components/gridTilemap/playerComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { MatchPresenceNakamaComponent } from 'game/ecs/components/nakama/matchPresenceNakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { MatchNode } from 'game/ecs/nodes/matchNode';
import { type EntityMapService } from 'game/services/entityMapService';
import { type EventService } from 'game/services/eventService';
import { type SessionNakamaService } from 'game/services/nakama/session/sessionNakamaService';
import { type NakamaService } from 'game/services/nakamaService';
import { EnitityComponents } from 'game/types/entityComponents';
import { type ChanceEventDesc } from 'game/types/entityDescs/chanceEvent';
import { type DiceFaceDesc, DiceFaceType } from 'game/types/entityDescs/diceFace';
import { type ApiMatchDataResponseSchema } from 'game/types/nakama/matchData';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { windowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getClientCurrency } from 'game/utils/resource';

const logger = mainLogger.getLogger('Match');

export class MatchSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly engineService: EngineService,
        private readonly nakamaService: NakamaService,
        private readonly eventService: EventService,
        private readonly entityMapService: EntityMapService,
        private readonly sessionNakamaService: SessionNakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MatchNode,
            add: this.addMatch,
            update: this.updateMatch,
            remove: this.removeMatch,
        });
    }

    private addMatch(node: MatchNode) {
        const { entity } = node;

        this.eventService.match.on('sellTile', this.onSellTile, entity);
        this.eventService.match.on('buyTile', this.onBuyTile, entity);
        this.eventService.match.on('move', this.onMove, entity);
        this.eventService.match.on('throwDice', () => this.onDiceThrow(node), entity);
        this.eventService.match.on('claimBonus', this.onClaimBonus, entity);
        this.eventService.match.on('cancelMatch', this.onMatchCancel, entity);
        this.eventService.match.on('endMatch', this.onMatchEndClaimRewards, entity);
    }

    private removeMatch(node: MatchNode) {
        const { match, entity } = node;

        if (match.mapEntity) {
            this.engineService.removeEntity(match.mapEntity);
        }

        this.eventService.match.offAll('sellTile', entity);
        this.eventService.match.offAll('buyTile', entity);
        this.eventService.match.offAll('move', entity);
        this.eventService.match.offAll('throwDice', entity);
        this.eventService.match.offAll('claimBonus', entity);
        this.eventService.match.offAll('cancelMatch', entity);
        this.eventService.match.offAll('endMatch', entity);
    }

    private updateMatch(node: MatchNode, dt: number) {
        if (!this.nakamaService.getMatch()) {
            return;
        }

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.MATCH_START_RESPONCE })
            .forEach(message => this.onMatchStart(node, message));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.MATCH_OVER_RESPONCE })
            .forEach(message => this.onMatchEnd(node, message));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.PLAYER_MATCH_STATE_RESPONCE })
            .forEach(message => this.onMatchState(node, message));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.COINS_UPDATE })
            .forEach(message => this.onCoinsUpdate(node, message));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.PLAYER_DICE_RESPONCE })
            .forEach(diceResponse => this.onDiceResponse(node, diceResponse));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.BUY_TILE_AVAILABILITY_RESPONCE })
            .forEach(message => this.onBuyTileAvailability(node, message));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.EVENT_RESPONCE })
            .forEach(message => this.onChanceEvent(node, message));

        this.updateMoveTilesRegion(node);
        this.updateTimers(node, dt);
    }

    private onDiceThrow(_node: MatchNode) {
        this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.PLAYER_DICE, message: {} });
    }

    private onMatchState(node: MatchNode, message: ApiMatchDataResponseSchema[api.OpCodeResponse.PLAYER_MATCH_STATE_RESPONCE]) {
        const { match } = node;
        const { state } = message;

        match.state = state;

        switch (state) {
            case api.PlayerMatchState.BANKRUPTY: {
                this.eventService.match.dispatch('bunkruptNotification');

                break;
            }
        }
    }

    private updateMoveTilesRegion(node: MatchNode) {
        const { match } = node;

        if (!match.moveTileIndexes || !match.mapEntity) {
            return;
        }

        const gridTilemap = match.mapEntity.get(GridTilemapComponent);
        if (!gridTilemap) {
            logger.error('GridTilemap not found');

            return;
        }

        gridTilemap.moveTileIndexes = match.moveTileIndexes;
    }

    private async onMatchEnd(node: MatchNode, message: ApiMatchDataResponseSchema[api.OpCodeResponse.MATCH_OVER_RESPONCE]) {
        const { match } = node;

        const nakamaEntity = this.nakamaService.get();
        if (!nakamaEntity) {
            logger.error('Nakama not found');

            return;
        }

        const matchPresence = nakamaEntity.get(MatchPresenceNakamaComponent);
        if (!matchPresence) {
            logger.error('MatchPresence not found');

            return;
        }

        const client = nakamaEntity.get(ClientNakamaComponent);
        if (!client) {
            logger.error('Client not found');

            return;
        }

        const session = nakamaEntity.get(SessionNakamaComponent);
        if (!session) {
            logger.error('Client not found');

            return;
        }

        const rewards = message.transactionsOut
            .map(transaction => ({
                amount: transaction.amount,
                clintserverUid: transaction.clientUid,
                currency: getClientCurrency(transaction.currency),
            }));

        const userIds = (await this.nakamaService.callRpc({
            rpcType: ApiRpc.customIdsToUserIds,
            payload: { ids: rewards.map(reward => reward.clintserverUid) },
        })).ids;

        const userMetadatas = (await this.sessionNakamaService.getUserData(client.client, session.session, userIds))
            .reduce<Record<string, User>>((acc, user) => {
                if (!user.id) {
                    logger.error('User id not found', user);

                    return acc;
                }

                acc[user.id] = user;

                return acc;
            }, {});

        logger.info('Match end', match);
        match.rewards.length = 0;

        for (let i = 0; i < rewards.length; i++) {
            const reward = rewards[i];
            if (!reward) {
                logger.error('Reward not found', rewards);

                continue;
            }

            const userId = userIds[i];
            if (!userId) {
                logger.error('User id not found', userIds);

                continue;
            }

            const userData = userMetadatas[userId];
            if (!userData) {
                logger.error('User metadata not found', userId);

                continue;
            }

            match.rewards.push({
                name: userData.username ?? '',
                avatar: userData.avatar_url ?? '',
                currency: {
                    type: reward.currency,
                    value: Number.parseInt(reward.amount),
                },
            });
        }
    }

    private onMatchStart(node: MatchNode, _message: ApiMatchDataResponseSchema[api.OpCodeResponse.MATCH_START_RESPONCE]) {
        const { match } = node;

        match.matchTimeMs = match.desc.timeS * 1000;

        windowManager.setState('match');
    }

    private onBuyTileAvailability(node: MatchNode, message: ApiMatchDataResponseSchema[api.OpCodeResponse.BUY_TILE_AVAILABILITY_RESPONCE]) {
        const { match } = node;

        match.buyTileAvailability = message.available;
    }

    private onChanceEvent(_node: MatchNode, message: ApiMatchDataResponseSchema[api.OpCodeResponse.EVENT_RESPONCE]) {
        const { uId } = message;

        if (!uId) {
            logger.info('You have won nothing');

            return;
        }

        const entity = this.entityMapService.getEntityDesc(uId);

        const chanceEvent = entity?.components[EnitityComponents.ChanceEvent] as ChanceEventDesc | undefined;
        if (!chanceEvent) {
            logger.error('Chance event not found', uId);

            return;
        }

        this.eventService.match.dispatch('chanceNotification', chanceEvent);
    }

    private onDiceResponse(node: MatchNode, message: ApiMatchDataResponseSchema[api.OpCodeResponse.PLAYER_DICE_RESPONCE]) {
        const { match } = node;
        const { result, turnTimeS, rIdTiles, uId } = message;

        let success = false;
        switch (result) {
            case api.PlayerDiceResult.DICE_SUCCESS:
                success = true;
                break;
            default:
                logger.warn('Dice response error', api.PlayerDiceResult[result]);
                break;
        }

        if (!success) {
            return;
        }

        const diceFaceDesc = this.entityMapService.getEntityDesc(uId);
        if (!diceFaceDesc) {
            logger.error('Dice face not found', uId);
            return;
        }

        const diceFace = diceFaceDesc.components[EnitityComponents.DiceFace] as DiceFaceDesc | undefined;
        if (!diceFace) {
            logger.error('Dice face not found', diceFaceDesc);
            return;
        }

        if (diceFace.type === DiceFaceType.Bonus) {
            this.eventService.match.dispatch('bonusNotification');
        }

        if (!turnTimeS) {
            logger.error('TurnTime not found in dice response', message);
            return;
        }

        match.turnTimeMs = {
            current: turnTimeS * 1000,
            max: turnTimeS * 1000,
        };

        match.moveTileIndexes = rIdTiles;
    }

    private updateTimers(node: MatchNode, dt: number) {
        const { match } = node;

        if (match.matchTimeMs) {
            match.matchTimeMs = clamp(0, match.matchTimeMs, match.matchTimeMs - dt);
        }
        if (match.turnTimeMs) {
            match.turnTimeMs.current = clamp(0, match.turnTimeMs.current, match.turnTimeMs.current - dt);
        }
    }

    private onCoinsUpdate(node: MatchNode, message: ApiMatchDataResponseSchema[api.OpCodeResponse.COINS_UPDATE]) {
        const { match } = node;
        const { value: coins, rid } = message;

        const mapEntity = match.mapEntity;
        if (!mapEntity) {
            logger.error('MapEntity not found');

            return;
        }

        const gridTilemap = mapEntity.get(GridTilemapComponent);
        if (!gridTilemap) {
            logger.error('GridTilemap not found');

            return;
        }

        const replicantEntity = gridTilemap.replicantEntities[rid];
        if (!replicantEntity) {
            logger.error('Replicant not found', rid);

            return;
        }

        const replicant = replicantEntity.get(ReplicantComponent);
        if (!replicant) {
            logger.error('Replicant without ReplicantComponent', replicantEntity);

            return;
        }

        replicant.coins = coins;

        if (replicantEntity.has(PlayerComponent)) {
            match.coins = coins;
        }
    }

    private onBuyTile = (index: number) => {
        this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.BUY_TILE, message: { index } });
    };

    private onSellTile = (index: number) => {
        this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.TILE_SELL, message: { index } });
    };

    private onMove = (index: number) => {
        this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.MOVE_ON_TILE, message: { index } });
    };

    private onClaimBonus = (isClaimed: boolean) => {
        if (!isClaimed) {
            return;
        }

        this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.APPLY_DICE_BONUS, message: {} });
    };

    private onMatchCancel = () => {
        this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.LEAVE_MATCH, message: {} });

        this.eventService.lobby.dispatch('leaveMatch');
    };

    private onMatchEndClaimRewards = () => {
        this.eventService.lobby.dispatch('leaveMatch');
    };
}
