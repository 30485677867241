import { type MatchData, type Socket } from '@heroiclabs/nakama-js';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type MatchDataHandlers } from 'game/services/nakama/matchData/matchDataHandlers';
import { type MatchDataQueue } from 'game/services/nakama/matchData/matchDataQueue';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('MatchData');
const receivedLogger = logger.getLogger('Received');
const sentLogger = logger.getLogger('Sent');

export type MatchDataRequestSchema<OpCodeType extends number> = Record<OpCodeType, object>;
export type MatchDataResponseSchema<OpCodeType extends number> = Record<OpCodeType, object>;

export interface MatchDataNakamaConfig {
}

export class MatchDataNakamaService {
    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        logger.info('Initialized');
    }

    configLogger<
        OpCodeRequestType extends number,
        OpCodeResponseType extends number,
    >(
        OpCodeRequest: OpCodeRequestType[],
        OpCodeResponse: OpCodeResponseType[],
        getMatchDataCodeName?: (code: number, type: 'receive' | 'send') => string,
    ) {
        for (const code of OpCodeRequest) {
            receivedLogger.getLogger(getMatchDataCodeName?.(code, 'send') ?? code.toString());
        }

        for (const code of OpCodeResponse) {
            sentLogger.getLogger(getMatchDataCodeName?.(code, 'receive') ?? code.toString());
        }
    }

    sendMatchData<Code extends number, MatchDataSchemaType extends MatchDataRequestSchema<Code>>(
        matchDataQueue: MatchDataQueue<Code>,
        matchId: string,
        opcode: Code,
        matchData: Required<MatchDataSchemaType[Code]>,
    ) {
        matchDataQueue.enqueueSendMatchData(matchId, opcode, matchData);
    }

    getMatchDataHandler<
        OpCodeRequestType extends number,
        OpCodeResponseType extends number,
        MatchDataRequestSchemaType extends MatchDataRequestSchema<OpCodeRequestType>,
        MatchDataResponseSchemaType extends MatchDataResponseSchema<OpCodeResponseType>,
    >(
        matchDataQueue: MatchDataQueue<OpCodeRequestType, OpCodeResponseType, MatchDataRequestSchemaType, MatchDataResponseSchemaType>,
        matchDataHandlers: MatchDataHandlers,
        getMatchDataCodeName?: (code: number, type: 'receive' | 'send') => string,
    ) {
        return ([matchData]: [MatchData]) => {
            const opCode = matchData.op_code as OpCodeResponseType;
            const parsedData = new TextDecoder().decode(matchData.data);
            const data: object = parsedData ? JSON.parse(parsedData) : {};

            const decodedMessage = matchDataHandlers.decode<MatchDataResponseSchemaType[OpCodeResponseType]>(opCode, data);

            matchDataQueue.enqueueReceivedMatchData(
                matchData.match_id,
                opCode,
                decodedMessage,
            );

            receivedLogger.getLogger(getMatchDataCodeName?.(opCode, 'receive') ?? opCode.toString()).info(parsedData, decodedMessage);
        };
    }

    async flushAllSendQueues<
        OpCodeRequestType extends number,
        OpCodeResponseType extends number,
        MatchDataRequestSchemaType extends MatchDataRequestSchema<OpCodeRequestType>,
        MatchDataResponseSchemaType extends MatchDataResponseSchema<OpCodeResponseType>,
    >(
        matchDataQueue: MatchDataQueue<OpCodeRequestType, OpCodeResponseType, MatchDataRequestSchemaType, MatchDataResponseSchemaType>,
        matchDataHandlers: MatchDataHandlers,
        socket: Socket,
        getMatchDataCodeName?: (code: number, type: 'receive' | 'send') => string,
    ) {
        const sendPromises: Promise<void>[] = [];

        for (const [matchId, queue] of matchDataQueue.requestMatchDataQueue) {
            for (const [opcode, messages] of queue) {
                for (const message of messages) {
                    sendPromises.push(this.flushSendQueue(matchDataHandlers, socket, matchId, opcode, message, getMatchDataCodeName));
                }
            }
        }

        await Promise.all(sendPromises);
    }

    private async flushSendQueue<Code extends number>(
        matchDataHandlers: MatchDataHandlers,
        socket: Socket,
        matchId: string,
        opcode: Code,
        message: object,
        getMatchDataCodeName?: (code: number, type: 'receive' | 'send') => string,
    ) {
        let encodedMessage: string;
        try {
            encodedMessage = matchDataHandlers.encode(opcode, message);
        } catch (error) {
            logger.error(`Failed to encode message with opcode ${opcode}`, error);
            return;
        }

        try {
            await socket.sendMatchState(matchId, opcode, encodedMessage);

            sentLogger.getLogger(getMatchDataCodeName?.(opcode, 'send') ?? opcode.toString()).info({ matchId, opcode, message, encodedMessage });
        } catch (error) {
            logger.error(`Error sending message with opcode ${opcode} to match ${matchId}`, error);
        }
    }
}
