import { Signal } from '@nord-beaver/core/utils/signal';
import { type GetAvailablePropertyPaths, type GetClosestPropertyPath, type ValidatePropertyPath } from '@nord-beaver/core/utils/types';

export type EventBusPath<Data, Path extends readonly string[]> = Data extends object ? ValidatePropertyPath<Data, Path> extends never ? GetAvailablePropertyPaths<Data, GetClosestPropertyPath<Data, Path>> : Path : [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class EventBus<EventList extends { [ event: string ]: any }> {
    private readonly signals: { [ Event in keyof EventList ]?: Signal<EventList[Event]> } = {};

    dispatch<K extends keyof EventList>(event: K, ...payload: EventList[K] extends never ? [never] : [EventList[K]]) {
        this.getSignal(event).dispatch(payload[0]);
    }

    on<K extends keyof EventList>(event: K, listener: (payload: EventList[K]) => void, context?: unknown) {
        this.getSignal(event).on(listener, context);
    }

    once<K extends keyof EventList>(event: K, listener: (payload: EventList[K]) => void, context?: unknown) {
        this.getSignal(event).once(listener, context);
    }

    off<K extends keyof EventList>(event: K, listener: (payload: EventList[K]) => void) {
        this.getSignal(event).off(listener);
    }

    offAll<K extends keyof EventList>(event: K, context?: unknown) {
        this.getSignal(event).offAll(context);
    }

    hasListeners<K extends keyof EventList>(event: K) {
        return this.getSignal(event).hasListeners();
    }

    future<K extends keyof EventList>(event: K) {
        return this.getSignal(event).future();
    }

    private getSignal<K extends keyof EventList>(event: K) {
        if (!this.signals[event]) {
            this.signals[event] = new Signal<EventList[K]>();
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.signals[event]!;
    }
}