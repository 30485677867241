import { type AudioServiceHowler } from '@nord-beaver/core/services/audioService';
import { soundVolumeMap, type SoundAssetIds } from 'game/constants';
import { type EventService } from 'game/services/eventService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

export class SoundManager { // TODO: move to ecs system or use audioService directly
    constructor(
        _dependencyContainer: DependencyContainer,
        readonly eventService: EventService,
        readonly audioService: AudioServiceHowler,
    ) {
    }

    init() {
        this.eventService.audio.on('play', this.playSound, this);
    }

    destroy() {
        this.eventService.audio.offAll('play', this);
    }

    private playSound = ({ assetId, volume }: { assetId: SoundAssetIds; volume?: number }) => {
        volume ??= soundVolumeMap[assetId] ?? 1;

        this.audioService.playSound({ assetId, volume });
    };
}