import { Currency } from 'game/types/entityDescs/currency';

export enum ServerCurrency {
    Soft = 'POINT',
    Hard = 'GG',
    Match = 'MATCH',
}

export enum CurrencyRates {
    HardToSoft = 'GG/POINT',
}

export const getServerCurrency = (clientCurrency: Currency) => clientCurrency === Currency.Soft
    ? ServerCurrency.Soft
    : clientCurrency === Currency.Hard
        ? ServerCurrency.Hard
        : ServerCurrency.Match;
export const getClientCurrency = (serverCurrency: string) => serverCurrency === ServerCurrency.Soft
    ? Currency.Soft
    : serverCurrency === ServerCurrency.Hard
        ? Currency.Hard
        : Currency.Match;

export const getCurrencyName = (currency: Currency) => {
    switch (currency) {
        case Currency.Soft:
            return 'POINTS';
        case Currency.Hard:
            return 'GG';
        case Currency.Match:
            return 'POINTS'; // Not used now, must be approved with gd
    }
};

export const convertCurrency = (value: number, from: Currency, to: Currency) => {
    if (from === to) {
        return value;
    }

    if (from === Currency.Soft && to === Currency.Hard) {
        return value / 100;
    }

    if (from === Currency.Hard && to === Currency.Soft) {
        return value * 100;
    }

    return value;
};