<script setup lang="ts">
import { type Clan } from 'game/types/model/clansModel';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import PanelList from 'game/ui/commonInstances/PanelList.vue';
import ClanItem from 'game/ui/components/lobby/clans/ClanItem.vue';
import ClanMemberItem from 'game/ui/components/lobby/clans/ClanMemberItem.vue';
import InfoBlock from 'game/ui/components/lobby/clans/InfoBlock.vue';
import JoinClan from 'game/ui/components/lobby/clans/JoinClan.vue';
import TutorialBlock from 'game/ui/components/lobby/clans/TutorialBlock.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { computed, ref, watch } from 'vue';

const eventService = useEventService();
const clans = useModel(['clans', 'list']);

const selectedClan = computed(() => clans.value.find(clan => clan.isJoined));
const lobbyLayout = ref<InstanceType<typeof LobbyLayout> | null>(null);

const pageState = ref<'clans' | 'joinClan' | 'clanDetails'>('clans');
const codeText = ref('');
const isCopyDescriptionVisible = ref(false);
let copyTimeout: ReturnType<typeof setTimeout> | null = null;

const openClanWindow = () => {
    pageState.value = 'clanDetails';
    lobbyLayout.value?.scrollToTop();
};

watch(
    () => selectedClan.value,
    selectClan => {
        if (selectClan) {
            openClanWindow();
        } else if (pageState.value === 'clanDetails') {
            closeWindows();
        }
    },
    { immediate: true },
);

const joinClan = (code: string) => {
    eventService.lobby.dispatch('joinClanByCode', code);
};

const selectClan = (clan: Clan) => {
    if (clan.isJoined) {
        return;
    }

    eventService.lobby.dispatch('joinClanById', clan.id);
};
const openJoinClanWindow = () => {
    codeText.value = '';
    pageState.value = 'joinClan';
    lobbyLayout.value?.scrollToTop();
};
const closeWindows = () => {
    pageState.value = 'clans';
    lobbyLayout.value?.scrollToTop();
};

const clanInvite = () => {
    const clan = selectedClan.value;
    if (!clan) {
        return;
    }

    eventService.lobby.dispatch('inviteClan', clan.id);
    isCopyDescriptionVisible.value = true;

    if (copyTimeout) {
        clearTimeout(copyTimeout);
    }

    copyTimeout = setTimeout(() => {
        isCopyDescriptionVisible.value = false;
    }, 3000);
};
const joinCommunity = () => {
    const clan = selectedClan.value;
    if (!clan) {
        return;
    }

    eventService.lobby.dispatch('joinCommunity', clan.id);
};
const leaveClan = () => {
    const clan = selectedClan.value;
    if (!clan) {
        return;
    }

    eventService.lobby.dispatch('leaveClan', clan.id);
};
</script>

<template>
    <LobbyLayout ref="lobbyLayout" :container-id="ContainerIds.Clans" class="clans-container">
        <Transition :duration="1000" name="fade">
            <div v-show="pageState === 'clans'" class="clans clans-container__clans clans-container__page">
                <InfoBlock class="clans__info-block" />

                <TutorialBlock class="clans__tutorial-block" />

                <MainButton class="clans__join-button" label="create clan" @click="openJoinClanWindow" />

                <template v-if="clans.length">
                    <h2 class="section-title clans__section-title">
                        Recommended clans
                    </h2>

                    <PanelList>
                        <div class="clans__clans-block-inner ">
                            <template v-for="clan in clans" :key="clan.id">
                                <ClanItem
                                    class="clans__clan-item"
                                    :clan="clan"
                                    @select-clan="selectClan"
                                />
                            </template>
                        </div>
                    </PanelList>
                </template>
            </div>
        </Transition>

        <Transition :duration="1000" name="fade">
            <div v-show="pageState === 'joinClan'" class="clans clans-container__clans clans-container__page">
                <JoinClan
                    v-model:code-text="codeText"
                    class="clans__join-clan"
                    :is-visible="pageState === 'joinClan'"
                    @close="closeWindows"
                    @join="joinClan"
                />
            </div>
        </Transition>

        <Transition :duration="1000" name="fade">
            <div
                v-if="selectedClan"
                v-show="selectedClan && pageState === 'clanDetails'"
                class="clans clans-container__clans clans-container__page"
            >
                <InfoBlock
                    class="clans__info-block"
                    :clan="selectedClan"
                    @join-community="joinCommunity"
                />

                <MainButton
                    v-if="!selectedClan?.isJoined"
                    class="clans__join-button"
                    label="join the clan"
                    @click="() => selectedClan && eventService.lobby.dispatch('inviteClan', selectedClan.id)"
                />

                <template v-if="selectedClan?.members?.length">
                    <h2 class="section-title">
                        Clan's Participants
                    </h2>

                    <PanelList>
                        <div class="clans__clans-block-inner">
                            <template v-for="clanMember in selectedClan.members" :key="clanMember.id">
                                <ClanMemberItem
                                    class="clans__clan-item"
                                    :member="clanMember"
                                />
                            </template>
                        </div>
                    </PanelList>
                </template>

                <div v-if="selectedClan?.isJoined" class="clans__buttons">
                    <MainButton
                        class="clans__button-medium clans__button-medium--invite"
                        label="copy link"
                        @click="clanInvite"
                    />

                    <MainButton
                        class="clans__button-medium clans__button-medium--leave"
                        label="leave"
                        color="red"
                        @click="leaveClan"
                    />
                </div>

                <Transition name="fade">
                    <div v-show="isCopyDescriptionVisible" class="invite-desc clans__invite-desc">
                        <Panel>
                            <div class="invite-desc__text">
                                <p class="invite-desc__description">
                                    It’s all good! The link has been copied
                                </p>
                            </div>
                        </Panel>
                    </div>
                </Transition>
            </div>
        </Transition>
    </LobbyLayout>
</template>

<style scoped lang="scss">
.invite-desc {
    position: fixed;
    bottom: size(450px);
    left: 50%;
    z-index: 20;

    width: 100%;
    height: size(239px);
    padding: 0 size(90px);

    filter: drop-shadow(size(2px) size(4px) size(6px) black);

    transform: translateX(-50%);

    &__text {
        display: flex;

        justify-content: center;

        width: 100%;
    }

    &__description {
        width: size(662px);

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 400;
        line-height: size(60.96px);
        color: var(--white, #FFF);
        text-align: center;
    }
}

.clans-container {
    position: relative;

    &__inner {
        position: relative;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;

        flex-direction: column;

        width: 100%;

        &::after {
            display: block;

            width: 100%;
            height: size(20px);

            content: '';
        }
    }
}

.clans {
    $parent: &;

    overflow: hidden;

    &__clans-block-inner {
        display: flex;

        flex-direction: column;

        gap: size(21px);
    }

    &__buttons {
        display: grid;

        grid-template-columns: repeat(2, 1fr);

        gap: size(46px);

        justify-content: center;

        margin-top: size(66px);
    }

    &__join-button {
        margin-top: size(55px);
    }

    &__section-title {
        margin-top: size(50px);
    }
}
</style>
