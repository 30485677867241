import { type Match } from '@heroiclabs/nakama-js';
import { Signal } from '@nord-beaver/core/utils/signal';

export class MatchMapNakamaComponent {
    readonly matchMap = new Map<string, Match>();
    private _matchJoinSignal?: Signal<string>;
    private _matchLeaveSignal?: Signal<string>;
    private _matchJoinErrorSignal?: Signal<string>;

    get matchJoinSignal() {
        return this._matchJoinSignal ?? (this._matchJoinSignal = new Signal());
    }

    get matchLeaveSignal() {
        return this._matchLeaveSignal ?? (this._matchLeaveSignal = new Signal());
    }

    get matchJoinErrorSignal() {
        return this._matchJoinErrorSignal ?? (this._matchJoinErrorSignal = new Signal());
    }

    getMatch(matchId?: string) {
        if (!matchId) {
            return this.matchMap.values().next().value;
        }

        return this.matchMap.get(matchId);
    }
}
