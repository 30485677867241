import { type Entity, SystemService } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { type EngineService } from '@nord-beaver/core/ecs/engineService';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { type AnyAssetDesc, ASSET_TYPE, type AssetServicePhaser } from '@nord-beaver/core/services/assetService';
import { type StageService } from '@nord-beaver/core/services/stageService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { api } from 'game/api/api';
import { Layers } from 'game/constants';
import { LoadingComponent } from 'game/ecs/components/loadingComponent';
import { DiceComponent } from 'game/ecs/components/match/diceComponent';
import { MatchComponent } from 'game/ecs/components/match/matchComponent';
import { MatchRoomsComponent, type MatchRoomsDesc } from 'game/ecs/components/match/matchRoomsComponent';
import { type ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { MatchJoinNakamaComponent } from 'game/ecs/components/nakama/match/matchJoinNakamaComponent';
import { MatchLeaveNakamaComponent } from 'game/ecs/components/nakama/match/matchLeaveNakamaComponent';
import { MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { type SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { CoreNakamaNode } from 'game/ecs/nodes/nakama/coreNakamaNode';
import { type EntityMapService } from 'game/services/entityMapService';
import { type EventService } from 'game/services/eventService';
import { type SessionNakamaService } from 'game/services/nakama/session/sessionNakamaService';
import { type NakamaService } from 'game/services/nakamaService';
import { EnitityComponents } from 'game/types/entityComponents';
import { type DiceComponentDesc } from 'game/types/entityDescs/dice';
import { type MatchComponentDesc } from 'game/types/entityDescs/match';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { windowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEntityComponentDesc } from 'game/utils/entityDesc';
import { parseMatchLabel } from 'game/utils/matchLablel';
import { getClientCurrency, getServerCurrency } from 'game/utils/resource';
import { getUserMetadata } from 'game/utils/userMetadata';

const logger = mainLogger.getLogger('MatchMakinng');

export class MatchmakingSystem extends SystemService {
    private unloadAssetIds: string[] = [];
    private matchDesc?: MatchComponentDesc;
    private diceDesc?: DiceComponentDesc;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly entityMapService: EntityMapService,
        private readonly assetService: AssetServicePhaser,
        private readonly engineService: EngineService,
        private readonly stageService: StageService,
        private readonly entityService: EntityService,
        private readonly eventService: EventService,
        private readonly nakamaService: NakamaService,
        private readonly sessionNakamaService: SessionNakamaService,
    ) {
        super();
    }

    async init() {
        this.eventService.game.once('entityLoaded', () => {
            const matchEntityDesc = getEntityComponentDesc<MatchComponentDesc>(this.entityService, '/match', EnitityComponents.Match);
            if (!matchEntityDesc) {
                logger.error('Match entity not found');

                return;
            }
            const diceEntityDesc = getEntityComponentDesc<DiceComponentDesc>(this.entityService, 'objects/dice', EnitityComponents.Dice);
            if (!diceEntityDesc) {
                logger.error('Dice entity not found');

                return;
            }

            this.matchDesc = matchEntityDesc;
            this.diceDesc = diceEntityDesc;
        }, this);

        this.setupNodeList({
            node: CoreNakamaNode,
            add: this.addCoreNakama,
            remove: this.removeCoreNakama,
        });
    }

    override destroy() {
        this.eventService.game.offAll('entityLoaded', this);

        this.unloadAssetIds.forEach(assetId => {
            this.assetService.unload(assetId, true);
        });

        this.unloadAssetIds.length = 0;

        super.destroy();
    }

    private async addCoreNakama(node: CoreNakamaNode) {
        const { client, session, entity } = node;

        this.eventService.lobby.on('createMatch', async ({ bet, name }) => {
            const {
                operationUid: operationUid,
            } = await this.nakamaService.callRpc({
                rpcType: ApiRpc.initBet,
                payload: {
                    currency: getServerCurrency(bet.type),
                    currencyOut: getServerCurrency(bet.type),
                    amount: bet.value.toString(),
                },
            });

            if (!operationUid) {
                logger.error('OperationUid not found');

                return;
            }

            this.eventService.metaData.dispatch('request', 'clientAccounts');

            const {
                match,
            } = await this.nakamaService.callRpc({
                rpcType: ApiRpc.createMatch,
                payload: {
                    matchName: name,
                    operationUid,
                },
            });

            if (!match?.matchId || !match?.tilemapEntityId) {
                logger.error('Match not found');

                return;
            }

            this.joinMatch(entity, match.matchId, match.tilemapEntityId);
        }, entity);

        this.eventService.lobby.on('joinMatch', async ({ matchId, currencyType }) => {
            const matchRoomsComponent = entity.get(MatchRoomsComponent);
            if (!matchRoomsComponent) {
                logger.error('MatchRoomsComponent not found');

                return;
            }

            const matchRoom = matchRoomsComponent.rooms[matchId];
            if (!matchRoom) {
                logger.error('MatchRoom not found');

                return;
            }

            const bet = matchRoom.bet.find(bet => bet.type === currencyType);
            if (!bet) {
                logger.error('Bet not found');

                return;
            }

            const {
                operationUid,
            } = await this.nakamaService.callRpc({
                rpcType: ApiRpc.addBet,
                payload: {
                    operationUid: matchRoom.operationUid,
                    currency: getServerCurrency(bet.type),
                },
            });
            if (!operationUid) {
                logger.error('OperationUid not found');

                return;
            }

            this.eventService.metaData.dispatch('request', 'clientAccounts');

            this.joinMatch(entity, matchId, matchRoom.tilemapEntityId);
        }, entity);

        this.eventService.lobby.on('leaveMatch', () => {
            this.leaveMatch(entity);
        }, entity);

        this.reconnectMatch(entity, session);

        // TODO: add event for updating match rooms
        this.updateMatchRooms(entity, client, session);
    }

    private removeCoreNakama(node: CoreNakamaNode) {
        const { matchMap, entity } = node;

        matchMap.matchJoinSignal.offAll(entity);
        matchMap.matchLeaveSignal.offAll(entity);
        matchMap.matchJoinErrorSignal.offAll(entity);
        this.eventService.match.offAll('startMatch', entity);
        this.eventService.nakama.offAll('disconnect', entity);
        this.eventService.lobby.offAll('createMatch', entity);
        this.eventService.lobby.offAll('joinMatch', entity);
        this.eventService.lobby.offAll('leaveMatch', entity);
    }

    private reconnectMatch(entity: Entity, session: SessionNakamaComponent) {
        const playerMetadata = getUserMetadata(session.userData?.metadata);
        if (!playerMetadata) {
            logger.error('Player metadata not found', session.userData);

            return;
        }

        this.joinMatch(entity, playerMetadata.matchId, playerMetadata.tilemapEntityId);
    }

    private async updateMatchRooms(entity: Entity, client: ClientNakamaComponent, session: SessionNakamaComponent) {
        const matchRoomsComponent = entity.get(MatchRoomsComponent);

        const matchList = await this.sessionNakamaService.getMatchList(client.client, session.session);
        if (!matchList) {
            logger.error('Match list not found');

            return;
        }

        const matchRooms = matchList.matches?.reduce<Record<string, MatchRoomsDesc>>((acc, match) => {
            const matchId = match.match_id;
            const matchParams = match.label ? parseMatchLabel(match.label) : undefined;

            if (matchId && matchParams) {
                acc[matchId] = {
                    matchId: matchId,
                    tilemapEntityId: matchParams.mapId,
                    matchName: matchParams.matchName,
                    bet: matchParams.bet.map(bet => ({
                        type: getClientCurrency(bet.currency),
                        value: bet.value,
                    })),
                    playerIds: matchParams.users,
                    operationUid: matchParams.operationUid,
                };
            }
            return acc;
        }, {}) ?? {};

        if (!matchRoomsComponent) {
            entity.add(new MatchRoomsComponent(matchRooms));
        } else {
            matchRoomsComponent.rooms = matchRooms;
            matchRoomsComponent.isDirty = true;
        }

        return matchRooms;
    }

    private async leaveMatch(entity: Entity, matchId?: string) {
        const matchMap = entity.get(MatchMapNakamaComponent);
        if (!matchMap) {
            logger.error('MatchMapNakamaComponent not found');

            return;
        }

        if (matchMap.matchMap.size === 0) {
            return;
        }

        if (!matchId) {
            matchId = matchMap.getMatch()?.match_id;
        }

        if (!matchId) {
            logger.error('MatchId not found');

            return;
        }

        entity.add(new MatchLeaveNakamaComponent(matchId));
    }

    private async joinMatch(entity: Entity, matchId: string, tilemapEntityId: string) {
        const matchMap = entity.get(MatchMapNakamaComponent);
        if (!matchMap) {
            logger.error('MatchMapNakamaComponent not found');

            return;
        }

        if (!this.matchDesc) {
            logger.error('MatchDesc not found');

            return;
        }

        if (!this.diceDesc) {
            logger.error('DiceDesc not found');

            return;
        }

        if (!matchId || !tilemapEntityId) {
            logger.error('MatchId or tilemapEntityId not found');

            return;
        }

        if (this.unloadAssetIds.length) {
            this.assetService.unload(this.unloadAssetIds, true);

            this.unloadAssetIds.length = 0;
        }

        const loading = new LoadingComponent();
        entity.add(loading);

        const onProgress = (progress: number) => {
            loading.progress = progress;
        };

        this.assetService.progressSignal.on(onProgress);

        const mapEntity = await this.loadMap(tilemapEntityId);
        if (!mapEntity) {
            logger.error('Map entity not found');

            return;
        }

        loading.progress = 1;
        this.assetService.progressSignal.off(onProgress);

        const match = new MatchComponent(this.matchDesc, mapEntity, matchId);
        const dice = new DiceComponent(this.diceDesc);

        entity
            .add(dice)
            .add(match)
            .add(new MatchJoinNakamaComponent(matchId));

        matchMap.matchJoinSignal.once(() => {
            entity.remove(LoadingComponent);
            windowManager.setState('matchmaking');
        }, entity);
        matchMap.matchLeaveSignal.once(() => {
            entity.remove(LoadingComponent);
            entity.remove(MatchComponent);
            entity.remove(DiceComponent);

            windowManager.setState('matchRooms');
        });
        matchMap.matchJoinErrorSignal.once(() => {
            entity.remove(LoadingComponent);
            entity.remove(MatchComponent);
            entity.remove(DiceComponent);

            windowManager.setState('matchRooms');
        });

        this.eventService.match.once('startMatch', () => {
            this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.MATCH_START, message: {} });
        }, entity);
        this.eventService.nakama.once('disconnect', disconnectNakamaEntity => {
            if (disconnectNakamaEntity !== entity) {
                return;
            }

            confirm('You have been disconnected from the server. Please refresh the page to reconnect.');

            window.location.reload();
        }, entity);
    }

    private async loadMap(mapUid: string) {
        const mapEntity = this.entityMapService.createEntity(mapUid);
        if (!mapEntity) {
            logger.error('Map entity not found');

            return null;
        }

        if (mapEntity.entityId) {
            const mapPackAssetId = mapEntity.entityId.split('/').pop();
            const assetId = `assets/packs/maps/${mapPackAssetId}.json`;
            const packs: AnyAssetDesc[] = [{
                type: ASSET_TYPE.PACK,
                url: assetId,
            }];

            this.unloadAssetIds.push(assetId);

            await this.assetService.load(packs);
        }

        const sceneObject = mapEntity.get(SceneObjectComponent);
        if (sceneObject) {
            sceneObject.parent = this.stageService.getLayer(Layers.Game);
        }

        this.engineService.addEntity(mapEntity, true);

        return mapEntity;
    }
}