<script setup lang="ts">
import Input from 'game/ui/common/Input.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { copyToClipboard } from 'game/utils/copyToClipboard';
import { computed } from 'vue';

const eventService = useEventService();

const matchPlayers = useModel(['match', 'matchPlayers']);
const matchLink = useModel(['match', 'matchLink']);
const startMatchTimeLeft = useModel(['match', 'startMatchTimeLeft']);
const name = useModel(['match', 'name']);

const matchPlayersArray = computed(() => Object.values(matchPlayers.value));
const isFullMatch = computed(() => matchPlayersArray.value.length === 4);

const forceStart = () => {
    eventService.match.dispatch('startMatch');
};

const leaveMatch = () => {
    eventService.lobby.dispatch('leaveMatch');
};

const copyMatchLink = () => {
    copyToClipboard(matchLink.value);
};
</script>

<template>
    <LobbyLayout :container-id="ContainerIds.Matchmaking" class="matchmaking-container">
        <h1 class="matchmaking-container__title section-title">
            {{ startMatchTimeLeft !== undefined ? 'The match starts in' : name }}
        </h1>

        <Panel height="320px" class="matchmaking-container__panel section-text">
            <p>Search for opponents...</p>
        </Panel>

        <div class="matchmaking-container__inner">
            <div class="matchmaking-container__page">
                <section class="matchmaking matchmaking--inner">
                    <div class="matchmaking__inner">
                        <template v-for="index in 4" :key="index">
                            <Panel v-if="matchPlayersArray[index - 1]" class="matchmaking__player">
                                <img
                                    class="matchmaking__image"
                                    :src="matchPlayersArray[index - 1].avatarUrl ?? getAssetUrl('lobby/user-image.webp')"
                                    alt=""
                                >

                                <p class="matchmaking__player-name">
                                    {{ matchPlayersArray[index - 1]?.name ?? "" }}
                                </p>
                            </Panel>

                            <Panel v-else class="matchmaking__player matchmaking__player--empty">
                                <div class="loading-icon matchmaking__loading">
                                    <SvgIcon
                                        class="loading-icon__icon"
                                        name="loading"
                                        pack="lobby"
                                    />
                                </div>
                            </Panel>
                        </template>
                    </div>


                    <template v-if="!isFullMatch">
                        <div class="matchmaking__link">
                            <Input
                                v-model="matchLink"
                                class="matchmaking__input section-input"
                                :is-readonly="true"
                            />

                            <MainButton
                                class="matchmaking__copy-button"
                                label="copy link"
                                @click="copyMatchLink"
                            />
                        </div>

                        <div v-if="!isFullMatch" class="matchmaking__buttons">
                            <MainButton
                                class="matchmaking__button matchmaking__button--force-start"
                                label="force start"
                                @click="forceStart"
                            />

                            <MainButton
                                class="matchmaking__button matchmaking__button--force-start"
                                label="cancel"
                                color="red"
                                @click="leaveMatch"
                            />
                        </div>
                    </template>

                    <h2
                        v-if="isFullMatch"
                        class="matchmaking__title section-title matchmaking__title--full-match"
                    >
                        All players have been found
                    </h2>
                </section>
            </div>
        </div>
    </LobbyLayout>
</template>

<style scoped lang="scss">
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-icon {
    color: var(--white, #FFF);

    transform-origin: center;
    animation: spin 1.2s steps(8) infinite;

    &__icon {
        width: 100%;
        height: 100%;
    }
}

.matchmaking-container {
    &__inner {
        position: relative;
    }

    &__panel {
        margin-bottom: size(42px);
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        &::after {
            display: block;

            width: 100%;
            height: size(20px);

            content: '';
        }
    }

    &__title {
        margin-bottom: size(20px);
    }

    &__match-start-time {
        display: flex;

        @include center;

        width: 100%;
        height: size(151px);
        margin-bottom: size(36px);

        font-family: $main-font-family;
        font-size: size(128px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }
}

.matchmaking {
    $parent: &;

    position: relative;

    display: flex;

    flex-direction: column;

    gap: size(38px);

    margin-bottom: size(55px);

    &__title {
        &--full-match {
            color: var(--white, #FFF);

            background: unset;
            -webkit-text-fill-color: unset;
        }
    }

    &__inner {
        position: relative;
        z-index: 20;

        display: grid;

        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);

        gap: size(50px) size(34px);
    }

    &__player {
        display: flex;

        flex-direction: column;

        height: size(536px);

        @include center;
    }

    &__image {
        width: size(262px);
        height: size(262px);

        border: size(4px) solid var(--yellow-300, #E5AA13);
        border-radius: size(20px);
    }

    &__player-name {
        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: var(--yellow-300, #E5AA13);
        text-align: center;
    }

    &__loading {
        width: size(212px);
        height: size(220px);
    }

    &__link {
        display: flex;

        gap: size(24px);

        align-items: center;
    }

    &__input {
        width: size(680px);
        height: size(113px);
    }

    &__copy-button {
        width: size(390px);
        height: size(113px);
        margin-bottom: 0;

        background-position: size(275px) size(171px);
    }

    &__buttons {
        display: grid;

        grid-template-columns: repeat(2, 1fr);

        gap: size(46px);

        justify-content: center;

        margin-top: size(26px);
    }
}
</style>
