import { Currency } from 'game/types/entityDescs/currency';

export enum ServerCurrency {
    Soft = 'POINT',
    Hard = 'GG',
    Match = 'MATCH',
}

export const getServerCurrency = (clientCurrency: Currency) => clientCurrency === Currency.Soft
    ? ServerCurrency.Soft
    : clientCurrency === Currency.Hard
        ? ServerCurrency.Hard
        : ServerCurrency.Match;
export const getClientCurrency = (serverCurrency: string) => serverCurrency === ServerCurrency.Soft
    ? Currency.Soft
    : serverCurrency === ServerCurrency.Hard
        ? Currency.Hard
        : Currency.Match;