<script setup lang="ts">
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { api } from 'game/api/api';
import { type ChanceEventDesc, ChanceEventType } from 'game/types/entityDescs/chanceEvent';
import { Currency } from 'game/types/entityDescs/currency';
import Button from 'game/ui/common/Buttons/Button.vue';
import Container from 'game/ui/common/Container.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import Dice from 'game/ui/components/match/Dice.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import { resourceIcon } from 'game/ui/constants/assets';
import { getAssetUrl } from 'game/ui/utils/assets';
import { formatNumber } from 'game/ui/utils/number';
import { getShortTime } from 'game/ui/utils/time';
import { computed, onMounted, onUnmounted, provide, ref } from 'vue';

const turnTimeLeft = useModel(['match', 'turnTimeLeft']);
const matchState  = useModel(['match', 'state']);
const matchTimeLeft = useModel(['match', 'matchTimeLeft']);
const playerCoins = useModel(['match', 'coins']);
const tiles = useModel(['match', 'tiles']);
const selectedTileIndex = useModel(['match', 'selectedTileIndex']);
const playerTileIndex = useModel(['match', 'playerTileIndex']);
const leaderboard = useModel(['match', 'leaderboard']);
const isBuyTileAvailable = useModel(['match', 'isBuyTileAvailable']);
const betCoins = useModel(['match', 'betCoins']);

const status = computed(() => {
    switch (matchState.value) {
        case api.PlayerMatchState.WAIT:
            return 'your turn';
        case api.PlayerMatchState.DICE:
            return 'throw the dice';
        case api.PlayerMatchState.MOVE:
            return 'move';
        case api.PlayerMatchState.BANKRUPTY:
            return 'sell your tiles';
        case api.PlayerMatchState.SPECTATOR:
            return 'spectator mode';
        default:
            return '';
    }
});
const action = computed<'buy' | 'sell' | 'move'>(() => {
    switch (matchState.value) {
        case api.PlayerMatchState.WAIT:
            return 'buy';
        case api.PlayerMatchState.BANKRUPTY:
            return 'sell';
        default:
            return 'move';
    }
});

const isActionEnabled = computed(() => {
    switch (action.value) {
        case 'buy':
            return isBuyTileAvailable.value
                && selectedTileIndex.value !== null
                && !tiles.value[selectedTileIndex.value]?.isPlayerTile
                && !tiles.value[selectedTileIndex.value]?.isChance
                && selectedTileIndex.value === playerTileIndex.value;
        case 'sell':
            return selectedTileIndex.value !== null
                && !tiles.value[selectedTileIndex.value]?.isChance
                && !!tiles.value[selectedTileIndex.value]?.isPlayerTile;
        case 'move':
            return matchState.value === api.PlayerMatchState.MOVE
                && selectedTileIndex.value !== null
                && !!tiles.value[selectedTileIndex.value]?.isMoveEnabled;
        default:
            return false;
    }
});
const selectedTile = computed(() => {
    const index = selectedTileIndex.value;
    if (index === null) {
        return null;
    }

    return tiles.value[index];
});
const actionBg = computed(() => {
    switch (action.value) {
        case 'buy':
            return 'match/bubble_green.webp';
        case 'sell':
            return 'match/bubble_red.webp';
        case 'move':
            return 'match/bubble_white.webp';
        default:
            return '';
    }
});
const isStatsVisible = ref(false);
const popupState = ref<'none' | 'bankruptcy' | 'chance' | 'bonus' | 'matchCancel'>('none');
const chancePopupButtonText = ref('');
const changePopupType = ref<ChanceEventType>(ChanceEventType.GainMoney);
const isSelectTileVisible = computed(() => selectedTileIndex.value !== null && !tiles.value[selectedTileIndex.value]?.isChance);

provide('matchState', matchState);

const eventService = useEventService();
const onThrowDice = () => {
    eventService.match.dispatch('throwDice');
};
const onAction = () => {
    const selectedTileIndexValue = selectedTileIndex.value;
    if (selectedTileIndexValue === null) {
        return;
    }

    switch (action.value) {
        case 'buy':
            eventService.match.dispatch('buyTile', selectedTileIndexValue);
            break;
        case 'sell':
            eventService.match.dispatch('sellTile', selectedTileIndexValue);
            break;
        case 'move':
            eventService.match.dispatch('move', selectedTileIndexValue);
            break;
    }
};
const onBankruptcy = () => {
    popupState.value = 'bankruptcy';
};

const onChanceEvent = (event: ChanceEventDesc) => {
    switch (event.type) {
        case ChanceEventType.GainMoney:
            chancePopupButtonText.value = `take ${event.data.money}`;
            break;
        case ChanceEventType.LoseMoney:
            chancePopupButtonText.value = `pay ${event.data.money}`;

            break;
        default:
            mainLogger.error('Unknown chance event type', event.type);

            chancePopupButtonText.value = 'okay';
            break;
    }

    changePopupType.value = event.type;

    popupState.value = 'chance';
};

const onBonusEvent = () => {
    popupState.value = 'bonus';
};

const onBonusEventResponce = (isClaimed: boolean) => {
    popupState.value = 'none';

    eventService.match.dispatch('claimBonus', isClaimed);
};

const onMatchCancel = () => {
    popupState.value = 'none';

    eventService.match.dispatch('cancelMatch');
};

onMounted(() => {
    eventService.match.on('bunkruptNotification', onBankruptcy);
    eventService.match.on('chanceNotification', onChanceEvent);
    eventService.match.on('bonusNotification', onBonusEvent);
});
onUnmounted(() => {
    eventService.match.off('bunkruptNotification', onBankruptcy);
    eventService.match.off('chanceNotification', onChanceEvent);
    eventService.match.off('bonusNotification', onBonusEvent);
});
</script>

<template>
    <div class="match">
        <div class="match__header">
            <Panel
                height="157px"
                class="match__money-bg"
                padding-left-right="0px"
                padding-top-bottom="0px"
            >
                <div class="match__money">
                    <SvgIcon :name="resourceIcon[Currency.Match]" pack="lobby" class="match__money-icon" />

                    <p class="match__money-text">
                        {{ formatNumber(playerCoins) }}
                    </p>
                </div>
            </Panel>

            <Panel
                padding-left-right="0px"
                padding-top-bottom="0px"
            >
                <div class="match__timer">
                    <p class="match__timer-title">
                        time left
                    </p>
                    <p class="match__timer-text">
                        {{ getShortTime(matchTimeLeft) }}
                    </p>
                </div>
            </Panel>

            <MainButton
                class="match__settings-text" label="escape" color="red"
                @click="popupState = 'matchCancel'"
            />
        </div>

        <Button v-show="!isStatsVisible" class="panel-dotted match__stats-button" @click="isStatsVisible = !isStatsVisible">
            <p class="match__stats-button-text">
                stats
            </p>
        </Button>

        <div class="match__status">
            {{ status }}
        </div>

        <div v-show="selectedTile && isSelectTileVisible" class="match__selected-tile">
            <img class="match__selected-tile-bg" :src="getAssetUrl('match/selected_tille_container.webp')" alt="">

            <div class="match__selected-tile-inner">
                <div v-show="selectedTile?.cost" class="match__selected-tile-info">
                    <p class="match__selected-tile-title match__selected-tile-title--buy">
                        buy
                    </p>

                    <div class="match__selected-tile-data">
                        <p class="match__selected-tile-text">
                            {{ formatNumber(selectedTile?.cost ?? 0) }}
                        </p>
                        <SvgIcon :name="resourceIcon[Currency.Match]" pack="lobby" class="match__selected-tile-icon" />
                    </div>
                </div>

                <div v-show="selectedTile?.passagePenalty" class="match__selected-tile-info">
                    <p class="match__selected-tile-title match__selected-tile-title--sell">
                        fine
                    </p>

                    <div class="match__selected-tile-data">
                        <p class="match__selected-tile-text">
                            {{ formatNumber(selectedTile?.passagePenalty ?? 0) }}
                        </p>
                        <SvgIcon :name="resourceIcon[Currency.Match]" pack="lobby" class="match__selected-tile-icon" />
                    </div>
                </div>
            </div>
        </div>

        <Button v-show="isStatsVisible" class="match__leaderboard" @click="isStatsVisible = !isStatsVisible">
            <img class="match__leaderboard-bg" :src="getAssetUrl('match/leaderboard_container.webp')" alt="">

            <div class="match__leaderboard-inner">
                <h1 class="match__leaderboard-title">
                    game statistic
                </h1>

                <div class="match__leaderboard-items">
                    <template v-for="(player, index) in leaderboard" :key="index">
                        <div class="leaderboard-item match__leaderboard-item">
                            <p class="leaderboard-item__place">
                                {{ index + 1 }}.
                            </p>

                            <p
                                class="leaderboard-item__name"
                                :class="{
                                    [`leaderboard-item__name--${player.rId + 1}`]: true,
                                }"
                            >
                                {{ player.name }}
                            </p>

                            <div class="leaderboard-item__data">
                                <p
                                    class="leaderboard-item__text"
                                    :class="{
                                        'leaderboard-item__text--negative': player.coins < 0,
                                    }"
                                >
                                    {{ formatNumber(player.coins) }}
                                </p>
                                <SvgIcon :name="resourceIcon[Currency.Match]" pack="lobby" class="leaderboard-item__icon" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </Button>

        <div class="match__footer">
            <Button
                v-show="isActionEnabled"
                class="match__action"
                :is-enabled="isActionEnabled"
                @click="onAction"
            >
                <img class="match__action-bg" :src="getAssetUrl(actionBg)" alt="">

                <p
                    class="match__action-text"
                    :class="{
                        'match__action-text--move': action === 'move',
                    }"
                >
                    {{ action }}
                </p>
            </Button>

            <Dice
                class="match__dice"
                :turn-time-left="turnTimeLeft"
                :is-enabled="matchState === api.PlayerMatchState.WAIT"
                :is-rolling="matchState === api.PlayerMatchState.DICE"
                @throw-dice="onThrowDice"
            />

            <Button v-show="isActionEnabled" class="match__action" />
        </div>

        <Transition name="fade">
            <Container v-show="popupState === 'bankruptcy'" class="info-block match__info-block" :container-id="ContainerIds.BankruptcyPopup">
                <div class="info-block__info">
                    <img class="info-block__bg" :src="getAssetUrl('match/popup_container.webp')" alt="">

                    <div class="info-block__text">
                        <div class="info-block__text-top">
                            <h1 class="info-block__title">
                                you’re a bankrupt
                            </h1>

                            <p class="info-block__description info-block__description--big">
                                You are bankrupt. Sell your property to pay the fine.
                            </p>
                        </div>

                        <p class="info-block__description info-block__description--small">
                            To do this, click on the property you want to sell
                        </p>
                    </div>

                    <img
                        class="info-block__image"
                        :src="getAssetUrl('match/bankrupt_image.webp')"
                        alt=""
                    >
                </div>

                <MainButton class="info-block__button" label="okay" @click="popupState = 'none'" />
            </Container>
        </Transition>

        <Transition name="fade">
            <Container v-show="popupState === 'chance'" class="info-block match__info-block" :container-id="ContainerIds.ChancePopup">
                <div class="info-block__info">
                    <img class="info-block__bg" :src="getAssetUrl('match/popup_container.webp')" alt="">

                    <div class="info-block__text">
                        <div class="info-block__text-top">
                            <h1 class="info-block__title">
                                you got a chance
                            </h1>

                            <p class="info-block__description info-block__description--big">
                                Do you want to choose a Chance card and try your luck?
                            </p>
                        </div>
                    </div>

                    <img
                        class="info-block__image"
                        :src="getAssetUrl('match/chance_image.webp')"
                        alt=""
                    >
                </div>

                <MainButton
                    class="info-block__button"
                    :color="changePopupType === ChanceEventType.GainMoney ? 'yellow' : 'red'"
                    @click="popupState = 'none'"
                >
                    <h1 class="info-block__button-text">
                        {{ chancePopupButtonText }}
                    </h1>

                    <SvgIcon name="gem" pack="lobby" class="info-block__button-icon" />
                </MainButton>
            </Container>
        </Transition>

        <Transition name="fade">
            <Container v-show="popupState === 'bonus'" class="info-block match__info-block" :container-id="ContainerIds.ChancePopup">
                <div class="info-block__info">
                    <img class="info-block__bg" :src="getAssetUrl('match/popup_container.webp')" alt="">

                    <div class="info-block__text">
                        <div class="info-block__text-top">
                            <h1 class="info-block__title">
                                you got a chance
                            </h1>

                            <p class="info-block__description info-block__description--big">
                                Do you want to choose a Chance card and try your luck?
                            </p>
                        </div>
                    </div>

                    <img
                        class="info-block__image"
                        :src="getAssetUrl('match/chance_image.webp')"
                        alt=""
                    >
                </div>

                <div class="info-block__buttons">
                    <MainButton
                        class="info-block__button-medium"
                        label="yes"
                        @click="() => onBonusEventResponce(true)"
                    />

                    <MainButton
                        class="info-block__button-medium"
                        label="no"
                        color="red"
                        @click="() => onBonusEventResponce(false)"
                    />
                </div>
            </Container>
        </Transition>

        <Transition name="fade">
            <Container v-show="popupState === 'matchCancel'" class="match-cancel match__match-cancel" :container-id="ContainerIds.MatchCancelPopup">
                <h1 class="match-cancel__title">
                    Match Cancellation
                </h1>

                <div class="match-cancel__info">
                    <p class="match-cancel__text">
                        If you leave the match, the debited tokens for the match will not be returned
                    </p>

                    <div class="match-cancel__inner">
                        <h1 class="match-cancel__text match-cancel__text--big">
                            You will lose
                        </h1>

                        <div class="match-cancel__content">
                            <SvgIcon
                                class="match-cancel__icon"
                                :name="resourceIcon[betCoins?.type ?? Currency.Soft]"
                                pack="lobby"
                            />

                            <h3 class="match-cancel__count">
                                {{ formatNumber(betCoins?.value ?? 0) }}
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="match-cancel__buttons">
                    <MainButton
                        class="match-cancel__button-medium"
                        label="continue"
                        @click="popupState = 'none'"
                    />

                    <MainButton
                        class="match-cancel__button-medium"
                        label="leave"
                        color="red"
                        @click="onMatchCancel"
                    />
                </div>
            </Container>
        </Transition>
    </div>
</template>

<style scoped lang="scss">
.match-cancel {
    display: flex;

    flex-direction: column;

    gap: size(64px);

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: var(--black-overlay, rgb(0 0 0 / 60%));

    &__title {
        font-family: $bangers-font-family;
        font-size: size(75px);
        font-style: normal;
        font-weight: 400;
        line-height: size(75px);
        color: var(--yellow-200, #FAC526);
        text-align: center;
        letter-spacing: size(4.5px);
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: var(--black, #000);
    }

    &__info {
        display: flex;

        flex-direction: column;

        gap: size(50px);

        align-items: center;
        justify-content: center;

        width: size(1113px);
        height: size(950px);

        background: #76757C;
        border: size(6px) solid var(--grey-400, #221F2B);
        border-radius: size(20px);
    }

    &__inner {
        display: flex;

        flex-direction: column;

        gap: size(20px);

        align-items: center;
        justify-content: center;
    }

    &__text {
        width: size(880px);

        font-family: $main-font-family;
        font-size: size(40px);
        font-style: normal;
        font-weight: 700;
        line-height: size(50.8px);
        color: var(--white, #FFF);
        text-align: center;

        &--big {
            font-size: size(48px);
            font-weight: 700;
            line-height: size(60.96px);
        }
    }

    &__icon {
        width: size(217px);
        height: size(214px);
    }

    &__content {
        display: flex;

        flex-direction: column;

        gap: size(57px);

        align-items: center;
        justify-content: center;

        width: size(484px);
        height: size(484px);

        background: var(--black-overlay, rgb(0 0 0 / 60%));
        border: size(6px) solid var(--grey-400, #221F2B);
        border-radius: size(40px);
    }

    &__count {
        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: size(64px);
        color: var(--yellow-300, #E5AA13);
        text-align: center;
    }

    &__buttons {
        display: flex;

        gap: size(50px);

        align-items: center;
        justify-content: center;

        width: size(1113px);
    }

    &__button-medium {
        width: 100%;
    }
}

.info-block {
    z-index: 2;

    display: flex;

    flex-direction: column;

    gap: size(50px);

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: var(--black-overlay, rgb(0 0 0 / 60%));

    &__info {
        position: relative;

        display: grid;

        grid-template-columns: 1fr auto;

        justify-content: center;

        width: size(1111px);
        height: size(414px);
        overflow: hidden;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;
    }

    &__text {
        z-index: 2;

        display: flex;

        flex-direction: column;

        align-items: flex-start;
        justify-content: space-between;

        padding: size(28px) size(43px) size(41px);
    }

    &__text-top {
        display: flex;

        flex-direction: column;

        width: 100%;
        height: 100%;
    }

    &__title {
        font-family: $bangers-font-family;
        font-size: size(75px);
        font-style: normal;
        font-weight: 400;
        line-height: size(75px);
        color: var(--yellow-200, #FAC526);
        letter-spacing: size(3.75px);
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #000;
    }

    &__description {
        font-family: $main-font-family;
        font-size: size(40px);
        font-weight: 400;
        line-height: size(50.8px);
        color: var(--white, #FFF);

        &--big {
            font-weight: 700;
        }

        &--center {
            display: flex;

            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
        }
    }

    &__image {
        z-index: 2;

        width: size(441px);
        height: 100%;
        padding: size(5px);

        object-fit: cover;
        border-radius: size(20px);
    }

    &__button-text {
        font-family: $bangers-font-family;
        font-size: size(76px);
        font-style: normal;
        font-weight: 400;
        line-height: size(76px);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: size(5.32px);
    }

    &__buttons {
        display: flex;

        gap: size(50px);

        width: size(1111px);
    }

    &__button-icon {
        width: size(73px);
        height: size(73px);
    }

    &__button {
        width: size(1111px);
    }

    &__button-medium {
        width: 100%;
    }
}

.leaderboard-item {
    display: grid;

    grid-template-columns: auto 1fr auto;

    gap: size(40px);

    align-items: center;
    justify-content: flex-start;

    width: 100%;
    padding-right: size(75px);

    &__place,
    &__name {
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(50px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--1 {
            color: #E0129F;
        }

        &--2 {
            color: #069EE8;
        }

        &--3 {
            color: #D8EB44;
        }

        &--4 {
            color: #AE53F9;
        }
    }

    &__place {
        color: #FFF;
    }

    &__data {
        display: flex;

        gap: size(20px);

        align-items: center;
        justify-content: center;
    }

    &__text {
        font-family: $main-font-family;
        font-size: size(54px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--yellow-300, #E5AA13);
        text-align: right;

        &--negative {
            color: var(--red-300, #CE1B50);
        }
    }

    &__icon {
        width: size(48px);
        height: size(48px);
    }
}

.match {
    display: flex;

    flex-direction: column;

    justify-content: space-between;

    width: 100%;
    height: 100%;
    overflow: hidden;

    &__info-block {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__match-cancel {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__header {
        position: relative;

        display: flex;

        align-items: center;
        justify-content: space-around;


        width: 100%;
        padding: 0 size(53px);
        margin-top: size(56px);
    }

    &__selected-tile {
        position: absolute;
        top: size(305px);
        right: size(50px);
        z-index: 30;

        display: flex;

        align-items: center;
        justify-content: center;

        width: size(618.177px);
        height: size(401px);

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            width: 100%;
            height: 100%;
        }

        &-inner {
            z-index: 2;

            display: flex;

            flex-direction: column;

            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
        }

        &-info {
            display: flex;

            gap: size(30px);

            align-items: center;
            justify-content: center;

            width: 100%;
        }

        &-title {
            font-family: $bangers-font-family;
            font-size: size(128px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: var(--yellow-150, #F6DE03);
            text-align: center;
            text-shadow: size(6px) size(6px) 0 #000;
            letter-spacing: size(7.68px);
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #000;

            &--buy {
                color: var(--green-100, #25BD1D);
            }

            &--sell {
                color: var(--red-300, #CE1B50);
            }
        }

        &-text {
            font-family: $bangers-font-family;
            font-size: size(128px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: var(--yellow-150, #F6DE03);
            text-align: center;
            text-shadow: size(6px) size(6px) 0 #000;
            letter-spacing: size(7.68px);
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #000;
        }

        &-data {
            display: flex;

            gap: size(20px);

            align-items: center;
            justify-content: center;
        }

        &-icon {
            width: size(110px);
            height: size(110px);
        }
    }

    &__leaderboard {
        position: absolute;
        top: size(305px);
        left: 0;
        z-index: 3;

        display: flex;

        flex-direction: column;

        align-items: center;
        justify-content: center;

        width: size(848px);
        height: size(538px);

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            width: 100%;
            height: 100%;
        }

        &-inner {
            z-index: 2;

            width: 100%;
            height: 100%;
            padding: size(67px) size(30px) 0;
        }

        &-title {
            margin-bottom: size(18px);

            font-family: $bangers-font-family;
            font-size: size(75px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: var(--yellow-200, #FAC526);
            letter-spacing: size(4.5px);
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #000;
        }
    }

    &__status {
        position: absolute;
        top: size(316px);
        left: 50%;

        display: flex;

        align-items: center;
        justify-content: center;

        font-family: $bangers-font-family;
        font-size: size(128px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--yellow-150, #F6DE03);
        text-align: center;
        text-shadow: size(9px) size(9px) size(0) #000;
        letter-spacing: size(7.68px);

        transform: translateX(-50%);
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #000;
    }

    &__stats-button {
        position: absolute;
        top: size(305px);
        left: size(-102px);

        display: flex;

        align-items: center;
        justify-content: flex-end;

        width: size(336px);
        height: size(157px);

        background-position: size(-140px);
        border-width: size(12px);

        &-text {
            z-index: 2;

            padding: 0 size(20px);

            font-family: $bangers-font-family;
            font-size: size(86px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #FFF;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: size(6.02px);
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #000;
        }
    }

    &__money {
        position: relative;

        display: flex;

        gap: size(17px);

        align-items: center;
        justify-content: flex-start;

        width: size(336px);
        padding: size(31px) size(22px);

        &-bg {
            background-position: size(-140px);
        }

        &-text {
            z-index: 2;

            font-family: $main-font-family;
            font-size: size(52px);

            background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &-icon {
            z-index: 2;

            width: size(87px);
            height: size(87px);
        }
    }

    &__timer {
        position: relative;

        display: flex;

        flex-direction: column;

        align-items: center;
        justify-content: center;
        justify-self: center;

        width: size(404px);
        height: size(198px);

        &-text {
            z-index: 2;

            font-family: $main-font-family;
            font-size: size(96px);
            font-style: normal;
            font-weight: 400;
            line-height: size(96px);
            text-align: center;

            background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
            background-clip: text;
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #000;
            -webkit-text-fill-color: transparent;
        }

        &-title {
            z-index: 2;

            font-family: $bangers-font-family;
            font-size: size(48px);
            font-style: normal;
            font-weight: 400;
            line-height: size(48px);
            color: #FFF;
            text-align: center;
            letter-spacing: size(2.88px);
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #000;
        }
    }

    &__settings {
        position: relative;

        display: flex;

        align-items: center;
        justify-content: center;
        justify-self: end;

        width: size(336px);
        height: size(157px);

        &-text {
            z-index: 2;

            width: size(350px);
            margin-bottom: 0;

            font-size: size(86px);
            color: #FFF;

            background-position: size(0);
            -webkit-text-stroke-width: 1;
            -webkit-text-stroke-color: #000;
        }
    }

    &__footer {
        position: relative;

        display: flex;

        grid-row: 3;

        gap: size(20px);

        align-items: center;
        justify-content: center;

        width: 100%;
        height: size(490.485px);
    }

    &__action {
        position: relative;

        display: flex;

        width: size(429.134px);
        height: size(490.485px);

        @include center;

        &--disabled {
            filter: grayscale(100%);
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            width: 100%;
            height: 100%;
        }

        &-text {
            z-index: 2;

            font-family: $bangers-font-family;
            font-size: size(96px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: var(--white, #FFF);
            text-align: center;
            text-shadow: 0 size(4px) size(4px) #000;
            text-transform: uppercase;
            letter-spacing: size(3.84px);
            -webkit-text-stroke-width: 2;
            -webkit-text-stroke-color: #000;

            &--move {
                color: var(--yellow-150, #F6DE03);
            }
        }
    }
}
</style>
