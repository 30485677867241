<script setup lang="ts">
import { type Clan } from 'game/types/model/clansModel';
import Button from 'game/ui/common/Buttons/Button.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import InfoBlock from 'game/ui/commonInstances/InfoBlock.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { formatNumber } from 'game/ui/utils/number';

const props = defineProps<{
    clan?: Clan;
}>();

const emit = defineEmits<{
    close: [void];
}>();

const onClose = () => {
    emit('close');
};

function getImage() {
    if (props.clan && props.clan.members && props.clan.members.length > 0) {
        return 'lobby/clan-name.webp';
    }

    return 'lobby/join-clans.webp';
}
</script>

<template>
    <div v-if="!clan" class="info-block">
        <InfoBlock
            title="Join the clan!"
            description="Yes! Join the squad to earn money together!"
            description2="Increase profits and rise in the ranking!"
            :asset-path="'lobby/join-clans.webp'"
        />
    </div>
    <div v-else class="info-block">
        <InfoBlock
            description="Click and join the clan!"
            :title="clan.name"
            :asset-path="getImage()"
            :align="'center'"
        >
            <Button class="close-button" @click="onClose">
                <SvgIcon
                    class="close-button__icon"
                    name="cross"
                    pack="lobby"
                />
            </Button>
            <div
                class="info-block__description info-block__description--center"
            >
                <img
                    class="info-block__clan-image"
                    :src="clan.avatarUrl ?? getAssetUrl('lobby/user-image.webp')"
                    alt=""
                >
            </div>
        </InfoBlock>

        <div class="info-block__details">
            <Panel padding-top-bottom="14px" padding-left-right="0px">
                <div class="info-block__details-data">
                    <h3 class="section-panel-title info-block__details-title">
                        Rank
                    </h3>

                    <p class="info-block__details-text">
                        {{ formatNumber(clan.rank) }}
                    </p>
                </div>
            </Panel>

            <Panel padding-top-bottom="14px" padding-left-right="0px">
                <div class="info-block__details-data info-block__details-data--icon">
                    <SvgIcon
                        class="info-block__details-icon info-block__details-icon--ticket"
                        name="points"
                        pack="lobby"
                    />

                    <h3 class="info-block__details-text  info-block__details-text--left">
                        Calculation
                    </h3>
                </div>
            </Panel>

            <Panel padding-top-bottom="14px" padding-left-right="0px">
                <div class="info-block__details-data">
                    <h3 class="section-panel-title info-block__details-title">
                        Clicks from tg
                    </h3>

                    <p class="info-block__details-text">
                        {{ formatNumber(clan.telegramClickCount) }}
                    </p>
                </div>
            </Panel>

            <Panel padding-top-bottom="14px" padding-left-right="0px">
                <div class="info-block__details-data info-block__details-data--icon">
                    <SvgIcon
                        class="info-block__details-icon info-block__details-icon--telegram"
                        name="telegram_logo_gold"
                        pack="lobby"
                    />

                    <h3 class="info-block__details-text  info-block__details-text--left">
                        Join the community
                    </h3>
                </div>
            </Panel>
        </div>
    </div>
</template>

<style scoped lang="scss">
.info-block {
    display: flex;

    flex-direction: column;

    &__details {
        display: grid;

        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);

        gap: size(18px) size(24px);

        width: 100%;
        margin-bottom: size(44px);
    }

    &__details-data {
        display: flex;

        flex-direction: column;

        gap: size(11px) size(42px);

        align-items: center;
        justify-content: center;

        height: size(164px);

        &--icon {
            flex-direction: row;
        }
    }

    &__details-title {
        font-size: size(48px);
    }

    &__details-text {
        width: size(258px);

        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 400;
        line-height: size(32px);
        color: var(--white, #FFF);
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: var(--grey-200, #464646);

        text-align: center;

        &--left {
            text-align: left;
        }
    }

    &__details-icon {
        &--ticket {
            width: size(79px);
            height: size(101px);
        }

        &--telegram {
            width: size(64px);
            height: size(64px);
        }
    }

    &__info {
        position: relative;

        display: grid;

        grid-template-columns: 1fr auto;

        justify-content: center;

        width: 100%;
        height: size(401.229px);
        overflow: hidden;

        background: #221F2B;
        border: size(6px) solid var(--grey-200, #464646);
        border-radius: size(20px);
    }

    &__text {
        display: flex;

        flex-direction: column;

        align-items: flex-start;
        justify-content: space-between;

        padding: size(11px) size(38px) size(41px);
    }

    &__text-top {
        display: flex;

        flex-direction: column;

        width: 100%;
        height: 100%;
    }

    &__title {
        font-family: $main-font-family;
        font-size: size(62px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__description {
        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 400;
        line-height: size(32px);
        color: var(--grey-100, var(--grey, #939393));

        &--big {
            font-weight: 700;
            line-height: size(45.72px);
            color: var(--white, #FFF);
        }

        &--center {
            display: flex;

            justify-content: center;

            width: 100%;
            height: 100%;
            margin-top: size(16px);
        }
    }

    &__image {
        width: size(430px);
        height: 100%;

        object-fit: cover;
        border-left: size(6px) solid var(--grey-200, #464646);

        &--details {
            width: size(480px);
            height: size(417.426px);
        }
    }

    &__clan-image {
        width: size(213px);
        height: size(203px);
    }
}
</style>
