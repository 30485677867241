<script setup lang="ts">
const props = defineProps<{
    imageUrl: string;
    title?: string;
    height?: string;
    paddingRight?: string;
}>();

const overridedHeight = typeof props.height === 'string' && props.height.length > 3 ? props.height : 'auto';
const bgImageUrl = `url(${props.imageUrl})`;
const overridePaddingRight = typeof props.paddingRight === 'string' && props.paddingRight.length > 2 ? props.paddingRight : '42px';
</script>

<template>
    <section class="panel-top-image panel-dotted">
        <div class="panel-top-image__inner">
            <div class="panel-top-image__inner__image" />
            <div class="panel-top-image__inner__content">
                <h1
                    v-if="title"
                    class="section-title"
                >
                    {{ title }}
                </h1>
                <slot />
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
.panel-top-image {
    position: relative;

    margin-bottom: size(42px);
    overflow: hidden;

    &__inner {
        display: flex;

        flex-direction: column;

        height: size(v-bind(overridedHeight));

        &__content {
            padding: size(52px) size(42px);
            padding-right: size(v-bind(overridePaddingRight));
        }

        &__image {
            width: 100%;
            height: size(419px);

            background-image: v-bind(bgImageUrl);
            background-size: cover;
        }
    }
}
</style>
