import { isDefined } from '@nord-beaver/core/utils/utils';
import { type VaultComponentDesc } from 'game/types/entityDescs/vault';

export class VaultComponent {
    isFetching = false;
    isAvailable = false;
    timerEndTime = Number.MAX_SAFE_INTEGER;
    timeElapsed = 0;
    claimTimeElapsed = 0;

    constructor(
        readonly desc: VaultComponentDesc,
        available?: boolean,
        timerEndTime?: number,
    ) {
        if (isDefined(available)) {
            this.isAvailable = available;
        }
        if (isDefined(timerEndTime)) {
            this.timerEndTime = timerEndTime;
        }
    }
}