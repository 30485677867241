import { type Entity } from '@nord-beaver/core/ecs';
import { throwIfNull } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { NakamaComponent } from 'game/ecs/components/nakama/nakamaComponent';
import { RpcNakamaComponent } from 'game/ecs/components/nakama/rpcNakamaComponent';
import { type RpcHandlersDesc } from 'game/services/nakama/rpc/rpcHandlers';
import { type RpcNakamaService } from 'game/services/nakama/rpc/rpcNakamaService';
import { RpcType } from 'game/services/nakama/rpc/rpcNakamaUtils';
import { ApiRpc, type ApiRpcSchema } from 'game/types/nakama/rpcData';

export const apiRpcHandlersDesc: RpcHandlersDesc = {
    [ApiRpc.createMatch]: {
        decoder: data => api.RpcCreateMatchResponse.fromObject(data),
        encoder: (message: api.IRpcCreateMatchRequest) => api.RpcCreateMatchRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.compareHashes]: {
        decoder: data => api.RpcCompareHashesResponce.fromObject(data),
    },
    [ApiRpc.findMatch]: {
        decoder: data => api.RpcFindMatchResponse.fromObject(data),
    },
    [ApiRpc.userStatus]: {
        decoder: data => api.RpcUserStatusResponse.fromObject(data),
    },
    [ApiRpc.rewards]: {
        decoder: data => api.RpcGetDailyRewardResponce.fromObject(data),
    },
    [ApiRpc.getTasks]: {
        decoder: data => api.RpcGetTasksResponse.fromObject(data),
    },
    [ApiRpc.collectTaskReward]: {
        decoder: data => api.RpcCollectTaskRewardResponce.fromObject(data),
        encoder: (message: api.IRpcGetTaskRewardRequest) => api.RpcGetTaskRewardRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.completeTask]: {
        decoder: data => api.RpcCompleteTaskResponce.fromObject(data),
        encoder: (message: api.IRpcCompleteTaskRequestDebug) => api.RpcCompleteTaskRequestDebug.fromObject(message).toJSON(),
    },
    [ApiRpc.getAccounts]: {
        decoder: data => api.RpcGetAccountsResponse.fromObject(data),
    },
    [ApiRpc.getClient]: {
        decoder: data => api.RpcRpcGetClientResponse.fromObject(data),
    },
    [ApiRpc.getInvitees]: {
        decoder: data => api.RpcGetInviteesResponse.fromObject(data),
        encoder: (message: api.IRpcGetInviteesRequest) => api.RpcGetInviteesRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.getState]: {
        decoder: data => api.RpcGetStateResponse.fromObject(data),
    },
    [ApiRpc.groupCreate]: {
        decoder: data => api.RpcGroupCreateResponse.fromObject(data),
        encoder: (message: api.IRpcGroupCreateRequest) => api.RpcGroupCreateRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.groupAddClient]: {
        decoder: data => api.RpcGroupAddClientResponse.fromObject(data),
        encoder: (message: api.IRpcGroupAddClientRequest) => api.RpcGroupAddClientRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.groupUpdate]: {
        decoder: data => api.RpcGroupUpdateResponse.fromObject(data),
        encoder: (message: api.IRpcGroupUpdateRequest) => api.RpcGroupUpdateRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.groupGet]: {
        decoder: data => api.RpcGroupGetResponse.fromObject(data),
        encoder: (message: api.IRpcGroupGetRequest) => api.RpcGroupGetRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.groupDelete]: {
        decoder: data => api.RpcGroupDeleteResponse.fromObject(data),
        encoder: (message: api.IRpcGroupDeleteRequest) => api.RpcGroupDeleteRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.groupList]: {
        decoder: data => api.RpcGroupListResponse.fromObject(data),
        encoder: (message: api.IRpcGroupListRequest) => api.RpcGroupListRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.groupListClients]: {
        decoder: data => api.RpcGroupListClientsResponse.fromObject(data),
        encoder: (message: api.IRpcGroupListClientsRequest) => api.RpcGroupListClientsRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.groupExclude]: {
        decoder: data => api.RpcGroupExcludeResponse.fromObject(data),
        encoder: (message: api.IRpcGroupExcludeRequest) => api.RpcGroupExcludeRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.customIdsToUserIds]: {
        decoder: data => api.RpcCustomIdsToUserIdsResponse.fromObject(data),
        encoder: (message: api.IRpcCustomIdsToUserIdsRequest) => api.RpcCustomIdsToUserIdsRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.initBet]: {
        decoder: data => api.RpcInitBetResponse.fromObject(data),
        encoder: (message: api.IRpcInitBetRequest) => api.RpcInitBetRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.addBet]: {
        decoder: data => api.RpcAddBetResponse.fromObject(data),
        encoder: (message: api.IRpcAddBetRequest) => api.RpcAddBetRequest.fromObject(message).toJSON(),
    },
    [ApiRpc.collectVault]: {
        decoder: data => api.RpcCollectVaultResponce.fromObject(data),
    },
};

export function callRpc<T extends ApiRpc = ApiRpc>({
    rpcService,
    entity,
    rpcType,
    payload,
    type = RpcType.Client,
}: {
    rpcService: RpcNakamaService;
    entity: Entity;
    rpcType: T;
    payload: ApiRpcSchema[T]['request'];
    type?: RpcType | undefined;
}) {
    const rpc = throwIfNull(entity.get(RpcNakamaComponent), 'RpcNakamaComponent not found');
    const nakama = throwIfNull(entity.get(NakamaComponent), 'NakamaComponent not found');

    return rpcService.callRpc<T, ApiRpcSchema>(rpc.rpcHandlers, type, {
        rpcType,
        nakamaEntity: entity,
        payload,
        rpcHttpKey: nakama.config.RPC_HTTP_KEY ?? undefined,
    });
}