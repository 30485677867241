<script setup lang="ts">
import { clamp } from '@nord-beaver/core/utils/utils';
import { hooks } from '@nord-beaver/html-ui';
import { computed, nextTick, onMounted, ref, watch } from 'vue';

const { useResize } = hooks.resize;

const props = defineProps<{
    modelValue: number;
    ignoreScrollEvent: boolean;
}>();
const emit = defineEmits(['update:modelValue', 'enable', 'disable', 'update-absolute-values']);

const outerModel = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', clamp(0, 1, value)),
});

const scrollpanel = ref<HTMLDivElement | null>(null);
let isScrollEnabled: boolean | null = null;

const isScrollable = ref(true);

const onScroll = () => {
    if (scrollpanel.value !== null && !props.ignoreScrollEvent) {
        outerModel.value = scrollpanel.value.scrollTop / (scrollpanel.value.scrollHeight - scrollpanel.value.clientHeight);
    }
};

useResize(onScroll);

const toggleScroll = (isEnabled: boolean) => {
    isScrollable.value = isEnabled;

    if (isEnabled !== isScrollEnabled) {
        isScrollEnabled = isEnabled;
        emit(isEnabled ? 'enable' : 'disable');
    }
};

const updateScroll = async () => {
    await nextTick();

    if (scrollpanel.value !== null) {
        const isEnabled = scrollpanel.value.scrollHeight > scrollpanel.value.clientHeight;
        const top = (scrollpanel.value.scrollHeight - scrollpanel.value.clientHeight) * outerModel.value;

        toggleScroll(isEnabled);

        isEnabled && scrollpanel.value.scrollTo({ top });

        emit('update-absolute-values', {
            scrollTop: scrollpanel.value.scrollTop,
            scrollHeight: scrollpanel.value.scrollHeight,
            clientHeight: scrollpanel.value.clientHeight,
            scrollBottom: scrollpanel.value.scrollHeight - scrollpanel.value.clientHeight - scrollpanel.value.scrollTop,
        });
    }
};

defineExpose({
    updateScroll,
    isScrollable,
});

watch([outerModel], () => {
    updateScroll();
}, {
    immediate: true,
});

onMounted(() => {
    updateScroll();
});
</script>

<template>
    <div
        ref="scrollpanel"
        class="scroll-panel"
        @scroll="onScroll"
    >
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.scroll-panel {
    @include hide-scroll;

    overflow: hidden scroll;

    pointer-events: initial;
}
</style>