import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { debugPanelService } from '@nord-beaver/core/services/debugPanelService';
import { ButtonElement } from '@nord-beaver/core/services/debugPanelService/debugElements';
import { FLAG_DEBUG, FLAG_TEST } from '@nord-beaver/core/utils/debug';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { isDefined } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { DailyRewardsComponent } from 'game/ecs/components/meta/dailyRewardsComponent';
import { DailyRewardsNode } from 'game/ecs/nodes/meta/dailyRewardsNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { EnitityComponents } from 'game/types/entityComponents';
import { type DailyRewardsDesc } from 'game/types/entityDescs/dailyRewards';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEntityComponentDesc } from 'game/utils/entityDesc';

const logger = mainLogger.getLogger('Meta').getLogger('DailyRewards');

export class DailyRewardsSystem extends SystemService {
    private dailyRewardsDesc?: DailyRewardsDesc;
    private dailyRewardsNodeList?: NodeList<DailyRewardsNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly entityService: EntityService,
        private readonly eventService: EventService,
        private readonly nakamaService: NakamaService,
    ) {
        super();
    }

    init() {
        this.eventService.game.once('entityLoaded', () => {
            const dailyRewardsDesc = getEntityComponentDesc<DailyRewardsDesc>(this.entityService, '/dailyRewards', EnitityComponents.DailyRewards);
            if (!dailyRewardsDesc) {
                logger.error('Daily rewards entity not found');

                return;
            }

            this.dailyRewardsDesc = dailyRewardsDesc;
        }, this);

        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
        });
        this.dailyRewardsNodeList = this.setupNodeList({
            node: DailyRewardsNode,
        });

        this.eventService.lobby.on('claimDailyReward', this.claimDailyReward, this);

        if (FLAG_DEBUG || FLAG_TEST) {
            debugPanelService?.addElement(new ButtonElement({
                path: ['Features', 'Daily rewards'],
                id: 'Reset daily rewards',
                initValue: () => {
                    this.nakamaService.callRpc({
                        rpcType: ApiRpc.debugResetDailyReward,
                        payload: {},
                    });
                },
            }));
        }
    }

    override destroy() {
        this.eventService.lobby.offAll('claimDailyReward', this);
    }

    private addMeta(node: MetaNode) {
        const { meta, entity } = node;

        if (!this.dailyRewardsDesc) {
            logger.error('Daily rewards entity not loaded');

            return;
        }

        entity.add(new DailyRewardsComponent(this.dailyRewardsDesc, meta.dailyRewardData));

        logger.log('Daily reward component added', { entity });
    }

    private claimDailyReward = async () => {
        if (!this.dailyRewardsNodeList) {
            logger.error('Daily rewards node list not found');

            return;
        }

        const { dailyRewards } = this.dailyRewardsNodeList.get(0) ?? {};
        if (!dailyRewards) {
            logger.error('Daily rewards not found');

            return;
        }

        if (dailyRewards.isClaiming) {
            return;
        }

        if (!dailyRewards.available) {
            logger.error('Daily rewards not available');

            return;
        }

        dailyRewards.isClaiming = true;

        const response = await this.nakamaService.callRpc({
            rpcType: ApiRpc.rewards,
            payload: {},
        });

        this.eventService.metaData.dispatch('request', 'clientAccounts');

        dailyRewards.rewardDay = isDefined(response.rewardDay)
            ? (response.rewardDay + 1) % 7
            : 0;
        dailyRewards.nextRewardS = response.nextRewardS;

        dailyRewards.isClaiming = false;
        dailyRewards.available = response.result !== api.GetDailyRewardResult.DAILY_REWARD_SUCCESS;

        logger.log('Daily reward claimed', { response });

        dailyRewards.isDirty = true;

        this.eventService.lobby.dispatch('lobbyShow');
    };
}