import { type User, type Client, type Session } from '@heroiclabs/nakama-js';
import { type Entity } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { ensureArray, retry } from '@nord-beaver/core/utils/utils';
import { type IEnvService, type SessionConfig, saveSession, restoreSession, sessionRefreshWithRetry, removeSession, type AuthWithCustomNakamaConfig, type AuthWithDeviceNakamaConfig, type AuthWithEmailNakamaConfig } from 'game/services/nakama/session/sessionNakamaUtils';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Session');

export class SessionNakamaService {
    private sessionExpirationTimespanS = 5;
    private sessionRefreshRetryCount = 5;
    private sessionRefreshRetryTimeoutMs = 100;
    private authRetryCount = 5;
    private authRetryTimeoutMs = 300;
    private userDataRetryCount = 5;
    private userDataRetryTimeoutMs = 300;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly envService: IEnvService,
    ) {
        logger.info('SessionNakamaService initialized');
    }

    config(config: SessionConfig) {
        if (config.sessionExpirationTimespanS !== undefined) {
            this.sessionExpirationTimespanS = config.sessionExpirationTimespanS;
        }
        if (config.sessionRefreshRetryCount !== undefined) {
            this.sessionRefreshRetryCount = config.sessionRefreshRetryCount;
        }
        if (config.sessionRefreshRetryTimeoutMs !== undefined) {
            this.sessionRefreshRetryTimeoutMs = config.sessionRefreshRetryTimeoutMs;
        }
        if (config.authRetryCount !== undefined) {
            this.authRetryCount = config.authRetryCount;
        }
        if (config.authRetryTimeoutMs !== undefined) {
            this.authRetryTimeoutMs = config.authRetryTimeoutMs;
        }
        if (config.userDataRetryCount !== undefined) {
            this.userDataRetryCount = config.userDataRetryCount;
        }
        if (config.userDataRetryTimeoutMs !== undefined) {
            this.userDataRetryTimeoutMs = config.userDataRetryTimeoutMs;
        }
    }

    isSessionValid(session: Session) {
        const currentTimeSec = Date.now() / 1000;
        return !session.isexpired(currentTimeSec + this.sessionExpirationTimespanS);
    }

    async getUserData(client: Client, session: Session, userIds: string): Promise<User>;
    async getUserData(client: Client, session: Session, userIds: string[]): Promise<User[]>;
    async getUserData(client: Client, session: Session, userIds: string | string[]): Promise<User | User[] | undefined> {
        const userIdsArray = ensureArray(userIds);
        const isSingle = typeof userIds === 'string';

        if (userIdsArray.length === 0) {
            return [];
        }

        try {
            const users = await this.getUserDataWithRetry(client, session, userIdsArray);
            return isSingle ? users[0] : users;
        } catch (e) {
            logger.error('getUserById error', e);
        }

        return undefined;
    }

    async getMatchList(client: Client, session: Session, {
        limit,
        authoritative,
        label,
        minSize,
        maxSize,
        query,
    }: {
        limit?: number;
        authoritative?: boolean;
        label?: string;
        minSize?: number;
        maxSize?: number;
        query?: string;
    } = {}) {
        const matchList = await client.listMatches(session, limit, authoritative, label, minSize, maxSize, query);

        logger.info('Match list fetched', matchList);

        return matchList;
    }

    async authenticateWithEmail(
        client: Client,
        {
            email,
            password,
            username,
            vars,
        }: Omit<AuthWithEmailNakamaConfig, 'type'>,
    ) {
        return this.authenticateWithRetry(
            async () => await client.authenticateEmail(email, password, true, username, vars),
            'authenticateWithEmail',
        );
    }

    async authenticateWithDevice(
        client: Client,
        {
            deviceId,
            username,
            vars,
        }: Omit<AuthWithDeviceNakamaConfig, 'type'>,
    ) {
        return this.authenticateWithRetry(
            async () => await client.authenticateDevice(deviceId, true, username, vars),
            'authenticateWithDevice',
        );
    }

    async authenticateWithCustom(
        client: Client,
        {
            customId,
            username,
            vars,
        }: Omit<AuthWithCustomNakamaConfig, 'type'>,
    ) {
        return this.authenticateWithRetry(
            async () => await client.authenticateCustom(customId, true, username, vars),
            'authenticateWithCustom',
        );
    }

    restoreSession(userId?: string) {
        return restoreSession(this.envService, userId);
    }

    async refreshSession(session: Session, entity: Entity) {
        if (this.isSessionValid(session)) {
            return;
        }

        try {
            const session = await sessionRefreshWithRetry(
                entity,
                this.sessionRefreshRetryCount,
                this.sessionRefreshRetryTimeoutMs,
            );

            const userId = session.user_id;
            if (!userId) {
                throw new Error('User ID is not available after session refresh');
            }

            saveSession(session, this.envService, userId);

            logger.info('Session refreshed successfully');
        } catch (error) {
            const message = error instanceof Error ? error.message : error;
            logger.error('Session refresh failed', { error: message });
            throw error;
        }
    }

    logout(session: Session, userId?: string) {
        const uid = userId ?? session.user_id;
        if (!uid) {
            logger.warn('Cannot logout: User ID is not available');
            return false;
        }

        removeSession(this.envService, uid);

        logger.info('User logged out');
        return true;
    }

    private async getUserDataWithRetry(
        client: Client,
        session: Session,
        userIds: string | string[],
    ) {
        let attempt = 0;

        return new Promise<User[]>((resolve, reject) => {
            retry({
                work: async () => {
                    const userDatas = await this.getUserDataInternal(client, session, ensureArray(userIds));
                    if (!userDatas || userDatas.length === 0) {
                        throw new Error('User data is undefined');
                    }

                    return userDatas;
                },
                beforeRetry: () => logger.warn(`getUserData: Retrying fetching user data, attempt ${++attempt}`),
                count: this.authRetryCount,
                timeout: this.authRetryTimeoutMs,
            })
                .then(resolve)
                .catch(reject);
        });
    }

    private async getUserDataInternal(
        client: Client,
        session: Session,
        userIds: string[],
    ) {
        if (userIds.length === 0) {
            return undefined;
        }

        const users = await client.getUsers(session, userIds);
        if (!users?.users || users.users.length === 0) {
            return undefined;
        }

        return users.users;
    }

    private async authenticateWithRetry(
        authFunc: () => Promise<Session>,
        methodName: string,
    ) {
        let attempt = 0;

        return new Promise<Session>((resolve, reject) => {
            retry({
                work: this.authenticate.bind(this, authFunc, methodName),
                beforeRetry: () => logger.warn(`${methodName}: Retrying authentication, attempt ${++attempt}`),
                count: this.userDataRetryCount,
                timeout: this.userDataRetryTimeoutMs,
            })
                .then(resolve)
                .catch(reject);
        });
    }

    private async authenticate(
        authFunc: () => Promise<Session>,
        methodName: string,
    ) {
        try {
            const session = await authFunc();

            const userId = session.user_id;
            if (!userId) {
                throw new Error('User ID is not available after session refresh');
            }

            saveSession(session, this.envService, userId);

            logger.info(`${methodName}: User authenticated successfully`);
            return session;
        } catch (error) {
            const message = error instanceof Error ? error.message : error;
            logger.error(`${methodName}: Authentication failed`, { error: message });
            throw error;
        }
    }
}
