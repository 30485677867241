import { SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { CoreNakamaNode } from 'game/ecs/nodes/nakama/coreNakamaNode';
import { type SessionNakamaService } from 'game/services/nakama/session/sessionNakamaService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Session');

export class SessionNakamaSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly sessionNakamaService: SessionNakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: CoreNakamaNode,
            add: this.addSession,
            update: this.updateSession,
        });
    }

    private addSession = (node: CoreNakamaNode) => {
        const { session, entity } = node;

        if (!this.sessionNakamaService.isSessionValid(session.session)) {
            this.sessionNakamaService.refreshSession(session.session, entity)
                .catch(error => {
                    logger.error('Session refresh failed', error);
                });
        }

        session.isSessionValid = this.sessionNakamaService.isSessionValid(session.session);
    };

    private updateSession = async (node: CoreNakamaNode) => {
        const { session, entity } = node;

        if (!this.sessionNakamaService.isSessionValid(session.session)) {
            session.isSessionRefreshing = true;

            logger.info('Session refreshing', { entity });
            this.sessionNakamaService.refreshSession(session.session, entity)
                .then(() => {
                    session.isSessionValid = this.sessionNakamaService.isSessionValid(session.session);
                    session.isSessionRefreshing = false;

                    logger.info('Session refreshed', { entity });
                });
        }
    };
}
