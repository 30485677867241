<script setup lang="ts">
import Button from 'game/ui/common/Buttons/Button.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { getShortTime } from 'game/ui/utils/time';

defineProps<{
    turnTimeLeft: number;
    isEnabled: boolean;
    isRolling: boolean;
}>();
const emit = defineEmits<{
    throwDice: [void];
}>();

const onDiceClick = () => {
    emit('throwDice');
};
</script>

<template>
    <Button
        class="dice"
        :class="{
            'dice--disabled': !isEnabled,
        }"
        :is-enabled="isEnabled"
        @click="onDiceClick"
    >
        <h1 v-if="isEnabled" class="dice__title">
            roll the dice
        </h1>

        <img
            class="dice__dice"
            :class="{
                'dice__dice--rolling': isRolling,
            }"
            :src="getAssetUrl('match/dice.webp')"
            alt=""
        >

        <p class="dice__text">
            {{ turnTimeLeft ? getShortTime(turnTimeLeft) : '' }}
        </p>
    </Button>
</template>

<style scoped lang="scss">
@keyframes roll-dice {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.dice {
    $self: &;
    position: relative;

    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    margin-bottom: size(50px);

    &__title {
        position: absolute;
        top: size(-130px);
        left: 50%;

        width: size(519px);

        font-family: $bangers-font-family;
        font-size: size(86px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #FFF;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: size(6.02px);

        transform: translateX(-50%);
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #000;
    }

    &__text {
        height: size(70px);

        font-family: $bangers-font-family;
        font-size: size(70px);
        font-style: normal;
        font-weight: 400;
        line-height: size(70px);
        color: var(--white, #FFF);
        text-align: center;
        text-shadow: 0 size(4px) size(4px) #000;
        text-transform: uppercase;
        letter-spacing: size(2.8px);

        -webkit-text-stroke-width: 2;
        -webkit-text-stroke-color: #000;
    }

    &--disabled {
        #{$self}__bg {
            filter: grayscale(100%);
        }

        #{$self}__dice-container {
            filter: grayscale(100%);
        }

        cursor: not-allowed;
    }

    &__dice {
        width: size(370px);
        height: size(363.729px);
        margin-bottom: size(35px);

        object-fit: contain;

        &--rolling {
            animation: roll-dice .3s infinite linear;
        }
    }
}
</style>
