<script setup lang="ts">
import { type Currency } from 'game/types/entityDescs/currency';
import Resource from 'game/ui/components/lobby/Resource.vue';
import { useResourceStore } from 'game/ui/store/resource';
import { getAssetUrl } from 'game/ui/utils/assets';
import { formatNumber } from 'game/ui/utils/number';

const resourcesStore = useResourceStore();

defineProps<{
    avatarUrl: string;
    name: string;
    reward: {
        type: Currency;
        value: number;
    };
}>();
</script>

<template>
    <div class="match-player">
        <img
            class="match-player__image"
            :src="avatarUrl ?? getAssetUrl('lobby/user-image.webp')"
            alt=""
        >

        <div class="match-player__text">
            <h3 class="match-player__title">
                {{ name }}
            </h3>
        </div>

        <div class="match-player__coins">
            <p class="match-player__coins-text">
                {{ formatNumber(resourcesStore.getValue(reward.type, reward.value)) }}
            </p>

            <Resource class="match-player__coins-icon" :resource-type="reward.type" />
        </div>
    </div>
</template>

<style scoped lang="scss">
.coins-icon {
    width: size(66px);
    height: size(59px);
}

.match-player {
    display: flex;

    gap: size(36px);

    align-items: center;

    width: size(1018px);
    padding: 0 size(20px) !important;

    &__image {
        width: size(110px);
        height: size(110px);

        border: size(4px) solid var(--yellow-200, #DA8207);
        border-radius: size(2px);
    }

    &__text {
        display: flex;

        flex-direction: column;

        justify-content: space-between;
    }

    &__title {
        width: size(408px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(50px);
        font-style: normal;
        font-weight: 500;
        color: var(--white, #FFF);
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__description {
        display: flex;

        gap: size(10px);

        align-items: center;

        width: size(408px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--grey-100, var(--grey, #939393));
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__coins {
        $parent: &;
        position: relative;
        z-index: 1;

        display: flex;

        flex-wrap: nowrap;

        gap: size(10px);

        align-items: center;
        align-self: center;
        justify-content: center;

        overflow: hidden;
    }

    &__coins-text {
        width: size(354px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--yellow-300, #E5AA13);
        text-align: right;
    }

    &__coins-icon {
        width: size(118.5px);
        height: size(151.5px);
    }
}
</style>
