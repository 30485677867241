import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('EntityMap');

export class EntityMapService {
    private readonly uidEntityIdMap = new Map<string, string>();

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly entityService: EntityService,
    ) {
        logger.info('Initialized');
    }

    init() {
        const entityDescs = this.entityService.findEntityDescs({});
        for (const entityId in entityDescs) {
            this.uidEntityIdMap.set(entityDescs[entityId]!.uid, entityId);
        }
    }

    createEntity(entityUid: string) {
        return this.entityService.createEntity(this.uidEntityIdMap.get(entityUid)!);
    }

    getEntityDesc(entityUid: string) {
        return this.entityService.getEntityDesc(this.uidEntityIdMap.get(entityUid)!);
    }
}