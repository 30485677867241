<script setup lang="ts">
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import PanelTopImage from 'game/ui/commonInstances/PanelTopImage.vue';
import MatchPlayer from 'game/ui/components/lobby/endMatch/MatchPlayer.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { getAssetUrl } from 'game/ui/utils/assets';

const eventService = useEventService();

const rewards = useModel(['match', 'rewards']);

const claimReward = () => {
    eventService.match.dispatch('endMatch');
};
</script>

<template>
    <LobbyLayout :container-id="ContainerIds.DailyReward" class="end-match-container">
        <h1 class="end-match-container__title section-title">
            Match results
        </h1>

        <div class="end-match-container__inner">
            <div class="end-match-container__page">
                <PanelTopImage v-if="rewards" :image-url="getAssetUrl('lobby/match-results.webp')" class="end-match-container__panel">
                    <div class="end-match__inner">
                        <div class="end-match__content">
                            <template v-for="(reward, _index) in rewards" :key="_index">
                                <MatchPlayer
                                    class="end-match__match-player panel-block-border"
                                    :avatar-url="reward.avatarUrl"
                                    :name="reward.name"
                                    :reward="reward.currency"
                                />
                            </template>
                        </div>
                    </div>
                </PanelTopImage>

                <MainButton
                    class="end-match-container__start-button"
                    :label="rewards?.length ? 'take the reward' : 'something went wrong'"
                    @click="claimReward"
                />
            </div>
        </div>
    </LobbyLayout>
</template>

<style scoped lang="scss">
.coins-icon {
    width: size(66px);
    height: size(59px);
}

.end-match-container {
    &__inner {
        position: relative;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        &::after {
            display: block;

            width: 100%;
            height: size(20px);

            content: '';
        }
    }

    &__panel {
        margin-bottom: size(102px);
    }

    &__title {
        &--big {
            margin-bottom: size(70px);

            font-size: size(96px);
        }
    }

    &__award {
        display: flex;

        gap: size(10px);

        align-items: center;
        justify-content: center;

        width: 100%;
        height: size(266px);
    }

    &__award-text {
        display: flex;

        gap: size(10px);

        align-items: center;
        justify-content: center;

        font-family: $main-font-family;
        font-size: size(54px);
        font-style: normal;
        font-weight: 700;
        line-height: size(68.58px);
        color: var(--yellow-300, #E5AA13);
        text-align: center;
    }

    &__start-button {
        width: 100%;
        height: size(178.119px);
        margin-top: size(70px);
    }
}

.end-match {
    $parent: &;

    position: relative;

    &__inner {
        position: relative;

        display: flex;

        flex-direction: column;

        gap: size(60px);

        align-items: center;
    }

    &__content {
        display: flex;

        flex-direction: column;

        gap: size(20px);

        align-items: center;
        justify-content: center;

        &--claimed {
            filter: grayscale(100%);
        }
    }

    &__icon {
        width: size(283px);
        height: size(252px);
    }

    &__count {
        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;

        background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__description {
        width: size(554px);

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 700;
        line-height: size(60.96px);
        color: var(--white, #FFF);
        text-align: center
    }
}
</style>
