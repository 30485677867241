import { mainLogger } from '@nord-beaver/core/utils/logger';

const logger = mainLogger.getLogger('FontLoader');

export interface IFontFace {
    fileName: string;
    filePath?: string;
    name?: string;
    descriptors?: FontFaceDescriptors;
}

export async function loadFonts({
    fontsPath,
    fonts,
    extensions = ['woff2', 'woff'],
}: {
    fontsPath: string;
    fonts: IFontFace[];
    extensions?: string[];
}) {
    const loadedFonts = getFonts();

    fonts = fonts.filter(
        font => !loadedFonts.some(
            loadedFont => loadedFont.family === (font.name ?? font.fileName),
        ),
    );

    if (!fonts.length) {
        return;
    }

    logger.log('Fonts before load', { fonts: getFonts() });

    await Promise.allSettled(
        fonts.map(
            async font => {
                for (const extension of extensions) {
                    const resolvedPath = font.filePath
                        ? font.filePath.trim().replace(/^\.?\//, '')
                        : '';
                    const isLoaded = await loadFont(
                        font.name ?? font.fileName,
                        `${fontsPath}/${resolvedPath}`,
                        font.fileName,
                        extension,
                        font.descriptors,
                    );

                    if (isLoaded) {
                        break;
                    }
                }
            },
        ),
    );

    logger.log('Loaded fonts', { fonts: getFonts() });
}

export function getFonts(): FontFace[] {
    // https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet/values
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Array.from((document as any).fonts.values());
}

export async function loadFont(name: string, path: string, fileName: string, extension: string, descriptors?: FontFaceDescriptors) {
    const url = `${path}/${fileName}.${extension}`;

    try {
        const fontFace = new FontFace(name, `url(${url})`, descriptors);
        await fontFace.load();

        // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Font_Loading_API
        // https://github.com/Microsoft/TypeScript/issues/30984
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (document.fonts as any).add(fontFace);
        logger.log(`Font "${name}" is loaded`, { url });
    } catch (error) {
        logger.error(`Failed to load "${name}"`, { url }, error);

        return false;
    }

    return true;
}