<script setup lang="ts">
import { Currency } from 'game/types/entityDescs/currency';
import { type ClanMember } from 'game/types/model/clansModel';
import Resource from 'game/ui/components/lobby/Resource.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { formatNumber } from 'game/ui/utils/number';

defineProps<{
    member: ClanMember;
}>();
</script>

<template>
    <div class="clan-item panel-block-border">
        <div class="clan-item__inner">
            <img
                class="clan-item__image"
                :src="member.avatarUrl ?? getAssetUrl('lobby/user-image.webp')"
                alt=""
            >

            <div class="clan-item__text">
                <h3 class="clan-item__title">
                    {{ member.name }}
                </h3>

                <p class="clan-item__description">
                    Wins: {{ formatNumber(member.wins) }}, Losses: {{ formatNumber(member.losses) }}
                </p>
            </div>
        </div>

        <div class="clan-item__coins">
            <p class="clan-item__coins-text">
                {{ formatNumber(member.softCoins) }}
            </p>

            <Resource class="clan-item__coins-icon" :resource-type="Currency.Soft" />
        </div>
    </div>
</template>

<style scoped lang="scss">
.clan-item {
    $self: &;
    display: grid;

    grid-template-columns: 1fr auto;

    gap: size(23px);

    padding: 0 size(20px) !important;

    &__inner {
        display: flex;

        gap: size(23px);

        align-items: center;
    }

    &__image {
        width: size(110px);
        height: size(110px);

        border: size(4px) solid var(--yellow-200, #DA8207);
        border-radius: size(2px);
    }

    &__text {
        display: flex;

        flex-direction: column;

        justify-content: space-between;
    }

    &__title {
        width: size(408px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(50px);
        font-style: normal;
        font-weight: 500;
        color: var(--white, #FFF);
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__description {
        font-family: $main-font-family;
        font-size: size(36px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--grey-100, var(--grey, #939393));
    }

    &__coins {
        $parent: &;
        position: relative;
        z-index: 1;

        display: flex;

        flex-wrap: nowrap;

        gap: size(10px);

        align-items: center;
        align-self: center;
        justify-content: center;

        padding: 0 size(30px) 0 0;
        overflow: hidden;
    }

    &__coins-text {
        width: size(354px);
        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: right;

        background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__coins-icon {
        width: size(118.5px);
        height: size(151.5px);
    }
}
</style>
