<script setup lang="ts">
import { type Currency } from 'game/types/entityDescs/currency';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import { resourceIcon } from 'game/ui/constants/assets';
import { formatNumber } from 'game/ui/utils/number';

defineProps<{
    resources: { [key in Currency]?: number };
}>();
</script>

<template>
    <div class="resources-counter">
        <div v-for="(resource, type) in resources" :key="type" class="resource-item">
            <SvgIcon :name="resourceIcon[type]" pack="lobby" class="resource-item__icon" />

            <div class="resource-item__name">
                {{ type }}
            </div>

            <div class="resource-item__count">
                {{ resource ? formatNumber(resource) : 0 }}
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.resources-counter {
    display: flex;

    gap: size(84px);

    align-items: center;
    justify-content: center;
}

.resource-item {
    display: grid;

    flex-shrink: 0;

    grid-template: 1fr / size(100px) 1fr;
    grid-template-areas:
        "icon name"
        "icon count";

    gap: size(8.65px) size(56px);

    align-items: center;

    width: size(398px);
    height: size(157px);
    padding: size(24px);
    overflow: hidden;

    background-color: var(--grey-400, #221F2B);
    background-image: url("assets/sprites/ui/lobby/resource-bg.webp");
    background-repeat: no-repeat;
    background-position: size(-8px) 0;
    background-size: contain;
    border: size(6px) solid var(--grey-200, #464646);
    border-radius: size(40px);

    &__bg {
        position: absolute;

        width: 100%;
        height: 100%;
    }

    &__icon {
        grid-area: icon;

        width: 100%;
        height: 100%;
    }

    &__name {
        grid-area: name;

        overflow: hidden;

        font-family: $main-font-family;
        font-size: size(40px);
        font-style: normal;
        font-weight: 700;
        line-height: size(43px);
        color: var(--white, #FFF);
        text-overflow: ellipsis;
        text-transform: capitalize;
        white-space: nowrap;
    }

    &__count {
        grid-area: count;

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 400;
        line-height: size(52px);
        color: var(--grey-100, var(--grey, #939393));
    }
}
</style>
