<script setup lang="ts">

import { throwIfNull } from '@nord-beaver/core/utils/utils';
import { hooks } from '@nord-beaver/html-ui';
import { useModel } from 'game/ui/composables/model';
import { computed, ref } from 'vue';

const { useKeyDown, Key } = hooks.keydown;
const { useEventListener } = hooks.eventListener;

const props = withDefaults(
    defineProps<{
        modelValue: string;
        placeholder?: string;
        maxLength?: number | string;
        isEnabled?: boolean;
        isReadonly?: boolean;
        type?: HTMLInputElement['type'] | 'textarea';
        colorValue?: string;
        colorPlaceholder?: string;
    }>(),
    {
        placeholder: '',
        maxLength: '',
        isEnabled: true,
        isReadonly: false,
        type: 'text',
        colorValue: 'white',
        colorPlaceholder: 'gray',
    },
);
const emit = defineEmits<{
    (event: 'input', payload: string): void;
    (event: 'enter', payload: string): void;
    (event: 'escape'): void;
    (event: 'focus'): void;
    (event: 'blur'): void;
    (event: 'update:modelValue', payload: string): void;
    (event: 'click'): void;
    (event: 'pointerdown', payload: PointerEvent): void;
    (event: 'pointerup', payload: PointerEvent): void;
    (event: 'pointerout', payload: PointerEvent): void;
    (event: 'pointerover', payload: PointerEvent): void;
    (event: 'pointerenter', payload: PointerEvent): void;
}>();
defineExpose({
    // eslint-disable-next-line no-undef
    focus: (options?: FocusOptions) => input.value?.focus(options),
    blur: () => input.value?.blur(),
});

const outerModel = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', value),
});

const isGameKeyboardEnabled = useModel(['core', 'isGameKeyboardEnabled']);

const text = ref('');
const input = ref<HTMLInputElement | null>(null);

const enter = () => {
    emit('enter', text.value);

    input.value?.blur();
};

const onFocus = () => {
    isGameKeyboardEnabled.value = false;

    emit('focus');
};
const onBlur = () => {
    isGameKeyboardEnabled.value = true;

    emit('blur');
};

useKeyDown(key => {
    if (key === Key.ESC || key === Key.TAB) {
        input.value?.blur();

        emit('escape');
    }
});
useEventListener(() => throwIfNull(input.value), 'focus', onFocus);
useEventListener(() => throwIfNull(input.value), 'blur', onBlur);
</script>

<template>
    <div class="input-wrapper">
        <input
            v-if="type !== 'textarea'"
            ref="input"
            v-model="outerModel"
            class="input-wrapper__input"
            :disabled="!(isEnabled ?? true)"
            :placeholder="placeholder ?? ''"
            :type="type ?? 'text'"
            :maxlength="maxLength ?? ''"
            :spellcheck="false"
            :readonly="isReadonly ?? false"
            @keypress.enter="enter"
            @click="emit('click')"
            @input="emit('input', ($event.target as HTMLInputElement | null)?.value ?? '')"
            @pointerdown="payload => { emit('pointerdown', payload) }"
            @pointerover="payload => { emit('pointerover', payload) }"
            @pointerup="payload => { emit('pointerup', payload) }"
            @pointerout="payload => { emit('pointerout', payload) }"
            @pointerenter="payload => { emit('pointerenter', payload) }"
        >
        <textarea
            v-if="type === 'textarea'"
            ref="input"
            v-model="outerModel"
            :class="['input-wrapper__input', 'input-wrapper__textarea-input']"
            :spellcheck="false"
            :disabled="!(isEnabled ?? true)"
            :placeholder="placeholder ?? ''"
            :maxlength="maxLength ?? ''"
            :readonly="isReadonly ?? false"
            @keypress.enter="enter"
            @click="emit('click')"
            @input="emit('input', ($event.target as HTMLInputElement | null)?.value ?? '')"
            @pointerdown="payload => { emit('pointerdown', payload) }"
            @pointerover="payload => { emit('pointerover', payload) }"
            @pointerup="payload => { emit('pointerup', payload) }"
            @pointerout="payload => { emit('pointerout', payload) }"
            @pointerenter="payload => { emit('pointerenter', payload) }"
        />
    </div>
</template>

<style scoped lang="scss">
.input-wrapper {
    align-content: center;

    &__input {
        all: unset;

        width: 100%;
        height: 100%;

        color: v-bind(colorValue);
    }

    &__input::placeholder {
        color: v-bind(colorPlaceholder);
    }

    &__textarea-input {
        width: 100%;
        height: 100%;

        color: v-bind(colorValue);
        word-break: break-all;
    }

    &__textarea-input::placeholder {
        color: v-bind(colorPlaceholder);
    }
}
</style>
