import { type MatchDataRequestSchema, type MatchDataResponseSchema } from 'game/services/nakama/matchData/matchDataNakamaService';

export class MatchDataQueue<
    OpCodeRequestType extends number = number,
    OpCodeResponseType extends number = number,
    MatchDataRequestSchemaType extends MatchDataRequestSchema<OpCodeRequestType> = MatchDataRequestSchema<OpCodeRequestType>,
    MatchDataResponseSchemaType extends MatchDataResponseSchema<OpCodeResponseType> = MatchDataResponseSchema<OpCodeResponseType>,
> {
    readonly responseMatchDataQueue: Map<string, Map<OpCodeResponseType, MatchDataResponseSchemaType[OpCodeResponseType][]>> = new Map();
    readonly requestMatchDataQueue: Map<string, Map<OpCodeRequestType, MatchDataRequestSchemaType[OpCodeRequestType][]>> = new Map();

    enqueueReceivedMatchData<Code extends OpCodeResponseType>(matchId: string, opcode: Code, data: MatchDataResponseSchemaType[Code]) {
        const queue = this.getOrCreateQueue(this.responseMatchDataQueue, matchId, opcode);
        queue.push(data);
    }

    getReceivedMatchData(matchId: string, opcode: OpCodeResponseType): MatchDataResponseSchemaType[OpCodeResponseType][] {
        return this.getQueue(this.responseMatchDataQueue, matchId, opcode) ?? [];
    }

    enqueueSendMatchData<Code extends OpCodeRequestType>(matchId: string, opcode: Code, message: MatchDataRequestSchemaType[Code]) {
        const queue = this.getOrCreateQueue(this.requestMatchDataQueue, matchId, opcode);
        queue.push(message);
    }

    getSendMatchData<Code extends OpCodeRequestType>(matchId: string, opcode: Code): object[] {
        return this.getQueue(this.requestMatchDataQueue, matchId, opcode) ?? [];
    }

    getAllSendMatchData(matchId: string): Map<OpCodeRequestType, object[]> {
        return this.requestMatchDataQueue.get(matchId) ?? new Map<OpCodeRequestType, object[]>();
    }

    clearQueues() {
        this.responseMatchDataQueue.clear();
        this.requestMatchDataQueue.clear();
    }

    clearQueuesForMatch(matchId: string) {
        this.responseMatchDataQueue.delete(matchId);
        this.requestMatchDataQueue.delete(matchId);
    }

    private getOrCreateQueue<
        OpCodeType,
        ValueType,
    >(
        queueMap: Map<string, Map<OpCodeType, ValueType[]>>,
        matchId: string,
        opcode: OpCodeType,
    ): ValueType[] {
        let matchQueue = queueMap.get(matchId);
        if (!matchQueue) {
            matchQueue = new Map();
            queueMap.set(matchId, matchQueue);
        }

        let opcodeQueue = matchQueue.get(opcode);
        if (!opcodeQueue) {
            opcodeQueue = [];
            matchQueue.set(opcode, opcodeQueue);
        }

        return opcodeQueue;
    }

    private getQueue<OpCodeType, T>(queueMap: Map<string, Map<OpCodeType, T[]>>, matchId: string, opcode: OpCodeType): T[] | undefined {
        return queueMap.get(matchId)?.get(opcode);
    }
}
