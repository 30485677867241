import { type Entity, SystemService } from '@nord-beaver/core/ecs';
import { type Model } from '@nord-beaver/core/mvc/model';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { isDefined } from '@nord-beaver/core/utils/utils';
import { GridTileComponent } from 'game/ecs/components/gridTilemap/gridTileComponent';
import { type GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { PlayerComponent } from 'game/ecs/components/gridTilemap/playerComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';
import { GridTilemapNode } from 'game/ecs/nodes/gridTilemap/gridTilemapNode';
import { GridTileType } from 'game/types/entityDescs/gridTile';
import { type MatchModel } from 'game/types/model/matchModel';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';

const logger = mainLogger.getLogger('Tilemap').getLogger('View');

export class GridTilemapViewSystem extends SystemService { // TODO: move to matchViewSystem
    private readonly matchModel: Model<MatchModel>;

    constructor(
        dependencyContainer: DependencyContainer,
    ) {
        super();

        this.matchModel = getModel(dependencyContainer, ['match']);
    }

    init() {
        this.setupNodeList({
            node: GridTilemapNode,
            add: this.addGridTilemap,
            update: this.updateGridTilemap,
        });
    }

    override destroy() {
        super.destroy();
    }

    private addGridTilemap(node: GridTilemapNode) {
        const { gridTilemap } = node;

        this.matchModel.data.tiles.length = 0;

        for (let i = 0; i < gridTilemap.desc.rows * gridTilemap.desc.columns; i++) {
            this.matchModel.data.tiles.push({
                ownerIndex: undefined,
                passagePenalty: undefined,
            });
        }
    }

    private updateGridTilemap(node: GridTilemapNode) {
        const { gridTilemap } = node;

        const playerTileEntity = isDefined(gridTilemap.playerEntityRid)
            ? gridTilemap.replicantEntities[gridTilemap.playerEntityRid]
            : undefined;
        if (playerTileEntity) {
            const replicant = playerTileEntity.get(ReplicantComponent);
            if (replicant) {
                this.matchModel.data.playerTileIndex = replicant.placeIndex ?? null;
            }
        }

        gridTilemap.tileEntities.forEach(childEntity => this.updateTileEntity(gridTilemap, childEntity));
        const selectedTileEntity = gridTilemap.selectedTileEntity;
        let selectedIndex: number | null = null;
        if (selectedTileEntity) {
            selectedIndex = selectedTileEntity.get(GridTileComponent)?.index ?? null;
        }

        this.matchModel.data.selectedTileIndex = selectedIndex;
    }

    private updateTileEntity(gridTilemap: GridTilemapComponent, tileEntity: Entity) {
        const tile = tileEntity.get(GridTileComponent);
        if (!tile) {
            return;
        }

        const isPlayerTile = tile.ownerEntity?.has(PlayerComponent);
        const ownerReplicantRuntimeId = tile.ownerEntity?.get(RuntimeIdComponent);
        const ownerReplicant = tile.ownerEntity?.get(ReplicantComponent);

        const tileView = this.matchModel.data.tiles[tile.index];
        if (!tileView) {
            logger.error('Failed to get tile view', tile.index);

            return;
        }

        if (!gridTilemap.levelTableDescs) {
            logger.error('LevelTableDescs not found');

            return;
        }

        switch (tile.desc.data.type) {
            case GridTileType.Regular: {
                const levelTable = gridTilemap.levelTableDescs[tile.desc.data.region];
                const ownerTileLevel = ownerReplicant?.tilesCount[tile.desc.data.region] ?? 0;

                const levelData = ownerTileLevel > 0
                    ? levelTable.items[ownerTileLevel - 1]
                    : undefined;

                const desc = tile.desc.data;
                const cost = !levelData
                    ? desc.nominalCost
                    : Math.max(desc.nominalCost, desc.nominalCost * levelData.protectionCoefficient);

                tileView.ownerIndex = ownerReplicantRuntimeId?.rId;
                tileView.cost = cost;
                tileView.passagePenalty = levelData?.passagePenalty ?? undefined;
                tileView.isPlayerTile = !!isPlayerTile;
                break;
            }
            case GridTileType.Chance: {
                tileView.isChance = true;
                break;
            }
        }

        tileView.isMoveEnabled = tile.isEnabled;
    }
}