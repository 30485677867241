import { useEventService } from 'game/ui/composables/eventService';
import { type windowManager } from 'game/ui/services/windowManager';
import { defineStore } from 'pinia';
import { ref, onMounted, onBeforeUnmount } from 'vue';

export const useWindowsState = defineStore('windowsState', () => {
    const eventService = useEventService();

    const currentState = ref<keyof typeof windowManager.view.states | null>(null);

    const updateState = ({ stateKey }: { stateKey: keyof typeof windowManager.view.states }) => {
        currentState.value = stateKey;
    };

    onMounted(() => {
        eventService.windowManager.on('changeState', updateState);
    });
    onBeforeUnmount(() => {
        eventService.windowManager.off('changeState', updateState);
    });

    return {
        currentState,
    };
});
