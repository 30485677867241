import { type Region } from 'game/types/entityDescs/region';

/**
 * Enum representing the type of the dice face.
 * @enum {string}
 * @description The type of dice face, either associated with a region or provides a bonus.
 */
export enum DiceFaceType {
    /**
     * Represents a dice face that corresponds to a specific region.
     */
    Region = 'Region',
    /**
     * Represents a dice face that gives a bonus to the player.
     */
    Bonus = 'Bonus',
}

/**
 * Represents the Region Face data.
 * @description Contains information about the region associated with the dice face.
 */
export type RegionFaceData = {
    /**
     * The region associated with this dice face.
     */
    region: Region;
};

/**
 * Represents the Bonus Face data.
 * @description Contains information about the amount of bonus money awarded when this dice face is rolled.
 */
export type BonusFaceData = {
    /**
     * An array of links to possible chance events.
     */
    chanceEventLinks: string[];
};

/**
 * Represents the description of the Dice Face Component.
 * @description The dice face can be either a region-based face or a bonus face, and the data will be determined by the type of face.
 */
export type DiceFaceDesc = {
    /**
     * The type of the dice face (Region or Bonus).
     * @enum {DiceFaceType}
     */
    type: DiceFaceType;

    /**
     * The data associated with the dice face.
     * @oneOf RegionFaceData | BonusFaceData
     * @description Data specific to the face type, either representing a region or a bonus.
     */
    data: RegionFaceData | BonusFaceData;
};
