import { type Entity, SystemService } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { type EngineService } from '@nord-beaver/core/ecs/engineService';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { isDefined } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { type GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { PlayerComponent } from 'game/ecs/components/gridTilemap/playerComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { RuntimeIdComponent } from 'game/ecs/components/runtimeIdComponent';
import { GridTilemapNode } from 'game/ecs/nodes/gridTilemap/gridTilemapNode';
import { ReplicantNode } from 'game/ecs/nodes/replicantNode';
import { moveTileEntity, placeTileEntity } from 'game/ecs/systems/gridTilemap/utils';
import { type NakamaService } from 'game/services/nakamaService';
import { type ApiMatchDataResponseSchema } from 'game/types/nakama/matchData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('Tilemap').getLogger('Replicant');

export class GridTilemapReplicantSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly engineService: EngineService,
        private readonly entityService: EntityService,
        private readonly nakamaService: NakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: GridTilemapNode,
            update: this.onGridTilemapUpdate,
        });

        this.setupNodeList({
            node: ReplicantNode,
            add: this.onReplicantAdd,
        });
    }

    private onGridTilemapUpdate(node: GridTilemapNode) {
        if (!this.nakamaService.getMatch()) {
            return;
        }

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.ADD_PLAYER })
            .forEach(message => this.addReplicantEntity(node, message.rId, true));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.ADD_REPLICANT })
            .forEach(message => this.addReplicantEntity(node, message.rId));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.PLAYER_POSITION_UPDATE })
            .forEach(message => this.updateReplicantPosition(node.gridTilemap, message));

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.PLAYER_LEFT_GAME })
            .forEach(message => this.removeReplicantEntity(node, message.rId));
    }

    private onReplicantAdd(node: ReplicantNode) {
        const { replicant, entity } = node;

        if (!replicant.placeIndex) {
            this.toggleReplicantVisibility(entity, false);
        }
    }

    private removeReplicantEntity(tilemapNode: GridTilemapNode, rId: number) {
        const { gridTilemap } = tilemapNode;

        const replicantEntity = gridTilemap.replicantEntities[rId];
        if (!replicantEntity) {
            logger.error('Failed to get replicant entity', rId);

            return;
        }

        const replicant = replicantEntity.get(ReplicantComponent);
        if (!replicant) {
            logger.error('Replicant entity without ReplicantComponent', replicantEntity);

            return;
        }

        this.toggleReplicantVisibility(replicantEntity, true);

        replicant.placeIndex = undefined;
    }

    private updateReplicantPosition(gridTilemap: GridTilemapComponent, { rId, index }: ApiMatchDataResponseSchema[api.OpCodeResponse.PLAYER_POSITION_UPDATE]) {
        const replicantEntity = gridTilemap.replicantEntities[rId];
        if (!replicantEntity) {
            logger.error('Failed to get replicant entity', rId);

            return;
        }

        const replicant = replicantEntity.get(ReplicantComponent);
        if (!replicant) {
            logger.error('Replicant entity without ReplicantComponent', replicantEntity);

            return;
        }

        if (!isDefined(index)) {
            this.toggleReplicantVisibility(replicantEntity, false);

            replicant.placeIndex = undefined;

            return;
        }

        if (!replicant.placeIndex) {
            placeTileEntity(replicantEntity, index);

            this.toggleReplicantVisibility(replicantEntity, true);

            replicant.placeIndex = index;

            return;
        }

        moveTileEntity(replicantEntity, index);
        replicant.placeIndex = index;
    }

    private addReplicantEntity(tilemapNode: GridTilemapNode, rId: number, isPlayer = false) {
        const { entity: tilemapEntity, gridTilemap } = tilemapNode;

        const replicantEntity = this.entityService.createEntity(`objects/players/player${rId + 1}`);
        if (!replicantEntity) {
            logger.error('Failed to create player entity');
            return;
        }

        const replicant = replicantEntity.get(ReplicantComponent);
        if (!replicant) {
            logger.error('Player entity without PlayerComponent', replicantEntity);
            return;
        }

        replicantEntity.add(new RuntimeIdComponent(rId));
        if (isPlayer) {
            replicantEntity.add(new PlayerComponent());

            gridTilemap.playerEntityRid = rId;
        }

        replicant.gridTilemapEntity = tilemapEntity;
        tilemapEntity.children.push(replicantEntity);
        replicantEntity.parent = tilemapEntity;
        gridTilemap.replicantEntities[rId] = replicantEntity;

        this.engineService.addEntity(replicantEntity);
    }

    private toggleReplicantVisibility(replicantEntity: Entity, visible: boolean) {
        const sceneObject = replicantEntity.get(SceneObjectComponent);
        if (!sceneObject) {
            logger.error('Replicant entity without SceneObjectComponent', replicantEntity);
            return;
        }

        sceneObject.object.setVisible(visible);
    }
}