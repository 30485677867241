import type Long from 'long';

import { defineNode, SystemService } from '@nord-beaver/core/ecs';
import { type Model } from '@nord-beaver/core/mvc/model';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { clamp, isDefined } from '@nord-beaver/core/utils/utils';
import { LoadingComponent } from 'game/ecs/components/loadingComponent';
import { type DailyRewardsComponent } from 'game/ecs/components/meta/dailyRewardsComponent';
import { ClansNode } from 'game/ecs/nodes/meta/clansNode';
import { DailyRewardsNode } from 'game/ecs/nodes/meta/dailyRewardsNode';
import { FriendsNode } from 'game/ecs/nodes/meta/friendsNode';
import { TasksNode } from 'game/ecs/nodes/meta/tasksNode';
import { VaultNode } from 'game/ecs/nodes/meta/vaultNode';
import { WelcomeRewardNode } from 'game/ecs/nodes/meta/welcomeRewardNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { Currency } from 'game/types/entityDescs/currency';
import { type ClansModel } from 'game/types/model/clansModel';
import { type DailyRewardModel } from 'game/types/model/dailyRewardModel';
import { type FriendsModel } from 'game/types/model/friendsModel';
import { type LobbyModel } from 'game/types/model/lobbyModel';
import { type ResourcesModel } from 'game/types/model/resourcesModel';
import { type TasksModel } from 'game/types/model/tasksModel';
import { type VaultModel } from 'game/types/model/vaultModel';
import { type WelcomeRewardModel } from 'game/types/model/welcomeRewardModel';
import { windowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';
import { getClientCurrency } from 'game/utils/resource';

class LoadingNode extends defineNode({
    loading: LoadingComponent,
}) { }

const logger = mainLogger.getLogger('LobbyViewSystem');

export class LobbyViewSystem extends SystemService {
    private readonly lobbyModel: Model<LobbyModel>;
    private readonly dailyRewardModel: Model<DailyRewardModel>;
    private readonly friendsModel: Model<FriendsModel>;
    private readonly resourcesModel: Model<ResourcesModel>;
    private readonly tasksModel: Model<TasksModel>;
    private readonly clansModel: Model<ClansModel>;
    private readonly vaultModel: Model<VaultModel>;
    private readonly welcomeRewardModel: Model<WelcomeRewardModel>;

    constructor(
        dependencyContainer: DependencyContainer,
    ) {
        super();

        this.lobbyModel = getModel(dependencyContainer, ['lobby']);
        this.dailyRewardModel = getModel(dependencyContainer, ['dailyReward']);
        this.friendsModel = getModel(dependencyContainer, ['friends']);
        this.resourcesModel = getModel(dependencyContainer, ['resources']);
        this.tasksModel = getModel(dependencyContainer, ['tasks']);
        this.clansModel = getModel(dependencyContainer, ['clans']);
        this.vaultModel = getModel(dependencyContainer, ['vault']);
        this.welcomeRewardModel = getModel(dependencyContainer, ['welcomeReward']);
    }

    async init() {
        this.setupNodeList({
            node: LoadingNode,
            add: this.addLoading,
            update: this.updateLoading,
            remove: this.removeLoading,
        });
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
            update: this.updateMeta,
        });
        this.setupNodeList({
            node: DailyRewardsNode,
            add: this.addDailyRewards,
            update: this.updateDailyRewards,
        });
        this.setupNodeList({
            node: FriendsNode,
            add: this.addFriends,
            update: this.updateFriends,
        });
        this.setupNodeList({
            node: TasksNode,
            add: this.addTasks,
            update: this.updateTasks,
        });
        this.setupNodeList({
            node: ClansNode,
            add: this.addClans,
            update: this.updateClans,
        });
        this.setupNodeList({
            node: VaultNode,
            add: this.updateVault,
            update: this.updateVault,
        });
        this.setupNodeList({
            node: WelcomeRewardNode,
            add: this.addWelcomeReward,
        });
    }

    private addLoading(node: LoadingNode) {
        this.updateLoading(node);

        windowManager.setState('loading');
    }

    private removeLoading() {
        this.lobbyModel.data.loadingProgress = 0;
    }

    private updateLoading(node: LoadingNode) {
        const { loading } = node;

        this.lobbyModel.data.loadingProgress = loading.progress;
    }

    private addMeta(node: MetaNode) {
        const { meta } = node;

        if (meta.currencyRates) {
            for (const rateKey in meta.currencyRates.rates) {
                const rate = meta.currencyRates.rates[rateKey];

                this.resourcesModel.data.rates[rateKey] = rate;
            }
        }

        this.updateMeta(node);
    }

    private updateMeta(node: MetaNode) {
        const { meta } = node;

        if (meta.clientAccounts) {
            for (const account of meta.clientAccounts) {
                const currencyType = getClientCurrency(account.currency);

                this.resourcesModel.data.currencies[currencyType] = this.getBalance(account.balance);
                this.resourcesModel.data.decimals[currencyType] = account.decimals;
            }
        }
    }

    private getBalance(balance: string | number | Long) {
        let integerBalance = typeof balance === 'string' ? parseInt(balance) : Number(balance);

        if (isNaN(integerBalance)) {
            integerBalance = 0;
        }

        return integerBalance;
    }

    private addDailyRewards(node: DailyRewardsNode) {
        const { dailyRewards } = node;

        this.dailyRewardModel.data.rewards.length = 0;

        const activeIndex = this.getActiveIndex(dailyRewards);
        for (let i = 0; i < dailyRewards.desc.rewards.length; i++) {
            const reward = dailyRewards.desc.rewards[i];
            if (!reward) {
                logger.error('Invalid reward', { reward });

                continue;
            }

            this.dailyRewardModel.data.rewards.push({
                type: reward.currency,
                count: reward.value,
                isClaimed: isDefined(activeIndex) && i < activeIndex,
            });
        }

        this.dailyRewardModel.data.activeRewardIndex = activeIndex;
        this.dailyRewardModel.data.claimAvailable = dailyRewards.available;

        dailyRewards.isDirty = false;
    }

    private updateDailyRewards(node: DailyRewardsNode) {
        const { dailyRewards } = node;

        this.dailyRewardModel.data.claimAvailable = dailyRewards.available && !dailyRewards.isClaiming;

        if (!dailyRewards.isDirty) {
            return;
        }

        const activeIndex = this.getActiveIndex(dailyRewards);
        for (let i = 0; i < this.dailyRewardModel.data.rewards.length; i++) {
            const reward = this.dailyRewardModel.data.rewards[i];
            if (!reward) {
                logger.error('Invalid reward', { reward });

                continue;
            }

            reward.isClaimed = isDefined(activeIndex) && i < activeIndex;
        }

        this.dailyRewardModel.data.activeRewardIndex = activeIndex;

        dailyRewards.isDirty = false;
    }

    private getActiveIndex(dailyRewards: DailyRewardsComponent) {
        const activeIndex = dailyRewards.rewardDay;
        if (isDefined(activeIndex)
            && (activeIndex < 0 || activeIndex >= dailyRewards.desc.rewards.length)
        ) {
            logger.error('Invalid active index', { activeIndex });

            return null;
        }

        return activeIndex;
    }

    private addFriends(node: FriendsNode) {
        const { meta, friends } = node;

        this.friendsModel.data.list.length = 0;

        this.friendsModel.data.refferalsCount = meta.client.ref_line1_count;
        this.friendsModel.data.refferalsCount2ndLevel = meta.client.ref_line2_count;
        this.friendsModel.data.refferalsCount3rdLevel = meta.client.ref_line3_count;

        for (const invitee of meta.inviteesData.invitees) {
            const state = invitee.client_state;
            this.friendsModel.data.list.push({
                id: invitee.uid,
                name: [invitee.firstname, invitee.lastname].filter(name => !!name).join(' '),
                wins: state?.profit_operations_count ?? 0,
                losses: state?.loss_operations_count ?? 0,
                softCoins: state?.points ?? 0,
                avatarUrl: invitee.avatar,
            });
        }

        friends.isDirty = false;
    }

    private updateFriends(node: FriendsNode) {
        const { meta, friends } = node;

        if (!friends.isDirty) {
            return;
        }

        this.friendsModel.data.refferalsCount = meta.client.ref_line1_count;
        this.friendsModel.data.refferalsCount2ndLevel = meta.client.ref_line2_count;
        this.friendsModel.data.refferalsCount3rdLevel = meta.client.ref_line3_count;

        for (const invitee of meta.inviteesData.invitees) {
            const state = invitee.client_state;
            const modelFriend = this.friendsModel.data.list.find(friend => friend.id === invitee.uid);
            if (!modelFriend) {
                this.friendsModel.data.list.push({
                    id: invitee.uid,
                    name: [invitee.firstname, invitee.lastname].filter(name => !!name).join(' '),
                    wins: state?.profit_operations_count ?? 0,
                    losses: state?.loss_operations_count ?? 0,
                    softCoins: state?.points ?? 0,
                    avatarUrl: invitee.avatar,
                });

                continue;
            }

            modelFriend.name = [invitee.firstname, invitee.lastname].filter(name => !!name).join(' ');
            modelFriend.wins = state?.profit_operations_count ?? 0;
            modelFriend.losses = state?.loss_operations_count ?? 0;
            modelFriend.softCoins = state?.points ?? 0;
            modelFriend.avatarUrl = invitee.avatar;
        }
    }

    private addClans(node: ClansNode) {
        const { clans, meta } = node;

        this.clansModel.data.list.length = 0;

        const userClan = meta.client.group;
        const userUid = meta.client.uid;

        for (const clan of clans.list) {
            const members = clans.groupClients[clan.uid] ?? [];

            this.clansModel.data.list.push({
                id: clan.uid,
                name: clan.name,
                rank: clan.rank,
                telegramClickCount: clan.clients_count,
                isJoined: userClan?.uid === clan.uid,
                isOwner: userClan?.admin_client_uid === userUid,
                avatarUrl: clan.avatar,
                softCoins: 0, // TODO: fix
                members: members.map(member => {
                    const data = {
                        id: member.uid,
                        name: [member.firstname, member.lastname].filter(name => !!name).join(' '),
                        wins: member.state?.profit_operations_count ?? 0,
                        losses: member.state?.loss_operations_count ?? 0,
                        softCoins: member.state?.points ?? 0,
                        avatarUrl: member.avatar,
                    };

                    return data;
                }),
            });
        }
    }

    private updateClans(node: ClansNode) {
        const { clans, meta } = node;

        if (!clans.isDirty) {
            return;
        }

        const userClan = clans.group;
        const userUid = meta.client.uid;

        const removeClansIndexes: number[] = [];
        const updatedClansIndexes: number[] = [];

        for (let i = 0; i < clans.list.length; i++) {
            const clan = clans.list[i];
            if (!clan) {
                logger.error('Invalid clan', { clan });

                continue;
            }

            const modelClan = this.clansModel.data.list[i];
            if (!modelClan) {
                removeClansIndexes.push(i);

                continue;
            }

            const members = clans.groupClients[clan.uid] ?? [];

            modelClan.id = clan.uid;
            modelClan.name = clan.name;
            modelClan.rank = clan.rank;
            modelClan.telegramClickCount = clan.clients_count;
            modelClan.isJoined = userClan?.uid === clan.uid;
            modelClan.isOwner = userClan?.admin_client_uid === userUid;
            modelClan.avatarUrl = clan.avatar;
            modelClan.softCoins = 0; // TODO: fix
            modelClan.members = members.map(member => {
                const data = {
                    id: member.uid,
                    name: [member.firstname, member.lastname].filter(name => !!name).join(' '),
                    wins: member.state?.profit_operations_count ?? 0,
                    losses: member.state?.loss_operations_count ?? 0,
                    softCoins: member.state?.points ?? 0,
                    avatarUrl: member.avatar,
                };

                return data;
            });

            updatedClansIndexes.push(i);
        }

        for (const index of removeClansIndexes) {
            this.clansModel.data.list.splice(index, 1);
        }

        const newClans = clans.list.filter((_, index) => !updatedClansIndexes.includes(index));

        for (const clan of newClans) {
            const members = clans.groupClients[clan.uid] ?? [];

            this.clansModel.data.list.push({
                id: clan.uid,
                name: clan.name,
                rank: clan.rank,
                telegramClickCount: clan.clients_count,
                isJoined: userClan?.uid === clan.uid,
                isOwner: userClan?.admin_client_uid === userUid,
                avatarUrl: clan.avatar,
                softCoins: 0, // TODO: fix
                members: members.map(member => {
                    const data = {
                        id: member.uid,
                        name: [member.firstname, member.lastname].filter(name => !!name).join(' '),
                        wins: member.state?.profit_operations_count ?? 0,
                        losses: member.state?.loss_operations_count ?? 0,
                        softCoins: member.state?.points ?? 0,
                        avatarUrl: member.avatar,
                    };

                    return data;
                }),
            });
        }

        logger.info('Clans updated', {
            removed: removeClansIndexes,
            updated: updatedClansIndexes,
            new: newClans,
        });

        clans.isDirty = false;
    }

    private addTasks(node: TasksNode) {
        const { tasks } = node;

        this.tasksModel.data.tasks.length = 0;

        for (let i = 0; i < tasks.list.length; i++) {
            const task = tasks.list[i];
            if (!task) {
                logger.error('Invalid task', { task });

                continue;
            }

            this.tasksModel.data.tasks.push({
                index: i,
                key: task.key,
                title: task.title,
                completed: task.done,
                claimed: task.collected,
                reward: {
                    type: Currency.Soft,
                    value: task.reward,
                },
            });
        }

        tasks.isDirty = false;
    }

    private updateTasks(node: TasksNode) {
        const { tasks } = node;

        if (!tasks.isDirty) {
            return;
        }

        for (let i = 0; i < tasks.list.length; i++) {
            const task = tasks.list[i];
            if (!task) {
                logger.error('Invalid task', { task });

                continue;
            }

            const modelTask = this.tasksModel.data.tasks[i];
            if (!modelTask) {
                logger.error('Invalid model task', { modelTask });

                continue;
            }

            modelTask.completed = task.done;
            modelTask.claimed = task.collected;
        }

        tasks.isDirty = false;
    }

    private updateVault(node: VaultNode) {
        const { vault } = node;

        this.vaultModel.data.isAvailable = vault.isAvailable;
        this.vaultModel.data.isFetching = vault.isFetching;
        this.vaultModel.data.progress = clamp(0, 1, vault.progress);
        this.vaultModel.data.leftTimeMs = vault.leftTimeMS;
    }

    private addWelcomeReward(node: WelcomeRewardNode) {
        const { welcomeReward } = node;

        if (!welcomeReward.data) {
            return;
        }

        if (!welcomeReward.data.currency || !isDefined(welcomeReward.data.value)) {
            logger.error('Invalid welcome reward', { welcomeReward });

            return;
        }

        this.welcomeRewardModel.data.reward = {
            type: getClientCurrency(welcomeReward.data.currency),
            count: welcomeReward.data.value,
            isClaimed: welcomeReward.data.available,
        };
    }
}