<script setup lang="ts">
import { type Currency } from 'game/types/entityDescs/currency';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import { resourceIcon } from 'game/ui/constants/assets';
import { formatNumber } from 'game/ui/utils/number';

defineProps<{
    resourceType: Currency;
    count: number;
    isBig?: boolean;
}>();

</script>

<template>
    <div
        class="resource"
        :class="{
            'resource--big': isBig
        }"
    >
        <span>{{ formatNumber(count) }}</span>

        <SvgIcon
            :name="resourceIcon[resourceType]"
            class="resource__icon"
            pack="lobby"
        />
    </div>
</template>

<style scoped lang="scss">
.resource {
    $parent: &;

    display: flex;

    gap: size(17px);

    align-items: center;

    span {
        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: right;

        background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__icon {
        width: size(105px);
        height: size(105px);
    }

    &--big {
        gap: size(20px);

        span {
            font-size: size(70px);
            line-height: normal;
        }

        #{$parent}__icon {
            width: size(100px);
            height: size(90px);
        }
    }
}
</style>
