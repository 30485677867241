import { type Socket } from '@heroiclabs/nakama-js';
import { type WebSocketAdapterPb } from '@heroiclabs/nakama-js-protobuf';
import { Signal } from '@nord-beaver/core/utils/signal';

export class SocketNakamaComponent {
    isConnected = true;

    private _processFrameSignal?: Signal<string>;

    constructor(
        readonly socket: Socket,
        readonly socketAdapter: WebSocketAdapterPb,
    ) {
    }

    get processFrameSignal() {
        return this._processFrameSignal ?? (this._processFrameSignal = new Signal());
    }
}
