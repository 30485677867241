import { type Entity } from '@nord-beaver/core/ecs';

export type RpcSchema<RpcType extends string = string> = Record<RpcType, { request: object; response: object }>;

export enum RpcType {
    Socket = 'socket',
    Client = 'client',
}

interface RpcParams<Method extends string, Schema extends RpcSchema<Method>> {
    nakamaEntity: Entity;
    rpcType: Method;
    payload: Schema[Method]['request'];
}


export interface ClientRpcParams<Method extends string, Schema extends RpcSchema<Method>> extends RpcParams<Method, Schema> {
}

export interface SocketRpcParams<Method extends string, Schema extends RpcSchema<Method>> extends RpcParams<Method, Schema> {
    rpcHttpKey?: string;
}

export type RpcNakamaParams<T extends RpcType, Method extends string, Schema extends RpcSchema<Method>> =
    T extends RpcType.Client ? ClientRpcParams<Method, Schema> :
        T extends RpcType.Socket ? SocketRpcParams<Method, Schema> :
            never;

export interface RpcNakamaConfig {
    readonly rpcRetryCount?: number;
    readonly rpcRetryTimeoutMs?: number;
}