import { ENV_CONFIG } from 'game/constants';
import { type EnvConfig } from 'game/types/envConfigService/config';

export class EnvConfigService {
    private config: EnvConfig;

    constructor() {
        this.config = ENV_CONFIG;
    }

    get Config() {
        return this.config;
    }
}