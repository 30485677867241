import { defineNode } from '@nord-beaver/core/ecs';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { NakamaComponent } from 'game/ecs/components/nakama/nakamaComponent';
import { NotificationNakamaComponent } from 'game/ecs/components/nakama/notificationNakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';

export class NotificationNakamaNode extends defineNode({
    nakama: NakamaComponent,
    client: ClientNakamaComponent,
    socket: SocketNakamaComponent,
    session: SessionNakamaComponent,
    notification: NotificationNakamaComponent,
}) {}