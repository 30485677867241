const minuteCount = 60;
const hourCount = 60;
const dayCount = 24;

const secondLength = 1000;
const minuteLength = secondLength * minuteCount;
const hourLength = minuteLength * hourCount;
const dayLength = hourLength * dayCount;

const dateFull = {
    days: (time: number) => Math.floor(time / dayLength),
    hours: (time: number) => Math.floor(time / hourLength),
    minutes: (time: number) => Math.floor(time / minuteLength),
    seconds: (time: number) => Math.floor(time / secondLength),
};

const date = {
    days: dateFull.days,
    hours: (time: number) => dateFull.hours(time) - dateFull.days(time) * dayCount,
    minutes: (time: number) => dateFull.minutes(time) - dateFull.hours(time) * hourCount,
    seconds: (time: number) => dateFull.seconds(time) - dateFull.minutes(time) * minuteCount,
    milliseconds: (time: number) => time - dateFull.seconds(time) * secondLength,
};

export const getTime = (timespan: number) => {
    const days = date.days(timespan);
    const hours = date.hours(timespan);
    const minutes = date.minutes(timespan);
    const seconds = date.seconds(timespan);
    const milliseconds = date.milliseconds(timespan);

    return { days, hours, minutes, seconds, milliseconds };
};

export const getSeconds = (timespan: number) => ({
    seconds: Math.trunc(dateFull.seconds(timespan)),
    milliseconds: Math.trunc(date.milliseconds(timespan)),
});

export const getShortTime = (timestamp: number) => {
    const allHours = Math.trunc(dateFull.hours(timestamp));
    const minutes = Math.trunc(date.minutes(timestamp));
    const second = Math.trunc(date.seconds(timestamp));

    if (allHours > 0) {
        return `${allHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
    } else {
        return `${minutes.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
    }
};

export const getShortDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return `${date.toLocaleString('default', { month: 'short' })}.${date.getDate()}.${date.getFullYear().toString().slice(2)}`;
};