// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InjectableServiceClass<T> = new (dependencyContainer: DependencyContainer, ...dependencies: any[]) => T;

/**
 * TODO: something like this
    @injectable
    export class SomeSystem extends SystemService {
        @inject(EventService)
        private readonly eventService!: EventService;
        @inject(RpcNakamaService)
        private readonly rpcNakamaService!: RpcNakamaService;

        constructor() {}
    }
 */
export class DependencyContainer {
    private ctors = new Set<InjectableServiceClass<unknown>>();
    private instances = new Map<InjectableServiceClass<unknown>, unknown>();
    // TODO: replace dependencyMap on field decorators inside InjectableServiceClass
    private _dependencyMap = new Map<InjectableServiceClass<unknown>, InjectableServiceClass<unknown>[]>();

    get dependencyMap() {
        return this._dependencyMap;
    }

    register<T>(
        Ctor: InjectableServiceClass<T>,
        instance?: T,
        dependencies?: InjectableServiceClass<unknown>[],
    ): T | void {
        if (instance) {
            this.instances.set(Ctor, instance);
            return instance;
        }

        if (dependencies && dependencies.length > 0) {
            this._dependencyMap.set(Ctor, dependencies);
        }

        this.ctors.add(Ctor);
    }

    // TODO: replace on get decorator
    get<T>(Ctor: InjectableServiceClass<T>): T {
        let instance: T = this.instances.get(Ctor) as T;
        if (instance) {
            return instance;
        }

        instance = this.resolveDependencies(Ctor);

        this.instances.set(Ctor, instance);
        this.ctors.delete(Ctor);

        return instance;
    }

    private resolveDependencies<T>(Ctor: InjectableServiceClass<T>): T {
        const dependencies = this._dependencyMap.get(Ctor);

        if (dependencies && dependencies.length > 0) {
            const resolvedDependencies = dependencies.map(dep => this.get(dep));
            return new Ctor(this, ...resolvedDependencies);
        } else {
            return new Ctor(this);
        }
    }
}
