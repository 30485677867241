import { defineNode } from '@nord-beaver/core/ecs';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { NakamaComponent } from 'game/ecs/components/nakama/nakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';

export class MatchMapNakamaNode extends defineNode({
    nakama: NakamaComponent,
    client: ClientNakamaComponent,
    socket: SocketNakamaComponent,
    session: SessionNakamaComponent,
    matchMap: MatchMapNakamaComponent,
}) {}