import { debugPanelService } from '@nord-beaver/core/services/debugPanelService';
import { SelectElement, ButtonElement } from '@nord-beaver/core/services/debugPanelService/debugElements';
import { FLAG_DEBUG, FLAG_TEST } from '@nord-beaver/core/utils/debug';
import { type windowManager as windowManagerGlobal } from 'game/ui/services/windowManager';

export const initWindowManagerDebugElements = FLAG_DEBUG || FLAG_TEST
    ? (windowManager: typeof windowManagerGlobal) => {
        const items = SelectElement.convertItems(Object.keys(windowManager.view.states));

        const selectElement = debugPanelService?.addElement(new SelectElement({
            path: ['UI', 'Window Manager'],
            id: 'State',
            items,
        }));

        debugPanelService?.addElement(new ButtonElement({
            path: ['UI', 'Window Manager'],
            id: 'Set state',
            initValue: () => {
                if (!selectElement) {
                    return;
                }

                windowManager.setState(selectElement.value as keyof typeof windowManager.view.states);
            },
        }));

        const selectOpenElement = debugPanelService?.addElement(new SelectElement({
            path: ['UI', 'Window Manager'],
            id: 'Open',
            items: SelectElement.convertItems(Object.keys(windowManager.view.windows)),
        }));

        debugPanelService?.addElement(new ButtonElement({
            path: ['UI', 'Window Manager'],
            id: 'Open window',
            initValue: () => {
                if (!selectOpenElement) {
                    return;
                }

                windowManager.open(selectOpenElement.value as keyof typeof windowManager.view.windows);
            },
        }));
    }
    : null;