<script setup lang="ts">
import { useModel } from 'game/ui/composables/model';
import { type ContainerIds } from 'game/ui/config/containers';
import { DEPENDENCY_CONTAINER_INJECTION_KEY } from 'game/ui/constants/provide';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';
import { inject, onBeforeUnmount, onUnmounted, ref } from 'vue';

const props = defineProps<{ containerId: ContainerIds }>();

const isMouseInputTriggered = useModel(['core', 'isMouseInputTriggered']);
const di = inject<DependencyContainer>(DEPENDENCY_CONTAINER_INJECTION_KEY);

const container = ref<HTMLDivElement | null>(null);
let isDestroyed = false;

defineExpose({
    container,
});

const mouseenter = () => {
    if (isDestroyed) {
        return;
    }

    isMouseInputTriggered.value[props.containerId] = true;
};
const mouseleave = () => {
    isMouseInputTriggered.value[props.containerId] = false;
};

onBeforeUnmount(() => {
    isDestroyed = true;
    mouseleave();
});
onUnmounted(() => {
    if (!di) {
        return;
    }

    const coreModel = getModel(di, ['core']).data;
    coreModel.isMouseInputTriggered[props.containerId] = false; // TODO: remove after fix bug
});
</script>

<template>
    <div
        ref="container"
        :style="{ pointerEvents: 'initial' }"
        @focus="mouseenter"
        @blur="mouseleave"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
    >
        <slot />
    </div>
</template>