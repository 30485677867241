import { SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { api } from 'game/api/api';
import { type MatchDataNakamaComponent } from 'game/ecs/components/nakama/matchDataNakamaComponent';
import { type NakamaComponent } from 'game/ecs/components/nakama/nakamaComponent';
import { type SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';
import { MatchDataNakamaNode } from 'game/ecs/nodes/nakama/matchDataNakamaNode';
import { type MatchDataNakamaService } from 'game/services/nakama/matchData/matchDataNakamaService';
import { type SocketNakamaService } from 'game/services/nakama/socketNakamaService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEnumValues } from 'game/utils/enum';

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('MatchData');

export class MatchDataNakamaSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly socketNakamaService: SocketNakamaService,
        private readonly matchDataNakamaService: MatchDataNakamaService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MatchDataNakamaNode,
            add: this.addMatchData,
            remove: this.removeMatchData,
        });
    }

    private addMatchData = (node: MatchDataNakamaNode) => {
        const { matchData, matchMap, socket, entity, nakama } = node;
        const { matchDataQueue, matchDataHandlers } = matchData;
        const matchDataSocketSignal = this.socketNakamaService.getSocketEventSignal(socket.socket, 'onmatchdata');

        this.matchDataNakamaService.configLogger(getEnumValues(api.OpCodeRequest), getEnumValues(api.OpCodeResponse), nakama.config.getMatchDataCodeName);
        socket.processFrameSignal.on(() => this.processFrame(nakama, matchData, socket), entity);
        matchMap.matchLeaveSignal.on((matchId: string) => this.onMatchLeave(matchData, matchId), entity);
        matchDataSocketSignal.on(this.matchDataNakamaService.getMatchDataHandler(matchDataQueue, matchDataHandlers, nakama.config.getMatchDataCodeName), entity);

        logger.info('add match data handler', { entity });
    };

    private removeMatchData = (node: MatchDataNakamaNode) => {
        const { matchData, matchMap, socket, entity } = node;
        const matchDataSocketSignal = this.socketNakamaService.getSocketEventSignal(socket.socket, 'onmatchdata');

        matchDataSocketSignal.offAll(entity);
        socket.processFrameSignal.offAll(entity);
        matchMap.matchLeaveSignal.offAll(entity);

        matchData.matchDataQueue.clearQueues();

        logger.info('remove match data handler', { entity });
    };

    private onMatchLeave = (matchData: MatchDataNakamaComponent, matchId: string) => {
        matchData.matchDataQueue.clearQueuesForMatch(matchId);
    };

    private processFrame = (nakama: NakamaComponent, matchData: MatchDataNakamaComponent, socket: SocketNakamaComponent) => {
        const { matchDataQueue, matchDataHandlers } = matchData;

        this.matchDataNakamaService.flushAllSendQueues(matchDataQueue, matchDataHandlers, socket.socket, nakama.config.getMatchDataCodeName);
        matchDataQueue.clearQueues();
    };
}
