import { api } from 'game/api/api';

export const parseMatchLabel = (label: string) => {
    if (!label) {
        return;
    }

    const matchLabelObject = JSON.parse(label);

    if (api.MatchLabel.verify(matchLabelObject)) {
        return;
    }

    return api.MatchLabel.fromObject(JSON.parse(label));
};