import { type api } from 'game/api/api';

export interface RequestMetaDesc {
    dailyRewardData: api.DailyRewardData;
    vaultData?: api.VaultData;
    tasks: api.TaskData[];
    clientAccounts: api.ClientAccount[];
    clientRewardAccounts: api.ClientRewardAccount[];
    client: api.Client;
    inviteesData: api.RpcGetInviteesResponseData;
    clientState: api.RpcClientState;
    groups: api.RpcGroupCreateResponseDataGroup[];
    groupsMeta: api.RpcGroupListResponseMeta | null;
}

export class RequestMetaComponent {
}

export class MetaComponent {
    dailyRewardData: api.DailyRewardData;
    vaultData?: api.VaultData;
    tasks: api.TaskData[];
    clientAccounts: api.ClientAccount[];
    clientRewardAccounts: api.ClientRewardAccount[];
    client: api.Client;
    inviteesData: api.RpcGetInviteesResponseData;
    clientState: api.RpcClientState;
    groups: api.RpcGroupCreateResponseDataGroup[];
    groupsMeta: api.RpcGroupListResponseMeta | null;
    /**
     * Map of server user id to nakama user id
     */

    constructor({
        dailyRewardData,
        vaultData,
        tasks,
        clientAccounts,
        clientRewardAccounts,
        client,
        inviteesData,
        clientState,
        groups,
        groupsMeta,
    }: RequestMetaDesc) {
        this.dailyRewardData = dailyRewardData;
        if (vaultData) {
            this.vaultData = vaultData;
        }
        this.tasks = tasks;
        this.clientAccounts = clientAccounts;
        this.clientRewardAccounts = clientRewardAccounts;
        this.client = client;
        this.inviteesData = inviteesData;
        this.clientState = clientState;
        this.groups = groups;
        this.groupsMeta = groupsMeta;
    }

}