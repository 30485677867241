/* eslint-disable */
// This file is generated automatically by the endy plugin-template,
// do not change it manually

import { ChainAnimationsComponent } from '@nord-beaver/core/ecs/components/chainAnimationsComponent';
import { DiceComponent } from 'game/ecs/components/match/diceComponent';
import { GridTileComponent } from 'game/ecs/components/gridTilemap/gridTileComponent';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { InteractiveComponent } from '@nord-beaver/core/ecs/components/interactiveComponent';
import { MatchComponent } from 'game/ecs/components/match/matchComponent';
import { MotionDetectorComponent } from '@nord-beaver/core/ecs/components/motionDetectorComponent';
import { PlayerComponent } from 'game/ecs/components/gridTilemap/playerComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { SoundComponent } from '@nord-beaver/core/ecs/components/soundComponent';
import { SpineComponent } from '@nord-beaver/core/ecs/components/spineComponent';
import { SpriteComponent } from '@nord-beaver/core/ecs/components/spriteComponent';
import { TargetViewSizeComponent } from 'game/ecs/components/targetViewSizeComponent';
import { TilemapComponent } from '@nord-beaver/core/ecs/components/tilemapComponent';
import { TransformComponent } from '@nord-beaver/core/ecs/components/transformComponent';
import { VaultComponent } from 'game/ecs/components/meta/vaultComponent';

export type ComponentRegistrator = <
    ComponentDesc,
    Component extends object,
    ComponentDefaults extends object
>(
    componentId: string,
    componentCtor: null | (new (desc: ComponentDesc) => Component),
    defaults: null | ComponentDefaults
) => void;

export function registerComponents(registrator: ComponentRegistrator) {
    registrator('chainAnimations', ChainAnimationsComponent, {"animations":[]});
    registrator('dice', DiceComponent, {"faceLinks":[],"throwDelayS":0});
    registrator('gridTile', GridTileComponent, {});
    registrator('gridTilemap', GridTilemapComponent, {"rows":1,"columns":1,"levelTableLinks":[]});
    registrator('interactive', InteractiveComponent, {});
    registrator('match', MatchComponent, {"profitTickTimeS":1,"startTimeS":1,"startCoins":0});
    registrator('motionDetector', MotionDetectorComponent, {"forceMoving":false});
    registrator('player', PlayerComponent, {});
    registrator('replicant', ReplicantComponent, {"offset":{"x":0,"y":0}});
    registrator('sceneObject', SceneObjectComponent, {});
    registrator('sound', SoundComponent, {"sounds":[]});
    registrator('spine', SpineComponent, {"loop":false,"accurateBounds":false,"skins":[]});
    registrator('sprite', SpriteComponent, {"origin":{"x":0,"y":0}});
    registrator('targetViewSize', TargetViewSizeComponent, {});
    registrator('tilemap', TilemapComponent, {});
    registrator('transform', TransformComponent, {"position":{"x":0,"y":0},"scale":{"x":1,"y":1},"rotation":0});
    registrator('vault', VaultComponent, {});
}
