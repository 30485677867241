import { SystemService } from '@nord-beaver/core/ecs';
import { SpriteComponent } from '@nord-beaver/core/ecs/components/spriteComponent';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { isDefined } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { SoundAssetIds } from 'game/constants';
import { GridTileComponent } from 'game/ecs/components/gridTilemap/gridTileComponent';
import { GridTilemapComponent } from 'game/ecs/components/gridTilemap/gridTilemapComponent';
import { ReplicantComponent } from 'game/ecs/components/gridTilemap/replicantComponent';
import { type MatchComponent } from 'game/ecs/components/match/matchComponent';
import { GridTileCoreNode } from 'game/ecs/nodes/gridTilemap/gridTilemapNode';
import { MatchNode } from 'game/ecs/nodes/matchNode';
import { placeTileEntity } from 'game/ecs/systems/gridTilemap/utils';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { EnitityComponents } from 'game/types/entityComponents';
import { type ChanceEventDesc } from 'game/types/entityDescs/chanceEvent';
import { GridTileType } from 'game/types/entityDescs/gridTile';
import { type ApiMatchDataResponseSchema } from 'game/types/nakama/matchData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEntityComponentDesc } from 'game/utils/entityDesc';

const logger = mainLogger.getLogger('Tilemap').getLogger('Tile');

export class GridTilemapTileSystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly entityService: EntityService,
        private readonly nakamaService: NakamaService,
        private readonly eventService: EventService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: GridTileCoreNode,
            add: this.onTileCoreAdd,
        });

        this.setupNodeList({
            node: MatchNode,
            update: this.onMatchUpdate,
        });
    }

    private onTileCoreAdd(node: GridTileCoreNode) {
        const { gridTile, entity } = node;
        const parentEntity = entity.parent;
        if (!parentEntity) {
            logger.error('Tile entity without parent', entity);
            return;
        }

        const gridTilemap = parentEntity.get(GridTilemapComponent);
        if (!gridTilemap) {
            logger.error('Tile entity without GridTilemapComponent', parentEntity);
            return;
        }

        switch (gridTile.desc.data.type) {
            case GridTileType.Chance: {
                this.resolveChanceTileEntity(gridTile);
                break;
            }
        }

        placeTileEntity(entity, gridTile.index);
    }

    private onMatchUpdate(node: MatchNode) {
        if (!this.nakamaService.getMatch()) {
            return;
        }

        const { match } = node;

        const gridTilemapEntity = match.mapEntity;
        if (!gridTilemapEntity) {
            logger.error('Match entity without map entity', match);

            return;
        }

        const gridTilemap = gridTilemapEntity.get(GridTilemapComponent);
        if (!gridTilemap) {
            logger.error('Match entity without GridTilemapComponent', gridTilemapEntity);

            return;
        }

        for (const replicantEntityKey in gridTilemap.replicantEntities) {
            const replicantEntity = gridTilemap.replicantEntities[replicantEntityKey];
            if (!replicantEntity) {
                logger.error('Replicant entity not found', replicantEntityKey);

                continue;
            }

            const replicant = replicantEntity.get(ReplicantComponent);
            if (!replicant) {
                logger.error('Replicant entity without ReplicantComponent', replicantEntity);

                continue;
            }

            replicant.isTilesCountChanged = false;
        }

        for (const entity of gridTilemap.tileEntities) {
            const gridTile = entity.get(GridTileComponent);
            if (!gridTile) {
                logger.error('Tile entity without GridTileComponent', entity);

                continue;
            }

            this.onTileUpdate(match, gridTilemap, gridTile);
        }

        this.nakamaService.getReceivedMatchData({ opCode: api.OpCodeResponse.TILE_OWNER_UPDATE })
            .forEach(message => this.onTileOwnerUpdate(node, message));

        this.selectTileUpdate(gridTilemap);
    }

    private onTileUpdate(
        match: MatchComponent,
        gridTilemap: GridTilemapComponent,
        gridTile: GridTileComponent,
    ) {
        gridTile.isJustEnabledChanged = false;
        gridTile.isJustOwnerChanged = false;

        switch (match.state) {
            case api.PlayerMatchState.MOVE: {
                const isEnable = gridTilemap.moveTileIndexes
                    ? gridTilemap.moveTileIndexes.includes(gridTile.index)
                    : true;
                if (gridTile.isEnabled !== isEnable) {
                    gridTile.isEnabled = isEnable;
                    gridTile.isJustEnabledChanged = true;
                }

                break;
            }
            case api.PlayerMatchState.BANKRUPTY: {
                const isSellEnable = isDefined(gridTilemap.playerEntityRid)
                    ? gridTile.ownerEntity === gridTilemap.replicantEntities[gridTilemap.playerEntityRid]
                    : false;
                if (gridTile.isEnabled !== isSellEnable) {
                    gridTile.isEnabled = isSellEnable;
                    gridTile.isJustEnabledChanged = true;
                }

                break;
            }
            default: {
                const isEnable = true;
                if (gridTile.isEnabled !== isEnable) {
                    gridTile.isEnabled = isEnable;
                    gridTile.isJustEnabledChanged = true;
                }

                break;
            }
        }

    }

    private selectTileUpdate(gridTilemap: GridTilemapComponent) {
        const prevSelectedTile = gridTilemap.prevSelectedTileEntity;
        const selectedTile = gridTilemap.selectedTileEntity;

        if (prevSelectedTile) {
            const prevGridTile = prevSelectedTile.get(GridTileComponent);
            if (!prevGridTile) {
                logger.error('Selected tile without GridTileComponent', selectedTile);

                return;
            }

            if (!prevGridTile.isSelected) {
                return;
            }

            const sprite = prevSelectedTile.get(SpriteComponent);
            if (!sprite) {
                logger.error('Selected tile without TransformComponent', selectedTile);

                return;
            }

            sprite.primitive.view?.setScale(1);

            prevGridTile.isSelected = false;
        }

        if (!selectedTile) {
            return;
        }

        const gridTile = selectedTile.get(GridTileComponent);
        if (!gridTile) {
            logger.error('Selected tile without GridTileComponent', selectedTile);

            return;
        }

        if (gridTile.isSelected) {
            return;
        }

        const sprite = selectedTile.get(SpriteComponent);
        if (!sprite) {
            logger.error('Selected tile without TransformComponent', selectedTile);

            return;
        }

        sprite.primitive.view?.setScale(.8);

        gridTile.isSelected = true;

        this.eventService.audio.dispatch('play', { assetId: SoundAssetIds.TileTap });

        logger.info('Selected tile', gridTile.index);
    }

    private resolveChanceTileEntity(gridTile: GridTileComponent) {
        const chanceTileDesc = gridTile.desc.data;
        if (chanceTileDesc.type !== GridTileType.Chance) {
            return;
        }

        const chanceEventDescs: ChanceEventDesc[] = [];
        for (const link of chanceTileDesc.chanceEventLinks) {
            const chanceEvent = getEntityComponentDesc<ChanceEventDesc>(
                this.entityService,
                link,
                EnitityComponents.ChanceEvent,
            );
            if (!chanceEvent) {
                logger.error('Chance event entity without ChanceEventComponent', gridTile);
                continue;
            }

            chanceEventDescs.push(chanceEvent);
        }

        gridTile.linkDescs = { chanceEventDescs };
    }

    private onTileOwnerUpdate(node: MatchNode, message: ApiMatchDataResponseSchema[api.OpCodeResponse.TILE_OWNER_UPDATE]) {
        const { match } = node;
        const { rId, index } = message;

        const gridTilemap = match.mapEntity?.get(GridTilemapComponent);
        if (!gridTilemap) {
            logger.error('GridTilemap not found');

            return;
        }

        const tileEntity = gridTilemap.tileEntities[index];
        if (!tileEntity) {
            logger.error('Tile not found', index);

            return;
        }

        const tile = tileEntity.get(GridTileComponent);
        if (!tile) {
            logger.error('Tile without GridTileComponent', tileEntity);

            return;
        }

        if (!tile.desc.data.region) {
            logger.error('Tile without region', tileEntity);

            return;
        }

        const prevOwnerEntity = tile.ownerEntity;
        const prevOwnerReplicant = prevOwnerEntity?.get(ReplicantComponent);
        if (prevOwnerReplicant) {
            prevOwnerReplicant.tilesCount[tile.desc.data.region] -= 1;

            if (prevOwnerReplicant.tilesCount[tile.desc.data.region] < 0) {
                logger.error('Invalid tiles count', prevOwnerReplicant.tilesCount[tile.desc.data.region]);

                prevOwnerReplicant.tilesCount[tile.desc.data.region] = 0;
            }

            prevOwnerReplicant.isTilesCountChanged = true;

            logger.info('Tile owner update', { rId, region: tile.desc.data.region, count: prevOwnerReplicant.tilesCount[tile.desc.data.region] });
        }

        if (!isDefined(rId)) {
            tile.ownerEntity = null;
            tile.isJustOwnerChanged = true;

            return;
        }

        const replicantEntity = gridTilemap.replicantEntities[rId];
        if (!replicantEntity) {
            logger.error('Replicant not found', rId);

            return;
        }

        const replicant = replicantEntity.get(ReplicantComponent);
        if (!replicant) {
            logger.error('Replicant without ReplicantComponent', replicantEntity);

            return;
        }

        if (!replicant.tilesCount[tile.desc.data.region]) {
            replicant.tilesCount[tile.desc.data.region] = 0;
        }

        replicant.tilesCount[tile.desc.data.region] += 1;

        replicant.isTilesCountChanged = true;

        logger.info('Tile owner update', { rId, region: tile.desc.data.region, count: replicant.tilesCount[tile.desc.data.region] });

        tile.ownerEntity = replicantEntity;
        tile.isJustOwnerChanged = true;
    }
}