import { isDefined } from '@nord-beaver/core/utils/utils';
import { Currency } from 'game/types/entityDescs/currency';
import { useModel } from 'game/ui/composables/model';
import { CurrencyRates } from 'game/utils/resource';
import { defineStore } from 'pinia';

export const useResourceStore = defineStore('resources', () => {
    const currencies = useModel(['resources', 'currencies']);
    const decimals = useModel(['resources', 'decimals']);
    const rates = useModel(['resources', 'rates']);

    const getValue = (type: Currency, currency?: number) => {
        currency ??= currencies.value[type] ?? 0;
        currency /= Math.pow(10, decimals.value[type] ?? 0);

        return currency;
    };
    const convertCurrency = (from: Currency, to: Currency, value: number) => {
        if (from === Currency.Match || to === Currency.Match) {
            throw new Error('Match currency is not supported');
        }

        const hardToSoftRate = rates.value[CurrencyRates.HardToSoft];
        if (!isDefined(hardToSoftRate)) {
            throw new Error('Hard to soft rate is not defined');
        }

        if (from === to) {
            return value;
        }

        if (from === Currency.Hard && to === Currency.Soft) {
            return value / hardToSoftRate;
        }

        if (from === Currency.Soft && to === Currency.Hard) {
            return value * hardToSoftRate;
        }

        throw new Error('Unsupported currency conversion');
    };

    return {
        currencies,
        getValue,
        convertCurrency,
    };
});