import { Point, type PointLike } from '@nord-beaver/core/utils/point';

export class DragComponent {
    readonly cameraStartMoveThreshold = 150;

    mouseDownPosition = new Point();
    isDragging = false;
    isPressed = false;
    dragTime = 0;

    prepare(fromPointerPosition: Readonly<PointLike>) {
        Point.copy(fromPointerPosition, this.mouseDownPosition);
        this.isDragging = false;
        this.isPressed = true;
        this.dragTime = 0;
    }

    hasMoved() {
        return this.dragTime > 100;
    }

    startDrag(dt: number) {
        this.isDragging = true;
        this.dragTime += dt;
    }

    endDrag() {
        this.isDragging = false;
        this.isPressed = false;
    }
}