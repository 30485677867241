import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { api } from 'game/api/api';
import { VaultComponent } from 'game/ecs/components/meta/vaultComponent';
import { VaultNode } from 'game/ecs/nodes/meta/vaultNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { EnitityComponents } from 'game/types/entityComponents';
import { type VaultComponentDesc } from 'game/types/entityDescs/vault';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEntityComponentDesc } from 'game/utils/entityDesc';

const logger = mainLogger.getLogger('Meta').getLogger('VaultSystem');

export class VaultSystem extends SystemService {
    private vaultNodeList?: NodeList<VaultNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly eventService: EventService,
        private readonly nakamaService: NakamaService,
        private readonly entityService: EntityService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
        });
        this.vaultNodeList = this.setupNodeList({
            node: VaultNode,
            update: this.updateVault,
        });

        this.eventService.lobby.on('claimVaultReward', this.claimReward, this);
    }

    override destroy() {
        this.eventService.lobby.offAll('claimVaultReward', this);
    }

    private addMeta(node: MetaNode) {
        const { meta, entity } = node;

        if (!meta.vaultData) {
            logger.error('Vault data not found', { entity });

            return;
        }

        const desc = getEntityComponentDesc<VaultComponentDesc>(this.entityService, '/vault', EnitityComponents.Vault);
        if (!desc) {
            logger.error('Vault component desc not found', { entity });

            return;
        }

        const nextRewardUnix = Number(meta.vaultData.nextRewardUnix ?? 0) * 1000;

        entity.add(new VaultComponent(desc, meta.vaultData.available, nextRewardUnix));

        logger.info('Vault added', { entity });
    }

    private updateVault = (node: VaultNode, dt: number) => {
        const { vault } = node;

        if (vault.claimTimeElapsed >= (vault.desc.collectTimeS * 1000)) {
            this.resetTimer(vault, vault.desc.fillDurationS * 1000);

            return;
        }

        if (vault.timeElapsed >= vault.timerEndTime) {
            if (!vault.isAvailable) {
                vault.claimTimeElapsed = 0;
            }

            vault.isAvailable = true;
            vault.claimTimeElapsed += dt;
        } else {
            vault.timeElapsed += dt;
        }
    };

    private claimReward = async () => {
        if (!this.vaultNodeList) {
            logger.error('Vault node list not found');

            return;
        }

        const { vault } = this.vaultNodeList.get(0) ?? {};
        if (!vault) {
            logger.error('Vault not found');

            return;
        }

        vault.isFetching = true;

        const { result, nextRewardUnix } = await this.nakamaService.callRpc({
            rpcType: ApiRpc.collectVault,
            payload: {},
        });

        vault.isFetching = false;

        if (result !== api.CollectVaultResult.VAULT_SUCCESS) {
            logger.error('Failed to collect vault', { result });
        }

        this.resetTimer(vault, Number(nextRewardUnix) * 1000);
    };

    private resetTimer(vault: VaultComponent, timerEndTime: number) {
        vault.timerEndTime = timerEndTime;
        vault.timeElapsed = 0;
        vault.isAvailable = false;
        vault.claimTimeElapsed = 0;
    }
}