export function getEnumKeys<T extends object>(enumObj: T): (keyof T)[] {
    return Object.keys(enumObj).filter(key => isNaN(Number(key))) as (keyof T)[];
}


export function getEnumValues<T extends object>(enumObj: T): T[keyof T][] {
    return Object.values(enumObj).filter(value => typeof value === 'number') as T[keyof T][];
}

export function getStringEnumKeys<T extends object>(enumObj: T): (keyof T)[] {
    return Object.keys(enumObj).filter(key => typeof enumObj[key as keyof T] === 'string') as (keyof T)[];
}

export function getStringEnumValues<T extends object>(enumObj: T): T[keyof T][] {
    return Object.values(enumObj).filter(value => typeof value === 'string') as T[keyof T][];
}