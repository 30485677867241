import { type Logger, mainLogger } from '@nord-beaver/core/utils/logger';
import { retry } from '@nord-beaver/core/utils/utils';
import { ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { SocketNakamaComponent } from 'game/ecs/components/nakama/socketNakamaComponent';
import { type RpcHandlers } from 'game/services/nakama/rpc/rpcHandlers';
import { type RpcSchema, type RpcNakamaConfig, type RpcNakamaParams, RpcType, type SocketRpcParams, type ClientRpcParams } from 'game/services/nakama/rpc/rpcNakamaUtils';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

type Data = { [key: string]: unknown };

class NakamaConnectionError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NakamaConnectionError';
    }
}

const logger = mainLogger.getLogger('Nakama', '#2c92ff').getLogger('Rpc');

export class RpcNakamaService {
    private rpcRetryCount = 60;
    private rpcRetryTimeoutMs = 1000;

    constructor(
        _dependencyContainer: DependencyContainer,
    ) {
        logger.info('RpcNakamaService initialized');
    }

    config(options: RpcNakamaConfig) {
        if (options.rpcRetryCount !== undefined) {
            this.rpcRetryCount = options.rpcRetryCount;
        }
        if (options.rpcRetryTimeoutMs !== undefined) {
            this.rpcRetryTimeoutMs = options.rpcRetryTimeoutMs;
        }
    }

    configLogger<Method extends string>(methods: Method[]) {
        for (const method of methods) {
            logger.getLogger(method, true).getLogger('Request', true);
            logger.getLogger(method, true).getLogger('Response', true);
        }
    }

    async callRpc<Method extends string, RpcSchemaType extends RpcSchema<Method>, Type extends RpcType = RpcType>(
        rpcHandlers: RpcHandlers,
        type: Type,
        params: RpcNakamaParams<Type, Method, RpcSchemaType>,
    ): Promise<RpcSchemaType[Method]['response']> {
        const {
            rpcType,
            payload,
        } = params;
        const rpcRequestLogger = logger.getLogger(rpcType, true).getLogger('Request', true);
        const rpcResponseLogger = logger.getLogger(rpcType, true).getLogger('Response', true);

        let attempt = 0;

        try {
            const result = new Promise<RpcSchemaType[Method]['response']>((resolve, reject) => {
                retry({
                    work: async () => {
                        rpcRequestLogger.info(payload);

                        let callFunction: typeof this.callRpcClient | typeof this.callRpcSocket;
                        switch (type) {
                            case RpcType.Client:
                                callFunction = this.callRpcClient;
                                break;
                            case RpcType.Socket:
                                callFunction = this.callRpcSocket;
                                break;
                            default:
                                throw new Error(`Unknown RPC type ${type}`);
                        }

                        try {
                            const responseData = await callFunction(rpcHandlers, params, rpcResponseLogger);

                            rpcResponseLogger.info(responseData);
                            return responseData;
                        } catch (error) {
                            if (error instanceof NakamaConnectionError) {
                                reject(error);
                            }

                            rpcRequestLogger.warn(error);

                            throw error;
                        }
                    },
                    beforeRetry: () => rpcRequestLogger.warn(`Retrying RPC request ${rpcType}, attempt ${++attempt}`),
                    count: this.rpcRetryCount,
                    timeout: this.rpcRetryTimeoutMs,
                })
                    .then(resolve)
                    .catch(reject);
            });

            return result;
        } catch (error) {
            const message = error instanceof Error ? error.message : error;
            rpcRequestLogger.error('RPC call failed', { error: message, rpcType, payload });
            throw error;
        }
    }

    private callRpcClient = async <Method extends string, RpcSchemaType extends RpcSchema<Method>>(
        rpcHandlers: RpcHandlers,
        params: ClientRpcParams<Method, RpcSchemaType>,
        logger: Logger,
    ) => {
        const { nakamaEntity, rpcType, payload } = params;
        const session = nakamaEntity.get(SessionNakamaComponent)?.session;
        if (!session) {
            throw new NakamaConnectionError('No session available');
        }

        const client = nakamaEntity.get(ClientNakamaComponent)?.client;
        if (!client) {
            throw new NakamaConnectionError('No client available');
        }

        let payloadEncoded: Data;
        try {
            payloadEncoded = rpcHandlers.encode<RpcSchemaType[Method]['request']>(rpcType, payload);
        } catch (encodeError) {
            const message = encodeError instanceof Error ? encodeError.message : String(encodeError);
            throw new Error(`Failed to encode payload for RPC type ${rpcType}: ${message}`);
        }

        logger.info('Raw request', { rpcType, payloadEncoded });

        const response = await client.rpc(session, rpcType, payloadEncoded);

        logger.info('Raw response', { response });

        return response.payload as RpcSchemaType[Method]['response'];
    };

    private callRpcSocket = async <Method extends string, RpcSchemaType extends RpcSchema<Method>>(
        rpcHandlers: RpcHandlers,
        params: SocketRpcParams<Method, RpcSchemaType>,
        logger: Logger,
    ) => {
        const { nakamaEntity, rpcType, payload } = params;

        const socket = nakamaEntity.get(SocketNakamaComponent)?.socket;
        if (!socket) {
            throw new NakamaConnectionError('No socket available');
        }

        let payloadEncoded: Data;
        try {
            payloadEncoded = rpcHandlers.encode<RpcSchemaType[Method]['request']>(rpcType, payload);
        } catch (encodeError) {
            const message = encodeError instanceof Error ? encodeError.message : String(encodeError);
            throw new Error(`Failed to encode payload for RPC type ${rpcType}: ${message}`);
        }

        logger.info('Raw request', { rpcType, payloadEncoded });

        const response = await socket.rpc(rpcType, JSON.stringify(payloadEncoded), params.rpcHttpKey);

        logger.info('Raw response', { response });

        let responseData: RpcSchemaType[Method]['response'];
        if (response.payload !== undefined) {
            try {
                const parsedPayload = JSON.parse(response.payload);
                responseData = rpcHandlers.decode<RpcSchemaType[Method]['response']>(rpcType, parsedPayload);
            } catch (decodeError) {
                const message = decodeError instanceof Error ? decodeError.message : String(decodeError);
                throw new Error(`Failed to decode response payload for RPC type ${rpcType}: ${message}`);
            }
        } else {
            responseData = {} as RpcSchemaType[Method]['response'];
        }

        return responseData;
    };
}
