import { type SceneObject } from '@nord-beaver/core/primitives/scenePrimitive';
import { type Tween } from '@nord-beaver/core/services/tweenService';

export enum OverlayTag {
    Disabled = 'disabled',
    Replicant = 'replicant',
}

export class GridTileOverlayComponent {
    readonly fadeTweenTime = 200;

    viewTween: Tween<SceneObject> | null = null;
    isVisible = true;
    prevIsVisible = true;
    inited = false;

    constructor(readonly tag: OverlayTag, readonly defaultAlpha: number) {
    }
}