import { type Presence, type MatchPresenceEvent, type User } from '@heroiclabs/nakama-js';
import { Signal } from '@nord-beaver/core/utils/signal';

export interface MatchPresenceData {
    frameJoins: Presence[];
    frameLeaves: Presence[];
    presence: Presence[];
    userData: { [userId: string]: User };
    isDirty: boolean;
}

export class MatchPresenceNakamaComponent {
    readonly matchPresenceSignals: Map<string, Signal<MatchPresenceEvent>> = new Map();
    readonly matchPresenceMap: Map<string, MatchPresenceData> = new Map();
    readonly requestUserDatasQueueMap: Map<string, MatchPresenceData[]> = new Map();

    isRequestUserDatasInProgress = false;

    getMatchPresenceSignal(matchId: string) {
        let matchPresenceSignal = this.matchPresenceSignals.get(matchId);
        if (!matchPresenceSignal) {
            matchPresenceSignal = new Signal();
            this.matchPresenceSignals.set(matchId, matchPresenceSignal);
        }

        return matchPresenceSignal;
    }
}