import { onMounted, onUnmounted, ref, watchEffect } from 'vue';

export function useRAF(callback: (dt: number, elapsed: number) => void, fps = 60) {
    const interval = 1000 / fps;
    const isActive = ref(true);
    let requestId: number | null = null;
    let then = performance.now();
    let elapsed = 0;

    const animate = (now: number) => {
        if (!isActive.value) {
            return;
        }

        requestId = requestAnimationFrame(animate);
        const dt = now - then;

        if (dt < interval) {
            return;
        }

        then = now - (dt % interval);
        elapsed += dt;
        callback(dt, elapsed);
    };

    const start = () => {
        if (requestId !== null) {
            return;
        }

        then = performance.now();
        requestId = requestAnimationFrame(animate);
    };

    const stop = () => {
        if (requestId === null) {
            return;
        }

        cancelAnimationFrame(requestId);
        requestId = null;
        elapsed = 0;
    };

    onMounted(start);
    onUnmounted(stop);
    watchEffect(() => isActive.value ? start() : stop());

    return {
        isActive,
    };
}