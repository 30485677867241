<script setup lang="ts">
import { isDefined } from '@nord-beaver/core/utils/utils';
import { Currency } from 'game/types/entityDescs/currency';
import { useResourceStore } from 'game/ui/store/resource';
import { getAssetUrl } from 'game/ui/utils/assets';
import { formatNumber } from 'game/ui/utils/number';
import { computed } from 'vue';

const resourcesStore = useResourceStore();

const props = defineProps<{
    resourceType: Currency;
    count?: number;
}>();

const value = computed(() => isDefined(props.count) ? resourcesStore.getValue(props.resourceType, props.count) : undefined);

const resourceImageUrl: Readonly<{ [key in Currency]: string }> = {
    [Currency.Soft]: getAssetUrl('currencies/soft.webp'),
    [Currency.Hard]: getAssetUrl('currencies/hard.webp'),
    [Currency.Match]: getAssetUrl('currencies/match.webp'),
};
</script>

<template>
    <div
        class="resource"
    >
        <span v-if="value !== undefined">{{ formatNumber(value) }}</span>

        <img
            :src="resourceImageUrl[resourceType]"
            class="resource__icon"
            alt=""
        >
    </div>
</template>

<style scoped lang="scss">
.resource {
    $parent: &;

    display: flex;

    gap: size(17px);

    align-items: center;

    height: size(105px);

    span {
        font-family: $main-font-family;
        font-size: size(64px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: right;

        background: var(--yellow-gradient, linear-gradient(95deg, #DA8207 -0.22%, #DD9318 10.66%, #FFCB3C 26.65%, #FFCB3C 78.95%, #E39C1E 93%, #FBC63A 97.24%));
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__icon {
        flex: 0;

        width: 100%;
        height: 100%;

        object-fit: contain;
    }
}
</style>
