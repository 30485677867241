<script setup lang="ts">
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import { useRAF } from 'game/ui/composables/raf';
import { useSize } from 'game/ui/composables/size';
import { onMounted, ref, watch } from 'vue';

const props = withDefaults(
    defineProps<{
        urls: string[];
        type: 'icon' | 'image';
        fps: number;
        defaultWidth: number | undefined;
        defaultHeight: number | undefined;
        isActive?: boolean;
    }>(),
    {
        isActive: true,
    },

);
const emit = defineEmits(['animationend']);

const { currentScale, toPX } = useSize();

const currentIconIndex = ref(0);

const onAnimationEnd = () => {
    currentIconIndex.value = (currentIconIndex.value + 1) % props.urls.length;

    if (currentIconIndex.value === 0) {
        emit('animationend');
    }
};

const { isActive } = useRAF(onAnimationEnd, props.fps);

watch(
    () => props.isActive,
    () => {
        isActive.value = props.isActive;
    },
);

onMounted(() => {
    isActive.value = props.isActive;
});
</script>

<template>
    <div class="frame">
        <template v-for="(name, index) in urls" :key="name">
            <template v-if="type === 'image'">
                <img
                    v-show="index === currentIconIndex"
                    :src="name"
                    class="frame__icon"
                    :style="{
                        width: props.defaultWidth !== undefined ? toPX(props.defaultWidth * currentScale) : '100%',
                        height: props.defaultHeight !== undefined ? toPX(props.defaultHeight * currentScale) : '100%',
                        objectFit: 'cover',
                    }"
                >
            </template>
            <template v-else>
                <SvgIcon
                    v-show="index === currentIconIndex"
                    :name="name"
                    class="frame__icon"
                    :style="{
                        width: props.defaultWidth !== undefined ? toPX(props.defaultWidth * currentScale) : '100%',
                        height: props.defaultHeight !== undefined ? toPX(props.defaultHeight * currentScale) : '100%',
                    }"
                />
            </template>
        </template>
    </div>
</template>