import { SystemService } from '@nord-beaver/core/ecs';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { retry } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { MetaComponent, RequestMetaComponent, type RequestMetaDesc } from 'game/ecs/components/metaComponent';
import { RequestMetaNode } from 'game/ecs/nodes/metaNode';
import { CoreNakamaNode } from 'game/ecs/nodes/nakama/coreNakamaNode';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

const logger = mainLogger.getLogger('LobbySystem');

export class LobbySystem extends SystemService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private eventService: EventService,
        private nakamaService: NakamaService,
    ) {
        super();

        this.setupNodeList({
            node: CoreNakamaNode,
            add: this.addCoreNakama,
            remove: this.removeCoreNakama,
        });

        this.setupNodeList({
            node: RequestMetaNode,
            add: this.addRequestMeta,
        });
    }

    init() {
    }

    private addCoreNakama(node: CoreNakamaNode) {
        const { nakama, entity } = node;

        if (nakama.config.authConfig.type === 'custom') {
            entity?.add(new RequestMetaComponent());
        } else {
            this.eventService.lobby.dispatch('lobbyReady');
        }
    }

    private removeCoreNakama(_node: CoreNakamaNode) {
        this.eventService.lobby.dispatch('lobbyDestroyed');
    }

    private async addRequestMeta(node: RequestMetaNode) {
        const { entity } = node;
        logger.log('Add meta node', { node });

        const requestMetaDesc: Partial<RequestMetaDesc> = {};

        await Promise.allSettled([
            this.requestMeta('dailyRewardData', requestMetaDesc),
            this.requestMeta('tasks', requestMetaDesc),
            this.requestMeta('clientAccounts', requestMetaDesc),
            this.requestMeta('client', requestMetaDesc),
            this.requestMeta('inviteesData', requestMetaDesc),
            this.requestMeta('clientState', requestMetaDesc),
            this.requestMeta('groups', requestMetaDesc),
        ]);

        const metaDesc = await retry({
            work: () => this.retryRequestMeta(requestMetaDesc),
            beforeRetry: () => logger.log('Retry request meta', { requestMetaDesc }),
            count: 3,
            timeout: 1000,
        });

        if (!metaDesc) {
            logger.error('Meta values not found', { requestMetaDesc });

            alert('You have been disconnected from the server. Please refresh the page to reconnect.');

            return;
        }

        const meta = new MetaComponent(metaDesc);

        entity.add(meta);

        logger.log('Meta node added', { node, metaDesc });

        this.eventService.lobby.dispatch('lobbyReady');

        this.eventService.metaData.on('request', async metaKey => {
            await this.requestMeta(metaKey, meta);

            this.eventService.metaData.dispatch('response', metaKey);
        }, this);
    }

    private async requestMeta(metaKey: keyof RequestMetaDesc, metaDesc: Partial<RequestMetaDesc>) {
        switch (metaKey) {
            case 'dailyRewardData':
                return this.getUserStatus(metaDesc);
            case 'tasks':
                return this.getTasks(metaDesc);
            case 'clientAccounts':
                return this.getAccounts(metaDesc);
            case 'client':
                return this.getClient(metaDesc);
            case 'inviteesData':
                return this.getInvitees(metaDesc);
            case 'clientState':
                return this.getState(metaDesc);
            case 'groups':
                return this.getGroups(metaDesc);
        }
    }

    private async retryRequestMeta(metaDesc: Partial<RequestMetaDesc>) {
        const retryMetaKeys = Object.entries(metaDesc).filter(([_, value]) => !value).map(([key]) => key as keyof RequestMetaDesc);

        if (retryMetaKeys.length === 0) {
            return metaDesc as RequestMetaDesc;
        }

        await Promise.allSettled(
            retryMetaKeys.map(metaKey => this.requestMeta(metaKey, metaDesc)),
        );

        if (!Object.values(metaDesc).some(value => !value)) {
            throw new Error('Some meta values are not found');
        }

        return metaDesc as RequestMetaDesc;
    }

    private async getUserStatus(metaDesc: Partial<RequestMetaDesc>) {
        const userStatus = await this.nakamaService.callRpc({
            rpcType: ApiRpc.userStatus,
            payload: {},
        });

        const verifyResult = api.RpcUserStatusResponse.verify(userStatus);
        if (verifyResult) {
            logger.error('Daily reward data verification failed', { userStatus, verifyResult });

            return;
        }

        if (!userStatus.dailyRewardData) {
            logger.error('Daily reward data not found', { userStatus });

            return;
        }

        metaDesc.dailyRewardData = userStatus.dailyRewardData;

        if (userStatus.vaultData) {
            metaDesc.vaultData = userStatus.vaultData;
        } else {
            logger.error('Vault data not found', { userStatus });
        }
    }

    private async getTasks(metaDesc: Partial<RequestMetaDesc>) {
        const tasks = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getTasks,
            payload: {},
        });

        if (!tasks.tasks) {
            logger.error('Tasks not found', { tasks });

            return;
        }

        const verifyResult = api.RpcGetTasksResponse.verify(tasks);
        if (verifyResult) {
            logger.error('Tasks verification failed', { tasks, verifyResult });

            return;
        }

        metaDesc.tasks = tasks.tasks;
    }

    private async getAccounts(metaDesc: Partial<RequestMetaDesc>) {
        const accounts = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getAccounts,
            payload: {},
        });

        if (!accounts.data) {
            logger.error('Client accounts not found', { accounts });

            return;
        }

        const verifyResult = api.RpcGetAccountsResponse.verify(accounts);
        if (verifyResult) {
            logger.error('Client accounts verification failed', { accounts, verifyResult });

            return;
        }

        metaDesc.clientAccounts = accounts.data.client_accounts;
        metaDesc.clientRewardAccounts = accounts.data.client_reward_accounts;
    }

    private async getClient(metaDesc: Partial<RequestMetaDesc>) {
        const client = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getClient,
            payload: {},
        });

        if (!client.data?.client) {
            logger.error('Client not found', { client });

            return;
        }

        const verifyResult = api.Client.verify(client.data.client);
        if (verifyResult) {
            logger.error('Client verification failed', { client, verifyResult });

            return;
        }

        metaDesc.client = client.data.client;
    }

    private async getInvitees(metaDesc: Partial<RequestMetaDesc>) {
        const invitees = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getInvitees,
            payload: {},
        });

        if (!invitees.data) {
            logger.error('Invitees not found', { invitees });

            return;
        }

        const verifyResult = api.RpcGetInviteesResponseData.verify(invitees.data);
        if (verifyResult) {
            logger.error('Invitees verification failed', { invitees, verifyResult });

            return;
        }

        metaDesc.inviteesData = invitees.data;
    }

    private async getState(metaDesc: Partial<RequestMetaDesc>) {
        const state = await this.nakamaService.callRpc({
            rpcType: ApiRpc.getState,
            payload: {},
        });

        if (!state.data?.client_state) {
            logger.error('Client state not found', { state });

            return;
        }

        const verifyResult = api.RpcClientState.verify(state.data.client_state);
        if (verifyResult) {
            logger.error('Client state verification failed', { state, verifyResult });

            return;
        }

        metaDesc.clientState = state.data.client_state;
    }

    private async getGroups(metaDesc: Partial<RequestMetaDesc>) {
        const groups = await this.nakamaService.callRpc({
            rpcType: ApiRpc.groupList,
            payload: {},
        });

        if (api.RpcGroupListResponseData.verify(groups)) {
            logger.error('Groups verification failed', { groups });

            return;
        }

        metaDesc.groups = groups.data?.groups ?? [];
        metaDesc.groupsMeta = groups.data?.meta ?? null;
    }
}