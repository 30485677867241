<script setup lang="ts">
import { links } from 'game/constants';
import { type Task } from 'game/types/model/tasksModel';
import PanelTopImage from 'game/ui/commonInstances/PanelTopImage.vue';
import TaskItem from 'game/ui/components/lobby/tasks/TaskItem.vue';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { windowManager } from 'game/ui/services/windowManager';
import { getAssetUrl } from 'game/ui/utils/assets';

const tasks = useModel(['tasks', 'tasks']);
const eventService = useEventService();

const selectTask = (task: Task) => {
    if (task.claimed) {
        return;
    }

    if (!task.completed) {
        switch (task.key) {
            case 'phone':
                windowManager.setState('friends');
                break;
            case 'subscribe-x':
                window.open(links.x, '_blank');
                break;
            case 'subscribe-yt':
                window.open(links.youtube, '_blank');
                break;
            case 'subscribe-tg':
                eventService.lobby.dispatch('openUrlViaTelegram', links.tg);
                break;
        }

        eventService.lobby.dispatch('completeTaskReward', task.index);

        return;
    }

    if (task.completed) {
        eventService.lobby.dispatch('claimTaskReward', task.index);

        return;
    }
};
</script>

<template>
    <LobbyLayout :container-id="ContainerIds.Tasks" class="tasks-container">
        <h1 class="tasks-container__title section-title">
            Tasks
        </h1>

        <div class="tasks-container__inner">
            <PanelTopImage :image-url="getAssetUrl('lobby/tasks.webp')">
                <section class="tasks tasks--all">
                    <div class="tasks__inner">
                        <TaskItem
                            v-for="(task, index) in tasks"
                            :key="`task-${index}`"
                            class="tasks__tasks-item"
                            :task="task"
                            @select-task="selectTask"
                        />
                    </div>
                </section>
            </PanelTopImage>
        </div>
    </LobbyLayout>
</template>

<style scoped lang="scss">
.tasks-container {
    &__inner {
        position: relative;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
    }
}

.tasks {
    $parent: &;

    position: relative;

    &__close-button {
        position: absolute;
        top: 0;
        right: 0;

        width: size(146px);
        height: size(146px);
        padding: size(50px);

        color: var(--grey-50, #D9D9D9);

        &:active {
            color: var(--grey-100, #939393);
        }
    }

    &__start-button {
        margin-top: size(72px);
    }

    &__close-icon {
        width: 100%;
        height: 100%;
    }

    &__tasks-item {
        height: size(147px);
        padding: 0 size(30px);
    }

    &--all {
        margin-top: size(-20px);

        #{$parent}__inner {
            display: flex;

            flex-direction: column;

            gap: size(33px);
        }
    }

    &--inner {
        #{$parent}__inner {
            padding-top: size(84px);
        }
    }

    &:not(:only-child) {
        margin-bottom: size(50px);
    }
}
</style>
