export enum ContainerIds {
    TooltipEmpty = 'TooltipEmpty',
    Matchmaking = 'Matchmaking',
    Loading = 'Loading',
    NavigationMenu = 'NavigationMenu',
    Tasks = 'Tasks',
    Clans = 'Clans',
    Friends = 'Friends',
    DailyReward = 'DailyReward',
    EndMatch = 'EndMatch',
    MatchRooms = 'MatchRooms',
    BankruptcyPopup = 'BankruptcyPopup',
    ChancePopup = 'ChancePopup',
    BonusPopup = 'BonusPopup',
    MatchCancelPopup = 'MatchCancelPopup',
    Vault = 'Vault',
    Tutorial = 'Tutorial',
    WelcomeReward = 'WelcomeReward',
}
