<script setup lang="ts">
import Button from 'game/ui/common/Buttons/Button.vue';
import SvgIcon from 'game/ui/common/SvgIcon.vue';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import PanelTopImage from 'game/ui/commonInstances/PanelTopImage.vue';
import { getAssetUrl } from 'game/ui/utils/assets';
import { ref, onUnmounted } from 'vue';

const emit = defineEmits<{
    close: [void];
    copyLink: [void];
    inviteFriend: [void];
}>();

const isCopyDescriptionVisible = ref(false);
let copyTimeout: ReturnType<typeof setTimeout> | null = null;

const onCopyLink = () => {
    emit('copyLink');
    isCopyDescriptionVisible.value = true;

    if (copyTimeout) {
        clearTimeout(copyTimeout);
    }

    copyTimeout = setTimeout(() => {
        isCopyDescriptionVisible.value = false;
    }, 3000);
};

const onInviteFriend = () => {
    emit('inviteFriend');
};

const onClose = () => {
    emit('close');
};

onUnmounted(() => {
    if (copyTimeout) {
        clearTimeout(copyTimeout);
    }
});
</script>

<template>
    <div class="invite-friend">
        <h1 class="invite-friend__title section-title">
            Invite a friend
        </h1>

        <PanelTopImage :image-url="getAssetUrl('lobby/invite-a-friend.webp')">
            <Button class="close-button" @click="onClose">
                <SvgIcon
                    class="close-button__icon"
                    name="cross"
                    pack="lobby"
                />
            </Button>
            <section class="invite-friend__top">
                <div class="invite-friend__inner">
                    <div class="invite-friend__buttons">
                        <MainButton
                            class="invite-friend__button"
                            label="Invite via telegram"
                            icon-name="telegram_logo"
                            icon-pack="lobby"
                            @click="onInviteFriend"
                        />

                        <MainButton
                            class="invite-friend__button"
                            label="copy a link"
                            icon-name="link"
                            icon-pack="lobby"
                            @click="onCopyLink"
                        />
                    </div>
                </div>
            </section>
        </PanelTopImage>

        <Transition name="fade">
            <div v-show="isCopyDescriptionVisible" class="invite-friend__bottom">
                <Panel>
                    <div class="invite-friend__bottom__text">
                        <p class="invite-friend__description">
                            It’s all good! The link has been copied
                        </p>
                    </div>
                </Panel>
            </div>
        </Transition>
    </div>
</template>

<style scoped lang="scss">
.invite-friend {
    &__top {
        position: relative;
    }

    &__inner {
        position: relative;

        display: flex;

        flex-direction: column;

        align-items: center;
        justify-content: center;
    }

    &__buttons {
        display: flex;

        flex-direction: column;

        gap: size(30px);

        align-items: center;
        justify-content: center;
    }

    &__button {
        width: size(1015px);
        height: size(154.119px);
        margin-bottom: size(18px);
    }

    &__bottom {
        width: 100%;
        height: size(239px);

        &__text {
            display: flex;

            justify-content: center;

            width: 100%;
        }
    }

    &__description {
        width: size(662px);

        font-family: $main-font-family;
        font-size: size(48px);
        font-style: normal;
        font-weight: 400;
        line-height: size(60.96px);
        color: var(--white, #FFF);
        text-align: center;
    }
}
</style>
