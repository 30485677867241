import { type api } from 'game/api/api';
import { type RpcSchema } from 'game/services/nakama/rpc/rpcNakamaUtils';

type EmptyObject = Record<string, never>;

export enum ApiRpc {
    rewards = 'daily_rewards',
    findMatch = 'find_match',
    getCarrencies = 'get_carrencies',
    getBuildings = 'get_buildings',
    upgradeBuilding = 'upgrade_building',
    createMatch = 'create_match',
    compareHashes = 'compare_hashes',
    getTasks = 'get_tasks',
    collectTaskReward = 'collect_task_reward',
    completeTask = 'complete_task',
    getState = 'get_state',
    getAccounts = 'get_accounts',
    getInvitees = 'get_invitees',
    getClient = 'get_client',
    groupCreate = 'group_create',
    groupAddClient = 'group_add_client',
    groupUpdate = 'group_update',
    groupGet = 'group_get',
    groupDelete = 'group_delete',
    groupList = 'group_list',
    groupListClients = 'group_list_clients',
    groupExclude = 'group_exclude',
    userStatus = 'user_status',
    customIdsToUserIds = 'custom_ids_to_user_ids',
    initBet = 'init_bet',
    addBet = 'add_bet',
    collectVault = 'collect_vault',
}

export interface ApiRpcSchema extends RpcSchema<ApiRpc> {
    [ApiRpc.createMatch]: {
        'response': api.RpcCreateMatchResponse;
        'request': api.IRpcCreateMatchRequest;
    };
    [ApiRpc.compareHashes]: {
        'response': api.RpcCompareHashesResponce;
        'request': EmptyObject;
    };
    [ApiRpc.findMatch]: {
        'response': api.RpcFindMatchResponse;
        'request': EmptyObject;
    };
    [ApiRpc.userStatus]: {
        'response': api.RpcUserStatusResponse;
        'request': EmptyObject;
    };
    [ApiRpc.rewards]: {
        'response': api.RpcGetDailyRewardResponce;
        'request': EmptyObject;
    };
    [ApiRpc.getTasks]: {
        'response': api.RpcGetTasksResponse;
        'request': EmptyObject;
    };
    [ApiRpc.collectTaskReward]: {
        'response': api.RpcCollectTaskRewardResponce;
        'request': api.IRpcGetTaskRewardRequest;
    };
    [ApiRpc.completeTask]: {
        'response': api.RpcCompleteTaskResponce;
        'request': api.IRpcCompleteTaskRequestDebug;
    };
    [ApiRpc.getAccounts]: {
        'response': api.RpcGetAccountsResponse;
        'request': EmptyObject;
    };
    [ApiRpc.getClient]: {
        'response': api.RpcRpcGetClientResponse;
        'request': EmptyObject;
    };
    [ApiRpc.getInvitees]: {
        'response': api.RpcGetInviteesResponse;
        'request': api.IRpcGetInviteesRequest;
    };
    [ApiRpc.getState]: {
        'response': api.RpcGetStateResponse;
        'request': EmptyObject;
    };
    [ApiRpc.groupCreate]: {
        'response': api.RpcGroupCreateResponse;
        'request': api.IRpcGroupCreateRequest;
    };
    [ApiRpc.groupAddClient]: {
        'response': api.RpcGroupAddClientResponse;
        'request': api.IRpcGroupAddClientRequest;
    };
    [ApiRpc.groupUpdate]: {
        'response': api.RpcGroupUpdateResponse;
        'request': api.IRpcGroupUpdateRequest;
    };
    [ApiRpc.groupGet]: {
        'response': api.RpcGroupGetResponse;
        'request': api.RpcGroupGetRequest;
    };
    [ApiRpc.groupDelete]: {
        'response': api.RpcGroupDeleteResponse;
        'request': api.IRpcGroupDeleteRequest;
    };
    [ApiRpc.groupList]: {
        'response': api.RpcGroupListResponse;
        'request': api.IRpcGroupListRequest;
    };
    [ApiRpc.groupListClients]: {
        'response': api.RpcGroupListClientsResponse;
        'request': api.IRpcGroupListClientsRequest;
    };
    [ApiRpc.groupExclude]: {
        'response': api.RpcGroupExcludeResponse;
        'request': api.IRpcGroupExcludeRequest;
    };
    [ApiRpc.customIdsToUserIds]: {
        'response': api.RpcCustomIdsToUserIdsResponse;
        'request': api.IRpcCustomIdsToUserIdsRequest;
    };
    [ApiRpc.initBet]: {
        'response': api.RpcInitBetResponse;
        'request': api.IRpcInitBetRequest;
    };
    [ApiRpc.addBet]: {
        'response': api.RpcAddBetResponse;
        'request': api.IRpcAddBetRequest;
    };
    [ApiRpc.collectVault]: {
        'response': api.RpcCollectVaultResponce;
        'request': EmptyObject;
    };
}