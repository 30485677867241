import { type NotificationSchema } from 'game/services/nakama/notification/notificationNakamaService';

export enum ApiNotificationCode {
    Reserved = 0,
    MessageReceivedOffline = -1,
    FriendRequestReceived = -2,
    FriendRequestAccepted = -3,
    GroupAcceptance = -4,
    GroupJoinRequest = -5,
    FriendJoinedGame = -6,
    SingleSocket = -7,
    Banned = -8,
}

export interface ApiNotificationSchema extends NotificationSchema<ApiNotificationCode> {
    [ApiNotificationCode.Reserved]: undefined;
    [ApiNotificationCode.MessageReceivedOffline]: undefined;
    [ApiNotificationCode.FriendRequestReceived]: undefined;
    [ApiNotificationCode.FriendRequestAccepted]: undefined;
    [ApiNotificationCode.GroupAcceptance]: undefined;
    [ApiNotificationCode.GroupJoinRequest]: undefined;
    [ApiNotificationCode.FriendJoinedGame]: undefined;
    [ApiNotificationCode.SingleSocket]: undefined;
    [ApiNotificationCode.Banned]: undefined;
}