<script setup lang="ts">
const props = defineProps<{
    imageUrl: string;
    title?: string;
    height?: string;
    align?: 'center' | 'left' | 'right';
}>();

const overridedHeight = typeof props.height === 'string' && props.height.length > 3 ? props.height : 'auto';
const bgImageUrl = `url(${props.imageUrl})`;
const overrideAlign = typeof props.align === 'string' && props.align.length > 3 ? props.align : 'left';
</script>

<template>
    <section class="panel-right-image panel-dotted">
        <div class="panel-right-image__inner">
            <div class="panel-right-image__inner__content">
                <h1
                    v-if="title"
                    class="section-title panel-right-image__inner__title"
                >
                    {{ title }}
                </h1>
                <slot />
            </div>
            <div class="panel-right-image__inner__image" />
        </div>
    </section>
</template>

<style scoped lang="scss">
.panel-right-image {
    position: relative;

    margin-bottom: size(42px);
    overflow: hidden;

    &__inner {
        display: flex;

        justify-content: space-between;

        height: size(v-bind(overridedHeight));

        &__title {
            margin: 0;

            text-align: v-bind(overrideAlign);
        }

        &__content {
            width: 100%;
            padding: size(30px) size(32px);

            text-align: v-bind(overrideAlign);
        }

        &__image {
            min-width: size(436px);
            min-height: size(v-bind(height));

            background-image: v-bind(bgImageUrl);
            background-size: cover;
        }
    }
}
</style>